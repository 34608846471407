import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { MDBDataTable, MDBBtn, MDBIcon, MDBInput } from 'mdbreact';
// import './style.scss';
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import { CSVLink } from "react-csv";
import { getNotSubmittedAssignmentDetailAction, getSubmittedAssignmentDetailAction } from '../../../../actions/institute/Teacherdashboard/ManageAssignment/ManageAssignmentAction';

class AssignmentDetail extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
			tableBody : { columns :[], rows: []},

            studentsList:[],
            type:'submitted',

            assignment:{},
			status:'',

			exportCsv:null,
			editId: this.props.editId,
			title:this.props.title
		};
	}

	componentWillReceiveProps(nextProps){
		if(this.state.type === 'submitted'){
			if(this.state.studentsList !== nextProps.assignment.submittedExamList){
				this.setState({studentsList: nextProps.assignment.submittedExamList},()=>{
					this.constructTableData()
					this.exportSubmittedCsv()
				})
			}
		}
		else{
			if(this.state.studentsList !== nextProps.assignment.notSubmittedExamList){
				this.setState({studentsList: nextProps.assignment.notSubmittedExamList},()=>{
					this.constructTableData()
					this.exportNotSubmittedCsv()
				})
			}
		}
	}

	exportSubmittedCsv =()=> {
		const exportCsvHeaders = [
			{ label: "STUDENT NAME", key: "student_name" },
			{ label: "SUBMITTED DATE", key: "percentage" },	
			{ label: "STATUS", key: "status" },	
			{ label: "TOTAL NUMBER", key: "obtained_marks" },	
			{ label: "Minimun Passing Number", key: "pass_mark" },	
			{ label: "PERCENTAGE", key: "percentage" }	
		];
		const exportCSVData = this.state.studentsList.map((item)=>{
			let obj = {
				student_name: item.student_name  ? item.student_name  : '-',
				status: item.status  ? item.status  : '-',
				percentage: item.mark_evaluation && item.mark_evaluation[0] ? item.mark_evaluation[0].percentage : '-',
				obtained_marks: item.mark_evaluation && item.mark_evaluation[0] ? item.mark_evaluation[0].obtained_marks : '-',
				pass_mark: item.mark_evaluation && item.mark_evaluation[0] ? item.mark_evaluation[0].pass_mark : '-',
				submit_date: item.submit_date ? moment(item.submit_date).format('YYYY-MM-DD') : '-',
			}
			return obj
		})
		let csv = {
			data: exportCSVData,
			headers: exportCsvHeaders,
			filename: 'Student.csv',
			style: {color:'white'}
		};
		this.setState({
			exportCsv: csv
		})
	}
	exportNotSubmittedCsv =()=> {
		const exportCsvHeaders = [
			{ label: "STUDENT NAME", key: "student_name" },	
		];
		const exportCSVData = this.state.studentsList.map((item)=>{
			let obj = {
				student_name: item.name  ? item.name  : '-',
			}
			return obj
		})
		let csv = {
			data: exportCSVData,
			headers: exportCsvHeaders,
			filename: 'Student.csv',
			style: {color:'white'}
		};
		this.setState({
			exportCsv: csv
		})
	}

	componentDidMount(){
		this.props.getSubmittedAssignmentDetailAction(this.state.editId)
	}
    changeTab=(value)=>{
		if(value === 'submitted'){
			this.props.getSubmittedAssignmentDetailAction(this.state.editId)
		}
		else if(value === 'notSubmitted'){
			this.props.getNotSubmittedAssignmentDetailAction(this.state.editId)
		}
		else{
			this.props.getNotSubmittedAssignmentDetailAction(this.state.editId)
		}
        this.setState({type:value})
    }
	handleChange=(e)=>{
		this.setState({
			status:e.target.value
		})
	}
	handleSearch=()=>{
		let arr = this.state.studentsList.filter(item => item.status == this.state.status)
		this.setState({studentsList:arr},()=>{
			this.constructTableData()
		})
	}
	reset=()=>{
		this.setState({status:''})
		this.props.getSubmittedAssignmentDetailAction(this.state.editId)
	}
	constructTableData =()=>{
        let arr = [
            {
                label: '#',
                field: 'id',
            },
            {
                label: 'Student Name',
                field: 'student_name',
                width: 150,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                },
            },
        ]
        if(this.state.type === 'submitted'){
            arr.push({
                label: 'Submitted Date',
                field: 'submit_date',
                width: 200,
            },
            {
                label: 'Status',
                field: 'status',
                width: 270,
            },
            {
                label: 'Result',
                field: 'result',
                width: 270,
            },
			{
                label: 'Percentage',
                field: 'percentage',
                width: 270,
            },
            {
                label: 'Action',
                field: 'action',
                width: 300,
            })
        }
        if(this.state.type === 'expired'){
            arr.push(
                {
                    label: 'Action',
                    field: 'actionCheckbox',
                    width: 300,
                }
            )
        }
		let tableData = {
			columns: arr,
			rows: 
				this.state.studentsList.map((item,i)=>{
					let obj = {
						id: i+1,
						student_name: item.student_name || item.name ? item.student_name || item.name : '-',
						percentage: item.mark_evaluation && item.mark_evaluation[0] ? item.mark_evaluation[0].percentage : '-',
						submit_date: item.submit_date ? moment(item.submit_date).format('YYYY-MM-DD') : '-',
						result:<>
						<div>Total Number:{item.mark_evaluation && item.mark_evaluation[0] ? item.mark_evaluation[0].obtained_marks : '-'}</div>
						<div>Minimun Passing Number:{item.mark_evaluation && item.mark_evaluation[0] ? item.mark_evaluation[0].pass_mark : '-'} </div>
						</>,
						status: item.status === 'Evaluated' ? (<div style={{color:'green'}}>Evaluated</div>) : <div style={{color:'red'}}>Pending</div>,
						action: 
						<div>
							{
								item.status === 'Evaluated' ? 
								<Link  icon="eye" size="lg" style={{margin:'5px'}} to={`/teacher/view-evaluated-answers/${this.state.editId}/${item.id}`}>View Submitted Answer</Link> :
								<Link  icon="eye" size="lg" style={{margin:'5px'}} to={`/teacher/view-submitted-answers/${this.state.editId}/${item.id}`}>View Submitted Answer</Link>
							}
						</div>,
						actionCheckbox: 
						<div class="form-check">
							<input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
							<label class="form-check-label" for="flexCheckDefault">
								Allow to submit Assignment
							</label>
					  </div>
					}
					return obj
				})
		}	
		this.setState({
			tableBody: tableData
		})	
	}

	render() {

		const { activeTab } = this.state
		// if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body" style={{width:"100%"}}>
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">{this.state.title}</h1>
							</div>
						</div>
                        <TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="">
									<div className="card-body">
                                        <div className={`row`}>
											<div className="card-body cardBodyContainer">
												<div className={`row btnContainer`}>
													<div className="col-lg-5 col-md-4 col-sm-6">
													    <MDBBtn color="primary" className='btn' onClick={()=>{this.changeTab('submitted')}} >Submitted</MDBBtn> &nbsp;
														{/* <MDBBtn color="primary" className='btn' onClick={()=>{this.changeTab('expired') }}>Expired</MDBBtn>&nbsp; */}
														<MDBBtn color="primary" className='btn' onClick={()=>{this.changeTab('notSubmitted')}}>Not Submitted</MDBBtn>&nbsp;
                                                    </div>
													{
														this.state.type === 'submitted' ?
															<div className="col-lg-3 col-md-4 col-sm-6">
																<select className="form-control show-tick" onChange={ this.handleChange } name="status" value={this.state.status}>
																	<option value="">Select Status</option>
																	<option value="Evaluated">Evaluated</option>
																	<option value="Pending">Pending</option>
																</select>
															</div> : 
														''}
                                                    <div className="col-lg-4 col-md-4 col-sm-6">
													    <MDBBtn color="primary" className='btn' onClick={ this.handleSearch }>Search</MDBBtn>&nbsp;
													    <MDBBtn color="primary" className='btn' onClick={this.reset}>Reset</MDBBtn>&nbsp;
													    <MDBBtn color="primary" className='btn' >
														{this.state.exportCsv && 
															<CSVLink {...this.state.exportCsv}>CSV<MDBIcon  icon="download" size="lg" className='icon' /></CSVLink>
														}
														</MDBBtn>
                                                    </div>
												</div>
											</div>
                                        </div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<MDBDataTable
												noBottomColumns
												className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
												entriesOptions={[5, 10, 20, 25]}
												entries={5}
												paging
												pagesAmount={15}
												data={this.state.tableBody}
												searchTop
												sortRows={['title']}
												searchBottom={false}
												onPageChange={{ activePage: 2, pagesAmount: 5 }}
												disableRetreatAfterSorting={true}
												searching = {false}
											/>
										</div>
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	assignment: state.ManageAssignmentReducer,
})

const mapDispatchToProps = dispatch => ({
	getNotSubmittedAssignmentDetailAction: (e) => dispatch(getNotSubmittedAssignmentDetailAction(e)),
	getSubmittedAssignmentDetailAction: (e) => dispatch(getSubmittedAssignmentDetailAction(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(AssignmentDetail)
