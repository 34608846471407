import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { isLogined } from "../../../utils/token";
import Loader from "../../common/Loader";
import "./style.scss";
import moment from "moment";
import {
  clearGetKnowledgeBaseAction,
  deleteKnowledgeBaseAction,
  getCategoryAction,
  getKnowledgeBaseAction,
  getKnowledgeBaseByIdAction,
} from "../../../actions/Admin/ManageKnowledgeBase/manageKnowledgeBaseAction";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  uploadPDFAction,
  ShowPDFListAction,
  deleteCurriculumAction,
} from "../../../actions/Admin/ManageCurriculum/ManageCurriculumPDFAction";

class ManageCurriculumPDF extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.state = {
      activeTab: 1,
      show: false,
      knowledgeBases: [],
      tableBody: { columns: [], rows: [] },
      modal: false,
      selectedKnowledgeBase: null,
      deleteModal: false,
      deleteStatus: null,
      deleteId: "",
      categories: [],
      title: "",
      subTitle: "",
      category: "",
      exportCsv: null,
      selectedFile: [],
      fileName: "",
      uploadPDF: null,
      deletePdflist: null,
      getPdfList: [],
      entries: 10, // Initial number of entries per page
      activePage: 1,
      totalCount: 0,
      loading: false,
      isloading: false,
      isButtonDisabled: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.getPdfList && nextProps.getPdfList.list) {
      if (
        !this.state.getPdfList.list ||
        this.state.getPdfList.list !== nextProps.getPdfList.list
      ) {
        const { list, totalCount } = nextProps.getPdfList;

        this.setState({
          getPdfList: list,
          totalCount: totalCount,
        });

        setTimeout(() => {
          this.constructTableData();
        }, 500);
      }
    }

    if (this.state.uploadPDF !== nextProps.uploadPDF.status) {
      this.setState({
        uploadPDF: nextProps.uploadPDF.status,
      });
    }

    if (this.state.deletePdflist !== nextProps.deletePdflist.status) {
      this.setState({
        deletePdflist: nextProps.deletePdflist.status,
      });
    }

    if (this.state.deleteStatus !== nextProps.deleteStatus.status) {
      this.props.getKnowledgeBaseAction();
      this.setState(
        {
          deleteStatus: nextProps.deleteStatus.status,
          deleteModal: false,
        },
        () => {
          this.props.getCategoryAction();
        }
      );
    }

    if (this.state.knowledgeBases !== nextProps.knowledgeBases.list) {
      this.setState(
        {
          knowledgeBases: nextProps.knowledgeBases.list,
        },
        () => {
          // this.constructTableData();
          this.exportCsv();
        }
      );
    }
    if (this.state.categories !== nextProps.categories.list) {
      this.setState({
        categories: nextProps.categories.list,
      });
    }
  }

  componentDidMount() {
    this.props.getCategoryAction();
    this.props.getKnowledgeBaseAction();
    const listLimit = {
      page: this.state.activePage,
      limit: 10,
    };
    this.props.ShowPDFListAction(listLimit);
  }

  componentDidUpdate(prevProp, prevState) {}

  componentWillUnmount() {
    this.props.clearGetKnowledgeBaseAction();
  }

  toggle = (value) => {
    this.setState({
      modal: true,
      selectedKnowledgeBase: value,
    });
  };

  toggleClose = () => {
    this.setState({
      modal: false,
      selectedKnowledgeBase: null,
      deleteModal: false,
    });
  };

  openDeleteModal = (value) => {
    this.setState({
      deleteModal: true,
      deleteId: value.id,
    });
  };

  // deletePDF = () => {
  //   this.props.deleteCurriculumAction(this.state.deleteId);
  //   this.setState({
  //     deleteModal: false,

  //   });
  //   setTimeout(() => {
  //     const listLimit = {
  //       page: this.state.activePage,
  //       limit: 10,
  //     };
  //     this.props.ShowPDFListAction(listLimit);
  //   }, 500);
  // };
  deletePDF = async () => {
    await this.props.deleteCurriculumAction(this.state.deleteId);
    this.setState({
      deleteModal: false,
    });

    const listLimit = {
      page: this.state.activePage,
      limit: 10,
    };

    try {
      await this.props.ShowPDFListAction(listLimit);
      if (this.state.getPdfList.length === 0) {
        const newActivePage = Math.max(this.state.activePage - 1, 1);
        this.handlePageChange(newActivePage);
      }
    } catch (error) {
      console.error("Error getting PDF list:", error);
    }
  };

  handleFileUpload = (e) => {
    const maxFiles = 20; // Set your desired limit
    const maxFileSize = 60 * 1024 * 1024; // 60MB in bytes
    const allowedFileTypes = ["application/pdf"]; // Allow only PDF files
    const files = e.target.files;
    const fileArray = [];
  
    let hasInvalidFile = false; // Flag to track if any invalid file is encountered
  
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
  
      if (file.size > maxFileSize || !allowedFileTypes.includes(file.type)) {
        // File size exceeds the limit or file type is not allowed
        // Set the flag to true to indicate an invalid file
        hasInvalidFile = true;
  
        // Show an error message based on the condition
        const errorMessage = file.size > maxFileSize
          ? `File "${file.name}" is too big. Please upload files up to 60MB.`
          : "Invalid file type. Please select valid PDF files only.";
  
        // Update the state with the error message
        this.setState({
          errorMessage,
        });
  
        // Clear the input field to prevent processing invalid files
        if (this.fileInputRef.current) {
          this.fileInputRef.current.value = "";
        }
  
        // Exit the function to prevent further processing
        return;
      }
  
      const reader = new FileReader();
  
      reader.onloadend = () => {
        const base64Data = reader.result.split(",")[1];
        const fileInfo = {
          name: file.name,
          type: file.type,
          size: file.size,
          base64: base64Data,
        };
  
        fileArray.push(fileInfo);
  
        // Check the length of fileArray after processing all files
        if (fileArray.length === files.length) {
          // If you want to update the state after all files are processed
          this.setState({ selectedFile: fileArray, errorMessage: "" });
        }
      };
  
      reader.readAsDataURL(file);
    }
  
    // Check if any invalid file was encountered
    if (hasInvalidFile) {
      // Clear the input field to prevent processing invalid files
      if (this.fileInputRef.current) {
        this.fileInputRef.current.value = "";
      }
    }
  };
  
  
  handlePageChange = (newPage) => {
    this.setState({ activePage: newPage, loading: true });
    const listLimit = {
      page: newPage,
      limit: 10,
    };
    this.props
      .ShowPDFListAction(listLimit)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  ////
  samplePDFList = [
    {
      id: 1,
      file_name: "iLikeMath_ALGEBRA_II_FUNDAMENTALS.pdf",
      file_type: "pdf",
    },
    {
      id: 2,
      file_name: "Mathematics_8_Fundamentals_Unit7.pdf",
      file_type: "pdf",
    },
  ];

  ////
  exportCsv = () => {
    const exportCsvHeaders = [
      { label: "Title", key: "title" },
      { label: "Sub Title", key: "subTitle" },
      { label: "Category", key: "cat_name" },
    ];
    const exportCSVData = this.state.knowledgeBases.map((item) => {
      let obj = {
        title: item.title,
        subTitle: item.subTitle,
        cat_name: item.cat_name,
      };
      return obj;
    });
    let csv = {
      data: exportCSVData,
      headers: exportCsvHeaders,
      filename: "knowledgebase-report.csv",
      style: { color: "white" },
    };
    this.setState({
      exportCsv: csv,
    });
  };

  constructTableData = () => {
    let tableData = {
      columns: [
        {
          label: "#",
          field: "id",
        },
        {
          label: "Curriculum PDF Name",
          field: "PdfName",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Action",
          field: "action",
          width: 300,
        },
      ],
      rows: this.state.getPdfList?.map((item, i) => {
        let obj = {
          id: i + 1 + 10 * (this.state.activePage - 1),
          PdfName: item.PdfName ? item.PdfName : "-",
          action: (
            <div
              className="actionContainer"
              style={{ padding: "0px !important" }}
            >
              <MDBIcon
                icon="eye"
                size="lg"
                style={{ margin: "5px", cursor: "pointer" }}
                onClick={() => {
                  this.props.history.push("/super-admin/CurrentPdfViewer", {
                    item,
                  });
                }}
              />
              <MDBIcon
                icon="trash"
                size="lg"
                style={{ margin: "5px", cursor: "pointer" }}
                onClick={() => {
                  this.openDeleteModal(item);
                }}
              />
            </div>
          ),
        };
        return obj;
      }),
    };
    this.setState({
      tableBody: tableData,
    });
  };

  removeTag = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  };

  clearSearch = () => {
    let search = {
      title: "",
      subTitle: "",
      category: "",
    };
    this.setState({
      title: "",
      subTitle: "",
      category: "",
    });
    Object.keys(search).forEach(
      (key) => search[key] == "" && delete search[key]
    );
    this.props.getKnowledgeBaseAction();
  };

  searchHandler = () => {
    let search = {
      title: this.state.title,
      subTitle: this.state.subTitle,
      category: this.state.category,
    };
    Object.keys(search).forEach(
      (key) => search[key] == "" && delete search[key]
    );
    this.props.getKnowledgeBaseAction(search);
  };

  handleSearch = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  exportPdf = () => {
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0, 200, 0);
      pdf.save("knowledgeBase.pdf");
    });
  };

  getKnowledgeBasesDetails = () => {
    return (
      <div className="card-body">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">Title</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
              {this.state.selectedKnowledgeBase.title}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">Category</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
              {this.state.selectedKnowledgeBase.cat_name}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">SubTitle:</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
              {this.state.selectedKnowledgeBase.subTitle}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">Slug Url:</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
              <a
                href={this.state.selectedKnowledgeBase.slugUrl}
                target="_blank"
              >
                {this.state.selectedKnowledgeBase.slugUrl}
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">Content :</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div
              className="input-group "
              dangerouslySetInnerHTML={this.createMarkup()}
            >
              {/* {this.removeTag(this.state.selectedKnowledgeBase.context)} */}
            </div>
          </div>
        </div>
      </div>
    );
  };
  createMarkup() {
    return { __html: this.state.selectedKnowledgeBase.context };
  }

  submitUplodedpdfFile = async (e) => {
    if (this.state.selectedFile.length === 0) {
      this.setState({ errorMessage: "Please select at least one file" });
      return;
    }

    const filesData = this.state.selectedFile;

    // Assuming this.props.uploadPDFAction accepts a single file
    for (let i = 0; i < filesData.length; i++) {
      const file = filesData[i];
      const fileData = {
        PdfBase64: file.base64,
        PdfName: file.name,
        CreatedBy: localStorage.getItem("UserId"),
        ModifiedBy: localStorage.getItem("UserId"),
      };
      this.setState({ isloading: true });

      try {
        await this.props.uploadPDFAction(fileData);
        await new Promise((resolve) => setTimeout(resolve, 500)); // Introduce a delay (e.g., 500 milliseconds)
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }

    // const data = {
    //   PdfBase64: this.state.selectedFile,
    //   PdfName: this.state.fileName,
    // };
    // await this.props.uploadPDFAction(data);
    e.preventDefault();
    setTimeout(async () => {
      const listLimit = {
        page: this.state.activePage,
        limit: 10,
      };
      await this.props.ShowPDFListAction(listLimit);
      this.setState({ isButtonDisabled: false });
      if (this.fileInputRef.current) {
        this.fileInputRef.current.value = "";
        this.setState({
          selectedFile: [],
          filename: "",
        });
        this.setState({ isloading: false, isButtonDisabled: false });
      }
    }, 500);
  };

  searchContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12 col-md-4 col-sm-6 adjust">
                <label>
                  Upload PDF<span style={{ color: "red" }}>*</span>
                </label>
                <div className="input-group">
                  <input
                    type="file"
                    accept=".pdf"
                    multiple
                    onChange={this.handleFileUpload}
                    ref={this.fileInputRef}
                  />
                  {this.state.isloading && <Loader />}
                </div>

                <div>
                  <MDBBtn
                    color="primary"
                    className="btn "
                    onClick={this.submitUplodedpdfFile}
                    disabled={this.state.isButtonDisabled}
                  >
                    Upload
                  </MDBBtn>
                </div>
              </div>
              <div></div>
            </div>
            {this.state.errorMessage && (
              <div style={{ color: "red" }}>{this.state.errorMessage}</div>
            )}
          </div>
        </div>
      </TabPane>
    );
  };

  renderPagination() {
    const { activePage, entries, totalCount, loading } = this.state;
    const totalPages = Math.ceil(totalCount / entries);
    const pagesToShow = 10; // Number of pages to show at once
    const constantPages = 10; // Constant number of pages to show
 
    let startPage = Math.max(1, activePage - Math.floor(constantPages / 2));
    let endPage = Math.min(startPage + constantPages - 1, totalPages);
 
    if (endPage === totalPages) {
      startPage = Math.max(1, endPage - constantPages + 1);
    }
 
    const pages = Array.from(
      { length: constantPages },
      (_, i) => startPage + i
    );
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>
            Showing page {activePage} from {totalPages} pages
          </p>
          <nav className="navCurri">
            <ul className="pagination">
              <li className={`page-item ${activePage === 1 ? "disabled" : ""}`}>
                <button
                  className="page-link"
                  onClick={() => this.handlePageChange(activePage - 1)}
                >
                  Previous
                </button>
              </li>
              {pages.map((page) => (
                <li
                  key={page}
                  className={`page-item ${page === activePage ? "active" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => this.handlePageChange(page)}
                  >
                    {page}
                  </button>
                </li>
              ))}
              <li
                className={`page-item ${
                  activePage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => this.handlePageChange(activePage + 1)}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </>
    );
  }

  tableContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body" id="capture">
            <h1 className="page-title">Manage Curriculum PDF List</h1>

            {this.state.loading ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <div
                  className={`table-container ${
                    this.state.pageChanging ? "blurred" : ""
                  }`}
                >
                  {this.state.tableBody.rows.length === 0 ? (
                    <Loader />
                  ) : (
                    <>
                      <MDBDataTable
                        noBottomColumns
                        className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                        entriesOptions={[10, 20, 25]}
                        entries={10}
                        paging={false}
                        data={this.state.tableBody}
                        searchTop
                        sortRows={["title"]}
                        searchBottom={false}
                        onPageChange={(e) => this.handlePageChange(e)}
                        disableRetreatAfterSorting={true}
                        searching={false}
                      />
                      {this.renderPagination()}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </TabPane>
    );
  };

  pageContainer = () => {
    const { activeTab } = this.state;
    return (
      <TabContent activeTab={activeTab}>
        {this.searchContainer()}
        {this.tableContainer()}
      </TabContent>
    );
  };

  modalContainer = () => {
    return (
      <div>
        <MDBContainer>
          <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
            <MDBModalHeader toggle={this.toggleClose}>
              Knowledge Base Details
            </MDBModalHeader>
            <MDBModalBody>
              <div className="card">
                {this.state.selectedKnowledgeBase &&
                  this.getKnowledgeBasesDetails()}
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={this.toggleClose}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.deleteModal} toggle={this.toggle}>
            <MDBModalHeader toggle={this.toggleClose}>
              Delete PDF
            </MDBModalHeader>
            <MDBModalBody>
              <div className="">Are you sure want to delete pdf?</div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={this.deletePDF}>
                Delete
              </MDBBtn>
              <MDBBtn color="secondary" onClick={this.toggleClose}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>
    );
  };

  render() {
    const { activeTab } = this.state;
    if (!isLogined()) this.props.history.push("/");
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">Manage Curriculum PDF </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.pageContainer()}</div>
          {this.state.modal && this.modalContainer()}
          {this.state.deleteModal && this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  knowledgeBases: state.getKnowledgeBaseReducer,
  knowledgeBaseByIdStatus: state.getKnowledgeBaseByIdReducer,
  deleteStatus: state.deleteKnowledgeBaseReducer,
  categories: state.getCategoryReducer,
  uploadPDF: state.UploadPDFReducer,
  getPdfList: state.GETPdfListReducer,
  deletePdflist: state.DeletePDFListReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getKnowledgeBaseAction: (e) => dispatch(getKnowledgeBaseAction(e)),
  getKnowledgeBaseByIdAction: (e) => dispatch(getKnowledgeBaseByIdAction(e)),
  deleteKnowledgeBaseAction: (e) => dispatch(deleteKnowledgeBaseAction(e)),
  getCategoryAction: (e) => dispatch(getCategoryAction(e)),
  clearGetKnowledgeBaseAction: (e) => dispatch(clearGetKnowledgeBaseAction(e)),
  uploadPDFAction: (e) => dispatch(uploadPDFAction(e)),
  ShowPDFListAction: (e) => dispatch(ShowPDFListAction(e)),
  deleteCurriculumAction: (e) => dispatch(deleteCurriculumAction(e)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageCurriculumPDF);
