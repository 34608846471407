
import { SHOW_PDF_LIST } from "../../../../actions/Constants" 
const initialState = {
   list:[],
   totalCount: 0,
}

export default (state = initialState, action) => {
	switch (action.type) {
        
		case SHOW_PDF_LIST:
			return {
				...state,
                list: action.payload.list.data,
                totalCount: action.payload.list.totalCount,
			}
        default:
            return state
    }
}