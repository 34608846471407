import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { MDBDataTable, MDBBtn, MDBIcon,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
// import './style.scss';
import { getSchoolAction } from '../../../actions/institute/ManageSchool/manageSchoolAction';
import { getCitiesAction, getStateAction } from '../../../actions/institute/Utils/utilsAction';
import { getInstitutesAction } from '../../../actions/Admin/ManageInstitutes/manageInstitutesAction';
import { CSVLink } from "react-csv";
import UploadExcel from './UploadExcel';
import html2canvas from 'html2canvas';
import Loader from "../../common/Loader";
import jsPDF from 'jspdf';

class ManageSchool extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
			tableBody : { columns :[], rows: []},
			modal: false,
			selectedSchool: null,
			
			schools:[],
			stateList:[],
			cityList:[],
			status:'',
			school_Name:'',
			city:'',
			state:'',

			openExcelModal:false,
			sampleCsv:null,
			exportCsv:null,

			institute:{}
		};
	}

	componentWillReceiveProps(nextProps){
		if(this.state.stateList !== nextProps.stateList){
            this.setState({
                stateList: nextProps.stateList
            })
        }

        if(this.state.cityList !== nextProps.cityList){
            this.setState({
                cityList: nextProps.cityList
            })
        }
		if(this.state.schools !== nextProps.schoolState.list){
			this.setState({
				schools: nextProps.schoolState.list
			},()=>{
				this.constructTableData()
				this.exportCsv()
				this.exportSampleCsv()
			})
		}
	
	}

	exportCsv =()=> {
		const exportCsvHeaders = [
			{ label: "SCHOOL NAME", key: "school_name" },
			{ label: "STATE", key: "state" },	
			{ label: "CITY", key: "city" },	
			{ label: "STATUS", key: "isActive" },	
			{ label: "CREATED DATE", key: "created_at" }	
		];
		const exportCSVData = this.state.schools.map((item)=>{
			let obj = {
				school_name: item.school_name ? item.school_name: '-',
				city: item.city_name ? item.city_name : '-',
				state: item.state_name ? item.state_name : '-',
				isActive: item.status === 1 ? 'Active': 'Inactive',
				created_at: item.created_at,
			}
			return obj
		})
		let csv = {
			data: exportCSVData,
			headers: exportCsvHeaders,
			filename: 'school.csv',
			style: {color:'white'}
		};
		this.setState({
			exportCsv: csv
		})
	}
	exportSampleCsv =()=> {
		const exportCsvHeaders = [
			{ label: "school_name", key: "school_name" },
			{ label: "contact_person", key: "contact_person" },	
			{ label: "email", key: "email" },	
			{ label: "mobile", key: "mobile" },	
			{ label: "address_one", key: "address_one" },	
			{ label: "address_two", key: "address_two" },	
			{ label: "city", key: "city" },	
			{ label: "state", key: "state" },	
			{ label: "zip", key: "zip" },	
			{ label: "status", key: "status" },	
		];
		const exportCSVData = [{
			school_name: 'school name',
			contact_person: 'contact Person',
			address_one: 'address1',
			address_two: 'address2',
			email: 'email@email.com',
			mobile:'9901923213',
			city:'varanasi',
			state:'uttar pradesh',
			zip:'zip',
			status:0,
		}]
		let csv = {
			data: exportCSVData,
			headers: exportCsvHeaders,
			filename: 'Sampleschool.csv',
			style: {color:'white'}
		};
		this.setState({
			sampleCsv: csv
		})
	}

	componentDidMount(){
		if(localStorage.getItem('institute')){
			this.setState({
				institute:JSON.parse(localStorage.getItem('institute'))
			})
		}
		this.props.getStateAction()
		// this.props.getCitiesAction()
		this.props.getSchoolAction()
	}


    componentDidUpdate(prevProp, prevState) {
        if (prevState.state !== this.state.state) {
          if (this.state.state) {
            this.props
              .getCitiesAction(this.state.state)
          }
        }
    }

	toggle = (value) =>{
		this.setState({
		  modal: true,
		  selectedSchool : value
		});
	}

	toggleClose = ()=>{
		this.setState({
			modal: false,
			selectedSchool: null,
			openExcelModal:false
		})
	}
	
	constructTableData =()=>{
		let tableData = {
			columns: [
				{
					label: '#',
					field: 'id',
				},
				{
					label: 'School Name',
					field: 'school_Name',
					width: 150,
					attributes: {
						'aria-controls': 'DataTable',
						'aria-label': 'Name',
					},
				},
				{
					label: 'State',
					field: 'state',
					width: 270,
				},
				{
					label: 'City',
					field: 'city',
					width: 270,
				},
				{
					label: 'created Date',
					field: 'created_at',
					width: 270,
				},
				{
					label: 'Status',
					field: 'isActive',
					width: 270,
				},
				{
					label: 'Action',
					field: 'action',
					width: 300,
				},
			],
			rows: 
				this.state.schools.map((item,i)=>{
					let obj = {
						id: i+1,
						state: item.state_name ? item.state_name : '-' ,
						city:item.city_name ? item.city_name : '-' ,
						created_at:item.created_at ,
						school_Name: item.school_name ? item.school_name : '-',
						isActive: item.status === 1 ? (<div style={{color:'green'}}>Active</div>) : <div style={{color:'red'}}>Inactive</div>,
						action: (
						<div>
							<MDBIcon  icon="edit" size="lg" style={{margin:'5px',cursor:'pointer'}}  onClick={()=>{
								this.props.history.push(`/institute/edit-school/${item.id}`)
							}}/>
							<MDBIcon  icon="eye" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{
								this.toggle(item)
							}}/>
						</div>)
					}
					return obj
				})
		}	
		this.setState({
			tableBody: tableData
		})	
	}
	
	getSchoolDetails = ()=>{
		return(
			<div className="card-body">
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Institute Name
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							: 
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							{this.state.institute ? this.state.institute.name : '-'}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							School Name
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedSchool.school_name}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Address 1:
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedSchool.address_one}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Address 2:
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedSchool.address_two}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							State :
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedSchool.state_name ? this.state.selectedSchool.state_name : '-'}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							City :
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedSchool.city_name ? this.state.selectedSchool.city_name  : '-'}
						</div>
					</div>
				</div>
				
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Zip :
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedSchool.zip}
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Contact Person :
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedSchool.contact_person}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Email :
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedSchool.email}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Mobile :
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedSchool.mobile}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Status :
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
						{this.state.selectedSchool.isActive ? 'Active' : 'Inactive'}
						</div>
					</div>
				</div>
			</div>		
		)
	}

	handleChange=(e)=>{
		this.setState({ [e.target.name] : e.target.value})
	}
	getApi=()=>{
		this.props.getSchoolAction()
	}
	clearSearch=()=>{
		this.setState({
			status:'',
			school_Name:'',
			city:'',
			state:''
		})
		this.props.getSchoolAction()
	}

	handleSearch=()=>{
		let search = {
            school_name:this.state.school_Name,
            status:this.state.status === 'Active' ? '1' : (this.state.status === 'Inactive' ? '0' : ''),
            city_id:this.state.city,
            state_id:this.state.state
		}
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		this.props.getSchoolAction(search)
	}
	modalContainer=()=>{
		return <MDBContainer>
		<MDBModal isOpen={this.state.modal} toggle={this.toggle}>
			<MDBModalHeader toggle={this.toggleClose}>School Details</MDBModalHeader>
				<MDBModalBody>
					<div className="card">
						{this.state.selectedSchool && this.getSchoolDetails()}
					</div>
				</MDBModalBody>
				<MDBModalFooter>
					<MDBBtn color="secondary" onClick={this.toggleClose}>Close</MDBBtn>
				</MDBModalFooter>
		</MDBModal>
	</MDBContainer>
	}
	exportPdf = () => {
		html2canvas(document.querySelector("#capture")).then(canvas => {
		   const imgData = canvas.toDataURL('image/png');
		   const pdf = new jsPDF();
		   pdf.addImage(imgData, 'PNG', 0, 0,200,0);
		   pdf.save("schoolList.pdf"); 
	   }); 
	}
	render() {


		const { activeTab } = this.state
		// if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage Schools </h1>
							</div>
						</div>
					</div>
				</div>

				<div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-4 col-md-4 col-sm-6">
												<div className="input-group">
													<input type="text" className="form-control" placeholder="School Name" value={ this.state.school_Name} onChange={ this.handleChange } name="school_Name" />
												</div>
											</div>
											<div className="col-lg-3 col-md-2 col-sm-12" >
												<select className="form-control show-tick" value={ this.state.state} onChange={ this.handleChange } name="state">
													<option value="">Select State</option>
													{this.state.stateList.map((item,i)=>{
                                                        return (<option value={item.id}>{item.name}</option>)
                                                    })}
												</select>
											</div>
											<div className="col-lg-3 col-md-2 col-sm-12" >
                                                <select className="form-control show-tick" value={ this.state.city} onChange={ this.handleChange } name="city">
                                                    <option value="">Select City</option>
													{this.state.cityList.map((item,i)=>{
                                                            return (<option value={item.id}>{item.name}</option>)
                                                    })}
                                                </select>
                                            </div>
											<div className="col-lg-2 col-md-2 col-sm-12">
												<select className="form-control show-tick" value={this.state.status} onChange={ this.handleChange } name="status">
													<option value="">Select Status</option>
													<option value="Active">Active</option>
													<option value="Inactive">Inactive</option>
												</select>
											</div>
										</div>
										<div className="btn-container">
													<div>
													<MDBBtn color="primary" className='btn' onClick={this.handleSearch}>Search</MDBBtn>
														<MDBBtn color="primary" className='btn' onClick={this.clearSearch}>Clear</MDBBtn>
													</div>
													<div>
																	<MDBBtn color="primary" className='btn' onClick={()=>{
																		this.props.history.push('/institute/create-school')
																	}}>Create</MDBBtn>
																	<MDBBtn color="primary" className='btn' onClick={()=>{
																		this.setState({openExcelModal : true})
																	}}>Bulk Upload</MDBBtn>
																	<MDBBtn color="primary" className='btn' onClick={this.exportPdf}>PDF 
																	<MDBIcon  icon="download" size="lg" className='icon' /></MDBBtn>&nbsp;
																	<MDBBtn color="primary" className='btn'>
																		{this.state.exportCsv &&(
																			<CSVLink {...this.state.exportCsv}>CSV<MDBIcon  icon="download" size="lg" className='icon' /></CSVLink>
																		)}
																	</MDBBtn>
																	
													</div>
													</div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body" id="capture">
									<h1 className="page-title">School List</h1>
									{this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={['title']}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching = {false}
                />
              </div>
            )}
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>
					
				</div>
				{this.state.modal ? this.modalContainer() : ''}
				{this.state.openExcelModal ?  <UploadExcel toggleClose ={ this.toggleClose } sampleCsv={this.state.sampleCsv} getApi={ this.getApi }/> : ''}

			</>
		);
	}
}

const mapStateToProps = state => ({
	schoolState: state.schoolReducer,
	stateList :state.utillReducer.stateList,
    cityList: state.utillReducer.cityList,
})

const mapDispatchToProps = dispatch => ({
	getSchoolAction: (e) => dispatch(getSchoolAction(e)),
	getCitiesAction: (e) => dispatch(getCitiesAction(e)),
	getStateAction: (e) => dispatch(getStateAction()),
})
export default connect(mapStateToProps, mapDispatchToProps)(ManageSchool)
