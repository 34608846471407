import {
  GET_SCHOOL,
  GET_ALL_SCHOOL,
  GET_STATE,
  GET_CITY,
  GET_GLOBAL_CLASS,
  GET_GLOBAL_SECTION,
  GET_GLOBAL_SUBJECT,
  GET_GLOBAL_TEACHER,
  GET_GLOBAL_SUBJECT_NEW,
  GET_GLOBAL_ASSIGNMENT,
  GET_GLOBAL_EXAMINATION,
  GET_GLOBAL_SKILL,
  GET_SUBJECT_FOR_EXAM
} from "../../Constants/index";
import axios from "axios";
import { utils } from "../../../utils/Api";
import { getToken } from "../../../utils/token";

export const getSchoolAction = () => {
  return (dispatch) => {
    const url = `${utils.getSchool}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_SCHOOL,
          payload: {
            list: data.DATA,
          },
        });
        return data.DATA;
      } else {
        dispatch({
          type: GET_SCHOOL,
          payload: [],
        });
      }
    });
  };
};

export const getStateAction = () => {
  return (dispatch) => {
    const url = `${utils.getState}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_STATE,
          payload: {
            list: data.DATA,
          },
        });
        return data.DATA;
      } else {
        dispatch({
          type: GET_STATE,
          payload: [],
        });
      }
    });
  };
};

export const getCitiesAction = (id) => {
  return (dispatch) => {
    const url = `${utils.getCity(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_CITY,
          payload: {
            list: data.DATA,
          },
        });
        return data.DATA;
      } else {
        dispatch({
          type: GET_CITY,
          payload: [],
        });
      }
    });
  };
};

export const getAllSchoolAction = () => {
  return (dispatch) => {
    const url = `${utils.getAllSchool}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_ALL_SCHOOL,
          payload: {
            list: data.DATA,
          },
        });
        return data.DATA;
      } else {
        dispatch({
          type: GET_ALL_SCHOOL,
          payload: [],
        });
      }
    });
  };
};

export const getClassAction = (params) => {
  return (dispatch) => {
    const url = `${utils.getClass}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params,
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_GLOBAL_CLASS,
          payload: {
            list: data.DATA,
          },
        });
        return data.DATA;
      } else {
        dispatch({
          type: GET_GLOBAL_CLASS,
          payload: [],
        });
      }
    });
  };
};

export const getSectionAction = (params) => {
  return (dispatch) => {
    const url = `${utils.getSection}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params,
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_GLOBAL_SECTION,
          payload: {
            list: data.DATA,
          },
        });
        return data.DATA;
      } else {
        dispatch({
          type: GET_GLOBAL_SECTION,
          payload: [],
        });
      }
    });
  };
};

export const getSubjectAction = (params) => {
  return (dispatch) => {
    const url = `${utils.getSubject}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params,
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_GLOBAL_SUBJECT,
          payload: {
            list: data.DATA,
          },
        });
        return data.DATA;
      } else {
        dispatch({
          type: GET_GLOBAL_SUBJECT,
          payload: [],
        });
      }
    });
  };
};

export const getTeacherAction = (params) => {
  return (dispatch) => {
    const url = `${utils.getTeacher}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params,
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_GLOBAL_TEACHER,
          payload: {
            list: data.DATA,
          },
        });
        return data.DATA;
      } else {
        dispatch({
          type: GET_GLOBAL_TEACHER,
          payload: [],
        });
      }
    });
  };
};

export const getStudentAction = (params) => {
  return (dispatch) => {
    const url = `${utils.getAllStudent}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params,
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        return data.DATA;
      }
    });
  };
};

export const getParentAction = (params) => {
  return (dispatch) => {
    const url = `${utils.getAllParent}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params,
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        return data.DATA;
      }
    });
  };
};

export const getNewSubjectAction = (params) => {
  return (dispatch) => {
    const url = `${utils.getNewSubject}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params,
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_GLOBAL_SUBJECT_NEW,
          payload: {
            list: data.DATA,
          },
        });
        return data.DATA;
      } else {
        dispatch({
          type: GET_GLOBAL_SUBJECT_NEW,
          payload: [],
        });
      }
    });
  };
};

export const getAssignmenttAction = (params) => {
  return (dispatch) => {
    const url = `${utils.getAssignment}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params,
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_GLOBAL_ASSIGNMENT,
          payload: {
            list: data.DATA,
          },
        });
        return data.DATA;
      } else {
        dispatch({
          type: GET_GLOBAL_ASSIGNMENT,
          payload: [],
        });
      }
    });
  };
};
export const getExamtAction = (params) => {
  return (dispatch) => {
    const url = `${utils.getExamination}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params,
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_GLOBAL_EXAMINATION,
          payload: {
            list: data.DATA,
          },
        });
        return data.DATA;
      } else {
        dispatch({
          type: GET_GLOBAL_EXAMINATION,
          payload: [],
        });
      }
    });
  };
};

export const getSkilltAction = (params) => {
  return (dispatch) => {
    const url = `${utils.getSkill}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params,
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_GLOBAL_SKILL,
          payload: {
            list: data.DATA,
          },
        });
        return data.DATA;
      } else {
        dispatch({
          type: GET_GLOBAL_SKILL,
          payload: [],
        });
      }
    });
  };
}

export const getSubjectForExamAction = (params) => {
  return (dispatch) => {
    const url = `${utils.getSubjectForExam}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params,
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_SUBJECT_FOR_EXAM,
          payload: {
            list: data.DATA,
          },
        });
        return data.DATA;
      } else {
        dispatch({
          type: GET_SUBJECT_FOR_EXAM,
          payload: [],
        });
      }
    });
  };
}