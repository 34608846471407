import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.scss";
import { classInfoAction } from "../../../../actions/institute/ManageAnnouncement/manageAnnouncementAction";

class ClassInformation extends Component {
  componentDidMount() {
    this.props.classInfoAction();
  }

  render() {
    return (
      <>
        <div class="container-teacher">
          <div className="box-teacher">
            <h1 className="mt-10">Class Information</h1>
            <hr />
            <div className="row col-lg-12 col-md-4 col-sm-6 mt-4">
              <h6 className="col-lg-4 col-md-4 col-sm-6">Institute Name</h6>
              <div className="col-lg-1 col-md-4 col-sm-6"> :</div>
              <h5 className="col-lg-7 col-md-4 col-sm-6">
                {this.props.classInfoList.map((item) => item.institute_name)}
              </h5>
            </div>
            <div className="row col-lg-12 col-md-4 col-sm-6 mt-4">
              <h6 className="col-lg-4 col-md-4 col-sm-6">School Name</h6>
              <div className="col-lg-1 col-md-4 col-sm-6"> :</div>
              <h5 className="col-lg-7 col-md-4 col-sm-6">
              {this.props.classInfoList.map((item) => item.school_name)}
              </h5>
            </div>
            <div className="row col-lg-12 col-md-4 col-sm-6 mt-4">
              <h6 className="col-lg-4 col-md-4 col-sm-6">Class Name</h6>
              <div className="col-lg-1 col-md-4 col-sm-6"> :</div>
              <h5 className="col-lg-7 col-md-4 col-sm-6">
                {this.props.classInfoList.map((item) => item.class_name)}
              </h5>
            </div>
            <div className="row col-lg-12 col-md-4 col-sm-6 mt-4">
              <h6 className="col-lg-4 col-md-4 col-sm-6">Section Name</h6>
              <div className="col-lg-1 col-md-4 col-sm-6"> :</div>
              <h5 className="col-lg-7 col-md-4 col-sm-6">
                {this.props.classInfoList.map((item) => item.section_name)}
              </h5>
            </div>
            <div className="row col-lg-12 col-md-4 col-sm-6 mt-4">
              <h6 className="col-lg-4 col-md-4 col-sm-6">Subject Name</h6>
              <div className="col-lg-1 col-md-4 col-sm-6"> :</div>
              <h5 className="col-lg-7 col-md-4 col-sm-6">
                {this.props.classInfoList.map((item) => item.subject_name)}
              </h5>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  classInfoList: state.getTeacherDetailReducer,
});

const mapDispatchToProps = (dispatch) => ({
  classInfoAction: () => dispatch(classInfoAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassInformation);
