import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import Loader from "../../../common/Loader";
import { MDBDataTable, MDBBtn, MDBIcon,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
// import './style.scss';
import moment from 'moment'
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import { getClassAction,getSectionAction, getSubjectAction } from '../../../../actions/institute/Utils/utilsAction';
import { clearGetAssignmentAction, deleteAssignmentAction, getAssignmentAction, publishAssignmentAction } from '../../../../actions/institute/Teacherdashboard/ManageAssignment/ManageAssignmentAction';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

class ManageAssignment extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
			tableBody : { columns :[], rows: []},
			modal: false,
			
			exportCsv:null,

            assignmentList:[],
			assignment:{},

			classList:[],
            sectionList:[],
            subjectList:[],
			utills:{},

			school_id:'',
			class_id:'',
			section_id:'',
			subject_id:'',
			deadline:'',
			is_publish:'',
			session_year:'',

			deleteModal:false,
			publishModal:false,
			deleteStatus:null,
			publishStatus:null,

			deleteId:'',
			publishId:'',
		};
	}

	componentWillReceiveProps(nextProps){
		
		if(this.state.assignmentList !== nextProps.assignment.list){
			this.setState({
				assignmentList: nextProps.assignment.list
			},()=>{
				this.constructTableData()
				this.exportCsv()
			})
		}
		if(this.state.subjectList !== nextProps.utills.subjectList){
            this.setState({
                subjectList: nextProps.utills.subjectList
            })
        }
        if(this.state.sectionList !== nextProps.utills.sectionList){
            this.setState({
                sectionList: nextProps.utills.sectionList
            })
        }
		if(this.state.classList !== nextProps.utills.classList){
			this.setState({
				classList: nextProps.utills.classList
			})
		}
		if(this.state.deleteStatus !== nextProps.assignment.deleteStatus){
			this.setState({
				deleteStatus:nextProps.assignment.deleteStatus
			},()=>{
				this.props.getAssignmentAction()
				this.setState({
					deleteModal:false
				})
			})
		}
		if(this.state.publishStatus !== nextProps.assignment.publishStatus){
			this.setState({
				publishStatus:nextProps.assignment.publishStatus
			},()=>{
				this.props.getAssignmentAction()
				this.setState({
					publishModal:false
				})
			})
		}
	
	}
	toggleClose = ()=>{
		this.setState({
			deleteModal: false,
			publishModal: false,
		})
	}
	openDeleteModal=(value)=>{
		this.setState({
			deleteModal: true,
			deleteId:value.id
		})
	}
	openPublishModal=(value)=>{
		this.setState({
			publishModal: true,
			publishId:value
		})
	}
	deleteExam=()=>{
		this.props.deleteAssignmentAction(this.state.deleteId)
	}
	exportCsv =()=> {
		const exportCsvHeaders = [
			{ label: "ASSIGNMENT TITLE", key: "assignment_title" },
			{ label: "CLASS NAME", key: "class_name" },	
			{ label: "SECTION NAME", key: "section_name" },	
			{ label: "SUBJECT", key: "subject_name" },
			{ label: "TOTAL STUDENTS", key: "total_students" },
			{ label: "NUMBER OF STUDENTS GIVEN ASSIGNMENT", key: "submitted_students" },
			{ label: "PENDING STUDENTS", key: "pending_students" },
			{ label: "EXAM DATE", key: "deadline" },
		];
		const exportCSVData = this.state.assignmentList.map((item)=>{
			let obj = {
				assignment_title: item.assignment_title ? item.assignment_title : '-',
				class_name: item.class_name ? item.class_name : '-',
				section_name: item.section_name ? item.section_name : '-',
				subject_name: item.subject_name ? item.subject_name : '-',

				deadline: item.deadline ? moment(item.deadline).format('YYYY-MM-DD') : '-',
				pending_students: item.submitted_tundent_info ? item.submitted_tundent_info.pending_students : '-',
				submitted_students: item.submitted_tundent_info ? item.submitted_tundent_info.submitted_students : '-',
				total_students: item.submitted_tundent_info ? item.submitted_tundent_info.total_students : '-',
			}
			return obj
		})
		let csv = {
			data: exportCSVData,
			headers: exportCsvHeaders,
			filename: 'Assignment.csv',
			style: {color:'white'}
		};
		this.setState({
			exportCsv: csv
		})
	}

	componentDidUpdate(prevProp,prevState){
		if(this.state.class_id !== prevState.class_id) {
            if(this.state.school_id && this.state.class_id){
				let search = {
					school_id: this.state.school_id,
					class_id: this.state.class_id,
				}
				this.props.getSectionAction(search)
			}
		}
		if(this.state.section_id !== prevState.section_id) {
            if(this.state.school_id && this.state.class_id){
				let search = {
					school_id: this.state.school_id,
					class_id: this.state.class_id,
					section_id: this.state.section_id,
				}
				this.props.getSubjectAction(search)
			}
		}

	}

	componentDidMount(){
		if(localStorage.getItem('school')){
            let school = JSON.parse(localStorage.getItem('school'))
            this.setState({ school_id:school.id },()=>{
				let search = {
					school_id: this.state.school_id
				}
				this.props.getClassAction(search)
			});
			
        }
		this.props.getAssignmentAction()
	}
	publishExam=()=>{
		this.props.publishAssignmentAction(this.state.publishId)
	}
	constructTableData =()=>{
		let tableData = {
			columns: [
				{
					label: '#',
					field: 'id',
				},
				{
					label: 'Assignment Title',
					field: 'assignment_title',
					width: 150,
					attributes: {
						'aria-controls': 'DataTable',
						'aria-label': 'Name',
					},
				},
				{
					label: 'Class Name',
					field: 'class_name',
					width: 200,
				},
				{
					label: 'Section Name',
					field: 'section_name',
					width: 270,
				},
				{
					label: 'Subject',
					field: 'subject_name',
					width: 270,
				},
                {
					label: 'Total Students',
					field: 'total_students',
					width: 270,
				},
				{
					label: 'Number Of Students Submitted Assesment',
					field: 'submitted_students',
                    width: 270,
				},
                {
					label: 'Pending Students',
					field: 'pending_students',
					width: 270,
				},
                {
					label: 'Deadline',
					field: 'deadline',
					width: 270,
				},
				{
					label: 'Action',
					field: 'action',
					width: 300,
				},
			],
			rows: 
				this.state.assignmentList.map((item,i)=>{
					let obj = {
						id: i+1,

						assignment_title: item.assignment_title ? item.assignment_title : '-',
						class_name: item.class_name ? item.class_name : '-',
						section_name: item.section_name ? item.section_name : '-',
						subject_name: item.subject_name ? item.subject_name : '-',
						deadline: item.deadline ? moment(item.deadline).format('YYYY-MM-DD') : '-',
						
						pending_students: item.submitted_tundent_info ? item.submitted_tundent_info.pending_students : '-',
						submitted_students: item.submitted_tundent_info ? item.submitted_tundent_info.submitted_students : '-',
						total_students: item.submitted_tundent_info ? item.submitted_tundent_info.total_students : '-',

						action: (
						<div>
							
								{
									item.is_publish === '1' ? <>
										<div><a href="#" style={{color:'green'}} >Published</a> </div>
										<Link size="lg" style={{margin:'5px'}} to={`/teacher/view-submitted-assignment/${item.id}`}>
								View Submitted Assignment</Link></>
									:
									<>
									<MDBBtn color="primary" className='btn' onClick={()=>{this.openPublishModal(item.id)}}>Publish</MDBBtn>
									<MDBIcon  icon="edit" size="lg" style={{margin:'5px',cursor:'pointer'}}  onClick={()=>{
										this.props.history.push(`/teacher/edit-assignment/${item.id}`)
									}}/>
									
									<MDBIcon  icon="trash" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{
											this.openDeleteModal(item)
										}}/>
									</>
								}
						</div>)
					}
					return obj
				})
		}	
		this.setState({
			tableBody: tableData
		})	
	}
	
	handleChange=(e)=>{
		this.setState({ [e.target.name] : e.target.value})
	}
	
	clearSearch=()=>{
		this.setState({
			is_publish:'',
			class_id:'',
			section_id:'',
			subject_id:'',
			session_year:'',
			deadline:'',
		})
		this.props.getAssignmentAction()
	}
	handleDate =(value)=>{
		this.setState({ deadline:value});
    }
	handleYear =(value)=>{
		this.setState({ session_year:value});
    }
	publishContainer=()=>{
		return <MDBContainer>
			<MDBModal isOpen={this.state.publishModal} toggle={this.toggleClose}>
				<MDBModalHeader toggle={this.toggleClose}>Publish Assignment</MDBModalHeader>
					<MDBModalBody>
						<div className="">
							Are you sure to publish this assignment?
						</div>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn color="secondary" onClick={this.publishExam}>Publish</MDBBtn>
						<MDBBtn color="secondary" onClick={this.toggleClose}>Close</MDBBtn>
					</MDBModalFooter>
			</MDBModal>
		</MDBContainer>
	}
	deleteContainer=()=>{
		return <MDBContainer>
			<MDBModal isOpen={this.state.deleteModal} toggle={this.toggleClose}>
				<MDBModalHeader toggle={this.toggleClose}>Delete Assignment</MDBModalHeader>
					<MDBModalBody>
						<div className="">
							Are you sure to delete this assignment?
						</div>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn color="secondary" onClick={this.deleteExam}>Delete</MDBBtn>
						<MDBBtn color="secondary" onClick={this.toggleClose}>Close</MDBBtn>
					</MDBModalFooter>
			</MDBModal>
		</MDBContainer>
	}
	handleSearch=()=>{
		let search = {
            class_id:this.state.class_id,
            section_id:this.state.section_id,
            subject_id:this.state.subject_id,
            session_year:this.state.session_year ? moment(this.state.session_year).format('YYYY') :'',
            deadline: this.state.deadline ? moment(this.state.deadline).format('YYYY-MM-DD') : '',
            is_publish:this.state.is_publish === 'Drafted' ? '0' : (this.state.is_publish === 'Published' ? '1' : ''),
		}
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		this.props.getAssignmentAction(search)
	}
	exportPdf = () => {
		html2canvas(document.querySelector("#capture")).then(canvas => {
		   const imgData = canvas.toDataURL('image/png');
		   const pdf = new jsPDF();
		   pdf.addImage(imgData, 'PNG', 0, 0,200,0);
		   pdf.save("AssignmentList.pdf"); 
	   }); 
	}
	render() {
		const { activeTab } = this.state
		// if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage Assignments</h1>
							</div>
						</div>
					</div>
				</div>

				<div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-3 col-md-2 col-sm-12" >
                                                <select className="form-control show-tick" value={ this.state.class_id} onChange={ this.handleChange } name="class_id">
													<option value="">Select Class</option>
														{this.state.classList && this.state.classList.map((item)=>{
															return <option value={item.id}>{item.name}</option>
														})}
													</select>
                                            </div>
											<div className="col-lg-3 col-md-2 col-sm-12" >
												<select className="form-control show-tick" value={ this.state.section_id} onChange={ this.handleChange } 
												name="section_id" disabled ={this.state.class_id && this.state.school_id ? false : true}>
													<option value="">Select Section</option>
													{this.state.sectionList && this.state.sectionList.map((item)=>{
														return <option value={item.id}>{item.name}</option>
													})}
												</select>
											</div>
											<div className="col-lg-3 col-md-2 col-sm-12">
												<select className="form-control show-tick" value={this.state.subject_id} onChange={ this.handleChange }
												 name="subject_id" 
												 disabled ={this.state.section_id && this.state.class_id && this.state.school_id ? false : true}>
													<option value="">Select Subject</option>
													{this.state.subjectList && this.state.subjectList.map((item)=>{
														return <option value={item.id}>{item.name}</option>
													})}
												</select>
											</div>
                                            <div className="col-lg-3 col-md-2 col-sm-12">
                                                <DatePicker
                                                    selected={this.state.deadline}
                                                    onChange={this.handleDate}
                                                    placeholderText="Select Deadline date"
                                                    className="form-control"
                                                />

											</div>
                                            <div className="col-lg-3 col-md-2 col-sm-12 mt-10">
												<select className="form-control show-tick" value={this.state.is_publish} onChange={ this.handleChange } name="is_publish">
													<option value="">Select Status</option>
													<option value="Drafted">Drafted</option>
													<option value="Published">Published</option>
												</select>
											</div>
                                            <div className="col-lg-3 col-md-2 col-sm-12 mt-10">
                                                <DatePicker
                                                    selected={this.state.session_year}
                                                    onChange={this.handleYear}
                                                    placeholderText="Select Year"
                                                    className="form-control"
                                                    showYearPicker
                                                    dateFormat="yyyy"
                                                />
											</div>
										</div>
										<div className="btn-container">
													<div>
													<MDBBtn color="primary" className='btn' onClick={this.handleSearch}>Search</MDBBtn>
														<MDBBtn color="primary" className='btn' onClick={this.clearSearch}>Clear</MDBBtn>
													</div>
													<div>

														<MDBBtn color="primary" className='btn' onClick={this.exportPdf}>PDF 
															<MDBIcon  icon="download" size="lg" className='icon' /></MDBBtn>
														<MDBBtn color="primary" className='btn'>
															{this.state.exportCsv &&(
																<CSVLink {...this.state.exportCsv}>CSV<MDBIcon  icon="download" size="lg" className='icon' /></CSVLink>
															)}
														</MDBBtn>
														<MDBBtn color="primary" className='btn' onClick={()=>{
															this.props.history.push('/teacher/new-assignment')
														}}>New Assignment</MDBBtn>
													</div>
											</div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card" id="capture">
									<div className="card-body">
									<h1 className="page-title">Assignment List</h1>
									{this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={['title']}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching = {false}
                />
              </div>
            )}
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>
				</div>
				{this.state.deleteModal && this.deleteContainer()}
				{this.state.publishModal && this.publishContainer() }
			</>
		);
	}
}

const mapStateToProps = state => ({
	assignment: state.ManageAssignmentReducer,
	utills: state.utillReducer
})

const mapDispatchToProps = dispatch => ({
	getAssignmentAction: (e) => dispatch(getAssignmentAction(e)),
	publishAssignmentAction: (e) => dispatch(publishAssignmentAction(e)),
	getClassAction: (e) => dispatch(getClassAction(e)),
	getSectionAction: (e) => dispatch(getSectionAction(e)),
	getSubjectAction: (e) => dispatch(getSubjectAction(e)),
	clearGetAssignmentAction: (e) => dispatch(clearGetAssignmentAction(e)),
	deleteAssignmentAction: (e) => dispatch(deleteAssignmentAction(e)),
	
})
export default connect(mapStateToProps, mapDispatchToProps)(ManageAssignment)
