import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { MDBDataTable, MDBBtn, MDBIcon,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter,MDBInput } from 'mdbreact';
// import './style.scss';
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import AddQuestion from '../Assignment/AddQuestion';
import { clearQuestionAction, updateQuestionListAction } from '../../../../actions/institute/Teacherdashboard/ManageQuestions/ManageQuestionAction';
import ImportQuestions from './ImportQuestions';
import { CSVLink } from "react-csv";

class AssignmentQuestion extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
			tableBody : { columns :[], rows: []},
			openExcelModal:false,
			sampleCsv:null,
			exportCsv:null,

            questions:[],
			openModal:false,
			questionState:{},

			editIndex:'',
			deleteIndex:'',
			
			selectAll:false,
			editId:this.props.editId,
			examination:{},
			openImportModal:false

		};
	}

	componentDidMount(){
		
        this.setState({
			questions: this.props.questionState.list
		},()=>{
			this.constructTableData()
			this.exportCsv()
			this.sampleCsv()
		})
	}

	componentWillReceiveProps(nextProps){
		
		if(this.state.questions !== nextProps.questionState.list){
			this.setState({
				questions: nextProps.questionState.list
			},()=>{
				this.constructTableData()
				this.exportCsv()
				this.sampleCsv()
			})
		}
	}
	componentWillUnmount(){
		this.props.clearQuestionAction()
	}
	sampleCsv=()=>{
	const exportCsvHeaders = [
		{ label: "Question", key: "Question" },
		{ label: "Marks", key: "Marks" },	
		{ label: "Is Descriptive", key: "is_descriptive" },	
		{ label: "Question Type", key: "questions_type" },	
		{ label: "Option 1", key: "option1" },	
		{ label: "Option 2", key: "option2" },	
		{ label: "Option 3", key: "option3" },	
		{ label: "Option 4", key: "option4" },	
		{ label: "Option 5", key: "option5" },	
		{ label: "Correct Answer", key: "correct_ans" },	
	];
	const exportCSVData = [
		{
			Question:'Question 1',
			Marks:5,
			is_descriptive:0,
			questions_type:'MCQ',
			option1:'A',
			option2:'B',
			option3:'C',
			option4:'D',
			option5:'E',
			correct_ans:'A',
		},
		{
			Question:'Question 2',
			Marks:5,
			is_descriptive:0,
			questions_type:'True False',
			option1:'value1',
			option2:'value2',
			option3:'-',
			option4:'-',
			option5:'-',
			correct_ans:'value1',
		},
		{
			Question:'Question 3',
			Marks:5,
			is_descriptive:1,
			questions_type:'Descriptive',
			option1:'-',
			option2:'-',
			option3:'-',
			option4:'-',
			option5:'-',
			correct_ans:'-',
		},
	]
	let csv = {
		data: exportCSVData,
		headers: exportCsvHeaders,
		filename: 'SampleQuestion.csv',
		style: {color:'white'}
	};
	this.setState({
		sampleCsv: csv
	})
	}
	exportCsv =()=> {
		const exportCsvHeaders = [
			{ label: "QUESTION NUMBER", key: "id" },
			{ label: "QUESTION", key: "name" },	
			{ label: "TYPE OF QUESTION", key: "questions_type" },	
			{ label: "MARKS", key: "marks" },	
		];
		const exportCSVData = this.state.questions.map((item,i)=>{
			let obj = {
				id: i+1,
				name: item.name ? item.name : '-',
				questions_type: item.is_descriptive === 1 ? 'Descriptive' : (item.options.length === 2 ? 'True False' : 'MCQ'),
				marks: item.marks
			}
			return obj
		})
		let csv = {
			data: exportCSVData,
			headers: exportCsvHeaders,
			filename: 'Question.csv',
			style: {color:'white'}
		};
		this.setState({
			exportCsv: csv
		})
	}

	selectAllCheckbox=(e)=>{
		let questions =  [...this.state.questions]
		questions.map((item)=>{
			item.is_selected = e.target.checked
		})
		this.setState({questions},()=>{
			this.constructTableData()
			this.props.updateQuestionListAction(questions)
		})
		
	}

	selectQuestion=(e,index)=>{
		let questions =  [...this.state.questions]
		questions[index].is_selected = e.target.checked
		this.props.updateQuestionListAction(questions)
	}

	editData=(index)=>{
		this.setState({
			editIndex:index,
			openModal:true
		})
	}
	deleteData=(index)=>{
		this.setState({
			deleteIndex:index
		},()=>{
			let questions =  [...this.state.questions]
			questions.splice(this.state.deleteIndex, 1)
			this.props.updateQuestionListAction(questions)
		})
		
	}
	
	toggleClose=()=>{
		this.setState({
			openModal:false,
			editIndex:'',
			openExcelModal:false
		})
	}
	constructTableData =()=>{
		
		let tableData = {
			columns: [
                {
					label: <MDBInput type='checkbox' id='checkboxheader' value={this.state.selectAll} onChange={ this.selectAllCheckbox}/>,
					field: 'checkbox',
				},
				{
					label: 'Question Number',
					field: 'id',
					width: 150,
					attributes: {
						'aria-controls': 'DataTable',
						'aria-label': 'id',
					},
				},
				{
					label: 'Question',
					field: 'name',
					width: 200,
				},
				{
					label: 'Type Of Question',
					field: 'questions_type',
					width: 270,
				},
				{
					label: 'Marks',
					field: 'marks',
					width: 270,
				},
				{
					label: 'Action',
					field: 'action',
					width: 300,
				},
			],
			rows: 
				this.state.questions.map((item,i)=>{
					let obj = {
						id: i+1,
						checkbox:<MDBInput type='checkbox' id={`checkbox1+${i}`} 
						value={this.state.questions[i].is_selected} 
						checked={this.state.questions[i].is_selected} 
						onChange={(e)=>{ this.selectQuestion(e,i)}}/>,

						name: item.name ? item.name : '-',
						questions_type: item.is_descriptive === 1 ? 'Descriptive' : (item.options.length === 2 ? 'True False' : 'MCQ'),
						marks: item.marks ? item.marks : '-',
						action: (
						<div>
							<MDBIcon  icon="edit" size="lg" style={{margin:'5px',cursor:'pointer'}}  onClick={()=>{this.editData(i)}}/>
                            <MDBIcon  icon="trash" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{ this.deleteData(i)}}/>
						</div>)
					}
					return obj
				})
		}	
		this.setState({
			tableBody: tableData
		})	
	}

	render() {

		const { activeTab } = this.state
		// if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Assignment Questions</h1>
							</div>
						</div>
					</div>
				</div>

				<div className="section-body" style={{width:"100%"}}>
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="">
									<div className="card-body">
                                        <div className={`row`}>
											<div className="card-body cardBodyContainer">
												<div className={`row btnContainer`}>
													<div className="col-lg-12 col-md-4 col-sm-6">
														<MDBBtn color="primary" className='btn' onClick={()=>{this.setState({openModal:true})}}>Add Question</MDBBtn>&nbsp;
														<MDBBtn color="primary" className='btn' onClick={()=>{this.setState({openExcelModal:true})}}>
															Import Questions
														</MDBBtn>&nbsp;
														<MDBBtn color="primary" className='btn'>
															{this.state.exportCsv &&(
																<CSVLink {...this.state.exportCsv}>CSV<MDBIcon  icon="download" size="lg" className='icon' /></CSVLink>
															)}
														</MDBBtn>&nbsp;
														<MDBBtn color="primary" className='btn'>
															{this.state.sampleCsv &&(
																<CSVLink {...this.state.sampleCsv}>Download Sample CSV<MDBIcon  icon="download" size="lg" className='icon' /></CSVLink>
															)}
														</MDBBtn>&nbsp;
														
													</div>
												</div>
											</div>
                                        </div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<MDBDataTable
												noBottomColumns
												className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
												entriesOptions={[5, 10, 20, 25]}
												entries={5}
												paging
												pagesAmount={15}
												data={this.state.tableBody}
												searchTop
												sortRows={['title']}
												searchBottom={false}
												onPageChange={{ activePage: 2, pagesAmount: 5 }}
												disableRetreatAfterSorting={true}
												searching = {false}
											/>
										</div>
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>
				</div>
				{ this.state.openExcelModal ? <ImportQuestions toggleClose={ this.toggleClose }/> :''}

				{this.state.openModal ? 
					<AddQuestion 
						openModal={this.state.openModal} 
						toggleClose={ this.toggleClose } 
						editIndex={this.state.editIndex}
					/> 
				: ''}
			</>
		);
	}
}

const mapStateToProps = state => ({
	questionState: state.ManageQuestionReducer,
	examination: state.ManageExaminationReducer,
})

const mapDispatchToProps = dispatch => ({
	updateQuestionListAction: (e) => dispatch(updateQuestionListAction(e)),
	clearQuestionAction: (e) => dispatch(clearQuestionAction(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(AssignmentQuestion)
