import { GET_EXAMINATION, 
    CREATE_EXAMINATION,
    EDIT_EXAMINATION, 
    GET_EXAMINATION_BY_ID,
    PUBLISH_EXAMINATION,
    DELETE_EXAMINATION,
    GET_SUBMITTED_EXAM_DETAIL,
    GET_NOT_SUBMITTED_EXAM_DETAIL,
    GET_SUBMITTED_ANSWER_WITH_PENDING_STATUS,
    GET_SUBMITTED_ANSWER_WITH_EVALUATED_STATUS,
    EVALUATED_MARK_STATUS,
    GET_EXAMINATION_FOR_STUDENT,
    GET_EXAMINATION_FOR_PARENT,
    SUBMIT_EXAMINATION } from '../../../../actions/Constants/index'

const initialState = {
    list : [],
    studentList : [],
    parentList : [],
    editStatus:null,
    createStatus:null,
    submitStatus:null,
    detail:null,
    publishStatus:null,
    deleteStatus:null,
    submittedExamList:[],
    notSubmittedExamList:[],
    submittedAnswersWithPendingStatus:null,
    submittedAnswersWithEvaluatedStatus:null,
    evaluatedMarkStatus:null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_EXAMINATION:
        return {
            ...state,
            list : action.payload.list,
        }
        case EDIT_EXAMINATION:
        return {
            ...state,
            editStatus : action.payload.list,
        }
        case CREATE_EXAMINATION:
        return {
            ...state,
            createStatus : action.payload.list,
        }
        case PUBLISH_EXAMINATION:
        return {
            ...state,
            publishStatus : action.payload.list,
        }
        case GET_EXAMINATION_BY_ID:
        return {
            ...state,
            detail : action.payload.list,
        }
        case DELETE_EXAMINATION:
            return {
                ...state,
                deleteStatus : action.payload.status,
            }
        case GET_SUBMITTED_EXAM_DETAIL:
            return {
                ...state,
                submittedExamList : action.payload.list,
            }
        case GET_NOT_SUBMITTED_EXAM_DETAIL:
            return {
                ...state,
                notSubmittedExamList : action.payload.list,
            }
        case GET_SUBMITTED_ANSWER_WITH_PENDING_STATUS:
            return {
                ...state,
                submittedAnswersWithPendingStatus : action.payload.list,
            }
        case EVALUATED_MARK_STATUS:
            return {
                ...state,
                evaluatedMarkStatus : action.payload.list,
            }
        case GET_SUBMITTED_ANSWER_WITH_EVALUATED_STATUS:
        return {
            ...state,
            submittedAnswersWithEvaluatedStatus : action.payload.list,
        }
        case GET_EXAMINATION_FOR_STUDENT:
            return {
                ...state,
                studentList : action.payload.list,
            }
        case GET_EXAMINATION_FOR_PARENT:
            return {
                ...state,
                parentList : action.payload.list,
            }
        case SUBMIT_EXAMINATION:
                return {
                    ...state,
                    submitStatus : action.payload.list,
                }
            
        default:
            return state
    }
}
