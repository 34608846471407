import { GET_CATEGORY_WITH_COUNT } from '../../../actions/Constants/index'

const initialState = {
   list:[]
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_CATEGORY_WITH_COUNT:
			return {
				...state,
                list : action.payload.list,
			}
        default:
            return state
    }
}
