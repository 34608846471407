import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import './style.scss';
import { connect } from 'react-redux';
import { MDBBtn, MDBFormInline, MDBInput} from 'mdbreact';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import AssignmentQuestion from './AssignmentQuestion';
import { getClassAction,getSectionAction, getSubjectForExamAction } from '../../../../actions/institute/Utils/utilsAction';
import DatePicker from "react-datepicker";
import moment from 'moment'
import { clearQuestionAction, updateQuestionListAction } from '../../../../actions/institute/Teacherdashboard/ManageQuestions/ManageQuestionAction';
import { clearAssignmentByIdAction, clearCreateAssignmentAction, clearEditAssignmentAction, createAssignmentAction, editAssignmentAction, getAssignmentAction, getAssignmentByIdAction } from '../../../../actions/institute/Teacherdashboard/ManageAssignment/ManageAssignmentAction';

class NewAssignment extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
            input:{
                assignment_title:'',
                class_id:'',
                section_id:'',
                subject_id:'',
                deadline: new Date(),
                minimum_pass:'',
                session_year: moment().format('YYYY'),
                school_id:'',
            },
            copy_assignment:'',
            editId: this.props.match.params.id,
            modal: false,

            classList:[],
            sectionList:[],
            subjectList:[],
            assignmentList:[],
			utills:{},
            assignment:{},
            questionState:{},
            questions:[],
            showError:false
		};
	}

    componentDidMount(){
        this.setState({
			questions: this.props.questionState.list
		})
        this.props.getAssignmentAction()
        if(localStorage.getItem('school')){
            let school = JSON.parse(localStorage.getItem('school'))
            let input = {...this.state.input}
            input.school_id = school.id
            this.setState({ input:input });
        }
        if(this.state.editId){
            this.props.getAssignmentByIdAction({id:this.state.editId})
        }
	}

    componentDidUpdate(prevProp,prevState){
        if (this.state.copy_assignment !== prevState.copy_assignment) {
            let found = this.state.assignmentList.find(item => item.id === parseInt(this.state.copy_assignment))
            if(found){
                let input = {...this.state.input}
                input.class_id = found.class_id
                input.assignment_title = found.assignment_title
                input.section_id = found.section_id
                input.subject_id = found.subject_id
                input.deadline = new Date(found.deadline)
                input.minimum_pass = found.minimum_pass

                this.setState({input:input})
                var result = found.questions.map(function(el) {
                    var _ = Object.assign({}, el);
                    delete _.id
                    delete _.assignment_id
                    _.is_selected = false;
                    return _;})
                this.props.updateQuestionListAction(result)
            }
		}
		if (this.state.input.school_id !== prevState.input.school_id) {
            if(this.state.input.school_id){
				let search = {
					school_id: this.state.input.school_id
				}
				this.props.getClassAction(search)
			}
		}
		if(this.state.input.class_id !== prevState.input.class_id) {
            if(this.state.input.school_id && this.state.input.class_id){
				let search = {
					school_id: this.state.input.school_id,
					class_id: this.state.input.class_id,
				}
				this.props.getSectionAction(search)
			}
		}
		if(this.state.input.section_id !== prevState.input.section_id) {
            if(this.state.input.school_id && this.state.input.class_id){
				let search = {
					school_id: this.state.input.school_id,
					class_id: this.state.input.class_id,
					section_id: this.state.input.section_id,
				}
				this.props.getSubjectForExamAction()
			}
		}

	}
    componentWillReceiveProps(nextProps){
        if(this.state.questions !== nextProps.questionState.list){
			this.setState({
				questions: nextProps.questionState.list
			})
		}
        if(this.state.assignmentList !== nextProps.assignment.list){
			this.setState({
				assignmentList: nextProps.assignment.list
			})
		}
        if(this.state.subjectList !== nextProps.utills.subjectForExam){
            this.setState({
                subjectList: nextProps.utills.subjectForExam
            })
        }
        if(this.state.sectionList !== nextProps.utills.sectionList){
            this.setState({
                sectionList: nextProps.utills.sectionList
            })
        }
		if(this.state.classList !== nextProps.utills.classList){
			this.setState({
				classList: nextProps.utills.classList
			})
		}
        if(this.props.assignment.detail !== nextProps.assignment.detail){
            let data = nextProps.assignment.detail[0]
            let input = {...this.state.input}

            input.class_id = data.class_id
            input.assignment_title = data.assignment_title
            input.section_id = data.section_id
            input.subject_id = data.subject_id
            input.deadline = new Date(data.deadline)
            input.minimum_pass = data.minimum_pass
            this.setState({input:input})
            var result = data.questions.map(function(el) {
                var _ = Object.assign({}, el);
                _.is_selected = false;
                return _;})
            this.props.updateQuestionListAction(result)
        }
	}
    componentWillUnmount(){
        this.props.clearQuestionAction()
    }
    clearData =()=>{
        this.toggleClose()
        this.props.clearCreateAssignmentAction()
        this.props.clearEditAssignmentAction()
        this.props.clearAssignmentByIdAction()
        this.props.history.push('/teacher/manage-assignment')
    }

    modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={ this.state.modal }>
                    <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
                    <MDBModalBody>
                        <div className='alertContainer'>
                            <h4> Do you want to {this.state.editId ? 'Update' : 'save'} it ? </h4>
                            <h6> Please check the information before submitting</h6>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleClose}>No</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.handleSave}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
			</MDBContainer>
		)
	}
    handleSave=()=>{
        let questions=[]
        if(this.state.questions){
            this.state.questions.map((item,i)=>{
                if(item.is_selected){
                    questions.push(item)
                }
                if(item.is_descriptive == '1'){
                    delete item.options
                }
            })
        }
        if(questions.length === 0){
            this.setState({showError:true,modal: false})
        }
        else{
            this.setState({showError:false})
            let data = {
                ...this.state.input,
                deadline:moment(this.state.input.deadline).format('YYYY-MM-DD'),
                questions:questions
            }
            if(this.state.editId){
                data = {
                    ...data,
                    id:this.state.editId
                }
            }
            if(this.state.editId){
                this.props.editAssignmentAction({id : this.state.editId,
                    data:data,history: this.props.history})
            }else{
                this.props.createAssignmentAction(data,this.props.history)
            }
        }
        
    }
    submitExamHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
		if(event.target.checkValidity()) {
            this.setState({
                modal: true,
            })
		}
	}
    onContentChange =(value)=>{
        this.setState({context:value})
    }
    changeHandler = event => {
        let input = {...this.state.input}
        input[event.target.name] = event.target.value
		this.setState({ input:input });
	};
    changeAssignment=(event)=>{
        this.setState({ copy_assignment:event.target.value });
    }
    handleDate =(value)=>{
        let input = {...this.state.input}
        input.deadline = value
		this.setState({ input:input });
    }
    toggleClose = ()=>{
		this.setState({
			modal: false,
		})
	}
    
    render(){
        const { activeTab } = this.state
        // if(!isLogined()) this.props.history.push('/')
        return(
            <>
            <div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">{this.state.editId ? 'Update Assignment' : 'New Assignment'} </h1>
							</div>
						</div>
					</div>
				</div>
                <div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
                                <form  onSubmit={this.submitExamHandler} noValidate>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Copy Assignment
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select className="form-control show-tick" value = {this.state.copy_assignment} name="copy_assignment" 
                                                                    onChange={this.changeAssignment}>
                                                                        <option value="">Select Assignment</option>
                                                                        {this.state.assignmentList && this.state.assignmentList.map((item)=>{
                                                                            return <option value={item.id}>{item.assignment_title}</option>
                                                                        })}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required assignment.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                Assignment Title
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value = {this.state.input['assignment_title']} name="assignment_title" 
                                                                    onChange={this.changeHandler} placeholder="Assignment Title" required/>
                                                                   
                                                                    <div className="invalid-feedback">
                                                                        required assignment title
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Class
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select className="form-control show-tick" value = {this.state.input['class_id']} 
                                                                    name="class_id" onChange={this.changeHandler} required>
                                                                    <option value="">Select Class</option>
                                                                        {this.state.classList && this.state.classList.map((item)=>{
                                                                            return <option value={item.id}>{item.name}</option>
                                                                        })}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required class.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Section 
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select className="form-control show-tick" value = {this.state.input['section_id']} 
                                                                    name="section_id" onChange={this.changeHandler} required
                                                                    disabled ={this.state.input.class_id && this.state.input.school_id ? false : true}>
                                                                    <option value="">Select Section</option>
                                                                    {this.state.sectionList && this.state.sectionList.map((item)=>{
                                                                        return <option value={item.id}>{item.name}</option>
                                                                    })}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required section.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Subject
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select className="form-control show-tick" value = {this.state.input['subject_id']} 
                                                                    name="subject_id" onChange={this.changeHandler} required
                                                                    disabled ={this.state.input.section_id && this.state.input.class_id && this.state.input.school_id ? false : true}>
                                                                        <option value="">Select Subject</option>
                                                                        {this.state.subjectList && this.state.subjectList.map((item)=>{
                                                                            return <option value={item.id}>{item.name}</option>
                                                                        })}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required subject.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Deadline
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <DatePicker
                                                                        selected={this.state.input.deadline}
                                                                        onChange={(date) => this.handleDate(date)}
                                                                        placeholder="Select Date"
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Minimum Passing
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="number" className="form-control" value = {this.state.input['minimum_pass']} name="minimum_pass" 
                                                                    onChange={this.changeHandler} placeholder="Minimum Passing" required/>
                                                                    <div className="invalid-feedback">
                                                                        required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Year
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    {moment().format('YYYY')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <AssignmentQuestion  editId={this.state.editId}/>
                                            </div>
                                            <div class="row">
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="errorFlag">
                                                        {this.state.showError ? 'Please select questions.' : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="row">
                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField submitBtn">
                                                    <MDBBtn color="primary" className='btn' onClick= {()=>{
                                                        this.props.history.push('/teacher/manage-assignment')
                                                    }}>Cancel</MDBBtn>
                                                    <MDBBtn color="primary" type={'submit'} className='btn'>{this.state.editId ? 'Update' : 'Save'}</MDBBtn>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </form>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
                { this.state.modal &&this.modalContainer()}

            </>
        )
    }
}

const mapStateToProps = state => ({
	utills: state.utillReducer,
	assignment: state.ManageAssignmentReducer,
	questionState: state.ManageQuestionReducer,

})

const mapDispatchToProps = dispatch => ({
    getClassAction: (e) => dispatch(getClassAction(e)),
	getSectionAction: (e) => dispatch(getSectionAction(e)),
	getSubjectForExamAction: (e) => dispatch(getSubjectForExamAction(e)),

	createAssignmentAction: (e,history) => dispatch(createAssignmentAction(e,history)),
	getAssignmentAction: (e) => dispatch(getAssignmentAction(e)),
	getAssignmentByIdAction: (e) => dispatch(getAssignmentByIdAction(e)),
	editAssignmentAction: (e,history) => dispatch(editAssignmentAction(e,history)),
	clearCreateAssignmentAction: (e) => dispatch(clearCreateAssignmentAction(e)),
	clearEditAssignmentAction: (e) => dispatch(clearEditAssignmentAction(e)),
	clearAssignmentByIdAction: (e) => dispatch(clearAssignmentByIdAction(e)),

    updateQuestionListAction: (e) => dispatch(updateQuestionListAction(e)),
    clearQuestionAction: (e) => dispatch(clearQuestionAction(e)),

})

export default connect(mapStateToProps, mapDispatchToProps)(NewAssignment)
