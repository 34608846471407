import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import {
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import "./style.scss";
import Ckeditor from "../../common/ckeditor";
import {
  createNewsLetterAction,
  uploadImageAction,
  getNewsletterEditByIdAction,
  editNewsLetterAction,
  sendNewsLetterAction,
  getEmailDropdownAction,
} from "../../../actions/institute/ManageNewsLetter/manageNewsLetterAction";
import { getAllSchoolAction } from "../../../actions/institute/Utils/utilsAction";

class CreateNewsletter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      isSave: true,
      input: {
        subscriber_type: "student",
      },
      modal: false,
      editId: this.props.match.params.id,
      dropDownDataSchool: [],
      dropDownDataEmail: [],
      isLoggedInRole:
        localStorage.getItem("loginedRole") === "principal" ? true : false,
        selectedOptions:null,
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props
        .getNewsletterEditByIdAction(this.props.match.params.id)

        .then((res) =>
          this.setState({
            input: {
              ...res,
              email: this.getEmailOptions(res.email.split(";")),
            },
          })
        );
    }
    this.props
      .getAllSchoolAction()
      .then((res) => this.setState({ dropDownDataSchool: res }));
  }

  componentDidUpdate(prevProp, prevState) {
    if (this.state.isLoggedInRole && this.state.input.school_id === undefined) {
      this.setState({
        input: {
          ...this.state.input,
          ["school_id"]: localStorage.getItem("school_id"),
        },
      });
    }
    if (
      prevState.input.school_id !== this.state.input.school_id ||
      prevState.input.subscriber_type !== this.state.input.subscriber_type
    ) {
      if (this.state.input.school_id && this.state.input.subscriber_type) {
        this.props
          .getEmailDropdownAction({
            schoolId: this.state.input.school_id,
            type:
              this.state.input.subscriber_type.charAt(0).toUpperCase() +
              this.state.input.subscriber_type.slice(1) +
              "s",
          })
          .then((res) => {
            let arr = res.map((ele) => ({
              label: ele.email,
              value: ele.email,
            }));
            this.setState({ dropDownDataEmail: [{ label: "Select All", value: "all" }, ...arr] })
          });
      }
    }
  }

  clearData = () => {
    this.setState({
      input: {},
      modal: false,
    });
    this.props.history.push("/institute/manage-newsletter");
  };

  toggle = () => {
    this.setState({
      modal: true,
    });
  };

  toggleClose = () => {
    this.setState({
      modal: false,
    });
  };

  changeHandler = (name, value) => {
    if(name === 'subscriber_type' && value === 'parent'){
      this.setState({selectedOptions: null})
    }
    this.setState({ input: { ...this.state.input, [name]: value } });
  };

  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (event.target.checkValidity()) {
      this.setState({
        modal: true,
      });
    }
  };

  handleImage = async (event) => {
    const image = event.target.files[0];
    const res = await this.props.uploadImageAction(image);
    let input = this.state.input;
    input["upload_link"] = res;
    this.setState({
      input,
    });
  };

  modalContainer = () => {
    return (
      <MDBContainer>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
          <MDBModalBody>
            <div className="alertContainer">
              <h4>
                {" "}
                Do you want to {this.state.editId ? "Update" : "save"} it ?{" "}
              </h4>
              <h6> Please check the information before submitting</h6>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              No
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.handleSave}>
              Yes
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  };

  handleSave = async () => {
    if (this.props.match.params.id) {
      await this.props.editNewsLetterAction({
        id: this.props.match.params.id,
        data: {
          ...this.state.input,
          email: this.state.selectedOptions && this.state.selectedOptions.map(e => e.label).join(";"),
        },
        history:this.state.isSave ? this.props.history : null
      })
      !this.state.isSave &&
        (await this.props.sendNewsLetterAction({
          id: this.props.match.params.id,
          data: {
            ...this.state.input,
            email: this.state.selectedOptions && this.state.selectedOptions.map(e => e.label).join(";"),
          },
          history:this.props.history
        }))
    } else {
      await this.props.createNewsLetterAction({
        data: {
          ...this.state.input,
        email: this.state.selectedOptions && this.state.selectedOptions.map(e => e.label).join(";"),
        },
        history :this.props.history
      })
    }
  };

  getEmailOptions = (options) =>{
    let arr = options.map((ele) => ({
      label: ele,
      value: ele,
    }));
    this.setState({selectedOptions:arr})
  }
  handleMultiSelectChange=(selectedOptions)=>{
    this.setState({selectedOptions})
  }

  inputFieldContainer = () => {
    const { activeTab } = this.state;
    return (
      <div className="container-fluid">
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1} className={classnames(["fade show"])}>
            <form onSubmit={this.submitHandler} noValidate>
              <div className="card">
                {!this.state.isLoggedInRole && (
                  <div className="col-lg-12 col-md-4 col-sm-6 pt-3 row">
                    <div className="col-lg-6 col-md-4 col-sm-6 ">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-3 col-md-4 col-sm-6 ">
                            <div className="input-group">School Name</div>
                          </div>
                          <div className="col-lg-1 col-md-4 col-sm-6 ">
                            <div className="input-group">:</div>
                          </div>
                          <div className="col-lg-8 col-md-4 col-sm-6 ">
                            <div className="input-group">
                              <select
                                value={this.state.input.school_id}
                                onChange={(e) =>
                                  this.changeHandler(
                                    "school_id",
                                    e.target.value
                                  )
                                }
                                required
                                className="form-control show-tick"
                              >
                                <option value="">School Name</option>
                                {this.state.dropDownDataSchool.map((ele) => (
                                  <option value={ele.id}>{ele.name}</option>
                                ))}
                              </select>
                              <div className="invalid-feedback">
                                required School Name field.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3 row">
                  <div className="col-lg-6 col-md-4 col-sm-6 ">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 ">
                          <div className="input-group">Subscriber Type</div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6 ">
                          <div className="input-group">:</div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-6 ">
                          <div className="input-group">
                            <select
                              value={this.state.input.subscriber_type}
                              onChange={(e) =>
                                this.changeHandler(
                                  "subscriber_type",
                                  e.target.value
                                )
                              }
                              required
                              className="form-control show-tick"
                            >
                              <option value="student">Student</option>
                              <option value="parent">Parent</option>
                            </select>
                            <div className="invalid-feedback">
                              required Subscriber field.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-4 col-sm-6 ">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 ">
                          <div className="input-group">Email</div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6 ">
                          <div className="input-group">:</div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-6 ">
                          <div className="input-group">
                            <Select
                              options= {this.state.dropDownDataEmail}
                              isDisabled={!this.state.input.school_id}
                              value={this.state.selectedOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              className="basic-multi-select w-100"
                              classNamePrefix="select"
                              onChange={selected => {
                                selected.find(option => option.value === "all")
                                  ? this.handleMultiSelectChange(this.state.dropDownDataEmail.slice(1))
                                  : this.handleMultiSelectChange(selected)
                              }}
                            ></Select>
                            <div className="invalid-feedback">
                              Enter a valid email address.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3 row">
                  <div className="col-lg-6 col-md-4 col-sm-6 ">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 ">
                          <div className="input-group">Title</div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6 ">
                          <div className="input-group">:</div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-6">
                          <div className="input-group">
                            <input
                              value={this.state.input.title}
                              placeholder="Title"
                              onChange={(e) =>
                                this.changeHandler("title", e.target.value)
                              }
                              type="text"
                              className="form-control"
                              required
                            />
                            <div className="invalid-feedback">
                              required Title field.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-4 col-sm-6 ">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-6 "></div>
                        <div className="col-lg-8 col-md-4 col-sm-6">
                          <input
                            ref="file"
                            onChange={this.handleImage}
                            type="file"
                            className="form-control"
                            placeholder="upload Image"
                            accept=".xlsx,.xls,image/*,.doc,,image/gif, image/jpeg,
                            text/plain"
                            required={
                              this.state.input["upload_link"] ? false : true
                            }
                          />
                          <p style={{color:'green'}}>Only accept Png, Jpeg, Doc, xls file</p> 
                        </div>
                        <div className="invalid-feedback">required Image.</div>
                        {this.state.uploadImageUrl && (
                          <div className="imageFlag">image upload successfully</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 pt-3 row">
                  <div className="col-lg-6 col-md-4 col-sm-6 ">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 ">
                          <div className="input-group">Subject</div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6">
                          <div className="input-group">:</div>
                        </div>
                        <div className="col-lg-8 col-md-4 col-sm-6 ">
                          <div className="input-group">
                            <input
                              value={this.state.input.subject}
                              placeholder="Subject"
                              onChange={(e) =>
                                this.changeHandler("subject", e.target.value)
                              }
                              type="text"
                              className="form-control"
                              required
                            />
                            <div className="invalid-feedback">
                              required Subject field.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 p-3">
                  <Ckeditor
                    content={this.state.input.content}
                    html={this.state.input.content}
                    onChange={(value) => {
                      this.changeHandler("content", value);
                    }}
                  />
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 submitBtn">
                  <MDBBtn
                    color="primary"
                    className="btn"
                    onClick={this.clearData}
                  >
                    Close
                  </MDBBtn>
                  <MDBBtn
                    onClick={() => {
                      this.changeHandler("status", "draft");
                      this.setState({ isSave: true });
                    }}
                    color="primary"
                    className="btn"
                    type="submit"
                  >
                    Save
                  </MDBBtn>
                  <MDBBtn
                    onClick={() => {
                      this.changeHandler("status", "sent");
                      this.setState({ isSave: false });
                    }}
                    color="primary"
                    className="btn"
                    type="submit"
                  >
                    Send
                  </MDBBtn>
                </div>
              </div>
            </form>
          </TabPane>
        </TabContent>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">
                  {" "}
                  {this.state.editId ? "Update" : "Create"} Newsletter
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.inputFieldContainer()}</div>
          {this.state.modal && this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createNewsLetterAction: (e,history) => dispatch(createNewsLetterAction(e,history)),
  sendNewsLetterAction: (e,history) => dispatch(sendNewsLetterAction(e,history)),
  editNewsLetterAction: (e,history) => dispatch(editNewsLetterAction(e,history)),
  getNewsletterEditByIdAction: (e) => dispatch(getNewsletterEditByIdAction(e)),
  uploadImageAction: (e) => dispatch(uploadImageAction(e)),
  getAllSchoolAction: (e) => dispatch(getAllSchoolAction(e)),
  getEmailDropdownAction: (e) => dispatch(getEmailDropdownAction(e)),
});
export default connect(null, mapDispatchToProps)(CreateNewsletter);
