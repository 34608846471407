import {CREATE_QUESTION,UPDATE_QUESTION_LIST,CLEAR_QUESTION } from '../../../../actions/Constants/index'

const initialState = {
    list : [],
}

export default (state = initialState, action) => {
	switch (action.type) {
		case CREATE_QUESTION:
        return {
            ...state,
            list : state.list.concat([action.payload]),
        }
        case UPDATE_QUESTION_LIST:
        return {
            ...state,
            list : action.payload,
        }
        case CLEAR_QUESTION:
        return {
            ...state,
            list : [],
        }
        default:
            return state
    }
}
