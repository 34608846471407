import { GET_INSTITUTES, 
    GET_STATESANDCITIES,
    CREATE_INSTITUTES, 
    EDIT_INSTITUTES, 
    GET_INSTITUTES_BY_ID,
    UPLOAD_INSTITUTES_FILE,
    UPLOAD_IMAGE,
    UPDATE_PROFILE,
    SHOW_SNACKBAR,
    GET_PROFILE,UPDATE_PASSWORD } from '../../Constants/index';
import axios from 'axios';
import { manageInstitutes } from '../../../utils/Api'
import { getToken } from '../../../utils/token'

export const getInstitutesAction = (params) => {
	return dispatch => {
        const url = params ? `${manageInstitutes.searchInstitute}` : `${manageInstitutes.getInstitute}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_INSTITUTES,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_INSTITUTES,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearGetInstitutesAction = () => {
	return dispatch => {
        dispatch({
            type: GET_INSTITUTES,
            payload: {
                list : []
            }
        })
    }
}

export const getStatesAndcitiesAction = () => {
	return dispatch => {
        const url = `${manageInstitutes.getStatesAndcities}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_STATESANDCITIES,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_STATESANDCITIES,
                    payload: {
                        list : null
                    }
                })
            }
        })
    }
}

export const createInstitutesAction = (data, history) => {
	return dispatch => {
        const url = `${manageInstitutes.createInstitute}`,    
        options = {
            url, 
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: CREATE_INSTITUTES,
                    payload: {
                        list : flag.status 
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                 history.push("/super-admin/manage-institutes");
            }else {
                dispatch({
                    type: CREATE_INSTITUTES,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const clearCreateInstitutesAction = () => {
	return dispatch => {
        dispatch({
            type: CREATE_INSTITUTES,
            payload: {
                list : null
            }
        })
    }
}

export const clearEditInstitutesAction = () => {
	return dispatch => {
        dispatch({
            type: EDIT_INSTITUTES,
            payload: {
                list : null
            }
        })
    }
}

export const editInstitutesAction = ({id,data, history}) => {
	return dispatch => {
        const url = `${manageInstitutes.editInstitute(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PATCH',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: EDIT_INSTITUTES,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                 history.push("/super-admin/manage-institutes");
            }else {
                dispatch({
                    type: EDIT_INSTITUTES,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const getInstitutesByIdAction = (id) => {
	return dispatch => {
        const url = `${manageInstitutes.getInstituteById(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_INSTITUTES_BY_ID,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_INSTITUTES_BY_ID,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearInstitutesByIdAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_INSTITUTES_BY_ID,
            payload: {
                list : {}
            }
        })
    }
}

export const clearUploadInstitutesFileAction = () => {
	return dispatch => {
        dispatch({
            type: UPLOAD_INSTITUTES_FILE,
            payload: {
                status : null
            }
        })
    }
}

export const uploadInstitutesFileAction = (data) => {
	return dispatch => {
        let formData = new FormData();
        formData.append('csv', data);
        const url = `${manageInstitutes.uploadInstituteFile}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
                "Content-type": "multipart/form-data",
            },
            method: 'post',
            data: formData
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = flag.status
                dispatch({
                    type: UPLOAD_INSTITUTES_FILE,
                    payload: {
                        status : data
                    }
                })
            }else {
                data = flag.status
                dispatch({
                    type: UPLOAD_INSTITUTES_FILE,
                    payload: {
                        status : data
                    }
                })
            }
        })
    }
}


export const clearUploadImageAction = () => {
	return dispatch => {
        dispatch({
            type: UPLOAD_IMAGE,
            payload: {
                status : null
            }
        })
    }
}

export const uploadImageAction = (data) => {
	return dispatch => {
        let formData = new FormData();
        formData.append('image', data);
        const url = `${manageInstitutes.uploadImage}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
                "Content-type": "multipart/form-data",
            },
            method: 'post',
            data: formData
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA.file
                dispatch({
                    type: UPLOAD_IMAGE,
                    payload: {
                        status : data
                    }
                })
            }else {
                dispatch({
                    type: UPLOAD_IMAGE,
                    payload: {
                        status : null
                    }
                })
            }
        })
    }
}

export const updateProfileAction = ({data}) => {
	return dispatch => {
        const url = `${manageInstitutes.updateProfile}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PUT',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: UPDATE_PROFILE,
                    payload: {
                        list : flag.status
                    }
                })
            }else {
                dispatch({
                    type: UPDATE_PROFILE,
                    payload: {
                        list : flag.status
                    }
                })
            }
        })
    }
}

export const updatePasswordAction = ({data}) => {
	return dispatch => {
        const url = `${manageInstitutes.updatePassword}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PATCH',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: UPDATE_PASSWORD,
                    payload: {
                        list : flag.status
                    }
                })
            }else {
                dispatch({
                    type: UPDATE_PASSWORD,
                    payload: {
                        list : flag.status
                    }
                })
            }
        })
    }
}

export const getProfileAction = () => {
	return dispatch => {
        const url = `${manageInstitutes.getProfile}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_PROFILE,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_PROFILE,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearGetProfileAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_PROFILE,
            payload: {
                list : {}
            }
        })
    }
}

export const clearUpdateProfileAction = () => {
	return dispatch => {
        dispatch({
            type: UPDATE_PROFILE,
            payload: {
                list : null
            }
        })
    }
}
export const clearUpdatePasswordAction = () => {
	return dispatch => {
        dispatch({
            type: UPDATE_PASSWORD,
            payload: {
                list : null
            }
        })
    }
}