import React, { Component } from 'react';
import moment from 'moment'
import DateTimePicker from "react-datetime-picker";

// import './style.scss';
import { connect } from 'react-redux';
import { MDBBtn,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

import { clearCreateEventsAction, clearEditEventsAction, clearEventsByIdAction, clearUploadEventAction, clearUploadEventImageAction, createEventsAction, editEventsAction, getEventByIdAction, uploadEventFileAction, uploadEventImageAction } from '../../../actions/institute/ManageEvents/manageEventsAction';
import { getSchoolAction } from '../../../actions/institute/ManageSchool/manageSchoolAction';

import DatePicker from "react-datepicker";

const ExampleCustomTimeInput = ({ date, value, onChange }) => (
       <input
            value={value}
            onChange={(e) => onChange(e.target.value)}
            style={{ border: "solid 1px pink" }}
        />
      );

class CreateEvents extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
            category:'',
            input:{
                school_id:"",
                event_title:"",
                description:"",
                date: new Date(),
                venue: "",
                coordinator_name:'',
                coordinator_number:''
            },
            file: null,
	        eventData : {},
            editId : '',
            url:'',
            imageUrl:'',
            image:null,
            uploadImageStatus:null,
            uploadFileStatus:null,
            school:[],
            createModal:true,
			role:this.props.role,
            isSend:false

		};
	}

    componentDidMount(){
        if(localStorage.getItem('school')){
            let school = JSON.parse(localStorage.getItem('school'))
            let input = {...this.state.input}
            input.school_id = school.id
            this.setState({ input:input });
        }

        this.props.getSchoolAction()
        if(this.props.editId){
            this.props.getEventByIdAction(this.props.editId)
        }
	}

    componentWillReceiveProps(nextProps){
        let input = {...this.state.input}
        if(this.state.school !== nextProps.schoolState.list){
			this.setState({
				school: nextProps.schoolState.list
			})
		}
        if(this.props.eventData.list !== nextProps.eventData.list){
            let data = nextProps.eventData.list
            input.school_id = data.school_id ? data.school_id : ''
            input.event_title = data.event_title
            input.description = data.description
            input.date = new Date(data.date)
            input.venue = data.venue
            input.coordinator_name = data.coordinator_name
            input.coordinator_number = data.coordinator_number
            this.setState({url:data.file_url,imageUrl:data.image_url,input:input})
        }
        if(this.state.uploadFileStatus !== (nextProps.uploadFileStatus && nextProps.uploadFileStatus.status && nextProps.uploadFileStatus.status.file)){
            if(nextProps.uploadFileStatus.status.file){
                this.setState({
                    uploadFileStatus:nextProps.uploadFileStatus.status.file,
                    url:nextProps.uploadFileStatus.status.file
                })
            }
        }

        if(this.state.uploadImageStatus !== (nextProps.uploadImageStatus && nextProps.uploadImageStatus.status && nextProps.uploadImageStatus.status.file)){
            if(nextProps.uploadImageStatus.status.file){
                this.setState({
                    uploadImageStatus:nextProps.uploadImageStatus.status.file,
                    imageUrl:nextProps.uploadImageStatus.status.file
                })
            }
        }
        this.setState({ input:input });

	}
    toggle=()=>{
        this.props.toggleClose()
    }
    componentWillUnmount(){
        this.clearData()
    }
    clearData =()=>{
        this.props.clearCreateEventsAction()
        this.props.clearEditEventsAction()
        this.props.clearEventsByIdAction()
        this.props.clearUploadEventAction()
        this.props.clearUploadEventImageAction()
        this.props.toggleClose()
    }
    createEvent=()=>{
        let data = {
            ...this.state.input,
            date:moment(this.state.input.date).format('YYYY-MM-DD HH:MM'),
            file_url:this.state.url,
            image_url:this.state.imageUrl,
            "status": this.state.isSend ? '1' : '0'
        }
        if(this.props.editId){
            this.props.editEventsAction({id : this.props.editId,data:data})
        }else{
            this.props.createEventsAction(data)
        }
        this.clearData()
        this.props.getEvents()
    }
    modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={ this.state.modal }>
                    <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
                    <MDBModalBody>
                        <div className='alertContainer'>
                            <h4> Do you want to {this.state.editId ? 'Update' : 'save'} it ? </h4>
                            <h6> Please check the information before submitting</h6>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleClose}>No</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.handleSave}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
			</MDBContainer>
		)
	}
    submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
		if(event.target.checkValidity()) {
            this.setState({
                createModal:false,
                modal: true,
            })
		}
	}
    handleSave=async()=>{
        if(this.state.file && this.state.image){
            await this.props.uploadEventFileAction(this.state.file)
            await this.props.uploadEventImageAction(this.state.image)
            this.createEvent()
        }
        else if(this.state.file){
            await this.props.uploadEventFileAction(this.state.file)
            this.createEvent()
        }
        else if(this.state.image){
            await this.props.uploadEventImageAction(this.state.image)
            this.createEvent()
        }
        else{
            this.createEvent()
        }
    }
    
    onContentChange =(value)=>{
        this.setState({context:value})
    }
    changeHandler = event => {
        let input = {...this.state.input}
        input[event.target.name] = event.target.value
		this.setState({ input:input });
	};
    toggleClose = ()=>{
		this.setState({
			modal: false,
            createModal:true
		})
	}

    handleFile=(event)=>{
        this.setState({file : event.target.files[0]})
        // this.props.uploadEventFileAction(this.state.file)
    }
    handleImage=(event)=>{
        this.setState({image : event.target.files[0]})
        // this.props.uploadEventImageAction(this.state.image)
    }
    handleDate =(value)=>{
        let input = {...this.state.input}
        input.date = value
		this.setState({ input:input });
    }
    render(){
        const { activeTab } = this.state
        // if(!isLogined()) this.props.history.push('/')
        return(
            <>
            {this.modalContainer()}
            <MDBContainer>
                <MDBModal isOpen={this.state.createModal} >
                    <MDBModalHeader toggle={this.toggle}>{ this.props.editId ? 'Update Event' : 'Create Event'}</MDBModalHeader>
                    <form onSubmit={this.submitHandler} noValidate>
                        <MDBModalBody>
                            <div className="card">
                                <div className="card-body">
                                    {
                                        this.state.role === 'institute admin' ? 
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Select School
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    : 
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <select name="school_id" value={this.state.input['school_id']} className="form-control show-tick" onChange={this.changeHandler} required>
                                                        <option value="">Select School</option>
                                                        {this.state.school.map((item)=>{
                                                            return <option value={item.id}>{item.school_name}</option>
                                                        })}
                                                    </select>
                                                    <div className="invalid-feedback">
                                                        required school name.
                                                    </div>                                 
                                                </div>
                                            </div>
                                        </div>:''
                                    }
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Event Title
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                :
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">

                                                <input type="text" className="form-control" value ={this.state.input['event_title']} 
                                                name="event_title" onChange={this.changeHandler} placeholder="Event title" required/>
                                                
                                                <div className="invalid-feedback">
                                                    required event title.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Description
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                :
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                <textarea type="text" className="form-control" value ={this.state.input['description']} 
                                                name="description" onChange={this.changeHandler} placeholder="Description" required></textarea>
                                                <div className="invalid-feedback">
                                                    required description.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Date And Time
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                :
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                            <DateTimePicker
                                                    value={this.state.input.date}
                                                    onChange={(date) => this.handleDate(date)}
                                                    placeholderText="Event date and Time"
                                                    className="form-control "
                                                    minDate={!this.props.editId && new Date()}
                                                />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Venue
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                :
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                <input type="text" className="form-control" value ={this.state.input['venue']} 
                                                name="venue" onChange={this.changeHandler} placeholder="Venue" required />
                                                <div className="invalid-feedback">
                                                    required venue.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Coordinator
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                :
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                <input type="text" className="form-control" value ={this.state.input['coordinator_name']} 
                                                name="coordinator_name" onChange={this.changeHandler} placeholder="Coordinator name" required />
                                                <div className="invalid-feedback">
                                                    required coordinator name.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                            Coordinator Contact No
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                :
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                <input type="text" className="form-control" value ={this.state.input['coordinator_number']} 
                                                name="coordinator_number" onChange={this.changeHandler} placeholder="Coordinator number" />
                                                <div className="invalid-feedback">
                                                    required coordinator no.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Upload Files
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                :
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="">
                                                <input type="file" className="form-control"
                                                    onChange={this.handleFile} accept = "application/pdf,.csv,.doc"/>
                                                <div className="invalid-feedback">
                                                    required file.
                                                </div>
                                                <div className="">
                                                    {this.state.file ? '' : this.state.url}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Upload Image
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                :
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="">
                                                <input type="file" className="form-control"
                                                    onChange={this.handleImage} accept = "image/png, image/gif,.jpg, image/jpeg"/>
                                                <div className="invalid-feedback">
                                                    required image.
                                                </div>
                                                <div className="">
                                                    {this.state.image ? '' : this.state.imageUrl}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn>
                            <MDBBtn color="secondary" type="submit">{this.props.editId ? 'Update': 'Save'}</MDBBtn>
                            <MDBBtn color="secondary" type="submit" onClick={()=>{this.setState({isSend:true})}}>Send</MDBBtn>
                        </MDBModalFooter>
                    </form>
                </MDBModal>
            </MDBContainer>
            </>
        )
    }
}

const mapStateToProps = state => ({
	eventData : state.getEventsByIdReducer,
    editStatus :state.editEventsReducer,
    uploadFileStatus :state.uploadEventFileReducer,
    uploadImageStatus :state.uploadEventImageReducer,
    schoolState: state.schoolReducer,
	role: state.settings.role,
})

const mapDispatchToProps = dispatch => ({
	createEventsAction: (e) => dispatch(createEventsAction(e)),
	editEventsAction: (e) => dispatch(editEventsAction(e)),
	clearCreateEventsAction: (e) => dispatch(clearCreateEventsAction (e)),
	clearEditEventsAction: (e) => dispatch(clearEditEventsAction(e)),
	getEventByIdAction: (e) => dispatch(getEventByIdAction(e)),
	uploadEventFileAction: (e) => dispatch(uploadEventFileAction(e)),
	uploadEventImageAction: (e) => dispatch(uploadEventImageAction(e)),
	clearEventsByIdAction: (e) => dispatch(clearEventsByIdAction(e)),
	clearUploadEventAction: (e) => dispatch(clearUploadEventAction(e)),
	clearUploadEventImageAction: (e) => dispatch(clearUploadEventImageAction(e)),  
	getSchoolAction: (e) => dispatch(getSchoolAction(e)),

})

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvents)