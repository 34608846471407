import { GET_GOAL, 
    CREATE_GOAL,
    EDIT_GOAL, 
    GET_GOAL_BY_ID,
    GET_STUDENT_GOAL,
    SHOW_SNACKBAR
} from '../../../Constants/index';
import axios from 'axios';
import { manageGoal } from '../../../../utils/Api'
import { getToken } from '../../../../utils/token'

export const getGoalAction = (params) => {
	return dispatch => {
        const url = `${manageGoal.getGoal}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_GOAL,
                    payload: {
                        list : data.data
                    }
                })
            }else {
                dispatch({
                    type: GET_GOAL,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const getStudentGoalAction = (params) => {
	return dispatch => {
        const url = `${manageGoal.getStudentGoal}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_STUDENT_GOAL,
                    payload: {
                        list : data.response
                    }
                })
            }else {
                dispatch({
                    type: GET_STUDENT_GOAL,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearGetGoalAction = () => {
	return dispatch => {
        dispatch({
            type: GET_GOAL,
            payload: {
                list : []
            }
        })
    }
}

export const createGoalAction = (data, history) => {
	return dispatch => {
        const url = `${manageGoal.createGoal}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: CREATE_GOAL,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                 history.push("/teacher/manage-goal");
            }else {
                dispatch({
                    type: CREATE_GOAL,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const clearCreateGoalAction = () => {
	return dispatch => {
        dispatch({
            type: CREATE_GOAL,
            payload: {
                list : null
            }
        })
    }
}

export const clearEditGoalAction = () => {
	return dispatch => {
        dispatch({
            type: EDIT_GOAL,
            payload: {
                list : null
            }
        })
    }
}

export const editGoalAction = ({id,data, history}) => {
	return dispatch => {
        const url = `${manageGoal.editGoal(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PATCH',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: EDIT_GOAL,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                 history.push("/teacher/manage-goal");
            }else {
                dispatch({
                    type: EDIT_GOAL,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const getGoalByIdAction = (id) => {
	return dispatch => {
        const url = `${manageGoal.getGoalById(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_GOAL_BY_ID,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_GOAL_BY_ID,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearSkillByIdAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_GOAL_BY_ID,
            payload: {
                list : {}
            }
        })
    }
}