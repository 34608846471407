import React, { Component } from 'react'

export default class AnswerDetail extends Component {
    constructor(props) {
		super(props);
		this.state = {
			questionsList:[],
            status:this.props.status,
            descriptiveAns:[]
		};
	}
    componentDidMount(){
    }
    componentWillReceiveProps(nextProps){
        if(this.state.questionsList !== nextProps.questions){
            this.setState({questionsList: nextProps.questions})
        }
	}
    changeHandler=(e,data,index)=>{
        
        let _ = {...data}
        _.student_answer_option.obtained_marks = Number(e.target.value)

        let questionsList = [...this.state.questionsList]
        questionsList[index] = _
        this.setState({questionsList:questionsList},()=>{
            this.props.setMark(this.state.questionsList)
        })
    }
    getCorrectAnswer=(options,student_answer_option)=>{
        if(options.length>0){
            let _ = {}
            options.map((item,i)=>{
                if(item.id === (student_answer_option && student_answer_option.answer_id)){
                    _ = item
                }
            })
            if(_.is_correct == '1'){
                return{name:_.name,correct:true}
            }
            else{
                return{name:_.name,correct:false}
            }
        }
        else{
            return{
                name:student_answer_option.answer,
            }
        }
    }
    textFormat=(data,i)=>{
        return <div style={{margin:"20px"}}>
        <div className="row">
            <h6><b>Question {i+1} in Text Format</b></h6>
        </div>
        <div className="text-format">
            <div className="row mt-2" >
                <div className="col-lg-9 col-md-4 col-sm-6">
                    <h6><b>Question :</b> {data.name} </h6>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                    <h6>Mark:{data.marks}</h6>
                    <h6>Obtained Mark:
                        {
                            data.is_descriptive ? this.markInputBox(data,i) : 
                            (this.getCorrectAnswer(data.options,data.student_answer_option).correct ? data.marks :0)
                        }</h6>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-lg-9 col-md-4 col-sm-6">
                    <h6><b>Answer : </b> {this.getCorrectAnswer(data.options,data.student_answer_option).name}</h6>
                </div>
            </div>
            {
                data.is_descriptive ? '':
                <div className="row mt-2">
                    <div className="col-lg-9 col-md-4 col-sm-6">
                        <h6><b>{this.getCorrectAnswer(data.options,data.student_answer_option).correct ? 'Correct Answer' : 'Wrong Answer'}</b></h6>
                    </div>
                </div>
            }
            
        </div>
    </div> 
    }
    markInputBox=(data,i)=>{
        if(this.state.status === 'pending' && data.is_descriptive){
            return <input type="number" className="form-control" value = {this.state.mark_obtained} name="mark_obtained" 
            onChange={(e)=>{this.changeHandler(e,data,i)}} placeholder="Enter Marks" required max={data.marks}/>
        }
        else{
            return data.student_answer_option.obtained_marks
        }
    }
    ImageFormat=(data,i)=>{
        return  <div style={{margin:"20px"}}>
        <div className="row">
            <h6><b>Question {i+1} in Image Format</b></h6>
        </div>
        <div className="text-format">
            <div className="row mt-2" >
                <div className="col-lg-9 col-md-4 col-sm-6">
                    <h6><b>Question :</b>
                        <img src={data.image_url} 
                        alt="imageurl" style={{paddingLeft:"20px",width:'50%'}}/>
                    </h6>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                    <h6>Mark:{data.marks}</h6>
                    <h6>Obtained Mark: {
                            data.is_descriptive ?  this.markInputBox(data,i) : 
                            (this.getCorrectAnswer(data.options,data.student_answer_option).correct ? data.marks :0)
                        }</h6>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-lg-9 col-md-4 col-sm-6">
                    <h6><b>Answer : </b>{this.getCorrectAnswer(data.options,data.student_answer_option).name}</h6>
                    {
                    data.is_descriptive ? '':
                    <div className="row mt-2">
                        <div className="col-lg-9 col-md-4 col-sm-6">
                            <h6><b>{this.getCorrectAnswer(data.options,data.student_answer_option).correct ? 'Correct Answer' : 'Wrong Answer'}</b></h6>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
    }
    VideoFormat=(data,i)=>{
        return  <div style={{margin:"20px"}}>
        <div className="row">
            <h6><b>Question {i+1} in Video Format</b></h6>
        </div>
        <div className="text-format">
            <div className="row mt-2" >
                <div className="col-lg-9 col-md-4 col-sm-6">
                    <h6><b>Question :</b>
                        <iframe src={data.video_url}
                        title="video"></iframe>
                    </h6>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                    <h6>Mark:{data.marks}</h6>
                    <h6>Obtained Mark: {
                            data.is_descriptive ? this.markInputBox(data,i) : 
                            (this.getCorrectAnswer(data.options,data.student_answer_option).correct ? data.marks :0)
                        }</h6>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-lg-9 col-md-4 col-sm-6">
                    <h6><b>Answer : </b>{this.getCorrectAnswer(data.options,data.student_answer_option).name}</h6>
                    {
                    data.is_descriptive ? '':
                    <div className="row mt-2">
                        <div className="col-lg-9 col-md-4 col-sm-6">
                            <h6><b>{this.getCorrectAnswer(data.options,data.student_answer_option).correct ? 'Correct Answer' : 'Wrong Answer'}</b></h6>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
    }
    render() {
        return (
            <div className="section-body" style={{width:"100%"}}>
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center ">
                        <div className="header-action">
                            <h1 className="page-title">Examination Detail</h1>
                        </div>
                    </div>
				</div>
                {
                    this.state.questionsList && this.state.questionsList.map((item,i)=>{
                        if(item.image_url){
                            return this.ImageFormat(item,i)
                        }
                        else if(item.video_url){
                            return this.VideoFormat(item,i)
                        }
                        else{
                            return this.textFormat(item,i)
                        }
                    })
                }
            </div>
        )
    }
}
