import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import configureStore from './store';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "./index.scss";
import axios from 'axios';
import md5 from 'md5'


// import registerServiceWorker from './registerServiceWorker';
// axios.defaults.baseURL = 'http://180.149.247.248:3064/';
// axios.defaults.baseURL = 'http://localhost:3064/'; 
// axios.defaults.baseURL = 'https://apielearning.azurewebsites.net/';
// axios.defaults.baseURL = 'https://elearningapinew.azurewebsites.net/';

// latest api server
axios.defaults.baseURL = 'https://elearningappapi.azurewebsites.net/';

//CLIENT API SERVER LINUX
// axios.defaults.baseURL = 'https://apilearningdevtest.azurewebsites.net/';


let hashUrl = ''

hashUrl = md5(window.origin);
// for developmet perpose
// super admin
// hashUrl = md5('http://elearning.php-dev.in')
// institute admin
// hashUrl = md5('http://client1.elearning.php-dev.in')ś

axios.defaults.headers.common['url'] = hashUrl
axios.defaults.headers.post['Content-Type'] = 'application/json';

ReactDOM.render(
    <Provider store={configureStore()}>
        <App />
    </Provider>,
    document.getElementById('root')
);
// registerServiceWorker();
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
