import React, { Component } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { CSVLink } from "react-csv";
import Loader from "../../common/Loader";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import Dropzone from "../../common/DropzoneExample";
import { isLogined } from "../../../utils/token";
import {
  getInstitutesAction,
  uploadInstitutesFileAction,
  clearUploadInstitutesFileAction,
  clearGetInstitutesAction,
} from "../../../actions/Admin/ManageInstitutes/manageInstitutesAction";
import "./style.scss";
import {
  getCitiesAction,
  getStateAction,
} from "../../../actions/institute/Utils/utilsAction";

const csvSampleTempleteHeaders = [
  { label: "institute_Name", key: "institute_Name" },
  { label: "contact_Person", key: "contact_Person" },
  { label: "address1", key: "address1" },
  { label: "address2", key: "address2" },
  { label: "email", key: "email" },
  { label: "mobile", key: "mobile" },
  { label: "city", key: "city" },
  { label: "username", key: "username" },
  { label: "state", key: "state" },
  { label: "password", key: "password" },
  { label: "zip", key: "zip" },
  { label: "institute_image", key: "institute_image" },
  { label: "domain_url", key: "domain_url" },
  { label: "licency_expiry", key: "licency_expiry" },
  { label: "status", key: "status" },
];

const csvSampleTempleteData = [
  {
    institute_Name: "institute1",
    contact_Person: "contact_Person1",
    address1: "institute1_address1",
    address2: "institute1_address2",
    email: "institute11@gmail.com",
    mobile: "9090909090",
    city: "ratnagiri",
    username: "institute1dd_username",
    state: "maharashtra",
    password: "inst1@123",
    zip: "415612",
    institute_image: "",
    domain_url: "https://institdddute1.in",
    licency_expiry: "2022-04-27 00:00:00",
    status: 0,
  },
];

let csvSampleTemplete = {
  data: csvSampleTempleteData,
  headers: csvSampleTempleteHeaders,
  filename: "sample-institutes.csv",
};

class ManageInstitutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      show: false,
      institutes: [],
      tableBody: { columns: [], rows: [] },

      viewModal: false,
      selectedInstitute: null,
      uploadModal: false,
      exportCsv: null,
      search: {},
      searchInstitutes: null,
      uploadFile: null,
      uploadFileStatus: null,
      stateList: [],
      cityList: [],
    };
  }

  componentDidMount() {
    this.props.getInstitutesAction();
    this.props.getStateAction();
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevState.search.state !== this.state.search.state) {
      if (this.state.search.state) {
        this.props.getCitiesAction(this.state.search.state);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.stateList !== nextProps.stateList) {
      this.setState({
        stateList: nextProps.stateList,
      });
    }

    if (this.state.cityList !== nextProps.cityList) {
      this.setState({
        cityList: nextProps.cityList,
      });
    }
    if (this.state.institutes !== nextProps.institutes) {
      this.setState(
        {
          institutes: nextProps.institutes.list,
        },
        () => {
          this.constructTableData();
          this.exportCsv();
        }
      );
    }
    if (this.state.uploadFileStatus !== nextProps.uploadFileStatus.status) {
      this.setState(
        {
          uploadFileStatus: nextProps.uploadFileStatus.status,
        },
        () => {
          if (
            this.state.uploadFileStatus &&
            this.state.uploadFileStatus.errcode === 0
          ) {
            this.props.getInstitutesAction();
            this.uploadToggleClose();
          }
        }
      );
    }
    if (this.state.stateList !== nextProps.stateList) {
      this.setState({
        stateList: nextProps.stateList,
      });
    }

    if (this.state.cityList !== nextProps.cityList) {
      this.setState({
        cityList: nextProps.cityList,
      });
    }
  }

  toggle = (institute, i) => () => {
    this.setState({
      viewModal: true,
      selectedInstitute: institute,
    });
  };

  uploadToggle = () => {
    this.setState({
      uploadModal: true,
    });
  };

  toggleClose = () => {
    this.setState({
      viewModal: false,
      selectedInstitute: null,
    });
  };

  handleSearch = (name, value) => {
    this.setState({ search: { ...this.state.search, [name]: value } });
  };

  uploadToggleClose = () => {
    this.props.clearUploadInstitutesFileAction();
    this.setState({
      uploadModal: false,
      uploadFile: null,
    });
  };

  exportPdf = () => {
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0, 200, 0);
      pdf.save("institutes.pdf");
    });
  };

  exportCsv = () => {
    const exportCsvHeaders = [
      { label: "Institute Name", key: "institute_Name" },
      { label: "State", key: "state" },
      { label: "City", key: "city" },
      { label: "Created Date", key: "time" },
      { label: "Licency Expiry", key: "licency_expiry" },
      { label: "Active", key: "isActive" },
    ];
    const exportCSVData = this.state.institutes.map((item) => {
      let obj = {
        institute_Name: item.institute_name,
        state: item.state_name,
        city: item.city_name,
        time: item.time,
        licency_expiry: moment(item.licency_expiry).format("DD MMM YYYY"),
        isActive: item.status === 1 ? "Active" : "Inactive",
      };
      return obj;
    });
    let csv = {
      data: exportCSVData,
      headers: exportCsvHeaders,
      filename: "institutes-report.csv",
      style: { color: "white" },
    };
    this.setState({
      exportCsv: csv,
    });
  };

  searchHandler = () => {
    let search = {
      name: this.state.search.name,
      state_id: this.state.search.state,
      city_id: this.state.search.city,
      status: this.state.search.status,
      licency_expiry: this.state.search.expiry
        ? moment(this.state.search.expiry).format("YYYY-MM-DD")
        : "",
    };
    Object.keys(search).forEach(
      (key) => search[key] == "" && delete search[key]
    );
    this.props.getInstitutesAction(search);
  };

  searchClear = () => {
    this.setState(
      {
        search: {},
      },
      () => {
        this.props.getInstitutesAction();
      }
    );
  };

  handleFile=(event)=>{
    this.setState({uploadFile : event.target.files[0]})
}

handleUploadFile = event => {
event.preventDefault();
event.target.className += " was-validated";
    if(this.state.uploadFile){ 
      this.props.uploadInstitutesFileAction(this.state.uploadFile);
    }
}

  constructTableData = () => {
    let tableData = {
      columns: [
        {
          label: "#",
          field: "id",
        },
        {
          label: "Institute Name",
          field: "institute",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "State",
          field: "state",
          width: 270,
        },
        {
          label: "City",
          field: "city",
          width: 270,
        },
        {
          label: "Created Date",
          field: "createdDate",
          width: 270,
        },
        {
          label: "Expiry Date",
          field: "expiryDate",
          width: 270,
        },
        {
          label: "Status",
          field: "status",
          width: 270,
        },
        {
          label: "Action",
          field: "action",
          width: 270,
        },
      ],
      rows: this.actionContainer(),
    };
    this.setState({
      tableBody: tableData,
    });
  };
  getName = (id, arr) => {
    let name = "";
    arr.map((item) => {
      if (item.id === id) {
        name = item.name;
      }
    });
    return name;
  };
  actionContainer = () => {
    return this.state.institutes.map((item, i) => {
      let date = new Date(item.time);
      let expiryDate = moment(item.licency_expiry);
      let currentDate = moment(new Date());
      let countDays = expiryDate.diff(currentDate, "days");
      let checkExpiryDate = false;
      if (countDays <= 30) {
        checkExpiryDate = true;
      }
      let obj = {
        id: i + 1,
        institute: item.institute_name,
        state: item.state_name,
        city: item.city_name,
        createdDate: moment(date, "DD/MM/YYYY").format("DD MMM YYYY"),
        expiryDate: (
          <div style={{ color: checkExpiryDate ? "#FFBF00" : "#666A6D" }}>
            {moment(new Date(item.licency_expiry), "DD/MM/YYYY").format(
              "DD MMM YYYY"
            )}
          </div>
        ),
        status: item.status ? (
          <div style={{ color: "green" }}>Active</div>
        ) : (
          <div style={{ color: "red" }}>Inactive</div>
        ),
        action: (
          <div className="actionContainer">
            <MDBIcon
              icon="edit"
              size="lg"
              className="icon"
              onClick={() => {
                this.props.history.push(
                  `/super-admin/edit-institutes/${item.id}`
                );
              }}
            />
            <MDBIcon
              icon="eye"
              size="lg"
              className="icon"
              onClick={this.toggle(item, i)}
            />
          </div>
        ),
      };
      return obj;
    });
  };

  getInstitutesDetails = (item) => {
    return (
      <div className="card-body">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">Institutes name</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
              {item.institute_name}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">Address 1</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
              {item.address1}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">Address 2</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
              {item.address2}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">City</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
              {item.city_name}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">State</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
            {item.state_name}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">Zip</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
              {item.zip}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">Contact Person</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
              {item.contact_person}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">Email</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
              {item.email}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">Mobile</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
              {item.mobile}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">User name</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
              {item.username}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">License Expiry</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
              {moment(
                new Date(item.licency_expiry),
                "DD/MM/YYYY"
              ).format("DD MMM YYYY")}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">Status</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
              {item.status === 1
                ? "Active"
                : "Inactive"}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 inputField">
            <div className="input-group">Institute URL</div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 inputField">
            <div className="input-group">:</div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-6 inputField">
            <div className="input-group">
              <a href={item.domain_url} target="_blank">
                {item.domain_url}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  searchContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="input-group">
                  <input
                    type="text"
					value={
                        Object.keys(this.state.search).length === 0
                          ? ""
                          : this.state.search.name
                      }
                      onChange={(e) =>
                        this.handleSearch("name", e.target.value)
                      }
                    className="form-control"
                    placeholder="Search by name"
                  />
                </div>
              </div>
              <div className="col-md-2 col-sm-12">
                <select
				  value={
					Object.keys(this.state.search).length === 0
					  ? ""
					  : this.state.search.state
				  }
				  onChange={(e) =>
					this.handleSearch("state", e.target.value)
				  }
                  className="form-control show-tick"
                >
                  <option value="">Select State</option>
                  {this.state.stateList.map((item, i) => {
                    return <option value={item.id}>{item.name}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-2 col-sm-12">
                <select
				  value={
					Object.keys(this.state.search).length === 0
					  ? ""
					  : this.state.search.city
				  }
				  onChange={(e) =>
					this.handleSearch("city", e.target.value)
				  }
				  disabled={!this.state.search.state}
                  className="form-control show-tick"
                >
                  <option value="">Select City</option>
                  {this.state.cityList.map((item, i) => {
                    return <option value={item.id}>{item.name}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-2 col-sm-12">
                <select
                  name="status"
				  value={
					Object.keys(this.state.search).length === 0
					  ? ""
					  : this.state.search.status
				  }
				  onChange={(e) =>
					this.handleSearch("status", e.target.value)
				  }
                  className="form-control show-tick"
                >
                  <option value="">Status</option>
                  <option value={1}>Active</option>
                  <option value={0}>Inactive</option>
                </select>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="input-group">
				<DatePicker
                  selected={this.state.search.expiry}
                  onChange={(value) => this.handleSearch("expiry", value)}
                  placeholderText="Expiry date"
                  className="form-control "
                />
                </div>
              </div>
            </div>
            <div className="btn-container">
              <div> <MDBBtn
                      color="primary"
                      className="btn"
                      onClick={this.searchHandler}
                    >
                      Search
                    </MDBBtn>
                    <MDBBtn
                      color="primary"
                      className="btn"
                      onClick={this.searchClear}
                    >
                      Clear
                    </MDBBtn></div>
              <div>
              <MDBBtn
                            color="primary"
                            className="btn"
                            onClick={() => {
                              this.props.history.push(
                                "/super-admin/create-institutes"
                              );
                            }}
                          >
                            Create
                          </MDBBtn>
                          <MDBBtn
                            color="primary"
                            className="btn"
                            onClick={() => {
                              this.uploadToggle();
                            }}
                          >
                            Bulk Upload
                          </MDBBtn>
                          <MDBBtn
                            color="primary"
                            className="btn"
                            onClick={this.exportPdf}
                          >
                            PDF{" "}
                            <MDBIcon
                              icon="download"
                              size="lg"
                              className="icon"
                            />
                          </MDBBtn>
                          <MDBBtn color="primary" className="btn">
                            {this.state.exportCsv && (
                              <CSVLink {...this.state.exportCsv}>
                                CSV
                                <MDBIcon
                                  icon="download"
                                  size="lg"
                                  className="icon"
                                />
                              </CSVLink>
                            )}
                          </MDBBtn>
              </div>
              </div>
          </div>
        </div>
      </TabPane>
    );
  };

  tableContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body" id="capture">
            <h1 className="page-title">Institutes List</h1>
            {this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={["title"]}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching={false}
                />
              </div>
            )}
          </div>
        </div>
      </TabPane>
    );
  };

  modalContainer = () => {
    return (
      <MDBContainer>
          return (
            <MDBModal
              isOpen={this.state.viewModal}
              toggle={this.toggle}
            >
              <MDBModalHeader toggle={this.toggleClose}>
                Institute Details
              </MDBModalHeader>
              <MDBModalBody>
                <div className="card">
                  {this.state.selectedInstitute && this.getInstitutesDetails(this.state.selectedInstitute)}
                </div>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggleClose}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          );
        <MDBModal isOpen={this.state.uploadModal} toggle={this.uploadToggle}>
          <MDBModalHeader toggle={this.uploadToggleClose}>
            Upload your files
          </MDBModalHeader>
        	<form onSubmit={this.handleUploadFile} noValidate>
          <MDBModalBody>
          <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Upload Files
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                :
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="">
                                                <input type="file" className="form-control"
                                                    onChange={this.handleFile} required={this.state.url ? false: true} accept = ".csv"/>
                                                <div className="invalid-feedback">
                                                    required file.
                                                </div>
                                                <div className="">
                                                    {this.state.file ? '' : this.state.url}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
            <small id="fileHelp" className="form-text text-muted dowloadLable">
              <div style={{ color: "red", marginBottom: "10px" }}>
                {this.state.uploadFileStatus
                  ? this.state.uploadFileStatus.msg + " *"
                  : ""}
              </div>
              <MDBBtn color="primary">
                  <CSVLink  style={{color:'white'}}{...csvSampleTemplete}>
                    Download Sample Template
                    <MDBIcon icon="download" size="lg" className="icon" />
                  </CSVLink>
              </MDBBtn>
            </small>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" type='submit'>
              Upload
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.uploadToggleClose}>
              Close
            </MDBBtn>
          </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    );
  };

  pageContainer = () => {
    const { activeTab } = this.state;
    return (
      <TabContent activeTab={activeTab}>
        {this.searchContainer()}
        {this.tableContainer()}
      </TabContent>
    );
  };

  render() {
    if (!isLogined()) this.props.history.push("/");
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">Manage Institutes</h1>
                <ol className="breadcrumb page-breadcrumb">
                  <li className="breadcrumb-item">Search</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.pageContainer()}</div>
          {this.state.viewModal && this.modalContainer()}
          {this.state.uploadModal&& this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  uploadFileStatus: state.uploadInstitutesFileReducer,
  stateList: state.utillReducer.stateList,
  cityList: state.utillReducer.cityList,
  institutes: state.getInstitutesReducer,
});
const mapDispatchToProps = (dispatch) => ({
  getInstitutesAction: (e) => dispatch(getInstitutesAction(e)),
  uploadInstitutesFileAction: (e) => dispatch(uploadInstitutesFileAction(e)),
  clearUploadInstitutesFileAction: (e) =>
    dispatch(clearUploadInstitutesFileAction(e)),
  clearGetInstitutesAction: (e) => dispatch(clearGetInstitutesAction(e)),
  getCitiesAction: (e) => dispatch(getCitiesAction(e)),
  getStateAction: (e) => dispatch(getStateAction()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ManageInstitutes);
