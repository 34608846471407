import { GET_STATESANDCITIES } from '../../../actions/Constants/index'

const initialState = {
    list : {
        states:[],
        cities:[]
    }
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_STATESANDCITIES:
			return {
				...state,
                list : action.payload.list,
			}
        default:
            return state
    }
}
