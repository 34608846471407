import { GET_EVENTS, 
    CREATE_EVENTS,
    EDIT_EVENTS, 
    GET_EVENTS_BY_ID, 
    SHOW_SNACKBAR,
    UPLOAD_FILE_EVENTS,UPLOAD_IMAGE_EVENTS,GET_EVENT_DROPDOWN_DATA} from '../../Constants/index';
import axios from 'axios';
import { manageEvents } from '../../../utils/Api'
import { getToken } from '../../../utils/token'

export const getEventsAction = (params) => {
    let params2= localStorage.getItem("school") ?{
        ...params,
        school_id:JSON.parse(localStorage.getItem("school")).id
      }
     : params 
	return dispatch => {
        const url =`${manageEvents.searchEvents}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params2
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_EVENTS,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_EVENTS,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearGetEventsAction = () => {
	return dispatch => {
        dispatch({
            type: GET_EVENTS,
            payload: {
                list : []
            }
        })
    }
}

export const createEventsAction = (data) => {
	return dispatch => {
        const url = `${manageEvents.createEvents}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: CREATE_EVENTS,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
            }else {
                dispatch({
                    type: CREATE_EVENTS,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const clearCreateEventsAction = () => {
	return dispatch => {
        dispatch({
            type: CREATE_EVENTS,
            payload: {
                list : null
            }
        })
    }
}

export const clearEditEventsAction = () => {
	return dispatch => {
        dispatch({
            type: EDIT_EVENTS,
            payload: {
                list : null
            }
        })
    }
}

export const editEventsAction = ({id,data}) => {
	return dispatch => {
        const url = `${manageEvents.editEvents(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PATCH',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: EDIT_EVENTS,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
            }else {
                dispatch({
                    type: EDIT_EVENTS,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const getEventByIdAction = (id) => {
	return dispatch => {
        const url = `${manageEvents.getEventsById(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_EVENTS_BY_ID,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_EVENTS_BY_ID,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearEventsByIdAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_EVENTS_BY_ID,
            payload: {
                list : {}
            }
        })
    }
}


export const uploadEventFileAction = (data) => {
	return dispatch => {
        let formData = new FormData();
        formData.append('file', data);
        const url = `${manageEvents.uploadEventFile}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data : formData
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: UPLOAD_FILE_EVENTS,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: UPLOAD_FILE_EVENTS,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearUploadEventAction = (id) => {
	return dispatch => {
        dispatch({
            type: UPLOAD_FILE_EVENTS,
            payload: {
                list : {}
            }
        })
    }
}

export const uploadEventImageAction = (data) => {
	return dispatch => {
        let formData = new FormData();
        formData.append('image', data);
        const url = `${manageEvents.uploadImage}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data : formData
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: UPLOAD_IMAGE_EVENTS,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: UPLOAD_IMAGE_EVENTS,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearUploadEventImageAction = (id) => {
	return dispatch => {
        dispatch({
            type: UPLOAD_IMAGE_EVENTS,
            payload: {
                list : {}
            }
        })
    }
}

export const getEventsDropdownAction = () => {
	return dispatch => {
        const url = `${manageEvents.dropDownData}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_EVENT_DROPDOWN_DATA,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_EVENT_DROPDOWN_DATA,
                    payload: {
                        list : null
                    }
                })
            }
        })
    }
}

export const clearGetEventDropdownAction = () => {
	return dispatch => {
        dispatch({
            type: GET_EVENT_DROPDOWN_DATA,
            payload: {
                list : null
            }
        })
    }
}