import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
// import './style.scss';
import { connect } from 'react-redux';
import { MDBBtn, MDBFormInline, MDBInput} from 'mdbreact';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { getAssignmenttAction, getClassAction, getExamtAction, getSectionAction, getSkilltAction, getSubjectAction, getSubjectForExamAction } from '../../../../actions/institute/Utils/utilsAction';
import { clearCreateSkillRuleAction, clearEditSkillRuleAction, clearSkillByIdAction, createSkillRuleAction, editSkillRuleAction, getSkillRuleByIdAction } from '../../../../actions/institute/Teacherdashboard/ManageSkill/ManageSkillRuleAction';
import moment from 'moment';

class AddNewSkillRule extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
            input:{
                class_id:'',
                section_id:'',
                subject_id:'',
                skill_id:'',
                examinationOrAssignment_id:[],
                rule_for:'',
                rule_condition:'',
                status:'',
            },
            selectAll:false,
            
            editId: this.props.match.params.id,
            createStatus: null,
            modal: false,

            classList:[],
            sectionList:[],
            subjectList:[],
            studentList:[],
            assignmentList:[],
            examList:[],
            skillList:[],

            showError:false

		};
	}

    componentDidMount(){
        let search = {
            school_id: JSON.parse(localStorage.getItem('school')).id
        }
		this.props.getClassAction(search)
        
        if(this.state.editId){
            this.props.getSkillRuleByIdAction(this.state.editId)
        }
	}

    componentDidUpdate(prevProp,prevState){
        if(this.state.input.class_id !== prevState.input.class_id) {
            if(this.state.input.class_id){
				let search = {
					class_id: this.state.input.class_id,
				}
				this.props.getSectionAction(search)
			}
		}
		if(this.state.input.section_id !== prevState.input.section_id) {
            if(this.state.input.section_id){
				let search = {
					section_id: this.state.input.section_id,
				}
				this.props.getSubjectForExamAction()
		        this.props.getSkilltAction(search)
			}
		}
        if(this.state.input.rule_for !== prevState.input.rule_for) {
            if(this.state.input.subject_id){
				let search = {
					subject_id: this.state.input.subject_id,
				}
                if(this.state.input.rule_for === 'Examination'){
                    this.props.getExamtAction(search)
                    
                }
                if(this.state.input.rule_for === 'Assignment'){
                    this.props.getAssignmenttAction(search)
                }
			}
		}
	}
    componentWillReceiveProps(nextProps){
        if(this.props.skillState.detail !== nextProps.skillState.detail){
            let input = {...this.state.input}

            let data = nextProps.skillState.detail
            input.class_id = data.class_id
            input.section_id = data.section_id
            input.subject_id = data.subject_id
            input.skill_id = data.skill_id
            input.rule_for = data.rule_for[0]
            input.rule_condition = data.rule_condition
            input.status = data.status
            input.examinationOrAssignment_id = data.ExamsOrAssignment.split(',').map(item => Number(item))
            this.setState({input:input})

        }
        if(this.state.subjectList !== nextProps.utills.subjectForExam){
            this.setState({
                subjectList: nextProps.utills.subjectForExam
            })
        }
        if(this.state.sectionList !== nextProps.utills.sectionList){
            this.setState({
                sectionList: nextProps.utills.sectionList
            })
        }
		if(this.state.classList !== nextProps.utills.classList){
			this.setState({
				classList: nextProps.utills.classList
			})
		}
        if(this.state.skillList !== nextProps.utills.skillList){
			this.setState({
				skillList: nextProps.utills.skillList
			})
		}
        if(this.props.utills.examList !== nextProps.utills.examList){
            this.setState({
                examList: nextProps.utills.examList
            },()=>{
                this.state.examList.map(o => o.is_selected = false)
                
                let list = JSON.parse(JSON.stringify(this.state.examList))
                list.map((item,i)=>{
                    if(this.state.input.examinationOrAssignment_id.includes(item.id)){
                        item.is_selected = true
                    }
                })
                this.setState({examList:list})
            })
        }
		if(this.props.utills.assignmentList !== nextProps.utills.assignmentList){
			this.setState({
				assignmentList: nextProps.utills.assignmentList
			},()=>{
                this.state.assignmentList.map(o => o.is_selected = false)

                let list = JSON.parse(JSON.stringify(this.state.assignmentList))
                list.map((item,i)=>{
                    if(this.state.input.examinationOrAssignment_id.includes(item.id)){
                        item.is_selected = true
                    }
                })
                this.setState({assignmentList:list})
            })
		}
        
        if(this.state.editId){
            if(this.state.createStatus !== nextProps.skillState.editStatus){
                this.setState({
                    createStatus: nextProps.skillState.editStatus
                },()=>{
                    if(this.state.createStatus.errcode === 0){
                        this.clearData()
                    }
                })
            }
        }else{
            if(this.state.createStatus !== nextProps.skillState.createStatus){
                this.setState({
                    createStatus: nextProps.skillState.createStatus
                },()=>{
                    if(this.state.createStatus.errcode === 0){
                        this.clearData()
                    }
                })
            }
        }
	}
    componentWillUnmount(){
        this.clearData()
    }
    clearData =()=>{
        this.props.clearCreateSkillRuleAction()
        this.props.clearEditSkillRuleAction()
        this.props.clearSkillByIdAction()
        this.toggleClose()
        this.props.history.push('/teacher/manage-skill-rule')
    }

    modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={ this.state.modal }>
                    <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
                    <MDBModalBody>
                        <div className='alertContainer'>
                            <h4> Do you want to {this.state.editId ? 'Update' : 'save'} it ? </h4>
                            <h6> Please check the information before submitting</h6>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleClose}>No</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.handleSave}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
			</MDBContainer>
		)
	}
    handleSave=()=>{
        let ids = []
        if(this.state.input.rule_for === 'Examination'){
            this.state.examList.map((item,i)=>{
                if(item.is_selected){
                    ids.push(item.id)
                }
            })
            if(this.state.selectAll){
                this.state.examList.map((item,i)=>{
                    ids.push(item.id)
                })
            }
        }
        if(this.state.input.rule_for === 'Assignment'){
            this.state.assignmentList.map((item,i)=>{
                if(item.is_selected){
                    ids.push(item.id)
                }
            })
            if(this.state.selectAll){
                this.state.assignmentList.map((item,i)=>{
                    ids.push(item.id)
                })
            }
        }
        let data = {
            ...this.state.input,
            examinationOrAssignment_id:[...new Set(ids)]
        }

        if(data.examinationOrAssignment_id.length === 0){
            this.setState({showError:true,modal:false})
        }
        else{
            if(this.state.editId){
                this.props.editSkillRuleAction({id : this.state.editId,
                    data:data})
            }else{
                this.props.createSkillRuleAction(data)
            }
        }
    }
    submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
		if(event.target.checkValidity()) {
            this.setState({
                modal: true,
            })
		}
	}
    changeHandler = event => {
        if(event.target.name === 'selectAll'){
		    this.setState({ selectAll:event.target.checked });
        }
        let input = {...this.state.input}
        input[event.target.name] = event.target.value
		this.setState({ input:input });
	}
    handleChkBox = (event,i,name) => {
        if(name === 'exam'){
            let list = JSON.parse(JSON.stringify(this.state.examList))
            list[i].is_selected = event.target.checked
            this.setState({ examList:list });
        }
        else{
            let list = JSON.parse(JSON.stringify(this.state.assignmentList))
            list[i].is_selected = event.target.checked
            this.setState({ assignmentList:list });
        }
	}
    toggleClose = ()=>{
		this.setState({
			modal: false,
            showError:false
		})
	}
    handleStatus = (event)=>{
        let input = {...this.state.input}
        let value = event.target.value === "1" ? 1 : event.target.value === "0" ? 0 : null
        input[event.target.name] = value
		this.setState({ input:input });
    }
    render(){
        const { activeTab } = this.state
        // if(!isLogined()) this.props.history.push('/')
        return(
            <>
            {this.modalContainer()}
            <div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">{this.state.editId ? 'Update New Skill Rule' : 'Add New Skill Rule'} </h1>
							</div>
						</div>
					</div>
				</div>
                <div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
                                <form  onSubmit={this.submitHandler} noValidate>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Class
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select name="class_id" value={this.state.input.class_id} 
                                                                    className="form-control show-tick" onChange={this.changeHandler} required
                                                                    >
                                                                        <option value="">Select Class</option>
                                                                        {this.state.classList && this.state.classList.map((item)=>{
                                                                            return <option value={item.id}>{item.name}</option>
                                                                        })}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required class.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Section
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select name="section_id" value={this.state.input.section_id} 
                                                                    className="form-control show-tick" onChange={this.changeHandler} required
                                                                    disabled={this.state.input.class_id ? false : true}>
                                                                        <option value="">Select Section</option>
                                                                        {this.state.sectionList && this.state.sectionList.map((item)=>{
                                                                            return <option value={item.id}>{item.name}</option>
                                                                        })}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required seaction.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Subject  
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select name="subject_id" value={this.state.input.subject_id} 
                                                                    className="form-control show-tick" onChange={this.changeHandler} required
                                                                    disabled={this.state.input.section_id ? false : true}>
                                                                        <option value="">Select Subject</option>
                                                                        {this.state.subjectList && this.state.subjectList.map((item)=>{
                                                                            return <option value={item.id}>{item.name}</option>
                                                                        })}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required subject.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Year
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    {moment().format('YYYY')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Skill For
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select className="form-control show-tick" value = {this.state.input.rule_for} name="rule_for" onChange={this.changeHandler} required>
                                                                        <option value="">Select</option>
                                                                        <option value="Examination">Examination</option>
                                                                        <option value="Assignment">Assignment</option>
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Skill Rule
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select className="form-control show-tick" value = {this.state.input.rule_condition} name="rule_condition" onChange={this.changeHandler} required>
                                                                        <option value="">Select</option>
                                                                        <option value="90%">Above 90%</option>
                                                                        <option value="80%">Above 80%</option>
                                                                        <option value="70%">Above 70%</option>
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.input.rule_for === 'Examination' ? 
                                                    <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                    <div className="input-group">
                                                                        Select Exams
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                    <div className="input-group">
                                                                        :
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                    <div className="input-group">
                                                                        <MDBFormInline>
                                                                            <MDBInput
                                                                                value = {this.state.selectAll}
                                                                                name ={'selectAll'}
                                                                                onChange =  {this.changeHandler}
                                                                                label='Any Exam'
                                                                                type='checkbox'
                                                                                id='anyExam'
                                                                                containerClass='mr-5'
                                                                            />
                                                                            {!this.state.selectAll ? 
                                                                                this.state.examList.map((item,i)=>{
                                                                                return <MDBInput
                                                                                    value = {this.state.examList[i].is_selected}
                                                                                    name ={'examIds'+i}
                                                                                    onChange =  {(e)=>{ this.handleChkBox(e,i,'exam')} }
                                                                                    label={item.name}
                                                                                    type='checkbox'
                                                                                    checked={this.state.examList[i].is_selected}
                                                                                    id={'examIds'+i}
                                                                                    containerClass='mr-5'
                                                                                />
                                                                                }) : ''
                                                                            }
                                                                        </MDBFormInline>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                :
                                                    <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                    <div className="input-group">
                                                                        Select Assignment
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                    <div className="input-group">
                                                                        :
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                    <div className="input-group">
                                                                        <MDBFormInline>
                                                                            <MDBInput
                                                                                value = {this.state.selectAll}
                                                                                name ={'selectAll'}
                                                                                onChange =  {this.changeHandler}
                                                                                label='Any Assignment'
                                                                                type='checkbox'
                                                                                id='anyAssignment'
                                                                                containerClass='mr-5'
                                                                            />
                                                                            {!this.state.selectAll ?
                                                                                this.state.assignmentList.map((item,i)=>{
                                                                                return <MDBInput
                                                                                    value = {this.state.assignmentList[i].is_selected}
                                                                                    name ={'assignmentIds'+i}
                                                                                    onChange =  {(e)=>{ this.handleChkBox(e,i,'assignment')} }
                                                                                    label={item.name}
                                                                                    type='checkbox'
                                                                                    checked={this.state.assignmentList[i].is_selected}
                                                                                    id={'assignmentIds'+i}
                                                                                    containerClass='mr-5'
                                                                                />
                                                                                }) : ''
                                                                            }
                                                                        </MDBFormInline>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Select Skill
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select className="form-control show-tick"
                                                                    disabled={this.state.input.section_id ? false : true}
                                                                     value = {this.state.input.skill_id} name="skill_id" onChange={this.changeHandler} required>
                                                                        <option value="">Select Skill</option>
                                                                        {this.state.skillList && this.state.skillList.map((item)=>{
                                                                            return <option value={item.skill_id}>{item.skill_title}</option>
                                                                        })}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Status
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <MDBFormInline>
                                                                        <MDBInput
                                                                            value = {1}
                                                                            checked= {this.state.input['status'] === 1}
                                                                            name ={'status'}
                                                                            onChange =  {this.handleStatus}
                                                                            label='Active'
                                                                            type='radio'
                                                                            id='radio1'
                                                                            containerClass='mr-5'
                                                                            required
                                                                        />
                                                                        <MDBInput
                                                                            checked= {this.state.input['status'] ===0}
                                                                            value = {0}
                                                                            name ={'status'}
                                                                            onChange =  {this.handleStatus}
                                                                            label='Inactive'
                                                                            type='radio'
                                                                            id='radio2'
                                                                            containerClass='mr-5'
                                                                            required
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            required Status.
                                                                        </div>
                                                                    </MDBFormInline>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    {this.state.createStatus && this.state.createStatus.errcode !== 0 &&(
                                                        <div className="errorFlag">
                                                            {this.state.createStatus.msg +' *'}
                                                        </div>
                                                    )}
                                                    {this.state.showError  ? 
                                                        <div className="errorFlag">
                                                            {'Please add assignemt/examination'}
                                                        </div> 
                                                        :''
                                                    }
                                                </div>

                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField submitBtn">
                                                    <MDBBtn color="primary" className='btn' onClick= {()=>{
                                                        this.props.history.push('/teacher/manage-skill-rule')
                                                    }}>Cancel</MDBBtn>
                                                    <MDBBtn color="primary" type={'submit'} className='btn'>{this.state.editId ? 'Update' : 'Save'}</MDBBtn>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            
                                </form>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
	utills: state.utillReducer,
	skillState: state.ManageSkillRuleReducer,

})

const mapDispatchToProps = dispatch => ({
	getClassAction: (e) => dispatch(getClassAction(e)),
    getSectionAction: (e) => dispatch(getSectionAction(e)),
	// getSubjectAction: (e) => dispatch(getSubjectAction(e)),
	getAssignmenttAction: (e) => dispatch(getAssignmenttAction(e)),
	getExamtAction: (e) => dispatch(getExamtAction(e)),
	getSkilltAction: (e) => dispatch(getSkilltAction(e)),
	createSkillRuleAction: (e) => dispatch(createSkillRuleAction(e)),
	getSubjectForExamAction: (e) => dispatch(getSubjectForExamAction(e)),

	clearCreateSkillRuleAction: (e) => dispatch(clearCreateSkillRuleAction(e)),
	clearEditSkillRuleAction: (e) => dispatch(clearEditSkillRuleAction(e)),
	editSkillRuleAction: (e) => dispatch(editSkillRuleAction(e)),
	getSkillRuleByIdAction: (e) => dispatch(getSkillRuleByIdAction(e)),
	clearSkillByIdAction: (e) => dispatch(clearSkillByIdAction(e)),
    
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNewSkillRule)
