import { combineReducers } from "redux";
import settings from "./settings";
import superUserLoginReducer from "./Authentication/superUserLoginReducer";
import superUserForgotPasswordReducer from "./Authentication/superUserForgotPasswordReducer";
import superUserChangePasswordReducer from "./Authentication/superUserChangePasswordReducer";
import getInstitutesReducer from "./Admin/ManageInstitutes/getInstitutesReducer";
import getStatesAndcitiesReducer from "./Admin/ManageInstitutes/getStatesAndcitiesReducer";
import createInstitutesReducer from "./Admin/ManageInstitutes/createInstitutesReducer";
import getInstitutesByIdReducer from "./Admin/ManageInstitutes/getInstitutesByIdReducer";
import editInstitutesReducer from "./Admin/ManageInstitutes/editInstitutesReducer";
import uploadInstitutesFileReducer from "./Admin/ManageInstitutes/uploadInstitutesFileReducer";
import getFAQReducer from "./Admin/ManageFAQ/getFAQReducer";
import uploadImageReducer from "./Admin/ManageInstitutes/uploadImageReducer";
import getCustomerSupportReducer from "./Admin/CustomerSupport/getCustomerSupportReducer";
import getUserAndInstituteReducer from "./Admin/CustomerSupport/getUserAndInstituteReducer";
import replyByIdReducer from "./Admin/CustomerSupport/replyByIdReducer";
import searchCustomerSupportReducer from "./Admin/CustomerSupport/searchCustomerSupportReducer";
import userLoginReducer from "./Institute/Authentication/userLoginReducer";
import userForgotPasswordReducer from "./Institute/Authentication/userForgotPasswordReducer";
import userChangePasswordReducer from "./Institute/Authentication/userChangePasswordReducer";
import getKnowledgeBaseReducer from './Admin/ManageKnowledgeBase/getKnowledgeBaseReducer';
import getKnowledgeBaseByIdReducer from './Admin/ManageKnowledgeBase/getKnowledgeBaseByIdReducer';
import editKnowledgeBaseReducer from './Admin/ManageKnowledgeBase/editKnowledgeBaseReducer';
import createKnowledgeBaseReducer from './Admin/ManageKnowledgeBase/createKnowledgeBaseReducer';
import getCategoryReducer from './Admin/ManageKnowledgeBase/getCategoryReducer';
import getCategoryWithCountReducer from './Admin/ManageKnowledgeBase/getCategoryWithCountReducer';
import createCategoryReducer from './Admin/ManageKnowledgeBase/createCategoryReducer';
import deleteKnowledgeBaseReducer from './Admin/ManageKnowledgeBase/deleteKnowledgeBaseReducer';
import getProfileInfoReducer from './Admin/ManageProfile/getProfileInfoReducer';
import updateProfileInfoReducer from './Admin/ManageProfile/updateProfileInfoReducer.js';
import getLogoAndImageReducer from './Admin/ManageProfile/getLogoAndImageReducer';
import updateLogoAndImageReducer from './Admin/ManageProfile/updateLogoAndImageReducer';
import profileLoginImageReducer from './Admin/ManageProfile/profileLoginImageReducer';
import getReceiptsReducer from "./Admin/MangeReceipts/getReceiptsReducer";
import getReceiptsByIdReducer from "./Admin/MangeReceipts/getReceiptsByIdReducer";
import createReceiptsReducer from "./Admin/MangeReceipts/createReceiptsReducer";
import editReceiptsReducer from "./Admin/MangeReceipts/editReceiptsReducer";
import getInvoiceNumReducer from "./Admin/MangeReceipts/getInvoiceNumReducer";
//Manage Study Material reducers
import getStudyMaterialUploadReducer from "./Institute/Teacherdashboard/ManageStudyMaterial/getStudyMaterialUploadReducer";
import getManageStudyMaterialReducer from "./Institute/Teacherdashboard/ManageStudyMaterial/getManageStudyMaterialReducer";
// New letter reducers
import getNewsLetterReducer from "./Institute/ManageNewsLetter/getNewsLetterReducer";

//Online Meeting Reducer
import onlineMeetingReducer from "./Institute/Teacherdashboard/ManageOnlineMeeting/onlineMeetingReducer";

//Announcement reducers
import getAnnouncementReducer from "./Institute/ManageAnnouncement/getAnnouncementReducer";
import getTeacherDetailReducer from "./Institute/ManageAnnouncement/getTeacherDetailReducer";

//Manage Section reducers
import getSectionReducer from "./Institute/ManageSection/getSectionReducer";

//Manage Class reducers
import getClassReducer from "./Institute/ManageClass/getClassReducer";

//Manage Subject reducers
import getSubjectReducer from "./Institute/ManageSubject/getSubjectReducer";
import getStudentAchievements from "./Institute/StudentAchievements/getStudentAchievements";
// Manage User reducers
import getUserReducer from "./Institute/ManageUser/getUserReducer";

// Manage Reward reducers
import getRewardReducer from "./Institute/ManageReward/getRewardReducer";

// Manage CustomerSupport reducers
import getCustomerSupportInstituteReducer from "./Institute/ManageCustomerSupport/getCustomerSupportInstituteReducer";

import createCircularReducer from './Institute/MangeCircular/createCircularReducer';
import editCircularReducer from './Institute/MangeCircular/editCircularReducer';
import getCircularByIdReducer from './Institute/MangeCircular/getCircularByIdReducer';
import getCircularReducer from './Institute/MangeCircular/getCircularReducer';
import deleteCircularReducer from './Institute/MangeCircular/deleteCircularReducer';
import searchCircularReducer from './Institute/MangeCircular/searchCircularReducer';
import uploadFileReducer from './Institute/MangeCircular/uploadFileReducer';
import createEventsReducer from './Institute/ManageEvents/createEventsReducer';
import editEventsReducer from './Institute/ManageEvents/editEventsReducer';
import getEventsByIdReducer from './Institute/ManageEvents/getEventsByIdReducer';
import getEventsReducer from './Institute/ManageEvents/getEventsReducer';
import getEventDropdownReducer from './Institute/ManageEvents/getEventDropdownReducer';
import uploadEventFileReducer from './Institute/ManageEvents/uploadFileReducer';
import getInformation from './Institute/Information/getInformation';
import uploadEventImageReducer from './Institute/ManageEvents/uploadImageReducer';

import getGalleryReducer from './Institute/ManageGallery/getGalleryReducer';
import createGalleryReducer from './Institute/ManageGallery/createGalleryReducer';
import editGalleryReducer from './Institute/ManageGallery/editGalleryReducer';
// import getGalleryByIdReducer from './Institute/ManageGallery/getGalleryByIdReducer';
import searchGalleryReducer from './Institute/ManageGallery/searchGalleryReducer';
import uploadGalleryFileReducer from './Institute/ManageGallery/uploadFileReducer';
import deleteGalleryReducer from './Institute/ManageGallery/deleteGalleryReducer';
import deleteAllGalleryReducer from './Institute/ManageGallery/deleteAllGalleryReducer';
import getGalleryDropdownReducer from './Institute/ManageGallery/getGalleryDropdownReducer';
import getFeedbackReducer from './Institute/ManageFeedback/getFeedbackReducer';
import searchFeedbackReducer from './Institute/ManageFeedback/searchFeedbackReducer';
import getFeedbackDropdownReducer from './Institute/ManageFeedback/getFeedbackDropdownReducer';
import manageAcedemics from './Institute/ManageAcedemics/manageAcedemics';
import schoolReducer from './Institute/ManageSchool/manageSchoolReducer'
import classInfo from './Institute/StudentDashboard/classInfo'

import managePrincipalReducer from "./Institute/ManagePrincipal/managePrincipalReducer";
import utillReducer from "./Institute/Utils/utillReducer";

import updatePasswordReducer from "./Admin/ManageInstitutes/updatePasswordReducer";
import getProfileReducer from "./Admin/ManageInstitutes/getProfileReducer";
import editProfileReducer from "./Admin/ManageInstitutes/editProfileReducer";

import uploadVideoReducer from "./Admin/ManageProfile/uploadVideoReducer";
import ManageStudentUserReducer from "./Institute/Teacherdashboard/ManageStudentUser/ManageStudentUserReducer";
import ManageSkillReducer from "./Institute/Teacherdashboard/ManageSkill/ManageSkillReducer";
import ManageSkillRuleReducer from "./Institute/Teacherdashboard/ManageSkill/ManageSkillRuleReducer";
import ManageGoalReducer from "./Institute/Teacherdashboard/ManageGoal/ManageGoalReducer";
import ManageCertificateReducer from "./Institute/Teacherdashboard/ManageCertificate/ManageCertificateReducer";
import ManageAchievementReducer from "./Institute/Teacherdashboard/ManageAchievement/ManageAchievementReducer";
import ManageExaminationReducer from "./Institute/Teacherdashboard/ManageExamination/ManageExaminationReducer";
import ManageQuestionReducer from "./Institute/Teacherdashboard/ManageQuestion/ManageQuestionReducer";
import ManageAssignmentReducer from "./Institute/Teacherdashboard/ManageAssignment/ManageAssignmentReducer";
// Manage Task reducers
import getTaskReducer from "./Institute/StudentDashboard/ManageTask/getTaskReducer";
import showSnackBarReducer from "./Institute/showSnackBarReducer";
import SaveBookMarkReducer from './Admin/ManageCurriculum/SaveBookMarkReducer'
import CreateBookMarklistReducer from "./Admin/ManageCurriculum/CreateBookMarklistReducer";
import DeleteBookMarklistReducer from "./Admin/ManageCurriculum/DeleteBookMarklistReducer";
import UploadPDFReducer from "./Admin/ManageCurriculum/ManageCurriculumPDF/UploadPDFReducer";
import GETPdfListReducer from "./Admin/ManageCurriculum/ManageCurriculumPDF/GETPdfListReducer";
import DeletePDFListReducer from "./Admin/ManageCurriculum/ManageCurriculumPDF/DeletePDFListReducer";
// import showSnackBarReducer from "./Institute/showSnackBarReducer";

//Manage Curriculum Reducers
import getCurriculumPdfByIdReducer from "./Institute/Teacherdashboard/ManageCurriculumPDF/getCurriculumPdfByIdReducer";
import getCurriculumPdfListReducer from "./Institute/Teacherdashboard/ManageCurriculumPDF/getCurriculumPdfListReducer";

export default combineReducers({
  settings: settings,
  superUserLoginReducer: superUserLoginReducer,
  superUserForgotPasswordReducer: superUserForgotPasswordReducer,
  superUserChangePasswordReducer: superUserChangePasswordReducer,
  getInstitutesReducer: getInstitutesReducer,
  getStatesAndcitiesReducer: getStatesAndcitiesReducer,
  createInstitutesReducer: createInstitutesReducer,
  getInstitutesByIdReducer: getInstitutesByIdReducer,
  editInstitutesReducer: editInstitutesReducer,
  uploadInstitutesFileReducer: uploadInstitutesFileReducer,
  uploadImageReducer: uploadImageReducer,
  getFAQReducer: getFAQReducer,
  getCustomerSupportReducer: getCustomerSupportReducer,
  getUserAndInstituteReducer: getUserAndInstituteReducer,
  replyByIdReducer: replyByIdReducer,
  searchCustomerSupportReducer: searchCustomerSupportReducer,
  getReceiptsReducer: getReceiptsReducer,
  getReceiptsByIdReducer: getReceiptsByIdReducer,
  createReceiptsReducer: createReceiptsReducer,
  editReceiptsReducer: editReceiptsReducer,
  getProfileInfoReducer: getProfileInfoReducer,
  updateProfileInfoReducer: updateProfileInfoReducer,
  getLogoAndImageReducer: getLogoAndImageReducer,
  updateLogoAndImageReducer: updateLogoAndImageReducer, 
  profileLoginImageReducer: profileLoginImageReducer,
  getInvoiceNumReducer: getInvoiceNumReducer,
  instituteUserLoginReducer: userLoginReducer,
  instituteUserForgotPasswordReducer: userForgotPasswordReducer,
  instituteUserChangePasswordReducer: userChangePasswordReducer,
  getKnowledgeBaseReducer: getKnowledgeBaseReducer,
  getKnowledgeBaseByIdReducer: getKnowledgeBaseByIdReducer,
  createKnowledgeBaseReducer: createKnowledgeBaseReducer,
  editKnowledgeBaseReducer: editKnowledgeBaseReducer,
  createCategoryReducer: createCategoryReducer,
  getCategoryReducer: getCategoryReducer,
  getCategoryWithCountReducer:getCategoryWithCountReducer,
  deleteKnowledgeBaseReducer: deleteKnowledgeBaseReducer,
  getNewsLetterReducer,
  getAnnouncementReducer,
  getSectionReducer,
  getClassReducer,
  getSubjectReducer,
  getUserReducer,
  getRewardReducer,
  getCustomerSupportInstituteReducer,
  managePrincipalReducer,
  utillReducer,
  createCircularReducer:createCircularReducer,
  editCircularReducer:editCircularReducer,
  getCircularByIdReducer:getCircularByIdReducer,
  getCircularReducer:getCircularReducer,
  searchCircularReducer:searchCircularReducer,
  deleteCircularReducer:deleteCircularReducer,
  createEventsReducer:createEventsReducer,
  editEventsReducer:editEventsReducer,
  getEventsByIdReducer:getEventsByIdReducer,
  getEventsReducer:getEventsReducer,
  uploadEventImageReducer:uploadEventImageReducer,
  uploadEventFileReducer:uploadEventFileReducer,
  uploadFileReducer:uploadFileReducer,
  getEventDropdownReducer:getEventDropdownReducer,
  getInformation:getInformation,
  getGalleryReducer:getGalleryReducer,
  createGalleryReducer:createGalleryReducer,
  editGalleryReducer:editGalleryReducer,
  searchGalleryReducer:searchGalleryReducer,
  uploadGalleryFileReducer:uploadGalleryFileReducer,
  deleteGalleryReducer:deleteGalleryReducer,
  getFeedbackReducer:getFeedbackReducer,
  getFeedbackDropdownReducer:getFeedbackDropdownReducer,
  searchFeedbackReducer:searchFeedbackReducer,
  getGalleryDropdownReducer:getGalleryDropdownReducer,
  manageAcedemics:manageAcedemics,
  schoolReducer:schoolReducer,
  updatePasswordReducer,
  getProfileReducer,
  editProfileReducer,
  deleteAllGalleryReducer,
  uploadVideoReducer:uploadVideoReducer,
  manageStudentUserReducer : ManageStudentUserReducer,
  getManageStudyMaterialReducer,
  getStudyMaterialUploadReducer,
  ManageSkillReducer,
  ManageSkillRuleReducer,
  ManageGoalReducer,
  ManageAchievementReducer,
  ManageExaminationReducer,
  ManageQuestionReducer,
  onlineMeetingReducer,
  ManageAssignmentReducer,
  getTaskReducer,
  getTeacherDetailReducer,
  classInfo,
  ManageCertificateReducer,
  getStudentAchievements,

  SaveBookMarkReducer,
  CreateBookMarklistReducer,
  DeleteBookMarklistReducer,
  UploadPDFReducer,
  GETPdfListReducer,
  DeletePDFListReducer,
  showSnackBarReducer,
  getCurriculumPdfByIdReducer,
  getCurriculumPdfListReducer
})
