
import { STORE_DATA } from "../../Constants";
import axios from 'axios';
import { getToken } from "../../../utils/token";

import { bookmarkOfPDF } from "../../../utils/Api";
import { ADD_BOOKMARK } from "../../Constants";
import { DELETE_BOOKMARK } from "../../Constants";

export const AllBookMarkAction = (curId) => {
  return (dispatch) => {
    const url = `${bookmarkOfPDF.bookMarkByPDF(curId)}`;
    const options = {
      url,
      headers: {
        Authorization: getToken(),
      },
      method: "GET",
    };

    return axios(options)
      .then((response) => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          return data.DATA;
        }
        return {};
      })
      .catch((error) => {
        // Handle error
        console.error('Error:', error);
      });
  };
};

export const createBookMarkAction = (bookmark) => {
  return (dispatch) => {
    const url = `${bookmarkOfPDF.createBookMark}`;
    const options = {
      url,
      headers: {
        Authorization: getToken(),
      },
      method: "POST",
      data: bookmark,
    };

    return axios(options)
      .then((response) => {
        const responseData = response && response.data ? response.data : false;
        const flag = responseData && (responseData.SUCCESS || responseData.ERROR);

        if (responseData && flag.status.errcode === 0) {
          dispatch({
            type: ADD_BOOKMARK,
            payload: { bookmark }, // Remove the 'open' property if not needed
          });
        } else {
          // Handle error or other cases if needed
        }

        return responseData;
      })
      .catch((error) => {
        // Handle error
        console.error('Error:', error);
      });
  };
};


export const deleteBookMarkAction = (id) => {
  return dispatch => {
    const url = `${bookmarkOfPDF.deleteBookMark(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: 'DELETE'
      };
    return axios(options)
      .then(response => {
      })
  }
}


