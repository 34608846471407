import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import {
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import {
  createStudyMaterialAction,
  uploadStudyMaterialFileAction,
} from "../../../actions/institute/Teacherdashboard/ManageStudyMaterial/ManageStudyMaterialAction";
import {
  getClassAction,
  getSubjectAction,
  getSectionAction,
} from "../../../actions/institute/Utils/utilsAction";


//import SaveBookMarkReducer from '../../../reducers/Admin/ManageCurriculum/SaveBookMarkReducer'
import { createBookMarkAction, deleteBookMarkAction, AllBookMarkAction } from '../../../actions/Admin/ManageCurriculum/SaveBookMarkAction';

class BookMarkPDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {
        school_id: "",
        year: new Date().getFullYear(),
      },
      bookmarkName: '',
      PageNumber: '',
      dataArray: [],
      dropDownDataClass: [],
      dropDownDataSection: [],
      dropDownDataSubject: [],
      file: null,
      modal: false,
      isSave: true,
      createModal: true,
      saveBookMark: [],

    };
  }


  addToDataArray = (event) => {
    const { bookmarkName, PageNumber, dataArray } = this.state;
    const isBookmarkNameValid = bookmarkName.trim() !== '' && bookmarkName.length <= 40;
    const isPageNumberValid = PageNumber.trim() !== '' && parseInt(PageNumber, 10) !== 0;
    if (isBookmarkNameValid && isPageNumberValid) {
      this.setState({
        dataArray: [...dataArray, { bookmarkName, PageNumber, "CurPdfId": this.props.curId }],
        bookmarkName: '',
        PageNumber: '',
        errorMessageName: '',
        errorMessagePage: '',
      });
    } else {
      if (!isBookmarkNameValid ) {
        this.setState({
          errorMessageName: 'Please fill BookMark Name(maximum 40 characters)',
        });
      }
      if (!isPageNumberValid) {
        this.setState({
          errorMessagePage: 'Please fill valid Page Number',
        });
      }

    }

    event.preventDefault();
    event.target.className += " was-validated";

    if (event.target.checkValidity()) {
      this.setState({
        modal: true,
      })
    }
  };

  handleDeleteEntry = (key) => {
    const { dataArray } = this.state;
    const updatedDataArray = dataArray.filter((item) => item.bookmarkName + item.PageNumber !== key);
    this.setState({ dataArray: updatedDataArray });
  };
  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    if (this.state.saveBookMark !== nextProps.saveBookMark.dataArray) {
      this.setState({
        saveBookMark: nextProps.saveBookMark.dataArray
      })
    }
  }
  componentDidUpdate(prevProp, prevState) {
    if (prevState.input.school_id !== this.state.input.school_id) {
      if (this.state.input.school_id) {
        let search = {
          school_id: this.state.input.school_id,
        };
        this.props
          .getClassAction(search)
          .then((res) => this.setState({ dropDownDataClass: res }));
      }
    }
    if (prevState.input.class_id !== this.state.input.class_id) {
      if (this.state.input.class_id) {
        let search = {
          class_id: this.state.input.class_id,
        };
        this.props
          .getSectionAction(search)
          .then((res) => this.setState({ dropDownDataSection: res }));
      }
    }
    if (prevState.input.section_id !== this.state.input.section_id) {
      if (this.state.input.section_id) {
        let search = {
          section_id: this.state.input.section_id,
        };
        this.props
          .getSubjectAction(search)
          .then((res) => this.setState({ dropDownDataSubject: res }));
      }
    }
  }

  modalContainer = () => {
    return (
      <MDBContainer>
        <MDBModal isOpen={this.state.modal}>
          <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
          <MDBModalBody>
            <div className="alertContainer">
              <h4>
                {" "}
                Do you want to {this.state.editId ? "Update" : "save"} it ?{" "}
              </h4>
              <h6> Please check the information before submitting</h6>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              No
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.handleSave}>
              Yes
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  };

  toggle = () => {
    this.props.toggleClose();
    this.clearData();
  };
  clearData = () => {
    this.props.toggleClose();
  };

  handleSave = async () => {
    //
    const dataArray1 = [...this.state.dataArray]; // Replace with your actual logic to get or prepare the dataArray

    // Dispatch the SaveBookMarkAction with dataArray
    this.props.SaveBookMarkAction(dataArray1);


    //storeData(...this.state.dataArray)
    const temp = {
      ...this.state.input,
      file: this.props.studyMaterialupload,
    };
    this.setState({
      input: temp,
    });
    await this.props.createStudyMaterialAction(temp);
    this.setState({
      modal: false,
    });
    await this.props.fetchStudyMaterialDataAction();
    this.props.toggleClose();
  };

  submitHandler = async (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (event.target.checkValidity()) {

      this.setState({
        // createModal: false,
        modal: true,
        // modal: true,
      });
    }
    await this.props.createBookMarkAction(this.state.dataArray);
    this.props.toggleClose();
  };

  toggleClose = () => {
    this.setState({
      modal: false,
      createModal: true,
    });
  };

  changeHandler = (name, value) => {
    this.setState({ input: { ...this.state.input, [name]: value } });
  };

  handleFile = (event) => {
    this.setState({ file: event.target.files[0] });
  };



  render() {


    const { bookmarkName, PageNumber, dataArray, errorMessageName, errorMessagePage } = this.state;

    const enabled =
       dataArray.length > 0;
    return (
      <>
        {this.modalContainer()}
        <MDBContainer>
          <MDBModal isOpen={this.state.createModal}>
            <MDBModalHeader toggle={this.toggle}>
              Add BookMarks
            </MDBModalHeader>
            {/* <form onSubmit={this.submitHandler} noValidate> */}
            <MDBModalBody>
              <div className="card">
                <div className="card-body scrollbar-item">
                  <div>
                    <div >
                      {dataArray.map((item, index) => (
                        <div key={item.bookmarkName + item.PageNumber}>
                          <div>

                            <span className="top-aling">BookMark Name : {item.bookmarkName}</span>
                            <span className="top-aling">Page Number : {item.PageNumber}</span>
                            <button className="top-aling" onClick={() => this.handleDeleteEntry(item.bookmarkName + item.PageNumber)}>
                              x
                            </button>

                          </div>
                        </div>

                      ))}
                    </div>

                    <div className="row">
                      <div className="col col-sm-5">
                        <div style={{ display: 'flex' }}>
                        <label>
                          BookMark Name <span style={{ color: 'red' }}>*</span>:
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="BookMark Name"
                          className="top-aling"
                          value={this.state.bookmarkName}
                          onChange={(e) => this.setState({ bookmarkName: e.target.value })}
                        /></div>
                        <div style={{ color: 'red', margin: '2px' }}>{errorMessageName}</div>
                      </div>

                      <div className="col col-sm-5">
                        <div style={{ display: 'flex' }}>
                          <label>
                          Page Number <span style={{ color: 'red' }}>*</span>:
                        </label>
                        <input
                          required
                          placeholder="Page Number"
                          className="top-aling"
                          value={this.state.PageNumber}
                          onChange={(e) => {
                            const input = e.target.value;
                            if (/^\d*$/.test(input)) {
                              this.setState({ PageNumber: input });
                            }
                          }}
                        />
                        </div>
                        <div style={{ color: 'red',margin: '2px' }}>{errorMessagePage}</div>
                      </div>

                      <div className="col" style={{margin:'10px'}}>
                        <button onClick={this.addToDataArray}>+</button>
                      </div>
                    </div>


                  </div>
                </div>
              </div>

            </MDBModalBody>
            <MDBModalFooter>

              <MDBBtn color="secondary" onClick={this.toggle}>
                Close
              </MDBBtn>
              {/* <MDBBtn
                  color="primary"
                
                  className="btn"
                  type="submit"
                >
                  Save
                </MDBBtn> */}
              <MDBBtn

                color="primary"
                type="submit"
                onClick={this.submitHandler}
                disabled={!enabled}

              >
                Save & Close
              </MDBBtn>
            </MDBModalFooter>
            {/* </form> */}
          </MDBModal >
        </MDBContainer >
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  studyMaterialupload: state.getStudyMaterialUploadReducer.file,
  saveBookMark: state.SaveBookMarkReducer.dataArray
});

const mapDispatchToProps = (dispatch) => ({
  createStudyMaterialAction: (e) => dispatch(createStudyMaterialAction(e)),
  uploadStudyMaterialFileAction: (e) =>
    dispatch(uploadStudyMaterialFileAction(e)),
  getClassAction: (e) => dispatch(getClassAction(e)),
  getSectionAction: (e) => dispatch(getSectionAction(e)),
  getSubjectAction: (e) => dispatch(getSubjectAction(e)),
  createBookMarkAction: (e) => dispatch(createBookMarkAction(e))
});

export default connect(mapStateToProps, mapDispatchToProps)(BookMarkPDF);
