

import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
// import './style.scss';
import { connect } from 'react-redux';
import { MDBBtn,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { clearAcedemicsByIdAction, clearCreateAcedemicsAction, clearEditAcedemicsAction, createAcedemicsAction, editAcedemicsAction, getAcedemicsByIdAction } from '../../../actions/institute/ManageAcedemics/manageAcedemicsAction';
import {  getClassAction,getSectionAction, getSubjectAction,getAllSchoolAction,getTeacherAction } from '../../../actions/institute/Utils/utilsAction';

class CreateAcedemics extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
            input:{
                school_id: "",
                class_id: "",
                section_id: "",
                subject_id: "",
                teacher_id: "",
                type: "",
                cutoff:''
            },
	        acedemicData : {},
            editId : this.props.editId,
            editStatus:null,

            classList:[],
            sectionList:[],
            subjectList:[],
            teacher:[],
            acedemics:{},
            school:[],
            createModal:true,
            modal:false,
            utills:{}

		};
	}

    componentDidMount(){
		this.props.getAllSchoolAction()

        if(this.state.editId){
            this.props.getAcedemicsByIdAction(this.state.editId)
        }
	}
    componentDidUpdate(prevProp,prevState){
		if (this.state.input.school_id !== prevState.input.school_id) {
            if(this.state.input.school_id){
				let search = {
					school_id: this.state.input.school_id
				}
				this.props.getClassAction(search)
				this.props.getTeacherAction(search)
			}
		}
		if(this.state.input.class_id !== prevState.input.class_id) {
            if(this.state.input.school_id && this.state.input.class_id){
				let search = {
					school_id: this.state.input.school_id,
					class_id: this.state.input.class_id,
				}
				this.props.getSectionAction(search)
			}
		}
		if(this.state.input.section_id !== prevState.input.section_id) {
            if(this.state.input.school_id && this.state.input.class_id){
				let search = {
					school_id: this.state.input.school_id,
					class_id: this.state.input.class_id,
					section_id: this.state.input.section_id,
				}
				this.props.getSubjectAction(search)
			}
		}
	}

    componentWillReceiveProps(nextProps){

        if(this.props.acedemics.detail !== nextProps.acedemics.detail){
            let data = nextProps.acedemics.detail
            let input = {...this.state.input}
            input.teacher_id = data.teacher_id
            input.school_id = data.school_id
            input.section_id = data.section_id
            input.subject_id = data.subject_id
            input.class_id = data.class_id

            input.type = data.type
            input.cutoff = data.cutoff
            
            this.setState({input:input})
        }
        if(this.state.teacher !== nextProps.utills.teacherList){
			this.setState({
				teacher: nextProps.utills.teacherList
			})
		}
		
        if(this.state.subjectList !== nextProps.utills.subjectList){
            this.setState({
                subjectList: nextProps.utills.subjectList
            })
        }
        if(this.state.sectionList !== nextProps.utills.sectionList){
            this.setState({
                sectionList: nextProps.utills.sectionList
            })
        }
		if(this.state.school !== nextProps.utills.schoolAllList){
			this.setState({
				school: nextProps.utills.schoolAllList
			})
		}
		if(this.state.classList !== nextProps.utills.classList){
			this.setState({
				classList: nextProps.utills.classList
			})
		}
	}
    toggle=()=>{
        this.props.toggleClose()
        this.clearData()
    }
    clearData =()=>{
        this.props.clearEditAcedemicsAction()
        this.props.clearCreateAcedemicsAction()
        this.props.clearAcedemicsByIdAction()
        this.props.toggleClose()
    }
    componentWillUnmount(){
        this.clearData()
    }
    createAcedemics=(url)=>{
        if(this.state.editId){
            this.props.editAcedemicsAction({id : this.state.editId,data:this.state.input})
        }else{
            this.props.createAcedemicsAction(this.state.input)
        }
        this.clearData()
        this.props.getAcedemics()
    }
    modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={ this.state.modal }>
                    <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
                    <MDBModalBody>
                        <div className='alertContainer'>
                            <h4> Do you want to {this.state.editId ? 'Update' : 'save'} it ? </h4>
                            <h6> Please check the information before submitting</h6>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleClose}>No</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.handleSave}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
			</MDBContainer>
		)
	}
    handleSave=()=>{
        this.createAcedemics('')
    }
    submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
        if(event.target.checkValidity()) {
            this.setState({
                createModal:false,
                modal: true,
            })
		}
        
	}
    onContentChange =(value)=>{
        this.setState({context:value})
    }
    changeHandler = event => {
        let input = {...this.state.input}
        input[event.target.name] = event.target.value
		this.setState({ input:input });
	};
    openCategoryModal = e =>{
        this.setState({
            modal:true
        })
    }
    toggleClose = ()=>{
		this.setState({
			modal: false,
            createModal:true
		})
	}

    handleFile=(event)=>{
        this.setState({file : event.target.files[0]})
    }

    render(){
        const { activeTab } = this.state
        // if(!isLogined()) this.props.history.push('/')
        return(
            <>
             {this.modalContainer()}
            <MDBContainer>
                <MDBModal isOpen={this.state.createModal} >
                    <MDBModalHeader toggle={this.toggle}>{this.state.editId ? 'Update Academics' : 'Create Academics'}</MDBModalHeader>
                    <form onSubmit={this.submitHandler} noValidate>
                        <MDBModalBody>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Select School
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                : 
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                <select name="school_id" value={this.state.input['school_id']} 
                                                className="form-control show-tick" onChange={this.changeHandler} required>
                                                    <option value="">Select School</option>
                                                    {this.state.school.map((item)=>{
                                                            return <option value={item.id}>{item.name}</option>
                                                        })}
                                                </select>
                                                <div className="invalid-feedback">
                                                    required school name.
                                                </div>                                 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Select Class
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                : 
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                <select name="class_id" value={this.state.input['class_id']} 
                                                className="form-control show-tick" onChange={this.changeHandler} required
                                                disabled ={this.state.input.school_id ? false : true}>
                                                    <option value="">Select Class</option>
                                                    {this.state.classList && this.state.classList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                                <div className="invalid-feedback">
                                                    required class name.
                                                </div>                                 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Select Section
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                : 
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                <select name="section_id" value={this.state.input['section_id']} 
                                                className="form-control show-tick" onChange={this.changeHandler} 
                                                required disabled ={this.state.input.class_id && this.state.input.school_id ? false : true}>
                                                    <option value="">Select Section</option>
                                                    {this.state.sectionList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                                <div className="invalid-feedback">
                                                    required section name.
                                                </div>                                 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Select Subject
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                : 
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                <select name="subject_id" value={this.state.input['subject_id']} 
                                                className="form-control show-tick" onChange={this.changeHandler} required
                                                disabled ={this.state.input.section_id && this.state.input.class_id && this.state.input.school_id ? false : true}
                                                >
                                                    <option value="">Select Subject</option>
                                                    {this.state.subjectList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                                <div className="invalid-feedback">
                                                    required subject name.
                                                </div>                                 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Select Teacher
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                : 
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                <select name="teacher_id" value={this.state.input['teacher_id']} 
                                                className="form-control show-tick" onChange={this.changeHandler} 
                                                required disabled ={this.state.input.school_id ? false : true}>
                                                    <option value="">Select Teacher</option>
                                                    {this.state.teacher.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                                <div className="invalid-feedback">
                                                    required teacher name.
                                                </div>                                 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Type
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                : 
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                <select name="type" value={this.state.input['type']} className="form-control show-tick" onChange={this.changeHandler} required>
                                                    <option value="">Select Type</option>
                                                    <option value="Assignment">Assignment</option>
                                                    <option value="Examination">Examination</option>
                                                </select>
                                                <div className="invalid-feedback">
                                                    required type.
                                                </div>                                 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 cutoff">
                                            <div className="input-group">
                                                Cut Off Percentage
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                :
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">

                                                <input type="text" className="form-control" value ={this.state.input['cutoff']} 
                                                name="cutoff" onChange={this.changeHandler} placeholder="Enter Cut off" required/>
                                                
                                                <div className="invalid-feedback">
                                                    required cut off.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn>
                            <MDBBtn color="secondary" type="submit">{this.state.editId ? 'Update': 'Save'}</MDBBtn>
                        </MDBModalFooter>
                    </form>
                </MDBModal>
            </MDBContainer>
            </>
        )
    }
}

const mapStateToProps = state => ({
	acedemics: state.manageAcedemics,
	utills: state.utillReducer
    
})

const mapDispatchToProps = dispatch => ({
	createAcedemicsAction: (e) => dispatch(createAcedemicsAction(e)),
	getAcedemicsByIdAction: (e) => dispatch(getAcedemicsByIdAction(e)),
	clearAcedemicsByIdAction: (e) => dispatch(clearAcedemicsByIdAction(e)),
	clearCreateAcedemicsAction: (e) => dispatch(clearCreateAcedemicsAction (e)),
	clearEditAcedemicsAction: (e) => dispatch(clearEditAcedemicsAction(e)),
	editAcedemicsAction: (e) => dispatch(editAcedemicsAction(e)),
	
    getAllSchoolAction: (e) => dispatch(getAllSchoolAction(e)),
	getClassAction: (e) => dispatch(getClassAction(e)),
	getSectionAction: (e) => dispatch(getSectionAction(e)),
	getSubjectAction: (e) => dispatch(getSubjectAction(e)),
	getTeacherAction: (e) => dispatch(getTeacherAction(e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateAcedemics)
