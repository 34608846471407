import { GET_ACHIEVEMENT, 
    CREATE_ACHIEVEMENT,
    EDIT_ACHIEVEMENT, 
    GET_ACHIEVEMENT_BY_ID,GET_ACHIEVEMENT_BADGE_URL } from '../../../../actions/Constants/index'

const initialState = {
    list : [],
    Badgelist : [],
    editStatus:null,
    createStatus:null,
    detail:null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_ACHIEVEMENT:
        return {
            ...state,
            list : action.payload.list,
        }
        case EDIT_ACHIEVEMENT:
        return {
            ...state,
            editStatus : action.payload.list,
        }
        case CREATE_ACHIEVEMENT:
        return {
            ...state,
            createStatus : action.payload.list,
        }
        case GET_ACHIEVEMENT_BY_ID:
        return {
            ...state,
            detail : action.payload.list,
        }
        case GET_ACHIEVEMENT_BADGE_URL:
        return {
            ...state,
            Badgelist : action.payload.list,
        }
        default:
            return state
    }
}
