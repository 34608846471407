import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
// import './style.scss';
import { connect } from 'react-redux';
import { MDBBtn,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon } from 'mdbreact';
import { clearUploadAcedemicsAction, uploadAcedemicsFileAction } from '../../../actions/institute/ManageAcedemics/manageAcedemicsAction';
import { CSVLink } from 'react-csv';

class UploadExcel extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
            file: null,
            uploadStatus:null,
            url:'',
            uploadFileStatus :null,
			role:this.props.role,
            
            modal:false,
            excelModal:true
		};
	}

    componentWillReceiveProps(nextProps){
            if(this.state.uploadFileStatus !== nextProps.uploadFileStatus){
                this.setState({
                    uploadFileStatus : nextProps.uploadFileStatus
                },()=>{
                    if(this.state.uploadFileStatus && this.state.uploadFileStatus.errcode === 0){
                        this.props.getApi()
                        this.setState({
                            createModal:false,
                        })
                    }
                })
        }
	}
    toggle=()=>{
        this.props.toggleClose()
        this.clearData()
    }
    clearData =()=>{
        this.props.clearUploadAcedemicsAction()
        this.props.toggleClose()
    }
    componentWillUnmount(){
        this.clearData()
    }
    
    handleSave=()=>{
        
    }
    submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
		if(event.target.checkValidity()) {
            if(this.state.file){
                this.props.uploadAcedemicsFileAction(this.state.file)
            }
		}
	}
    openCategoryModal = e =>{
        this.setState({
            modal:true
        })
    }
    toggleClose = ()=>{
		this.setState({
			modal: false,
            excelModal:true
		})
	}

    handleFile=(event)=>{
        this.setState({file : event.target.files[0]})
    }

    render(){
        const { activeTab } = this.state
        // if(!isLogined()) this.props.history.push('/')
        return(
            <>
            <MDBContainer>
                <MDBModal isOpen={ this.state.excelModal } >
                    <MDBModalHeader toggle={this.toggle}>Upload Excel</MDBModalHeader>
                    <form onSubmit={this.submitHandler} noValidate>
                        <MDBModalBody>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Upload Files
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                :
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="">
                                                <input type="file" className="form-control"
                                                    onChange={this.handleFile} required={this.state.url ? false: true} accept = ".csv"/>
                                                <div className="invalid-feedback">
                                                    required file.
                                                </div>
                                                <div className="">
                                                    {this.state.file ? '' : this.state.url}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-4 col-sm-6 inputField ">
                                            <div className="errorFlag">
                                                {this.state.uploadStatus}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{display:'flex',justifyContent:'end','color':'red', marginBottom: '10px'}}>
                    {this.state.uploadFileStatus ? this.state.uploadFileStatus.msg +' *' : ''}
								</div>
                                <div style={{display:'flex',justifyContent:'end'}}>
                                <MDBBtn color="primary">
								<CSVLink {...this.props.sampleCsv}>Download Sample Template
                                <MDBIcon  icon="download" size="lg" className='icon' /></CSVLink>
								</MDBBtn>
                                </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="primary" type="submit">Upload</MDBBtn>
                            <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn>
                        </MDBModalFooter>
                    </form>
                   
                </MDBModal>
            </MDBContainer>
            </>
        )
    }
}

const mapStateToProps = state => ({
    acedemics: state.manageAcedemics,
	uploadFileStatus: state.manageAcedemics.uploadFileStatus,
})

const mapDispatchToProps = dispatch => ({
	uploadAcedemicsFileAction: (e) => dispatch(uploadAcedemicsFileAction(e)),
	clearUploadAcedemicsAction: (e) => dispatch(clearUploadAcedemicsAction(e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadExcel)
