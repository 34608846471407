import { SHOW_PDF_LIST, UPLOAD_PDF, DELETE_PDF_LIST_ID } from "../../Constants";
import { SHOW_SNACKBAR } from "../../Constants/index";
import axios from 'axios';
import { getToken } from "../../../utils/token";
import { ManageCurriculumPDF } from "../../../utils/Api";

export const uploadPDFAction = (pdfs) => {
    return (dispatch) => {
      const url = `${ManageCurriculumPDF.uploadPDF}`;
      const options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "POST",
        data: pdfs,
      };
      return axios(options)
        .then((response) => {
          const responseData = response && response.data ? response.data : false;
          const flag = responseData && (responseData.SUCESS || responseData.ERROR);
          if (responseData && flag.status.errcode === 0) {
           
            dispatch({
              type: UPLOAD_PDF,
              payload: { pdfs}  // Remove the 'open' property if not needed
            });
            // dispatch({
            //   type: UPLOAD_PDF,
            //   payload: flag.status,

            //   payload: { pdfs },  // Remove the 'open' property if not needed
            // });
           setTimeout(() => {
            dispatch({
              type: SHOW_SNACKBAR,
              payload: { type: "success", message: responseData.SUCESS.status.msg, open: true },
            });
           }, 1500);
          } else {
            // Handle error or other cases if needed
            dispatch({
              type: SHOW_SNACKBAR,
              payload: { type: "error", message: responseData.ERROR.status.msg, open: true },
            });
          }
  
          return responseData;
        })
        .catch((error) => {
          // Handle error
          
          console.error('Error:', error);
        });
    };
  };

  export const ShowPDFListAction = (ght) => {
	return dispatch => {
        const url = `${ManageCurriculumPDF.showPDFList(ght.page,ght.limit)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: SHOW_PDF_LIST,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: SHOW_PDF_LIST,
                    payload: {
                        list : [],
                        totalCount : 0
                    }
                })
                dispatch({
                  type: SHOW_SNACKBAR,
                  payload: { type: "error", message: data.ERROR.status.msg, open: true },
                });
            }
        })
    }
}

export const deleteCurriculumAction = (id) => {
	return dispatch => {
        const url = `${ManageCurriculumPDF.deletePDF(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'DELETE'
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            if(response.data.affected == 1){
                dispatch({
                    type: DELETE_PDF_LIST_ID,
                    payload: {
                        list : "Deleted"
                    }
                })
                setTimeout(() => {
                  dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: "Deleted Successfully", open: true },
                  });
                }, 400); 
            }
        })
    }
}