import React, { Component } from "react";
import { connect } from "react-redux";
import dummy from "../../../assets/pdfs/dummy.pdf";
import iLikeMath_ALGEBRA_II_FUNDAMENTALS from "../../../assets/pdfs/iLikeMath_ALGEBRA_II_FUNDAMENTALS.pdf";
import Mathematics_MEASURES_OF_SOLID_FIGURES from "../../../assets/pdfs/Mathematics_MEASURES_OF_SOLID_FIGURES.pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { pdfjs } from "react-pdf";
import { CiZoomIn, CiZoomOut } from "react-icons/ci";
import { IoCaretForwardOutline, IoCaretBackOutline } from "react-icons/io5";
import { BiBookmarkAlt } from "react-icons/bi";
import BookMarkPDF from "./BookMarkPDF";
import "./style.scss";
import {
  AllBookMarkAction,
  deleteBookMarkAction,
} from "../../../actions/Admin/ManageCurriculum/SaveBookMarkAction";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { Button } from "reactstrap";

// import "./style.scss";
// import "./style.scss";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class CurrentPdfViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      pageNumber: 1,
      openCreateModal: false,
      zoom: 1.0, // Add zoom state
      pdfBase64: null,
      pdffile: null,
      isPagination: false,
      bookmarks: [
        { title: "Introduction", page: 1 },
        { title: "Chapter 1", page: 5 },
        { title: "Chapter 2", page: 10 },
        { title: "Conclusion", page: 15 },
      ],
      isActive: true,
      showBookmarks: false,
      bookmarkClicked: false,
      activeBookmark: null,
    };
  }

  toggleClose = async () => {
    this.setState({
      modal: false,
      selectedImage: null,
      deleteModal: false,
      openCreateModal: false,
      deleteAllModal: false,
    });
    const book = await this.props.AllBookMarkAction(
      this.props.location.state.item.id
    );
    this.setState({ bookmarks: book });
  };

  componentDidMount = async () => {
    const book = await this.props.AllBookMarkAction(
      this.props.location.state.item.id
    );
    const fullbase64 =
      "https://elearningpdfstorage.blob.core.windows.net/curriculum/" +
      this.props.location.state.item.PdfName;
    this.setState({
      pdfBase64: fullbase64,
      pdffile: this.props.location.state.item,
      bookmarks: book,
    });

    // client bolb_BASEURL = "https://elearningpdfstorage.blob.core.windows.net/curriculum/"
    // csharptek blob_baseUrl = "https://velocityelearningsa.blob.core.windows.net/curriculum/"

    // Load and convert the PDF to base64
    // fetch(iLikeMath_ALGEBRA_II_FUNDAMENTALS) // Replace with the URL to your PDF
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const reader = new FileReader();
    // reader.onload = () => {
    //   this.setState({ pdfBase64: reader.result });
    // };
    //     reader.readAsDataURL(blob);
    //   });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    const { zoom } = this.state;
    this.setState({
      numPages,
    });
  };
  showBookmarkChange = () => {
    this.setState({
      showBookmarks: !this.state.showBookmarks,
      bookmarkClicked: !this.state.bookmarkClicked,
      isActive: false,
    });
    this.goToBookmark(1);
    // const selectedPage = parseInt(e.target.value, 10);
    // this.setState({ bookmarkPage: e.target.value });
    // this.goToBookmark(selectedPage);
  };

  handleBookmarkChange = (event) => {
    if (event.target.value == "") {
      this.setState({
        IsBookmark: false,
      });
    } else {
      this.setState({
        IsBookmark: true,
        isActive: false,
      });
      const selectedPage = parseInt(event.target.value, 10);
      this.setState({ bookmarkPage: event.target.value });
      this.goToBookmark(selectedPage);
    }
  };
  handleToggle = (e) => {
    const value = e.target.checked;
    if (value == true) {
      this.setState({
        IsBookmark: false,
        isActive: true,
        bookmarkPage: 0,
        isPagination: false,
        bookmarkClicked: false,
        activeBookmark: null,
        showBookmarks: false,
      });
    } else {
      this.setState({
        isActive: false,
        IsBookmark: true,
      }); 
    }
    this.goToBookmark(1);
  };

  onPageChange = (newPage) => {
    this.setState({ pageNumber: newPage });
  };

  goToBookmark = (page) => {
    this.setState({
      pageNumber: page,
      isPagination: true,
      activeBookmark: page,
    });
  };

  goToNextPage = () => {
    const { pageNumber, numPages } = this.state;
    if (pageNumber < numPages) {
      this.setState({
        pageNumber: pageNumber + 1,
        isPagination: true,
        isActive: false,
        IsBookmark: true,
        activeBookmark: null,
      });
    }
  };

  goToPreviousPage = () => {
    const { pageNumber } = this.state;
    if (pageNumber > 1) {
      this.setState({
        pageNumber: pageNumber - 1,
        isPagination: true,
        isActive: false,
        IsBookmark: true,
        activeBookmark: null,
      });
    }
  };

  zoomIn = () => {
    this.setState((prevState) => ({ zoom: prevState.zoom + 0.2 }));
  };

  zoomOut = () => {
    this.setState((prevState) => ({
      zoom: Math.max(0.2, prevState.zoom - 0.2),
    }));
  };

  deleteBookmark = (id) => {
    this.props.deleteBookMarkAction(id);
    setTimeout(async () => {
      const book = await this.props.AllBookMarkAction(
        this.props.location.state.item.id
      );
      this.setState({ bookmarks: book });
    }, 1000);
  };

  render() {
    const { pageNumber, numPages, bookmarks, zoom, isActive, showBookmarks } =
      this.state;
    const buttonStyle = this.state.bookmarkClicked
      ? { textAlign: "left", backgroundColor: "#E5D4FF" }
      : { textAlign: "left" };

    return (
      <>
        {this.state.openCreateModal && (
          <BookMarkPDF
            toggleClose={this.toggleClose}
            fetchStudyMaterialDataAction={this.fetchStudyMaterialDataAction}
            curId={this.state.pdffile.id}
          />
        )}
        <div className="PdfViewerDiv1">
          <div className="PdfBookmark1">
            <div
              style={{
                backgroundColor: "#F0F0F0",
                borderBottom: "1px solid grey",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* <select
              className="BookmarkSelect"
              onChange={this.handleBookmarkChange}
              value={this.state.bookmarkPage}
            >
              <option value={0} hidden>
                Bookmark
              </option>
              {(bookmarks || []).map((bookmark, index) => (
                <option key={index} value={bookmark.PageNumber}>
                  {bookmark.bookmarkName}
                </option>
              ))}
            </select>  */}

              <button
                style={buttonStyle}
                className="BookmarkSelect1"
                onClick={this.showBookmarkChange}
                onChange={this.handleBookmarkChange}
                value={this.state.bookmarkPage}
              >
                Bookmark
              </button>

              <div className="plusButton">
                {/* <button className="BkHead" disabled> <BiBookmarkAlt /></button> */}
                <button
                  onClick={() => {
                    this.setState({ openCreateModal: true });
                  }}
                  className="BkHead1"
                >
                  +
                </button>
              </div>
            </div>

            {showBookmarks && (
              <div style={{ overflowY: "scroll", maxHeight: "90vh" }}>
                {(bookmarks || []).map((bookmark, index) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "5px",
                    }}
                  >
                    <button
                      value={bookmark.PageNumber}
                      className={`BkButton ${
                        this.state.activeBookmark === bookmark.PageNumber
                          ? "activeBookmark"
                          : ""
                      }`}
                      onClick={() => this.goToBookmark(bookmark.PageNumber)}
                    >
                      {bookmark.bookmarkName}
                    </button>

                    <span
                      onClick={() => this.deleteBookmark(bookmark.id)}
                      className="crossSpan"
                    >
                      <MDBIcon
                        icon="trash"
                        size="md"
                        style={{ margin: "5px", cursor: "pointer" }}
                      />
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="PdfViewerDoc">
            <div className="PageNavigation">
              <div>
                <label className="Scroll">Scroll</label>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isActive}
                    onChange={this.handleToggle}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div>
                <button className="btnPage">Page</button>
                <button className="btnPrev" onClick={this.goToPreviousPage}>
                  <IoCaretBackOutline />
                </button>
                <button className="btnPageNo">{pageNumber} </button>
                <button className="btnNext" onClick={this.goToNextPage}>
                  <IoCaretForwardOutline />
                </button>
                <button className="btnPage"> of {numPages}</button>
                <button className="btnZoomIn" onClick={this.zoomIn}>
                  <CiZoomIn size={20} />
                </button>
                <button className="btnZoomIn">
                  {(zoom * 100).toFixed(0) + "%"}
                </button>
                <button className="btnZoomOut" onClick={this.zoomOut}>
                  <CiZoomOut size={20} />
                </button>
              </div>
            </div>
            <div
              className="PdfViewerContainer"
              style={{
                overflowX: "auto",
                textAlign: "center",
                display: "flex",
              }}
            >
              <Document
                file={this.state.pdfBase64}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                {this.state.isActive == false ? (
                  <Page pageNumber={pageNumber} width={600} scale={zoom} />
                ) : (
                  <>
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={
                          this.state.isPagination ? pageNumber : index + 1
                        }
                        width={600}
                        scale={zoom}
                      />
                    ))}
                  </>
                )}
              </Document>
            </div>
            {/* <p>
              Page {pageNumber} of {numPages}
            </p> */}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  AllBookMarkAction: (e) => dispatch(AllBookMarkAction(e)),
  deleteBookMarkAction: (e) => dispatch(deleteBookMarkAction(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentPdfViewer);
