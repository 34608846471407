import React, { Component } from "react";
import dummy from "../../../../assets/pdfs/dummy.pdf";
import iLikeMath_ALGEBRA_II_FUNDAMENTALS from "../../../../assets/pdfs/iLikeMath_ALGEBRA_II_FUNDAMENTALS.pdf";
import Mathematics_MEASURES_OF_SOLID_FIGURES from "../../../../assets/pdfs/Mathematics_MEASURES_OF_SOLID_FIGURES.pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { pdfjs } from "react-pdf";
import { CiZoomIn, CiZoomOut } from "react-icons/ci";
import { IoCaretForwardOutline, IoCaretBackOutline } from "react-icons/io5";
import { BiBookmarkAlt } from "react-icons/bi";
// import { getCurriculumPdfByIdAction } from "../../../../actions/institute/Teacherdashboard/ManageCurriculumPdf/ManageCurriculumPdfAction";
import { connect } from "react-redux";
// import { getCurriculumpdfFDataAction } from "../../../../actions/institute/Teacherdashboard/ManageCurriculumPdf/ManageCurriculumPdfAction";
import "./style.scss";
import { AllBookMarkAction } from "../../../../actions/Admin/ManageCurriculum/SaveBookMarkAction";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
class currPdfViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // pdfId: this.props.match.params.id,
      numPages: null,
      pageNumber: 1,
      zoom: 1.0, // Add zoom state
      pdfBase64: null,
      IsBookmark: false,
      bookmarkPage: 0,
      isPagination: false,
      bookmarks: [
        { title: "Introduction", page: 1 },
        { title: "Chapter 1", page: 5 },
        { title: "Chapter 2", page: 11 },
        { title: "Conclusion", page: 13 },
      ],
      CurriculumPdf: {},
      CurriculumPdfList: [],
      isActive: true,
      showBookmarks: false,
      activeBookmark: null,
      bookmarkClicked: false,
    };
  }

  componentDidMount = async () => {
    // this.props.getCurriculumpdfFDataAction();
    const book = await this.props.AllBookMarkAction(
      this.props.location.state.item.id
    );
    const fullbase64 =
      "https://elearningpdfstorage.blob.core.windows.net/curriculum/" +
      this.props.location.state.item.PdfName;
    this.setState({
      pdfBase64: fullbase64,
      pdffile: this.props.location.state.item,
      bookmarks: book,
    });

    // client bolb_BASEURL = "https://elearningpdfstorage.blob.core.windows.net/curriculum/"
    // csharptek blob_baseUrl = "https://velocityelearningsa.blob.core.windows.net/curriculum/"

    // if (this.props.match.params.id) {
    //   this.props.getCurriculumPdfByIdAction().then((res) => {
    //     this.setState({ CurriculumPdf: res });
    //   });
    // }
    // Load and convert the PDF to base64
    // fetch(iLikeMath_ALGEBRA_II_FUNDAMENTALS) // Replace with the URL to your PDF
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const reader = new FileReader();
    // reader.onload = () => {
    //   this.setState({ pdfBase64: reader.result });
    // };
    //     reader.readAsDataURL(blob);
    //   });
    //this.setState({ pdfBase64: this.state.CurriculumPdfList[0].pdfBase64 });

    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.CurriculumPdfList !== this.props.CurriculumPdfList) {
      this.setState({
        CurriculumPdfList: nextProps.CurriculumPdfList,
      });
    }
  }

  componentDidUpdate(prevProp, prevState) {}

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);
  }
  showBookmarkChange = (e) => {
    this.setState((prevState) => ({
      showBookmarks: !prevState.showBookmarks,
      bookmarkClicked: !prevState.bookmarkClicked,
      isActive: prevState.bookmarkClicked,
    }));
    this.goToBookmark(1);
  };

  handleKeyDown = (event) => {
    // Detect Print Screen key
    if (
      event.key === "PrintScreen" ||
      event.key === "PrtSc" ||
      event.key === "PrtScn" ||
      event.key === "Meta" ||
      event.key === "Control"
    ) {
      // Blur the content by adding a CSS class to the body
      document.body.classList.add("blurred");
      event.preventDefault();
    }
  };

  handleKeyUp = (event) => {
    // Detect Print Screen key
    if (
      event.key === "PrintScreen" ||
      event.key === "PrtSc" ||
      event.key === "PrtScn" ||
      event.key === "Meta" ||
      event.key === "Control"
    ) {
      // Blur the content by adding a CSS class to the body
      document.body.classList.add("blurred");
      event.preventDefault();
    }
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    const { zoom } = this.state;
    this.setState({
      numPages,
    });
  };

  onPageChange = (newPage) => {
    this.setState({ pageNumber: newPage });
  };

  handleBookmarkChange = (event) => {
    if (event.target.value == "") {
      this.setState({
        IsBookmark: false,
      });
    } else {
      this.setState({
        IsBookmark: true,
        isActive: false,
      });
      const selectedPage = parseInt(event.target.value, 10);
      this.setState({ bookmarkPage: event.target.value });
      this.goToBookmark(selectedPage);
    }
  };
  goToBookmark = (page) => {
    this.setState({
      pageNumber: page,
      isPagination: true,
      activeBookmark: page,
    });
  };
  handleToggle = (e) => {
    const value = e.target.checked;
    if (value == true) {
      this.setState({
        IsBookmark: false,
        isActive: true,
        bookmarkPage: 0,
        isPagination: false,
        bookmarkClicked: false,
        activeBookmark: null,
        showBookmarks: false,
      });
    } else {
      this.setState({
        isActive: false,
        IsBookmark: true,
      });

    }
    this.goToBookmark(1);
  };

  goToNextPage = () => {
    const { pageNumber, numPages } = this.state;
    if (pageNumber < numPages) {
      this.setState({
        pageNumber: pageNumber + 1,
        isPagination: true,
        isActive: false,
        IsBookmark: true,
        activeBookmark: null,
        bookmarkClicked: false,
        showBookmarks: false,
      });
    }
  };

  goToPreviousPage = () => {
    const { pageNumber } = this.state;
    if (pageNumber > 1) {
      this.setState({
        pageNumber: pageNumber - 1,
        isPagination: true,
        isActive: false,
        IsBookmark: true,
        activeBookmark: null,
        bookmarkClicked: false,
        showBookmarks: false,
      });
    }
  };

  zoomIn = () => {
    this.setState((prevState) => ({ zoom: prevState.zoom + 0.2 }));
  };

  zoomOut = () => {
    this.setState((prevState) => ({
      zoom: Math.max(0.2, prevState.zoom - 0.2),
    }));
  };

  handleContextMenu = (event) => {
    event.preventDefault();
  };

  render() {
    const { pageNumber, numPages, bookmarks, zoom, isActive, showBookmarks } =
      this.state;
    const buttonStyle = this.state.bookmarkClicked
      ? { textAlign: "left", backgroundColor: "#E5D4FF" }
      : { textAlign: "left", backgroundColor: "transparent" };

    return (
      <>
        <div className="PdfViewerDiv" onContextMenu={this.handleContextMenu}>
          <div className="PdfBookmark">
            <div
              style={{
                borderBottom: "1px solid grey",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* <select
              className="BookmarkSelect"
              onChange={this.handleBookmarkChange}
              value={this.state.bookmarkPage}
            >
              <option value={0} hidden>
                Bookmark
              </option>
              {(bookmarks || []).map((bookmark, index) => (
                <option key={index} value={bookmark.PageNumber}>
                  {bookmark.bookmarkName}
                </option>
              ))}
            </select> */}

              <button
                style={buttonStyle}
                className="BookmarkSelect"
                onClick={this.showBookmarkChange}
                onChange={this.handleBookmarkChange}
                value={this.state.bookmarkPage}
              >
                Bookmark
              </button>
            </div>

            {showBookmarks && (
              <div style={{ overflowY: "scroll", maxHeight: "85vh" }}>
                {(bookmarks || []).map((bookmark, index) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "5px",
                    }}
                  >
                    <button
                      value={bookmark.PageNumber}
                      className={`BkButton ${
                        this.state.activeBookmark === bookmark.PageNumber
                          ? "activeBookmark"
                          : "transparentBookmark"
                      }`}
                      onClick={() => this.goToBookmark(bookmark.PageNumber)}
                    >
                      {bookmark.bookmarkName}
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="PdfViewerDoc">
            <div className="PageNavigation">
              <div>
                <label className="Scroll">Scroll</label>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isActive}
                    onChange={this.handleToggle}
                  />
                  <span className="slider round"></span>
                </label>
                {/* <p>{isActive ? "Active" : "Inactive"}</p> */}
              </div>
              <div>
                <button className="btnPage">Page</button>
                <button className="btnPrev" onClick={this.goToPreviousPage}>
                  <IoCaretBackOutline />
                </button>
                <button className="btnPageNo">{pageNumber} </button>
                <button className="btnNext" onClick={this.goToNextPage}>
                  <IoCaretForwardOutline />
                </button>
                <button className="btnPage"> of {numPages}</button>
                <button className="btnZoomIn" onClick={this.zoomIn}>
                  <CiZoomIn size={20} />
                </button>
                <button className="btnZoomIn">
                  {(zoom * 100).toFixed(0) + "%"}
                </button>
                <button className="btnZoomOut" onClick={this.zoomOut}>
                  <CiZoomOut size={20} />
                </button>
              </div>
            </div>
            <div className="PdfViewerContainer">
              <Document
                file={this.state.pdfBase64}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                {this.state.isActive == false  ? (
                  <Page pageNumber={pageNumber} width={600} scale={zoom} />
                ) : (
                  <>
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={
                          this.state.isPagination ? pageNumber : index + 1
                        }
                        width={600}
                        scale={zoom}
                      />
                    ))}
                  </>
                )}
              </Document>
            </div>
            {/* <p>
              Page {pageNumber} of {numPages}
            </p> */}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  CurriculumPdf: state.getCurriculumPdfByIdReducer,
  CurriculumPdfList: state.getCurriculumPdfListReducer,
});

const mapDispatchToProps = (dispatch) => ({
  // getCurriculumPdfByIdAction: (e) => dispatch(getCurriculumPdfByIdAction(e)),
  // getCurriculumpdfFDataAction: (e) => dispatch(getCurriculumpdfFDataAction(e)),
  AllBookMarkAction: (e) => dispatch(AllBookMarkAction(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(currPdfViewer);
