//import { DELETE_KNOWLEDGE_BASE } from '../../../actions/Constants/index'
import { DELETE_BOOKMARK } from "../../../actions/Constants" 

const initialState = {
    status : null
}

export default (state = initialState, action) => {
	switch (action.type) {
		case DELETE_BOOKMARK:
			return {
				...state,
                status : action.payload.list,
			}
        default:
            return state
    }
}