import axios from "axios";
import { GET_FAQ, SHOW_SNACKBAR } from "../../Constants/index";
import { manageFAQ } from "../../../utils/Api";
import { getToken } from "../../../utils/token";

export const getFAQAction = (params) => {
  return (dispatch) => {
    const url =`${manageFAQ.searchFAQ}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params,
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_FAQ,
          payload: data.DATA,
        });
      } else {
        dispatch({
          type: GET_FAQ,
          payload: [],
        });
      }
    });
  };
};

export const editFAQAction = ({ id, data, history }) => {
  return (dispatch) => {
    const url = `${manageFAQ.editFAQ(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "PATCH",
        data,
      };
      return axios(options)
      .then(response => {
          let data = response && response.data ? response.data : false;
          const flag = data.SUCESS || data.ERROR
          if (data && (flag.status.errcode === 0)) {
              dispatch({
                  type: SHOW_SNACKBAR,
                  payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                });
               history.push("/super-admin/manage-faq");
          }else {
              dispatch({
                  type: SHOW_SNACKBAR,
                  payload: { type: "error", message: data.ERROR.status.msg, open: true },
                });
          }
      })
  };
};

export const getFAQByIdAction = (id) => {
  return (dispatch) => {
    const url = `${manageFAQ.getFAQByID(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        return data.DATA;
      }
      return {};
    });
  };
};

export const deleteFAQAction = (id) => {
  return () => {
    const url = `${manageFAQ.deleteFAQ(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "DELETE",
      };
    return axios(options);
  };
};

export const searchFAQAction = (params) => {
  return (dispatch) => {
    const url = `${manageFAQ.searchFAQ}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params,
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_FAQ,
          payload: data.DATA,
        });
      } else {
        dispatch({
          type: GET_FAQ,
          payload: [],
        });
      }
    });
  };
};

export const createFAQAction = (data, history) => {
  return (dispatch) => {
    const url = `${manageFAQ.createFAQ}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "POST",
        data,
      };
      return axios(options)
      .then(response => {
          let data = response && response.data ? response.data : false;
          const flag = data.SUCESS || data.ERROR
          if (data && (flag.status.errcode === 0)) {
              dispatch({
                  type: SHOW_SNACKBAR,
                  payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                });
               history.push("/super-admin/manage-faq");
          }else {
              dispatch({
                  type: SHOW_SNACKBAR,
                  payload: { type: "error", message: data.ERROR.status.msg, open: true },
                });
          }
      })
      
  };
};

export const addFAQCategoryAction = (data) => {
  return (dispatch) => {
    const url = `${manageFAQ.addCategory}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "POST",
        data,
      };
    return axios(options);
  };
};

export const getFAQCategoryAction = () => {
  return () => {
    const url = `${manageFAQ.getCategory}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        return data.DATA;
      }
      return {};
    });
  };
};
