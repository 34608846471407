
import { UPLOAD_PDF } from "../../../../actions/Constants" 
const initialState = {
    status : null,
    // uploadedPDFs: [],
}

export default (state = initialState, action) => {
	switch (action.type) {
		case UPLOAD_PDF:
			return {
				...state,
                status : action.payload.list,
                // status : "PDF uploaded succesfully.",
			}
        default:
            return state
    }
}