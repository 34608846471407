import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { MDBDataTable, MDBBtn, MDBIcon,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import './style.scss';
import Loader from "../../common/Loader";
import { clearGetGAlleryAction, deleteGalleryAction, getGalleryAction, getGalleryDropdownAction, clearGetGalleryDropdownAction, deletAlleGalleryAction } from '../../../actions/institute/ManageGallery/manageGallery';
import UploadGallery from './UploadGallery';
import { getEventsAction } from '../../../actions/institute/ManageEvents/manageEventsAction';
import { getSchoolAction } from '../../../actions/institute/ManageSchool/manageSchoolAction';


class ManageGallery extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
			galleryData : [],
			tableBody : { columns :[], rows: []},
			modal: false,
			selectedImage: null,
			deleteModal:false,
			deleteStatus:null,
			deleteAllStatus:null,
			deleteId: {
				ids: []
			  },
			openCreateModal:false,
			searchGallery:[],
			dropdownData: null,
			 isLoggedInRole:
			localStorage.getItem("loginedRole") === "principal" ? true : false,
			file:'',
			school_name:'',
			file_type:'',
			event_name:'',
			gallery_type: '',

            events:[],
            view:'list',
            school:[],
			role:this.props.role,
			deleteAllModal:false
		};
	}

	componentWillReceiveProps(nextProps){
        if(this.state.events !== nextProps.events.list){
			this.setState({
				events: nextProps.events.list
			})
		}
		if(this.state.dropdownData !== nextProps.dropdownData.list){
			this.setState({
				dropdownData: nextProps.dropdownData.list
			})
		}
		if(this.state.galleryData !== nextProps.galleryData.list){
			this.setState({
				galleryData: nextProps.galleryData.list
			},()=>{
				this.constructTableData()
			})
		}
	
		if(this.state.deleteStatus !== nextProps.deleteStatus.status){
			this.setState({
				deleteStatus:nextProps.deleteStatus.status
			},()=>{
				this.props.getGalleryAction()
				this.setState({
					deleteModal:false
				})
			})
		}
		if(this.state.deleteAllStatus !== nextProps.deleteAllStatus.status){
			this.setState({
				deleteAllStatus:nextProps.deleteAllStatus.status
			},()=>{
				this.props.getGalleryAction()
				this.setState({
					deleteAllModal:false
				})
			})
		}
		if(this.state.school !== nextProps.schoolState.list){
			this.setState({
				school: nextProps.schoolState.list
			})
		}
	}

	componentDidMount(){
        this.props.getSchoolAction()
		this.props.getGalleryAction()
        this.props.getGalleryDropdownAction()
        this.props.getEventsAction()
	}

	toggle = (value) =>{
		this.setState({
		  modal: true,
		  selectedImage : value
		});
	}

	
	toggleClose = ()=>{
		this.setState({
			modal: false,
			selectedImage: null,
			deleteModal: false,
			openCreateModal: false,
			deleteAllModal:false
		})
	}

	openEditModal=(value)=>{
		this.setState({
			openCreateModal:true
		})
	}

	openDeleteModal=(value)=>{
		this.setState({
			deleteModal: true,
			deleteId: {...this.state.deleteId,['ids']:[value.id]},
		})
	}

	componentWillUnmount(){
		this.props.clearGetGAlleryAction()
		this.props.clearGetGalleryDropdownAction()
	}

	deleteGallery=()=>{
		this.props.deleteGalleryAction(this.state.deleteId)
	}

	tableColumns = () => [
		{
			label: '#',
			field: 'id',
		},
		!this.state.isLoggedInRole &&  {
			label: 'School Name',
			field: 'school',
			width: 270,
		},
		{
			label: 'File Name',
			field: 'file',
			width: 150,
			attributes: {
				'aria-controls': 'DataTable',
				'aria-label': 'Name',
			},
		},
		{
			label: 'Type',
			field: 'gallery_type',
			width: 270,
		},
		{
			label: 'file Type',
			field: 'file_type',
			width: 270,
		},
		{
			label: 'Action',
			field: 'action',
			width: 300,
		},
	];

	constructTableData = () => {
		let tableData = {
		  columns: this.tableColumns().filter((ele) => ele),
		  rows: this.actionContainer(),
		};
		this.setState({
			tableBody: tableData,
		  });
	};
		actionContainer = () => {
			return this.state.galleryData.map((item,i)=>{
				let obj = {
					id: i+1,
					file: item.file_name ? item.file_name : '-',
					school: item.school_name ? item.school_name : '-',
					file_type: item.file_type ? item.file_type : '-' ,
					gallery_type: item.gallery_type ? item.gallery_type : '-' ,
					action: (
					<div>
						<MDBIcon  icon="eye" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{
							this.toggle(item)
						}} />
						<MDBIcon  icon="trash" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{
							this.openDeleteModal(item)
						}}/>
					</div>)

				}
				return obj
			})
		  };
	
	getGallery=()=>{
		this.props.getGalleryAction()
	}
	handleChange=(e)=>{
		this.setState({ [e.target.name] : e.target.value})
	}
	getCircularDetail = ()=>{
		return(
			<div className="card-body">
				<div className="row">
					<div className="col-lg-12 col-md-4 col-sm-6">
							{this.state.selectedImage && 
								(this.state.selectedImage.file_type === "Jpeg") ? 
								<img src={this.state.selectedImage.file} alt="imageurl" /> : 
								<i class="fa fa-file-pdf-o fa-5x" aria-hidden="true"></i>
							}
							                                
					</div>
				</div>
			</div>		
		)
	}
	deleteAllGallery=()=>{
		this.props.deletAlleGalleryAction()
	}
	deleteAll=()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={this.state.deleteAllModal} toggle={this.toggle}>
					<MDBModalHeader toggle={this.toggleClose}>Delete All Gallery</MDBModalHeader>
					<MDBModalBody>
						<div className="">
							Are you sure to delete all gallery?
						</div>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn color="secondary" onClick={this.deleteAllGallery}>Delete</MDBBtn>
						<MDBBtn color="secondary" onClick={this.toggleClose}>Close</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
			</MDBContainer>
		)
	}
   
	handleSearch=()=>{
		let search = {
			file_name:	this.state.file,
			school_id:this.state.school_name,
			file_type:	this.state.file_type,
			event_name:	this.state.event_name,
			gallery_type:	this.state.gallery_type,
		}
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		this.props.getGalleryAction(search)
	}
	clearSearch=()=>{
		this.setState({
			file:'',
			school_name:'',
			event_name:'',
			file_type:'',
			gallery_type:''
		})
		this.props.getGalleryAction()
	}
	forceDownload=(blob, filename)=> {
		var a = document.createElement('a');
		a.download = filename;
		a.href = blob;
		document.body.appendChild(a);
		a.click();
		a.remove();
	  }
	downloadResource=(url, filename)=> {
		if (!filename) filename = url.split('\\').pop().split('/').pop();
		fetch(url, {
			headers: new Headers({
			  'Origin': window.location.origin
			}),
			mode: 'cors'
		  })
		  .then(response => response.blob())
		  .then(blob => {
			let blobUrl = window.URL.createObjectURL(blob);
			this.forceDownload(blobUrl, filename);
		  })
		  .catch(e => console.error(e));
	  }
	render() {
		const { activeTab } = this.state
		// if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage Gallery</h1>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-3 col-md-4 col-sm-6">
												<div className="input-group">
													<input type="text" className="form-control" placeholder="File name" value={ this.state.file} 
                                                    onChange={ this.handleChange } name="file"/>
												</div>
											</div>
											{
												this.state.role === 'institute admin' ? 
												<div className="col-lg-3 col-md-2 col-sm-12">
													<select className="form-control show-tick" value={ this.state.school_name} onChange={ this.handleChange } name="school_name">
														<option value="">Select School</option>
														{this.state.school.map((item)=>{
															return <option value={item.id}>{item.school_name}</option>
														})}
													</select>
												</div>
												: ''
											}
                                            <div className="col-lg-3 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.file_type} onChange={ this.handleChange } name="file_type">
                                                    <option value="">Select File Type</option>
													<option value="doc">Doc</option>
													<option value="Jpeg">JPEG</option>
													<option value="pdf">PDF</option>
                                                </select>
                                            </div>
											<div className="col-lg-3 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.gallery_type} onChange={ this.handleChange } name="gallery_type">
                                                    <option value="">Select Type</option>
													<option value="Event Name">Event name</option>
                                                    <option value="School Gallery">School Gallery</option>
                                                    <option value="Misc Images">Misc</option>
                                                </select>
                                            </div>
										</div>
                                        <div className={`row`}>
											<div className="card-body cardBodyContainer">
												<div className={`row btnContainer`}>
													<div className="col-lg-4 col-md-4 col-sm-6">
														<MDBBtn color="primary" className='btn' onClick={this.handleSearch}>Search</MDBBtn> &nbsp;
														<MDBBtn color="primary" className='btn' onClick={this.clearSearch}>Clear</MDBBtn>
													</div>
                                                   
                                                    <div className="col-lg-8 col-md-4 col-sm-6 ">
                                                        <div className="card-body cardBodyContainer">
                                                            <div className={`row justify-content-end`}>
                                                                <div className="col-lg-2 col-md-4 col-sm-6 ">
                                                                    <MDBBtn color="primary" className='btn' onClick={()=>{
                                                                        this.setState({openCreateModal : true})
                                                                    }}>Upload</MDBBtn>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6 ">
                                                                    <MDBBtn color="primary" className='btn' onClick={()=>{
                                                                        this.setState({deleteAllModal : true})
                                                                    }}>Delete All</MDBBtn>
                                                                </div>
                                                                <div className="col-lg-3 col-md-2 col-sm-12">
                                                                    <select className="form-control show-tick" name="view" value={this.state.view} onChange={this.handleChange}>
                                                                        <option value="list" selected>List View</option>
                                                                        <option value="grid">Grid View</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
												</div>
											</div>
                                        </div>
									</div>
								</div>
							</TabPane>
							{
                                this.state.view === 'list' ? 
                                    <TabPane tabId={1} className={classnames(['fade show'])}>
                                        <div className="card">
                                            <div className="card-body">
											<h1 className="page-title">Gallery List</h1>
											{this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={['title']}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching = {false}
                />
              </div>
            )}
                                            </div>
                                        </div>
                                    </TabPane> 
                                    :
                                    <div className="gridView">
                                    {this.state.galleryData.map((item) => {
                                      return (
                                        <>
                                          {item.file_type === "pdf" ||
                                          item.file_type === "doc" ? (
                                            <div
                                              className="gridListView"
                                              onClick={() => {
                                                this.downloadResource(item.file);
                                              }}
                                            >
                                              {" "}
                                              <div>
                                                <i
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                                  class="fa fa-file-pdf-o fa-5x"
                                                  aria-hidden="true"
                                                ></i>
                                              </div>
                                              <div className="file-name">{item.file_name}</div>
                                            </div>
                                          ) : (
                                            <div
                                              className="gridListView"
                                              onClick={() => {
                                                this.downloadResource(item.file);
                                              }}
                                            >
                                              <div>
                                                {" "}
                                                <img
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    width: "80%",
                                                  }}
                                                  src={item.file}
                                                  alt="imageurl"
                                                />
                                              </div>
                                              <div className="file-name">{item.file_name}</div>
                                            </div>
                                          )}
                                        </>
                                      );
                                    })}
                                    </div>
                            }   
						</TabContent>
					</div>
					{this.state.openCreateModal ?  <UploadGallery  toggleClose ={ this.toggleClose } getGallery={ this.getGallery }/> : ''}
					{this.state.modal && <MDBContainer>
						<MDBModal isOpen={this.state.modal} toggle={this.toggle}>
							<MDBModalHeader toggle={this.toggleClose}></MDBModalHeader>
								<MDBModalBody>
									<div className="">
										{this.getCircularDetail()}
									</div>
								</MDBModalBody>
								<MDBModalFooter>
									<MDBBtn color="secondary" onClick={ this.toggleClose }>Close</MDBBtn>
									<MDBBtn color="secondary" onClick={()=>{this.downloadResource(this.state.selectedImage ? this.state.selectedImage.file : '')}}>Download</MDBBtn>
								</MDBModalFooter>
						</MDBModal>
					</MDBContainer>}
					
					{this.state.deleteModal && <MDBContainer>
						<MDBModal isOpen={this.state.deleteModal} toggle={this.toggle}>
							<MDBModalHeader toggle={this.toggleClose}>Delete Gallery</MDBModalHeader>
								<MDBModalBody>
									<div className="">
										Are you sure to delete this gallery?
									</div>
								</MDBModalBody>
								<MDBModalFooter>
									<MDBBtn color="secondary" onClick={this.deleteGallery}>Delete</MDBBtn>
									<MDBBtn color="secondary" onClick={this.toggleClose}>Close</MDBBtn>
								</MDBModalFooter>
						</MDBModal>
					</MDBContainer>}
					


				</div>
				{this.state.deleteAllModal ? this.deleteAll() : ''}

			</>
		);
	}
}

	const mapStateToProps = state => ({
	galleryData: state.getGalleryReducer,
	events: state.getEventsReducer,
	deleteStatus:state.deleteGalleryReducer,
	deleteAllStatus:state.deleteAllGalleryReducer,
	dropdownData : state.getGalleryDropdownReducer,
    schoolState: state.schoolReducer,
	role: state.settings.role,

})

const mapDispatchToProps = dispatch => ({
	getGalleryAction: (e) => dispatch(getGalleryAction(e)),
	clearGetGAlleryAction: (e) => dispatch(clearGetGAlleryAction(e)),
	deleteGalleryAction: (e) => dispatch(deleteGalleryAction(e)),
	deletAlleGalleryAction: (e) => dispatch(deletAlleGalleryAction(e)),
	getGalleryDropdownAction: (e) => dispatch(getGalleryDropdownAction(e)),
	clearGetGalleryDropdownAction: (e) => dispatch(clearGetGalleryDropdownAction(e)),
	getEventsAction: (e) => dispatch(getEventsAction(e)),
	getSchoolAction: (e) => dispatch(getSchoolAction(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ManageGallery)
