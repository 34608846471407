import { GET_ANNOUNCEMENT,GET_CLASSDETAIL_TEACHER,SHOW_SNACKBAR } from "../../Constants/index";
import axios from "axios";
import { manageAnnouncement } from "../../../utils/Api";
import { getToken } from "../../../utils/token";

export const searchAnnouncementAction = (params) => {
  let params2= localStorage.getItem("school") || localStorage.getItem("section")?{
    ...params,
    school_id:JSON.parse(localStorage.getItem("school")).id,
    teacher_id:JSON.parse(localStorage.getItem("school")).teacher_id,
    section_id:localStorage.getItem("section") ? JSON.parse(localStorage.getItem("section")).id : null
  }
 : params 
  return (dispatch) => {
    const url = `${manageAnnouncement.searchAnnouncement}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params2,
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_ANNOUNCEMENT,
          payload: data.DATA,
        });
      } else {
        dispatch({
          type: GET_ANNOUNCEMENT,
          payload: [],
        });
      }
    });
  };
};

export const createAnnouncementAction = (data, history) => {
  return (dispatch) => {
    const url = `${manageAnnouncement.createAnnouncement}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "POST",
        data,
      };
      return axios(options).then((response) => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "success", message: data.SUCESS.status.msg, open: true },
          });
         history.push("/teacher/manage-announcement");
        } else {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "error", message: data.ERROR.status.msg, open: true },
          });
        }
        return data;
      });
  };
};

export const getAnnouncementEditByIdAction = (id) => {
  return (dispatch) => {
    const url = `${manageAnnouncement.getEditAnnouncementById(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        return data.DATA;
      }
      return {};
    });
  };
};

export const editAnnouncementAction = ({ id, data, history }) => {
  return (dispatch) => {
    const url = `${manageAnnouncement.editAnnouncementById(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "PATCH",
        data,
      };
      return axios(options).then((response) => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "success", message: data.SUCESS.status.msg, open: true },
          });
         history.push("/teacher/manage-announcement");
        } else {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "error", message: data.ERROR.status.msg, open: true },
          });
        }
        return data;
      });
  };
};

export const classInfoAction = () => {
  return (dispatch) => {
    const url = `${manageAnnouncement.classInfoTeacher}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_CLASSDETAIL_TEACHER,
          payload: data.DATA,
        });
        return data.DATA
      } else {
        dispatch({
          type: GET_CLASSDETAIL_TEACHER,
          payload: [],
        });
      }
    });
  };
};