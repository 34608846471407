import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from "../../../common/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { MDBDataTable, MDBBtn, MDBIcon,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import './style.scss';
import moment from 'moment'
import { clearCreateFeedbackAction, clearGetFeedbackAction, clearGetFeedbackDropdownAction, createFeedbackAction, getFeedbackAction } from '../../../../actions/institute/ManageFeedback/manageFeedback';
import DatePicker from "react-datepicker";
import { getSubjectAction } from '../../../../actions/institute/Utils/utilsAction';


class FeedbackList extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
			feedbackData : [],
			tableBody : { columns :[], rows: []},
			modal: false,

			selectedFeedback: null,
			
			openSentModal:false,
			
			subject_id:'',
			title:'',
			comment:'',

            
			type:'',
			createdAt:'',

            subjectList:[],
			utills:{},
		};
	}

	componentWillReceiveProps(nextProps){
	
		if(this.state.feedbackData !== nextProps.feedbackData.list){
			this.setState({
				feedbackData: nextProps.feedbackData.list
			},()=>{
				this.constructTableData()
			})
		}
		if(this.state.subjectList !== nextProps.utills.subjectList){
            this.setState({
                subjectList: nextProps.utills.subjectList
            })
        }
	}
    clearData =()=>{
        this.props.clearCreateFeedbackAction()
        this.toggleClose()
    }
	componentDidMount(){
		
        let search = {
            section_id: JSON.parse(localStorage.getItem('section')).id
        }
        this.props.getSubjectAction(search)
		this.props.getFeedbackAction()
	}
	constructTableData =()=>{
		let col= [
			{
				label: '#',
				field: 'id',
			},
			{
				label: 'Feedback Title',
				field: 'title',
				width: 150,
			},
			{
				label: 'Class Name',
				field: 'class_name',
				width: 150,
			},
			{
				label: 'Section Name',
				field: 'section_name',
				width: 150,
			},
			{
				label: 'Subject',
				field: 'subject',
				width: 150,
			},
			{
				label: 'Type',
				field: 'type',
				width: 150,
			},
			{
				label: 'Send Date',
				field: 'createdDate',
				width: 150,
			},
			{
				label: 'Action',
				field: 'action',
				width: 150,
			}
		]
		let tableData = {
			columns: col,
			rows: 
				this.state.feedbackData.map((item,i)=>{
					let obj = {
						id: i+1,
						title: item.title ? item.title : '-',
						class_name: item.class_name ? item.class_name : '-' ,
						section_name: item.section_name ? item.section_name: '-',
						subject: item.subject_name ? item.subject_name: '-',
						type: item.user_type ? item.user_type: '-',
						createdDate: moment(item.createdAt).format("YYYY-MM-DD") ,
						status: item.status ? 'Sent' : 'Draft',
						action: (<div>
							<MDBIcon  icon="eye" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{
								this.viewFeedback(item)
							}} />
						</div>)

					}
					return obj
				})
		}	
		this.setState({
			tableBody: tableData
		})	
	}
	handleChange=(e)=>{
		this.setState({ [e.target.name] : e.target.value})
	}

	handleYear =(value)=>{
		this.setState({ year:value});
    }

	getFeedbackDetail = ()=>{
		return(
			<div className="card-body">
				
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Title
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							{this.state.selectedFeedback && this.state.selectedFeedback.title ? this.state.selectedFeedback.title : '-'}                                  
						</div>
					</div>
				</div>
				
				
                <div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Subject
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							{this.state.selectedFeedback && this.state.selectedFeedback.subject_name ? this.state.selectedFeedback.subject_name : '-'}                                  
						</div>
					</div>
				</div>
                <div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Comments
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							{this.state.selectedFeedback && this.state.selectedFeedback.comment ? this.state.selectedFeedback.comment : '-'}                                  
						</div>
					</div>
				</div>
			</div>		
		)
	}
    submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
		if(event.target.checkValidity()) {
            this.props.createFeedbackAction({
                title:this.state.title,
                subject_id:this.state.subject_id,
                comment:this.state.comment,
            })
			this.clearData()
                    this.props.getFeedbackAction()
		}
	}
	componentWillUnmount(){
		this.props.clearGetFeedbackDropdownAction()
		this.props.clearGetFeedbackAction()
	}

	toggleClose = ()=>{
		this.setState({
			openSentModal: false,
            modal:false,
            subject_id:'',
			title:'',
			comment:'',
		})
	}
    viewFeedback=(item)=>{
        this.setState({selectedFeedback:item,modal:true})
    }
	handleSearch=()=>{
		let search = {
			user_type:this.state.type,
			createdAt: this.state.createdAt ? moment(this.state.createdAt).format('YYYY-MM-DD') : '',
		}
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		this.props.getFeedbackAction(search)
	}
    sendFeedback=()=>{
        this.setState({openSentModal:true})
    }
    changeHandler = event => {
		this.setState({ [event.target.name]: event.target.value});
	};
	clearSearch=()=>{
		this.setState({
            type:'',
            createdAt:'',
		})
		// this.props.clearGetFeedbackAction()
		this.props.getFeedbackAction()
	}

    handleDate =(value)=>{
		this.setState({ createdAt:value});
    }

	render() {
		const { activeTab } = this.state
		// if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage Feedback</h1>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-4 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.type} onChange={ this.handleChange } name="type">
                                                    <option value="">Select Type</option>
                                                    <option value="student">Student</option>
                                                    <option value="parent">Parent</option>
                                                </select>
                                            </div>
											<div className="col-lg-4 col-md-2 col-sm-12">
                                                <DatePicker
                                                    selected={this.state.createdAt}
                                                    onChange={this.handleDate}
                                                    placeholderText="Select date"
                                                    className="form-control"
                                                />
											</div> 
										</div>
                                            <div className="btn-container">
												<div>
                                                <MDBBtn color="primary" className='btn' onClick={this.handleSearch}>Search</MDBBtn>
                                                <MDBBtn color="primary" className='btn' onClick={this.clearSearch}>Clear</MDBBtn>
												</div>
												<div>
											    <MDBBtn color="primary" className='btn' onClick={()=>{this.sendFeedback() }}>Send Feedback</MDBBtn>
                                            </div>
											</div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
									<h1 className="page-title">Feedback List</h1>
									{this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={['title']}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching = {false}
                />
              </div>
            )}
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>

					{this.state.modal &&
					<MDBContainer>
						<MDBModal isOpen={this.state.modal} toggle={this.toggleClose}>
							<MDBModalHeader toggle={this.toggleClose}>View Feedback</MDBModalHeader>
								<MDBModalBody>
									<div className="card">
										{this.getFeedbackDetail()}
									</div>
								</MDBModalBody>
								<MDBModalFooter>
									<MDBBtn color="secondary" onClick={this.toggleClose}>Close</MDBBtn>
								</MDBModalFooter>
						</MDBModal>
					</MDBContainer>}

                    {this.state.openSentModal &&
					<MDBContainer>
						<MDBModal isOpen={this.state.openSentModal} toggle={this.togtoggleClosegle}>
							<MDBModalHeader toggle={this.toggleClose}>Send Feedback</MDBModalHeader>
                                <form onSubmit={this.submitHandler} noValidate>
                                    <MDBModalBody>
                                        <div className="card">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                    <div className="input-group">
                                                        Title
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                    <div className="input-group">
                                                        :
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="input-group">

                                                        <input type="text" className="form-control" value ={this.state.title} 
                                                        name="title" onChange={this.changeHandler} placeholder="Enter title" required/>
                                                        
                                                        <div className="invalid-feedback">
                                                            required.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                    <div className="input-group">
                                                        Select Subject
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                    <div className="input-group">
                                                        :
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="input-group">
                                                        <select name="subject_id" value={this.state.subject_id} className="form-control show-tick" onChange={this.changeHandler} required>
                                                            <option value="">Select Subject</option>
                                                            {this.state.subjectList.map((item)=>{
                                                                return <option value={item.id}>{item.name}</option>
                                                            })}
                                                        </select>
                                                        <div className="invalid-feedback">
                                                            required.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                    <div className="input-group">
                                                        Comments:
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                    <div className="input-group">
                                                        :
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="input-group">
                                                        <textarea type="text" className="form-control" value ={this.state.comment} 
                                                        name="comment" onChange={this.changeHandler} placeholder="Enter Comments" required></textarea>
                                                        <div className="invalid-feedback">
                                                            required.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </MDBModalBody>
                                    <MDBModalFooter>
                                        <MDBBtn color="secondary" onClick={this.toggleClose}>Close</MDBBtn>
                                        <MDBBtn color="secondary" type="submit">Send</MDBBtn>
                                    </MDBModalFooter>
                                </form>
						</MDBModal>
					</MDBContainer>}
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	feedbackData: state.getFeedbackReducer,
	utills: state.utillReducer

})

const mapDispatchToProps = dispatch => ({
	getFeedbackAction: (e) => dispatch(getFeedbackAction(e)),
	createFeedbackAction: (e) => dispatch(createFeedbackAction(e)),
	clearCreateFeedbackAction: (e) => dispatch(clearCreateFeedbackAction(e)),
	clearGetFeedbackAction: (e) => dispatch(clearGetFeedbackAction(e)),
	clearGetFeedbackDropdownAction: (e) => dispatch(clearGetFeedbackDropdownAction(e)),
	
	getSubjectAction: (e) => dispatch(getSubjectAction(e)),

})
export default connect(mapStateToProps, mapDispatchToProps)(FeedbackList)
