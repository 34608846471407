import { MDBBtn, MDBInput } from 'mdbreact';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { clearSubmitAssignmentAction, getAssignmentByIdAction, submitAssignmentByStudentAction } from '../../../../actions/institute/Teacherdashboard/ManageAssignment/ManageAssignmentAction';

class SubmitAssignment extends Component {
    constructor(props) {
		super(props);
		this.state = {
			questionsList:[],

            editId: this.props.match.params.id,
            title:'',
            submitStatus:null,
            modal: false,
		};
	}
    componentDidMount(){
        this.props.getAssignmentByIdAction({id:this.state.editId})
    }
    componentWillReceiveProps(nextProps){
        if(this.props.assignment.detail !== nextProps.assignment.detail){
            let data = nextProps.assignment.detail[0]
            this.setState({
                questionsList: data.questions,
                title:data.assignment_title
            })
        }
        if(this.state.submitStatus !== nextProps.assignment.submitStatus){
            this.setState({
                submitStatus: nextProps.assignment.submitStatus
            },()=>{
                this.toggleClose()
                this.props.history.push('/student/manage-assignment')
            })
        }
	}
    changeHandler=(e,data,index)=>{
        let questionsList = [...this.state.questionsList]
        questionsList[index].answer = e.target.value
        this.setState({questionsList})
   
    }
    textFormat=(data,i)=>{
        return <div style={{margin:"20px"}}>
        <div className="row">
            <h6><b>Question {i+1} in Text Format</b></h6>
        </div>
        <div className="text-format">
            <div className="row mt-2" >
                <div className="col-lg-12 col-md-4 col-sm-6">
                    <h6><b>Question :</b> {data.name} </h6>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6">
                <h6>{
                        data.is_descriptive ?  this.markInputBox(data,i) : 
                        data.options.map((item,index)=>{
                            return <>
                            <div className="input-group">
                                <MDBInput value={item.id} type='radio' name={'textFormat'+i} 
                                    className="form-control" onChange={(e)=>{ this.changeHandler(e,data,i)}}/>{item.name}
                            </div>
                            </>
                        })
                    }
                </h6>
                </div>
            </div>
        </div>
    </div> 
    }
    markInputBox=(data,i)=>{
        if(data.is_descriptive){
            return<textarea type="text" placeholder='Type your answer here' className="form-control" 
            onChange={(e)=>{ this.changeHandler(e,data,i)}} name="answer" required></textarea>
        }
    }
    componentWillUnmount(){
        this.props.clearSubmitAssignmentAction()
    }
    handleSave=()=>{
        let obj = {
            assignment_id: this.state.editId,
            maq_answers:[],
            descriptive_answers:[]
        }
        this.state.questionsList.map((item,i)=>{
            if(item.is_descriptive){
                obj.descriptive_answers.push({
                    "question_id":item.id,
                    "answer":item.answer
                })
            }
            else{
                obj.maq_answers.push({
                    "question_id":item.id,
                    "answer_id":Number(item.answer)
                })
            }
        })
        this.props.submitAssignmentByStudentAction(obj)
    }
    submitHandler=(event)=>{
        event.preventDefault();
		event.target.className += " was-validated";
		if(event.target.checkValidity()) {
            this.setState({
                modal: true,
            })
		}
    }
    ImageFormat=(data,i)=>{
        return  <div style={{margin:"20px"}}>
        <div className="row">
            <h6><b>Question {i+1} in Image Format</b></h6>
        </div>
        <div className="text-format">
            <div className="row mt-2" >
                <div className="col-lg-12 col-md-4 col-sm-6">
                    <h6><b>Question :</b>
                        <img src={data.image_url} 
                        alt="imageurl" style={{paddingLeft:"20px",width:'50%'}}/>
                    </h6>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6">
                    <h6>{
                        data.is_descriptive ?  this.markInputBox(data,i) : 
                        data.options.map((item,index)=>{
                            return <>
                            <div className="input-group">
                                <MDBInput value={item.id} type='radio' name={'imageFormat'+i} 
                                    className="form-control" onChange={(e)=>{ this.changeHandler(e,data,i)}}/>{item.name}
                            </div>
                            </>
                        })
                    }</h6>
                </div>
            </div>
        </div>
    </div>
    }
    VideoFormat=(data,i)=>{
        return  <div style={{margin:"20px"}}>
        <div className="row">
            <h6><b>Question {i+1} in Video Format</b></h6>
        </div>
        <div className="text-format">
            <div className="row mt-2" >
                <div className="col-lg-12 col-md-4 col-sm-6">
                    <h6><b>Question :</b>
                        <iframe src={data.video_url}
                        title="video"></iframe>
                    </h6>
                </div>
                
            </div>
            <div className="row mt-2">
                <div className="col-lg-12 col-md-4 col-sm-6">
                <h6>
                    {
                        data.is_descriptive ?  this.markInputBox(data,i) : 
                        data.options.map((item,index)=>{
                            return <>
                            <div className="input-group">
                                <MDBInput value={item.id} type='radio' name={'videoFormat'+i} 
                                    className="form-control" onChange={(e)=>{ this.changeHandler(e,data,i)}}/>{item.name}
                            </div>
                            </>
                        })
                    }
                </h6>
                </div>
            </div>
        </div>
    </div>
    }
    toggleClose = ()=>{
		this.setState({
			modal: false,
		})
	}
    modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={ this.state.modal }>
                    <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
                    <MDBModalBody>
                        <div className='alertContainer'>
                            <h4> Do you want to save it ? </h4>
                            <h6> Please check the information before submitting</h6>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleClose}>No</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.handleSave}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
			</MDBContainer>
		)
	}
    render() {
        return (
            <div className="section-body" style={{width:"100%"}}>
                <form onSubmit={this.submitHandler} noValidate>
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center ">
                            <div className="header-action">
                                <h1 className="page-title">{this.state.title}</h1>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.questionsList && this.state.questionsList.map((item,i)=>{
                            if(item.image_url){
                                return this.ImageFormat(item,i)
                            }
                            else if(item.video_url){
                                return this.VideoFormat(item,i)
                            }
                            else{
                                return this.textFormat(item,i)
                            }
                        })
                    }
                    <div class="row">
                        <div className="col-lg-12 col-md-4 col-sm-6 inputField submitBtn">
                            <MDBBtn color="primary" className='btn' onClick= {()=>{
                                this.props.history.push('/student/manage-assignment')
                            }}>Cancel</MDBBtn>
                            <MDBBtn color="primary" type={'submit'} className='btn'>Submit</MDBBtn>
                        </div>
                    </div>
                </form>
                {this.modalContainer()}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    assignment: state.ManageAssignmentReducer,
})

const mapDispatchToProps = dispatch => ({
	getAssignmentByIdAction: (e) => dispatch(getAssignmentByIdAction(e)),
	submitAssignmentByStudentAction: (e) => dispatch(submitAssignmentByStudentAction(e)),
	clearSubmitAssignmentAction: (e) => dispatch(clearSubmitAssignmentAction(e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SubmitAssignment)

