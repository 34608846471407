import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import Loader from "../../../common/Loader";
import { MDBDataTable, MDBBtn, MDBIcon,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
// import './style.scss';
import moment from 'moment'
import DatePicker from "react-datepicker";
import { getAllSchoolAction, getClassAction,getSectionAction, getSubjectAction, getSubjectForExamAction, getTeacherAction } from '../../../../actions/institute/Utils/utilsAction';
import { getSkillRuleAction } from '../../../../actions/institute/Teacherdashboard/ManageSkill/ManageSkillRuleAction';


class ManageSkillRule extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
			tableBody : { columns :[], rows: []},

			class_id:'',
			section_id:'',
			subject_id:'',
			rule_for:'',
			
			year:'',
			createdAt:'',

			role:this.props.role,

			classList:[],
            sectionList:[],
            subjectList:[],
			utills:{},
			skillList:[]

		};
	}

	componentWillReceiveProps(nextProps){
	
		if(this.state.skillList !== nextProps.skillState.list){
			this.setState({
				skillList: nextProps.skillState.list
			},()=>{
				this.constructTableData()
			})
		}
		if(this.state.subjectList !== nextProps.utills.subjectForExam){
            this.setState({
                subjectList: nextProps.utills.subjectForExam
            })
        }
        if(this.state.sectionList !== nextProps.utills.sectionList){
            this.setState({
                sectionList: nextProps.utills.sectionList
            })
        }
		if(this.state.school !== nextProps.utills.schoolAllList){
			this.setState({
				school: nextProps.utills.schoolAllList
			})
		}
		if(this.state.classList !== nextProps.utills.classList){
			this.setState({
				classList: nextProps.utills.classList
			})
		}
		
	}
	componentDidUpdate(prevProp,prevState){
		if(this.state.class_id !== prevState.class_id) {
            if(this.state.class_id){
				let search = {
					class_id: this.state.class_id,
				}
				this.props.getSectionAction(search)
			}
		}
		if(this.state.section_id !== prevState.section_id) {
            if(this.state.class_id){
				let search = {
					class_id: this.state.class_id,
					section_id: this.state.section_id,
				}
				this.props.getSubjectForExamAction()
			}
		}
	}
	componentDidMount(){
		let search = {
            school_id: JSON.parse(localStorage.getItem('school')).id
        }
		this.props.getClassAction(search)
		this.props.getSkillRuleAction()
	}
	constructTableData =()=>{
		let col= [
			{
				label: '#',
				field: 'id',
			},
			{
				label: 'Rule Type',
				field: 'rule_for',
				width: 270,
			},
			{
				label: 'Class',
				field: 'class_name',
				width: 270,
			},
			{
				label: 'Section',
				field: 'section_name',
				width: 270,
			},
			{
				label: 'Subject',
				field: 'subject_name',
				width: 270,
			},
            {
				label: 'Session Year',
				field: 'year',
				width: 270,
			},
            {
				label: 'Rule For',
				field: 'name',
				width: 270,
			},
            {
				label: 'Skill Name',
				field: 'skill_name',
				width: 270,
			},
            {
				label: 'Created Date',
				field: 'created_at',
				width: 270,
			},
            {
				label: 'Action',
				field: 'action',
				width: 270,
			}
		]
		let tableData = {
			columns: col,
			rows: 
				this.state.skillList.map((item,i)=>{
					let obj = {
						id: i+1,
						rule_for: item.rule_for ? item.rule_for : '-',
						name: item.name ? item.name.join(',') : '-' ,
						percentage: item.percentage ? item.percentage : '-' ,
						subject_name: item.subject_name ? item.subject_name : '-' ,
						class_name: item.class_name ? item.class_name : '-' ,
						section_name: item.section_name ? item.section_name : '-' ,
						rule_condition: item.rule_condition ? item.rule_condition: '-',
						subject: item.subject ? item.subject.name: '-',
						skill_name: item.skill_name ? item.skill_name: '-',
						year: item.year ? item.year: '-',
						created_at: moment(item.created_at).format("YYYY-MM-DD") ,
						action: (<div>
							<MDBIcon  icon="edit" size="lg" style={{margin:'5px',cursor:'pointer'}}  onClick={()=>{
								this.props.history.push(`/teacher/edit-new-skill-rule/${item.id}`)
							}}/>
						</div>)
					}
					return obj
				})
		}	
		this.setState({
			tableBody: tableData
		})	
	}
	handleChange=(e)=>{
		this.setState({ [e.target.name] : e.target.value})
	}

	componentWillUnmount(){
		
	}

	handleSearch=()=>{
		let search = {
			class_id:this.state.class_id,
			section_id:this.state.section_id,
			subject_id:this.state.subject_id,
			rule_for:this.state.rule_for,
			// year:this.state.year,
			// createdAt: this.state.createdAt ? moment(this.state.createdAt).format('YYYY-MM-DD') : '',
		}
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		this.props.getSkillRuleAction(search)
	}

	clearSearch=()=>{
		this.setState({
            class_id:'',
            section_id:'',
            subject_id:'',
            rule_for:'',
		})
		this.props.getSkillRuleAction()
	}

	render() {
		const { activeTab } = this.state
		// if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage Skill Rules</h1>
								<ol className="breadcrumb page-breadcrumb">
									<li className="breadcrumb-item">Search</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-2 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.class_id} 
												onChange={ this.handleChange } name="class_id">
                                                    <option value="">Select Class</option>
													{this.state.classList && this.state.classList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
											<div className="col-lg-2 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.section_id} 
												onChange={ this.handleChange } name="section_id" disabled ={ this.state.class_id ? false : true}>
                                                    <option value="">Select Section</option>
													{this.state.sectionList && this.state.sectionList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
											<div className="col-lg-2 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.subject_id} 
												onChange={ this.handleChange } name="subject_id" disabled ={this.state.section_id ? false : true}>
                                                    <option value="">Select Subject</option>
                                                    {this.state.subjectList && this.state.subjectList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.rule_for} onChange={ this.handleChange } name="rule_for">
                                                    <option value="">Select Type</option>
                                                    <option value="Examination">Examination</option>
                                                    <option value="Assignment">Assignment</option>
                                                </select>
                                            </div>
                                            {/* <div className="col-lg-2 col-md-2 col-sm-12">
											<DatePicker
												onChange={(value) => this.handleSearch("year", value)}
												placeholderText="Select Year"
												className="form-control "
												showYearPicker
												dateFormat="yyyy"
											/>
                                            </div>  */}
										</div>
                                        <div className={`row`}>
											<div className="card-body cardBodyContainer">
												<div className={`row btnContainer`}>
													<div className="col-lg-9 col-md-4 col-sm-6">
														<MDBBtn color="primary" className='btn' onClick={()=>{this.handleSearch()}}>Search</MDBBtn> &nbsp;
														<MDBBtn color="primary" className='btn' onClick={this.clearSearch}>Clear</MDBBtn>
													</div>
													<div className="col-lg-3 col-md-4 col-sm-6">
														<MDBBtn color="primary" className='btn' onClick={()=>{this.props.history.push('/teacher/add-new-skill-rule')}}>Add New Skill Rule</MDBBtn> &nbsp;
													</div>
												</div>
											</div>
                                        </div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
									<h1 className="page-title">Skill Rule List</h1>
									{this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={['title']}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching = {false}
                />
              </div>
            )}
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	skillState: state.ManageSkillRuleReducer,
	role: state.settings.role,
	utills: state.utillReducer
})

const mapDispatchToProps = dispatch => ({
	getAllSchoolAction: (e) => dispatch(getAllSchoolAction(e)),
	getClassAction: (e) => dispatch(getClassAction(e)),
	getSectionAction: (e) => dispatch(getSectionAction(e)),
	// getSubjectAction: (e) => dispatch(getSubjectAction(e)),
	getTeacherAction: (e) => dispatch(getTeacherAction(e)),
	getSkillRuleAction: (e) => dispatch(getSkillRuleAction(e)),
	getSubjectForExamAction: (e) => dispatch(getSubjectForExamAction(e)),
	
})
export default connect(mapStateToProps, mapDispatchToProps)(ManageSkillRule)
