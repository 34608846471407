import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import React, { Component } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import { MDBBtn } from "mdbreact";
import { getTaskCalenderAction } from "../../../../actions/institute/StudentDashboard/ManageTask/manageTaskAction";

import { connect } from "react-redux";
const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

class CalendarView extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.getTaskCalenderAction();
  }

  calenderTableData = () => {
    return this.props.taskList.map((item, i) => {
      var obj = {
        title: item.title,
        start: new Date(item.start),
        end: new Date(item.end),
      };
      return obj;
    });
  };

  render() {
    return (
      <div className="App" style={{ padding: "10px" }}>
        <MDBBtn
          color="primary"
          className="btn"
          onClick={() => {
            this.props.history.push("/student/manage_task");
          }}
        >
          Back
        </MDBBtn>
        <Calendar
          localizer={localizer}
          events={this.calenderTableData()}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500, margin: "50px" }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  taskList: state.getTaskReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getTaskCalenderAction: () => dispatch(getTaskCalenderAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarView);
