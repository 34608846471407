import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import Loader from "../../../common/Loader";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import "./style.scss";
import {
  deleteGalleryAction,
  getGalleryAction,
  deletAlleGalleryAction,
} from "../../../../actions/institute/ManageGallery/manageGallery";
import {
  getClassAction,
  getSectionAction,
  getSubjectAction,
} from "../../../../actions/institute/Utils/utilsAction";
// import UploadGalleryTeacher from "./UploadGalleryTeacher";
// import { getCurriculumpdfFDataAction } from "../../../../actions/institute/Teacherdashboard/ManageCurriculumPdf/ManageCurriculumPdfAction";
import {ShowPDFListAction} from "../../../../actions/Admin/ManageCurriculum/ManageCurriculumPDFAction"
class ManageCurriculumPDFteacher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      tableBody: { columns: [], rows: [] },
      modal: false,
      selectedImage: null,
      deleteModal: false,
      deleteId: {
        ids: [],
      },
      openCreateModal: false,
      view: "list",
      CurriculumPdfList: [],
      getPdfList:[],
      entries: 10, // Initial number of entries per page
      activePage: 1,
      totalCount: 0,
      loading: false,
    };
  }

  componentDidMount() {
    // this.props.getCurriculumpdfFDataAction();
    const listLimit = {
      page: this.state.activePage,
      limit: 10,
    };
    this.props.ShowPDFListAction(listLimit);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.getPdfList && nextProps.getPdfList.list) {
      if (!this.state.getPdfList.list || this.state.getPdfList.list !== nextProps.getPdfList.list) {
        const { list, totalCount } = nextProps.getPdfList;
  
        this.setState({
          getPdfList: list,
          totalCount: totalCount,
        });
  
        setTimeout(() => {
          this.constructTableData();
        }, 500);
      }
    }
  }

  componentDidUpdate(prevProp, prevState) {
  }
  Base64() {
    // fetch(iLikeMath_ALGEBRA_II_FUNDAMENTALS) // Replace with the URL to your PDF
    // .then((response) => response.blob())
    // .then((blob) => {
    //   const reader = new FileReader();
    //   reader.onload = () => {
    //     this.setState({ pdfBase64: reader.result });
    //     console.log("abc", this.state.pdfBase64);
    //   };
    //   reader.readAsDataURL(blob);
    // });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggle = (value) => {
    this.setState({
      modal: true,
      selectedImage: value,
    });
  };

  toggleClose = () => {
    this.setState({
      modal: false,
      selectedImage: null,
      deleteModal: false,
      openCreateModal: false,
      deleteAllModal: false,
    });
  };

  samplePDFList = [
    {
      id: 1,
      file_name: "iLikeMath_ALGEBRA_II_FUNDAMENTALS.pdf",
      file_type: "pdf",
    },
    {
      id: 2,
      file_name: "Mathematics_8_Fundamentals_Unit7.pdf",
      file_type: "pdf",
    },
  ];

  tableColumns = () => [
    {
      label: "#",
      field: "id",
    },
    {
      label: "Curriculum PDF Name",
      field: "PdfName",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Name",
      },
    },
    {
      label: "Action",
      field: "action",
      width: 300,
    },
  ];

  constructTableData = () => {
    let tableData = {
      columns: this.tableColumns().filter((ele) => ele),
      rows: this.actionContainer(),
    };

    this.setState({
      tableBody: tableData,
    });
  };

  actionContainer = () => {
    return this.state.getPdfList.map((item, i) => {
      let obj = {
        id: item.id,
        PdfName: item.PdfName ? item.PdfName : "-",
        action: (
          <div
            className="actionContainer"
            style={{ padding: "0px !important" }}
          >
            <MDBIcon
              icon="eye"
              size="lg"
              style={{ margin: "5px", cursor: "pointer" }}
              onClick={() => {
                this.props.history.push(`/teacher/view_currpdf`, { item });
              }}
            />
            {/* <MDBIcon
              icon="trash"
              size="lg"
              style={{ margin: "5px", cursor: "pointer" }}
              onClick={() => {
                this.openDeleteModal(item);
              }}
            /> */}
          </div>
        ),
      };
      return obj;
    });
  };

  handleSearch = (name, value) => {
    this.setState({ search: { ...this.state.search, [name]: value } });
  };

  handlePageChange = (newPage) => {
    this.setState({ activePage: newPage,loading: true});
    const listLimit = {
      page: newPage,
      limit: 10,
    };
    this.props
    .ShowPDFListAction(listLimit)
    .then(() => {
      this.setState({ loading: false });
    })
    .catch(() => {
      this.setState({ loading: false });
    });
  };
  renderPagination() {
    const { activePage, entries, totalCount, loading } = this.state;
    const totalPages = Math.ceil(totalCount / entries);
    const pagesToShow = 10; // Number of pages to show at once
    const constantPages = 10; // Constant number of pages to show
 
    let startPage = Math.max(1, activePage - Math.floor(constantPages / 2));
    let endPage = Math.min(startPage + constantPages - 1, totalPages);
 
    if (endPage === totalPages) {
      startPage = Math.max(1, endPage - constantPages + 1);
    }
 
    const pages = Array.from(
      { length: constantPages },
      (_, i) => startPage + i
    );
  
    return (
      <>
       <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>
            Showing page {activePage} from {totalPages} pages
          </p>
      <nav className="navCurri">
        <ul className="pagination">
          <li className={`page-item ${activePage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => this.handlePageChange(activePage - 1)}>
              Previous
            </button>
          </li>
          {pages.map((page) => (
            <li key={page} className={`page-item ${page === activePage ? 'active' : ''}`}>
              <button className="page-link" onClick={() => this.handlePageChange(page)}>
                {page}
              </button>
            </li>
          ))}
          <li className={`page-item ${activePage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => this.handlePageChange(activePage + 1)}>
              Next
            </button>
          </li>
        </ul>
      </nav>
      </div>
      </>
    );
  }

  tableContainer = () => {
    if (this.state.view === "list") {
      return (
        <TabPane tabId={1} className={classnames(["fade show"])}>
          <div className="card">
            <div className="card-body">
              <h1 className="page-title">Curriculum List</h1>
              {this.state.loading ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <div
                  className={`table-container ${
                    this.state.pageChanging ? "blurred" : ""
                  }`}
                >
                  {this.state.tableBody.rows.length === 0 ? (
                    <Loader />
                  ) : (
                    <>
                  <MDBDataTable
                    noBottomColumns
                    className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                    // entriesOptions={[5, 10, 20, 25]}
                    entries={5}
                    paging={false}
                    // pagesAmount={15}
                    data={this.state.tableBody}
                    // searchTop
                    sortRows={["title"]}
                    // searchBottom={false}
                    // onPageChange={{ activePage: 2, pagesAmount: 5 }}
                    onPageChange={(e) => this.handlePageChange(e)}
                    disableRetreatAfterSorting={true}
                    searching={false}
                  />
                    {this.renderPagination()}
                    </>
                  )}
                </div>
                </div>
              )}
            </div>
          </div>
        </TabPane>
      );
    }
  };

  pageContainer = () => {
    const { activeTab } = this.state;
    return (
      <TabContent activeTab={activeTab}>{this.tableContainer()}</TabContent>
    );
  };

  modalContainer = () => {
    return (
      <div>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggleClose}></MDBModalHeader>
          <MDBModalBody>
            <div className="">{this.getCircularDetail()}</div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              Close
            </MDBBtn>
            <MDBBtn
              color="secondary"
              onClick={() => {
                this.downloadResource(
                  this.state.selectedImage ? this.state.selectedImage.file : ""
                );
              }}
            >
              Download
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal isOpen={this.state.deleteModal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggleClose}>
            Delete Gallery
          </MDBModalHeader>
          <MDBModalBody>
            <div className="">Are you sure to delete this gallery?</div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.deleteGallery}>
              Delete
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">Manage Curriculum PDF</h1>
                <ol className="breadcrumb page-breadcrumb">
                  <li className="breadcrumb-item">Search</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.pageContainer()}</div>
          {this.state.modal && this.modalContainer()}
          {this.state.deleteModal && this.modalContainer()}
        </div>
        {this.state.deleteAllModal && this.deleteAll()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  CurriculumPdfList: state.getCurriculumPdfListReducer,
  getPdfList:state.GETPdfListReducer, 
});

const mapDispatchToProps = (dispatch) => ({
  // getCurriculumpdfFDataAction: (e) => dispatch(getCurriculumpdfFDataAction(e)),
  ShowPDFListAction:(e)=>dispatch(ShowPDFListAction(e)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageCurriculumPDFteacher);
