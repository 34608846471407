import { GET_CIRCULAR, 
    CREATE_CIRCULAR,
    EDIT_CIRCULAR, 
    GET_CIRCULAR_BY_ID, 
    SEARCH_CIRCULAR,
    DELETE_CIRCULAR,
    UPLOAD_CIRCULAR,SHOW_SNACKBAR} from '../../Constants/index';
import axios from 'axios';
import { manageCircular } from '../../../utils/Api'
import { getToken } from '../../../utils/token'

export const getCircularAction = (params) => {
    let params2= localStorage.getItem("school") ?{
        ...params,
        school_id:JSON.parse(localStorage.getItem("school")).id
      }
     : params 
	return dispatch => {
        const url =`${manageCircular.searchCircular}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params2
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_CIRCULAR,
                    payload: {
                        list : data.response
                    }
                })
                return data.DATA
            }else {
                dispatch({
                    type: GET_CIRCULAR,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearGetCircularAction = () => {
	return dispatch => {
        dispatch({
            type: GET_CIRCULAR,
            payload: {
                list : []
            }
        })
    }
}

export const createCircularAction = (data) => {
	return dispatch => {
        const url = `${manageCircular.createCircular}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: CREATE_CIRCULAR,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
            }else {
                dispatch({
                    type: CREATE_CIRCULAR,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const clearCreateCircularAction = () => {
	return dispatch => {
        dispatch({
            type: CREATE_CIRCULAR,
            payload: {
                list : null
            }
        })
    }
}

export const clearEditCircularAction = () => {
	return dispatch => {
        dispatch({
            type: EDIT_CIRCULAR,
            payload: {
                list : null
            }
        })
    }
}

export const editCircularAction = ({id,data}) => {
	return dispatch => {
        const url = `${manageCircular.editCircular(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PATCH',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: EDIT_CIRCULAR,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
            }else {
                dispatch({
                    type: EDIT_CIRCULAR,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const getCircularByIdAction = (id) => {
	return dispatch => {
        const url = `${manageCircular.getCircularById(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_CIRCULAR_BY_ID,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_CIRCULAR_BY_ID,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearCircularByIdAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_CIRCULAR_BY_ID,
            payload: {
                list : {}
            }
        })
    }
}

export const searchCircularAction = (params) => {
	return dispatch => {
        const url = `${manageCircular.searchCircular}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: SEARCH_CIRCULAR,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: SEARCH_CIRCULAR,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const deleteCircularAction = (data) => {
	return dispatch => {
        const url = `${manageCircular.deleteCircular(data)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'DELETE',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: DELETE_CIRCULAR,
                    payload: {
                        status : flag.status
                    }
                })
            }else {
                dispatch({
                    type: DELETE_CIRCULAR,
                    payload: {
                        status : {}
                    }
                })
            }
        })
    }
}

export const uploadCircularFileAction = (data) => {
	return dispatch => {
        let formData = new FormData();
        formData.append('file', data);
        const url = `${manageCircular.uploadCircular}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data : formData
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: UPLOAD_CIRCULAR,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: UPLOAD_CIRCULAR,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearUploadCircularAction = (id) => {
	return dispatch => {
        dispatch({
            type: UPLOAD_CIRCULAR,
            payload: {
                list : {}
            }
        })
    }
}
