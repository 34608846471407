export const superUser = {
  superUserLogin: `/auth/login`, //POST
  superUserForgotPassword: `/auth/forgot-password`, //POST
  superUserChangePassword: `/auth/change-password`, //POST
};

export const manageInstitutes = {
  getInstitute: `/institute`, //GET
  getStatesAndcities: `/institute/getStatesCities`, //GET
  createInstitute: `/institute/create`, //POST
  getInstituteById: (id) => `/institute/${id}`, //GET,
  editInstitute: (id) => `/institute/${id}`, //PATCH,
  searchInstitute: `/institute/search`, //GET
  uploadInstituteFile: `/bulk-upload/institute`, //POST
  uploadImage: `/file-upload/image`, //POST
  getProfile: `/institute/profile`, //GET,
  updateProfile: `/institute/profile`,
  updatePassword: `/institute/password`, //PATCH,
  uploadVideo: `/video-upload/upload`, //POST
};

export const manageFAQ = {
  getFAQ: `/faq`, //GET
  createFAQ: `/faq/create`, //POST
  getFAQByID: (id) => `/faq/${id}`, //GET
  editFAQ: (id) => `/faq/${id}`, //POST
  deleteFAQ: (id) => `/faq/${id}`, //DELETE
  searchFAQ: `/faq/search`, //GET
  addCategory: `/category/create`, //POST
  getCategory: `/category`, //GET
};

export const studentAchievements = {
  getStudentAchievements: `/manage-achievement/search/student-achievement`, //GET
};

export const customerSupport = {
  getCustomerSupport: `/support`, //GET
  getUserAndInstitute: `/institute/getUserAndInstiture`, //GET
  replyById: (id) => `/support/reply/${id}`, //PATCH
  searchCustomerSupport: `/support/search`, //GET
  getInstitute: `/institute-name`, //GET
  getUserName: `/username`, //GET
};

export const manageNewsLetter = {
  createNewsLetter: `/newsletter/create`, //POST
  sendNewsLetterById: (id) => `/newsletter/send/${id}`, //POST
  uploadImage: `/newsletter/upload`, //POST
  getEditNewsLetterById: (id) => `/newsletter/${id}`, //GET
  EditNewsLetterById: (id) => `/newsletter/${id}`, //PATCH
  searchNewsLetter: `/newsletter/search`, //GET
  getEmail: (schoolId, id) => `/newsletter/emails/${schoolId}/${id}`, //GET
  subscribeEmail: `/newsletter/subscribe`, //POST
  getSubscribeEmail: `/newsletter/subscribe`, //GET
  UnsubscribeEmailNewsletter: `/newsletter/unsubscribe`, //DELETE
};

export const manageKnowledgeBase = {
  getKnowledgeBase: `/knowledge-base/search`, //GET
  createKnowledgeBase: `/knowledge-base/create`, //POST
  getKnowledgeBaseById: (id) => `/knowledge-base/${id}`, //GET,
  editKnowledgeBase: (id) => `/knowledge-base/update/${id}`, //PATCH,
  deleteKnowledgeBase: (id) => `/knowledge-base/${id}`, //delete,
  searchKnowledgeBase: `/knowledge-base/search`, //GET
  createCategory: `/kbcategory/create`, //GET
  getCategory: `/kbcategory`, //GET
  getCategoryCount: `/kbcategory/category-document-count`, //GET
};

export const manageReceipts = {
  getReceipts: `/receipts/search`, //GET
  getInvoiceNum: `/receipts/getInvoice`, //GET INVOICE NUM
  createReceipts: `/receipts/create`, //POST
  getReceiptsById: (id) => `/receipts/${id}`, //GET,
  editReceipts: (id) => `/receipts/update/${id}`, //PATCH,
  searchReceipts: `/receipts/search`, //GET
};
export const manageProfile = {
  getProfileInfo: `/super-admin/profile`, //GET
  updateProfileInfo: `/super-admin/profile`, //PUT
  getLogoAndImage: `/super-admin/logo-image`, //GET
  updateLogoAndImage: `/super-admin/logo-image`, //PUT,
  changePassword: ``,
};

export const manageAnnouncement = {
  searchAnnouncement: `/announcements/search`, //GET
  createAnnouncement: `/announcements/create`, //POST
  getEditAnnouncementById: (id) => `/announcements/${id}`, //GET,
  editAnnouncementById: (id) => `/announcements/update/${id}`, //PATCH,
  classInfoTeacher: `/teachers/class-info`, //GET
};

export const manageSchool = {
  createSchool: `/school/create`, //POST
  getSchoolById: (id) => `/school/${id}`, //GET,
  editSchool: (id) => `/school/${id}`, //PATCH,
  searchSchool: `/school/search`, //GET
  uploadSchool: `/bulk-upload/school`,
};

export const manageCircular = {
  createCircular: `/circular/create`, //POST
  getCircularById: (id) => `/circular/${id}`, //GET,
  editCircular: (id) => `/circular/${id}`, //PATCH,
  searchCircular: `/circular/search`, //GET
  deleteCircular: (id) => `/circular/${id}`, //GET
  uploadCircular: `/circular/upload`, //post
};

export const manageEvents = {
  createEvents: `/event/create`, //POST
  getEventsById: (id) => `/event/${id}`, //GET,
  editEvents: (id) => `/event/update/${id}`, //PATCH,
  searchEvents: `/event/search`, //GET
  uploadEventFile: `/file-upload/upload`, //POST
  uploadImage: `/file-upload/image`, //POST
  dropDownData: `/event/dropdown`, //POST
};
export const instituteUser = {
  userLogin: `/institute-auth/login`, //POST
  userForgotPassword: `/institute-auth/forgot-password`, //POST
  userChangePassword: `/institute-auth/change-password`, //POST
};
export const instituteInfo = {
  getInformation: `/institute/current-institute`,
};

export const manageGallery = {
  createGallery: `/gallery/create`, //POST
  getGalleryById: (id) => `/gallery/${id}`, //GET,
  editGallery: (id) => `/gallery/${id}`, //PATCH,
  searchGallery: `/gallery/search`, //GET
  uploadGalleryFile: `/gallery/upload`,
  deleteGallery: `/gallery/delete`,
  deleteAllGallery: `/gallery/delete-all`,
  dropDownData: `/gallery/dropdown`,
};

export const manageFeedback = {
  searchFeedback: `/feedback/search`, //GET
  dropDownData: `/feedback/getSchoolAndClass`, //POST
  createFeedback: `/feedback/create`, //POST
};

export const manageSection = {
  createSection: `/section/create`, //POST
  getEditSectionById: (id) => `/section/${id}`, //GET
  EditSectionById: (id) => `/section/${id}`, //PATCH
  searchSection: `/section/search`, //GET
};

export const manageClass = {
  createClass: `/class/create`, //POST
  getEditClassById: (id) => `/class/${id}`, //GET
  EditClassById: (id) => `/class/${id}`, //PATCH
  searchClass: `/class/search`, //GET
};

export const manageSubject = {
  createSubject: `/subject/create`, //POST
  getEditSubjectById: (id) => `/subject/${id}`, //GET
  EditSubjectById: (id) => `/subject/${id}`, //PATCH
  searchSubject: `/subject/search`, //GET
};

export const manageUser = {
  //Teachers
  createTeacherUser: `/teachers/create`, //POST
  getEditTeacherUserById: (id) => `/teachers/${id}`, //GET
  EditTeacherUserById: (id) => `/teachers/update/${id}`, //PATCH
  searchTeacherUser: `/teachers/search`, //GET
  uploadTeacherCSV: `/bulk-upload/teacher`, //POST
  //Student
  createStudentUser: `/student/create`, //POST
  getEditStudentUserById: (id) => `/student/${id}`, //GET
  EditStudentUserById: (id) => `/student/update/${id}`, //PATCH
  searchStudentUser: `/student/search`, //GET
  studentPasswordUpdate: `/student/change-password`, //POST
  uploadStudentCSV: `/bulk-upload/student`, //POST
  //Parent
  createParentUser: `/parent/create`, //POST
  getEditParentUserById: (id) => `/parent/${id}`, //GET
  EditParentUserById: (id) => `/parent/update/${id}`, //PATCH
  searchParentUser: `/parent/search`, //GET
  uploadParentCSV: `/bulk-upload/parent`, //POST
  //
  getAllStudentNoParent: `/student/no-parent`, //GET
};

export const manageReward = {
  manageReward: `/rewards/list`, //GET
  createReward: `/rewards/create`, //POST
  getEditRewardById: (id) => `/rewards/${id}`, //GET
  EditRewardById: (id) => `/rewards/update/${id}`, //PATCH
  searchReward: `/rewards/search`, //GET
};

export const manageSupport = {
  findAdminSupport: `/support/super-admin/list`, //GET
  findInstituteSupport: `/support/institute-admin/list`, //GET
  createSupport: `/support/create`, //POST
  getEditSupportById: (id) => `/support/${id}`, //GET
  EditSupportById: (id) => `/support/${id}`, //PATCH
  searchAdminSupport: `/support/super-admin/search`, //GET
  searchInstituteSupport: `/support/institute-admin/search`, //GET
  getInstitute: `/support/institute-name`, //GET
  getUserName: `/support/username`, //GET
};

export const manageAcedemics = {
  getTeacher: `/teachers`, //GET
  createAcedemics: `/academics/create`, //POST
  getAcedemicsById: (id) => `/academics/${id}`, //GET,
  editAcedemics: (id) => `/academics/${id}`, //PATCH,
  searchAcedemics: `/academics/search`, //GET
  deleteAcedemics: (id) => `/academics/${id}`, //GET
  uploadAcedemics: `/academics/upload`, //post
};
export const managePrincipal = {
  createPrincipal: `/principal/create`, //POST
  getPrincipalById: (id) => `/principal/${id}`, //GET
  editPrincipalById: (id) => `/principal/update/${id}`, //PATCH
  searchPrincipal: `/principal/search`, //GET
  uploadPrincipalFile: `/bulk-upload/principal`, //GET
  principalNameList: `/principal/names`, //GET
  schoolForPrincipal: `/global/school/no-principal`, //GET
};

export const utils = {
  getSchool: `/institute/schools`, //GET
  getState: `/global/states`, //GET
  getCity: (id) => `/global/cities/${id}`, //GET
  getAllSchool: `/global/schools`, //GET
  getTeacher: `/global/teachers`, //GET
  getAllStudent: `/global/students`, //GET
  getAllParent: `/global/parents`, //GET
  getClass: `/global/classes`, //GET
  getSection: `/global/sections`, //GET
  getSubject: `/global/subjects`, //GET
  getNewSubject:`/global/create-dropdown/subjects`,
  getAssignment:`/global/assignment-list`,
  getExamination:`/global/exam-list`,
  getSkill:`/global/skills`,
  getSubjectForExam:`/global/teacher/subjects`,
};

export const manageStudyMaterial = {
  getStudyMaterial: `/study-material`, //GET
  createStudyMaterial: `/study-material/create`, //POST
  searchStudyMaterial: `/study-material/search`, //GET
  deleteStudyMaterial: `/study-material/delete`,
  GoLiveStudyMaterialById: (id) => `/study-material/live/${id}`, //PATCH,
  deleteAllStudyMaterial: `/study-material/delete-all`, //PATCH
  uploadImageStudy: `/study-material/upload`,
};
export const manageSkill = {
  getSkill: `/manage-skill/list`, //GET
  createSkill: `/manage-skill/create`, //POST
  getSkillById: (id) => `/manage-skill/${id}`, //GET,
  editSkill: (id) => `/manage-skill/update/${id}`, //PATCH,
  searchSkill: `/manage-skill/search`, //GET
  getStudentSkill: `/manage-skill-rule/search/students-skills`, //GET
  searchStudentSkill: `/manage-skill/search`, //GET
  uploadSkillImage: `/manage-skill/upload`, //GET
};

export const manageSkillRule = {
  getSkillRule: `/manage-skill-rule/search`, //GET
  createSkillRule: `/manage-skill-rule/create`, //POST
  getSkillRuleById: (id) => `/manage-skill-rule/${id}`, //GET,
  editSkillRule: (id) => `/manage-skill-rule/update/${id}`, //PATCH,
};

export const manageCertificate = {
  getCertificate: `/manage-certificate/search`, //GET
  createCertificate: `/manage-certificate/create`, //POST
  getCertificateById: (id) => `/manage-certificate/${id}`, //GET,
  getPrincipal: `/manage-certificate/principal-details`, //GET,
  uploadCertificateLogo:`/manage-certificate/upload/logo`,
  uploadCertificateBadge:`/manage-certificate/upload/badge`,
  uploadCertificateSign:`/manage-certificate/upload/signature`
};

export const manageGoal = {
  getGoal: `/goals/search`, //GET
  getStudentGoal: `/goals/search/students-goals`, //GET
  createGoal: `/goals/create`, //POST
  getGoalById: (id) => `/goals/${id}`, //GET,
  editGoal: (id) => `/goals/update/${id}`, //PATCH,
  // searchGoal: `/goal/search`, //GET
};

export const manageAchievement = {
  getAchievement: `/manage-achievement/search`, //GET
  getAchievementBadgeURL: `/manage-achievement/badge`, //GET
  createAchievement: `/manage-achievement/create`, //POST
  getAchievementById: (id) => `/manage-achievement/${id}`, //GET,
  editAchievement: (id) => `/manage-achievement/update/${id}`, //PATCH,
};

export const manageExamination = {
  getExamination: `/manage-examination/list`, //GET
  getExaminationForStudent: `/manage-examination/student/list`, //GET
  getExaminationForParent: `/manage-examination/student-exam-list`, //GET
  createExamination: `/manage-examination/create`, //POST
  submitExamByStudent: `/manage-examination/submit-exam`, //POST
  getExaminationById: `/manage-examination`, //GET,
  editExamination: (id) => `/manage-examination/${id}`, //PATCH,
  publishExamination: (id) => `/manage-examination/publish/${id}`, //PATCH,
  searchExamination: `/manage-examination/search`, //GET
  deleteExamination: (id) => `/manage-examination/${id}`,
  getSubmittedExamDetail: (id) => `/manage-examination/submitted-list/${id}`,
  getNotSubmittedExamDetail: (id) =>
    `/manage-examination/not-submitted-students/${id}`,
  getSubmittedAnswerWithPendingStatus: (id, id2) =>
    `/manage-examination/submitted-questions-answer/${id}/${id2}`,
  getSubmittedAnswerWithEvaluatedStatus: (id, id2) =>
    `/manage-examination/student-evaluated-marks/${id}/${id2}`,
  evaluateMark: (id, id2) => `/manage-examination/mark-evaluation/${id}/${id2}`,
};

export const manageOnlineMeeting = {
  searchOnlineMeeting: `/online-meeting/search`, //GET
  searchStudentOnlineMeeting: `/online-meeting/search/student`, //GET
  createOnlineMeeting: `/online-meeting/create`, //POST
  getEditOnlineMeetingById: (id) => `/online-meeting/${id}`, //GET,
  editOnlineMeetingById: (id) => `/online-meeting/update/${id}`, //PATCH,
};

export const manageAssignment = {
  getAssignment: `/manage-assignment/list`, //GET
  getAssignmentForStudent: `/manage-assignment/student/list`, //GET
  getAssignmentForParent: `/manage-assignment/student-assignment-list`, //GET
  createAssignment: `/manage-assignment/create`, //POST
  getAssignmentById: `/manage-assignment`, //GET,
  editAssignment: (id) => `/manage-assignment/${id}`, //PATCH,
  publishAssignment: (id) => `/manage-assignment/publish/${id}`, //PATCH,
  searchAssignment: `/manage-assignment/search`, //GET
  deleteAssignment: (id) => `/manage-assignment/${id}`,
  getSubmittedAssignmentDetail: (id) =>
    `/manage-assignment/submitted-list/${id}`,
  getNotSubmittedAssignmentDetail: (id) =>
    `/manage-assignment/not-submitted-students/${id}`,
  getSubmittedAssignmentAnswerWithPendingStatus: (id, id2) =>
    `/manage-assignment/submitted-questions-answer/${id}/${id2}`,
  getSubmittedAssignmentAnswerWithEvaluatedStatus: (id, id2) =>
    `/manage-assignment/student-evaluated-marks/${id}/${id2}`,
  assignmentEvaluateMark: (id, id2) =>
    `/manage-assignment/mark-evaluation/${id}/${id2}`,
  submitAssignmentByStudent: `/manage-assignment/submit-assignment`, //POST

};

export const manageTask = {
  manageTask: `/task`, //GET
  createTask: `/task/create`, //POST
  getEditTaskById: (id) => `/task/${id}`, //GET
  EditTaskById: (id) => `/task/${id}`, //PATCH
  searchTask: `/task/search`, //GET
  deleteTask: (id) => `/task/${id}`, //DELETE
  manageTaskCalendar: `/task/calender-view`, //GET
};

export const classInfo = {
  getClassInfo: `/global/class-info`
};
// super Admin curriculum PDF BookMark
export const bookmarkOfPDF = {
  bookMarkByPDF: (id) => `/bookmark/pdf/${id}`, // POST 
  createBookMark:`/bookmark/create`, // POST 
  deleteBookMark: (id) => `/bookmark/${id}`, //delete,
 
};

//super Admin Manage curriculum PDF

export const ManageCurriculumPDF={
  uploadPDF:'/curriculum/create', // POST
  showPDFList: (page,limit) => `/curriculum/${page}/${limit}` ,// GET
  deletePDF: (id) => `/curriculum/${id}`, //delete,
}