import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { isLogined } from '../../../../utils/token'
import './style.scss';
import { connect } from 'react-redux';
import { MDBBtn, MDBFormInline, MDBInput} from 'mdbreact';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import AnswerDetail from './AnswerDetail';
import { getSubmittedAnswerWithEvaluatedStatusAction } from '../../../../actions/institute/Teacherdashboard/ManageExamination/ManageExaminationAction';
import moment from 'moment';
import { Link } from 'react-router-dom';

class ViewSubmittedAnswer extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
            
            id: this.props.match.params.id,
            studentId:this.props.match.params.studentId,
            createStatus: null,
            modal: false,
            detail:{}
		};
	}

    componentDidMount(){

      this.props.getSubmittedAnswerWithEvaluatedStatusAction(this.state.id,this.state.studentId)      
	}


    componentWillReceiveProps(nextProps){
        if(this.state.detail !== nextProps.examination.submittedAnswersWithEvaluatedStatus){
            this.setState({detail: nextProps.examination.submittedAnswersWithEvaluatedStatus})
        }
	}
    
    render(){
        const { activeTab } = this.state
        // if(!isLogined()) this.props.history.push('/')
        return(
            <>
            <div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action" style={{width:"60%"}}>
                                <div className="row">
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <h1 className="page-title">
                                            {this.state.detail && this.state.detail.exam_info && this.state.detail.exam_info[0] 
                                                                        ? this.state.detail.exam_info[0].student_name : '-'}
                                        </h1>
                                    </div>
                                    <div className="col-lg-5 col-md-4 col-sm-6">
                                        <Link to="/teacher/manage-examination">Back To Manage Examination</Link>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                        <MDBBtn color="primary" >Evaluated</MDBBtn> 
                                    </div>
                                </div>
							</div>
						</div>
					</div>
				</div>
                <div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
                                <form  noValidate>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Assignment Title:
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                {this.state.detail && this.state.detail.exam_info && this.state.detail.exam_info[0] 
                                                                ? this.state.detail.exam_info[0].examination_title : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Class Name:
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.detail && this.state.detail.exam_info && this.state.detail.exam_info[0] 
                                                                ? this.state.detail.exam_info[0].class_name : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Section Name:
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.detail && this.state.detail.exam_info && this.state.detail.exam_info[0] 
                                                                ? this.state.detail.exam_info[0].section_name : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Subject Name:
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.detail && this.state.detail.exam_info && this.state.detail.exam_info[0] 
                                                                ? this.state.detail.exam_info[0].subject_name : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Deadline Date:
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.detail && this.state.detail.exam_info && this.state.detail.exam_info[0] 
                                                                ? this.state.detail.exam_info[0].deadline : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Passign Marks:
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.detail && this.state.detail.mark_evaluation ? this.state.detail.mark_evaluation.pass_mark : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Submitted Date:
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.detail && this.state.detail.mark_evaluation  
                                                                ? moment(this.state.detail.mark_evaluation.createdAt).format('YYYY-MM-DD') : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Total Marks Obtained:
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                {this.state.detail && this.state.detail.mark_evaluation ? this.state.detail.mark_evaluation.obtained_marks : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                               Year:
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.detail && this.state.detail.mark_evaluation  
                                                                ? moment(this.state.detail.mark_evaluation.createdAt).format('YYYY') : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                               Result:
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                {this.state.detail && this.state.detail.mark_evaluation ? this.state.detail.mark_evaluation.result : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Percentage Obtained:
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.detail && this.state.detail.mark_evaluation ? this.state.detail.mark_evaluation.percentage : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                                Remarks :
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-6">
                                                            <div className="input-group">
                                                            {this.state.detail && this.state.detail.mark_evaluation && this.state.detail.mark_evaluation.remarks ? this.state.detail.mark_evaluation.remarks : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField"></div>
                                            </div>
                                            <div className="row">
                                                <AnswerDetail questions={this.state.detail && this.state.detail.qeustion_list } title="Examination Detail"/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </form>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    examination: state.ManageExaminationReducer,

})

const mapDispatchToProps = dispatch => ({
	getSubmittedAnswerWithEvaluatedStatusAction: (e,e1) => dispatch(getSubmittedAnswerWithEvaluatedStatusAction(e,e1)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewSubmittedAnswer)
