import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
  Modal,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import Loader from "../../../common/Loader";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import "./style.scss";
import {
  searchOnlineMeetingAction,
  getOnlineMeetingEditByIdAction,
} from "../../../../actions/institute/Teacherdashboard/ManageOnlineMeeting/ManageOnlineMeetingAction";
import {
  getClassAction,
  getSectionAction,
  getSubjectAction,
} from "../../../../actions/institute/Utils/utilsAction";

class ManageOnlineMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      tableBody: { columns: [], rows: [] },
      modalId: null,
      search: {
        school_id: "",
      },
      input: {},
      dropDownDataClass: [],
      dropDownDataSection: [],
      dropDownDataSubject: [],
      isOpenIframe: {
        show: false,
        item: ''
      },
    };
  }

  componentDidMount() {
    this.props.searchOnlineMeetingAction().then((res) => {
      this.constructTableData();
    });
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevState.search.school_id !== this.state.search.school_id) {
      if (this.state.search.school_id) {
        let search = {
          school_id: this.state.search.school_id,
        };
        this.props
          .getClassAction(search)
          .then((res) => this.setState({ dropDownDataClass: res }));
      }
    }
    if (prevState.search.class_id !== this.state.search.class_id) {
      if (this.state.search.class_id) {
        let search = {
          class_id: this.state.search.class_id,
        };
        this.props
          .getSectionAction(search)
          .then((res) => this.setState({ dropDownDataSection: res }));
      }
    }
    if (prevState.search.section_id !== this.state.search.section_id) {
      if (this.state.search.section_id) {
        let search = {
          section_id: this.state.search.section_id,
        };
        this.props
          .getSubjectAction(search)
          .then((res) => this.setState({ dropDownDataSubject: res }));
      }
    }
    {
      this.state.search.school_id === "" &&
        this.setState({
          search: {
            ...this.state.search,
            ["school_id"]: JSON.parse(localStorage.getItem("school")).id,
          },
        });
    }
  }

  toggle = (institute, i) => () => {
    this.props
      .getOnlineMeetingEditByIdAction(institute.id)
      .then((res) => this.setState({ input: res }));
    this.setState({
      modalId: i,
    });
  };

  toggleClose = () => {
    this.setState({
      modalId: null,
    });
  };

  handleSearch = (name, value) => {
    this.setState({ search: { ...this.state.search, [name]: value } });
  };

  searchHandler = async () => {
    let search = {
      title: this.state.search.title,
      class_id: this.state.search.class_id,
      section_id: this.state.search.section_id,
      subject_id: this.state.search.subject_id,
      status: this.state.search.status,
      shedule_at: this.state.search.shedule_at
        ? moment(this.state.search.shedule_at).format("YYYY-MM-DD hh:mm")
        : null,
    };
    Object.keys(search).forEach(
      (key) => search[key] == "" && delete search[key]
    );
    await this.props.searchOnlineMeetingAction(search);
    this.constructTableData();
  };

  clearHandler = async () => {
    this.setState({
      search: {},
      dropDownDataClass: [],
    });
    await this.props.searchOnlineMeetingAction().then((res) => {
      this.constructTableData();
    });
    {
      this.setState({
        search: {
          ...this.state.search,
          ["school_id"]: JSON.parse(localStorage.getItem("school")).id,
        },
      });
    }
  };

  tableColumns = () => [
    {
      label: "#",
      field: "id",
    },
    {
      label: "Title",
      field: "title",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Name",
      },
    },
    {
      label: "Class",
      field: "class",
      width: 270,
    },
    {
      label: "Section",
      field: "section",
      width: 270,
    },
    {
      label: "Subject",
      field: "subject",
      width: 270,
    },
    {
      label: "Meeting Date and Time",
      field: "start_time",
      width: 270,
    },
    {
      label: "Status",
      field: "status",
      width: 270,
    },
    {
      label: "Action",
      field: "action",
      width: 270,
    },
  ];

  constructTableData = () => {
    let tableData = {
      columns: this.tableColumns().filter((ele) => ele),
      rows: this.actionContainer(),
    };

    this.setState({
      tableBody: tableData,
    });
  };

  actionContainer = () => {
    return this.props.onlineMeetingList.map((item, i) => {
      let obj = {
        id: i + 1,
        title: item.title,
        class: item.class_name,
        section: item.section_name,
        subject: item.subject_name,
        start_time:  moment(item.start_time).format("YYYY-MM-DD hh:mm"),
        status: item.status === "1" ? "Scheduled" : "Cancelled",
        action: (
          <div className="actionContainer">
            <MDBIcon
              icon="edit"
              size="lg"
              className="icon"
              onClick={() => {
                this.props.history.push(
                  `/teacher/edit-online-meeting/${item.id}`
                );
              }}
            />
            <MDBIcon
              icon="eye"
              size="lg"
              className="icon"
              onClick={this.toggle(item, i)}
            />
            {item.status === "1" && (
              <a href={item.host_url} target="_blank">Join</a>
              // <button onClick={() => this.setState({ isOpenIframe: { show: true, item: item.host_url} })}>
              //   Join
              // </button>
            )}
          </div>
        ),
      };
      return obj;
    });
  };

  viewModalDetails = (item) => {
    return (
      <>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Meeting Title</h6>
            <h6>{item.title}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Class</h6>
            <h6>{item.class_name}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Section</h6>
            <h6>{item.section_name}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Subject</h6>
            <h6>{item.subject_name}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Schedule Date and Time</h6>
            <h6>{moment(item.start_time).format("YYYY-MM-DD hh:mm")}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Status</h6>
            <h6>{item.status === "1" ? "Scheduled" : "Cancelled"}</h6>
          </div>
        </div>
      </>
    );
  };

  searchContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-3 md-3 col-sm-12">
                <input
                  type="text"
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search.title
                  }
                  onChange={(e) => this.handleSearch("title", e.target.value)}
                  className="form-control"
                  placeholder="Meeting Title"
                />
              </div>
              <div className="col-lg-3 md-3 col-sm-12">
                <DateTimePicker
                  value={this.state.search.shedule_at}
                  onChange={(value) => this.handleSearch("shedule_at", value)}
                  placeholderText="Meeting date and Time"
                  className="form-control "
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="input-group">
                  <select
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search.class_id
                    }
                    onChange={(e) =>
                      this.handleSearch("class_id", e.target.value)
                    }
                    className="form-control show-tick"
                  >
                    <option value="">Select Class</option>
                    {this.state.dropDownDataClass.map((ele) => (
                      <option value={ele.id}>{ele.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="input-group">
                  <select
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search.section_id
                    }
                    onChange={(e) =>
                      this.handleSearch("section_id", e.target.value)
                    }
                    disabled={!this.state.search.class_id}
                    className="form-control show-tick"
                  >
                    <option value="">Select Section</option>
                    {this.state.dropDownDataSection.map((ele) => (
                      <option value={ele.id}>{ele.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 mt-10">
                <select
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search.subject_id
                  }
                  onChange={(e) =>
                    this.handleSearch("subject_id", e.target.value)
                  }
                  className="form-control show-tick"
                  disabled={!this.state.search.section_id}
                >
                  <option value="">Subject</option>
                  {this.state.dropDownDataSubject.map((ele) => (
                    <option value={ele.id}>{ele.name}</option>
                  ))}
                </select>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 mt-10">
                <select
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search.status
                  }
                  onChange={(e) => this.handleSearch("status", e.target.value)}
                  className="form-control show-tick"
                >
                  <option value="">Status</option>
                  <option value="1">Scheduled</option>
                  <option value="0">Cancelled</option>
                </select>
              </div>
            </div>
            <div className="btn-container">
              <div>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.searchHandler}
                >
                  Search
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.clearHandler}
                >
                  Clear
                </MDBBtn>
              </div>
              <div>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={() => {
                    this.props.history.push("/teacher/create-online-meeting");
                  }}
                >
                  Create
                </MDBBtn>
              </div>
            </div>
          </div>
        </div>
      </TabPane>
    );
  };

  tableContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body" id="capture">
            <h1 className="page-title">Meeting List</h1>
            {this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  searching={false}
                  sortRows={["title"]}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                />
              </div>
            )}
          </div>
        </div>
      </TabPane>
    );
  };

  modalContainer = () => {
    return (
      <div>
        {this.props.onlineMeetingList.map((item, i) => {
          return (
            <MDBModal
              isOpen={this.state.modalId === i ? true : false}
              toggle={this.toggle}
            >
              <MDBModalHeader toggle={this.toggleClose}>
                View Meeting
              </MDBModalHeader>
              <MDBModalBody>{this.viewModalDetails(item)}</MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggleClose}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          );
        })}
      </div>
    );
  };
  modalContainerIframe = (item) => {
    return (
      <div>
        {this.props.onlineMeetingList.map((item, i) => {
          return (
            <Modal
              isOpen={this.state.isOpenIframe.show}
              fullscreen
              className="viewIframe-modal"
            >
              <MDBModalHeader
                toggle={() => this.setState({ isOpenIframe: { show: false, item: ''} })}
              ></MDBModalHeader>
              <iframe
                src={this.state.isOpenIframe.item}
                scrolling="yes"
                allow="camera;microphone"
                className="viewIframe-modal"
              ></iframe>
            </Modal>
          );
        })}
      </div>
    );
  };
  pageContainer = () => {
    const { activeTab } = this.state;
    return (
      <TabContent activeTab={activeTab}>
        {this.searchContainer()}
        {this.tableContainer()}
      </TabContent>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">Manage Online Meeting</h1>
                <ol className="breadcrumb page-breadcrumb">
                  <li className="breadcrumb-item">Search</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.pageContainer()}</div>
          {this.modalContainer()}
        </div>
        {this.modalContainerIframe()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  onlineMeetingList: state.onlineMeetingReducer,
});

const mapDispatchToProps = (dispatch) => ({
  searchOnlineMeetingAction: (e) => dispatch(searchOnlineMeetingAction(e)),
  getClassAction: (e) => dispatch(getClassAction(e)),
  getSectionAction: (e) => dispatch(getSectionAction(e)),
  getSubjectAction: (e) => dispatch(getSubjectAction(e)),
  getOnlineMeetingEditByIdAction: (e) =>
    dispatch(getOnlineMeetingEditByIdAction(e)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageOnlineMeeting);
