import { GET_STUDY_MATERIAL,UPLOAD_STUDY_MATERIAL_FILE, SHOW_SNACKBAR } from "../../../Constants/index";
import axios from "axios";
import { manageStudyMaterial } from "../../../../utils/Api";
import { getToken } from "../../../../utils/token";
  
export const getStudyMaterialDataAction = () => {
  return (dispatch) => {
    const url = `${manageStudyMaterial.getStudyMaterial}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_STUDY_MATERIAL,
          payload: data.DATA,
        });
      } else {
        dispatch({
          type: GET_STUDY_MATERIAL,
          payload: [],
        });
      }
    });
  };
};

export const createStudyMaterialAction = (data) => {
  return (dispatch) => {
    const url = `${manageStudyMaterial.createStudyMaterial}`, 
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "POST",
        data,
      };
      return axios(options).then((response) => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "success", message: data.SUCESS.status.msg, open: true },
          });
        } else {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "error", message: data.ERROR.status.msg, open: true },
          });
        }
        return data;
      });
  };
};

export const searchStudyMaterialAction = (params) => {
  return (dispatch) => {
    const url = `${manageStudyMaterial.searchStudyMaterial}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params,
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_STUDY_MATERIAL,
          payload: data.DATA,
        });
      } else {
        dispatch({
          type: GET_STUDY_MATERIAL,
          payload: [],
        });
      }
    });
  };
};

export const deleteStudyMaterialAction = (data) => {
	return dispatch => {
        const url = `${manageStudyMaterial.deleteStudyMaterial}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'DELETE',
            data
        };
    return axios(options)
    .then(response => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR
        if (data && (flag.status.errcode === 0)) {
            data = data.DATA
        }
    })
}
}

export const GoLiveStudyMaterialAction = (id) => {
    return () => {
      const url = `${manageStudyMaterial.GoLiveStudyMaterialById(id)}`,
        options = {
          url,
          headers: {
            Authorization: getToken(),
          },
          method: "PATCH",
        };
      return axios(options);
    };
  };

  export const deletAllStudyMaterialAction = () => {
	return dispatch => {
        const url = `${manageStudyMaterial.deleteAllStudyMaterial}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'DELETE',
        };
        return axios(options)
    .then(response => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR
        if (data && (flag.status.errcode === 0)) {
            data = data.DATA
        }
    })
    }
}

export const uploadStudyMaterialFileAction = (data) => {
	return dispatch => {
        let formData = new FormData();
        formData.append('file', data);
        const url = `${manageStudyMaterial.uploadImageStudy}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data : formData
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: UPLOAD_STUDY_MATERIAL_FILE,
                    payload: data
                })
            }else {
                dispatch({
                    type: UPLOAD_STUDY_MATERIAL_FILE,
                    payload: {}
                })
            }
        })
    }
}
