import { GET_GOAL, 
    CREATE_GOAL,
    EDIT_GOAL, 
    GET_GOAL_BY_ID,GET_STUDENT_GOAL } from '../../../../actions/Constants/index'

const initialState = {
    list : [],
    studentList : [],
    editStatus:null,
    createStatus:null,
    detail:null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_GOAL:
        return {
            ...state,
            list : action.payload.list,
        }
        case GET_STUDENT_GOAL:
        return {
            ...state,
            studentList : action.payload.list,
        }
        case EDIT_GOAL:
        return {
            ...state,
            editStatus : action.payload.list,
        }
        case CREATE_GOAL:
        return {
            ...state,
            createStatus : action.payload.list,
        }
        case GET_GOAL_BY_ID:
        return {
            ...state,
            detail : action.payload.list,
        }
        default:
            return state
    }
}
