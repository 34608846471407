import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import Loader from "../../../common/Loader";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter
} from "mdbreact";
import "./style.scss";
import { getEventsAction } from "../../../../actions/institute/ManageEvents/manageEventsAction";

class ViewEventStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      tableBody: { columns: [], rows: [] },
      modalId: null,
      search: {},
    };
  }

  componentDidMount() {
    this.props.getEventsAction().then((res) => {
      this.constructTableData();
    });
  }

  toggle = (institute, i) => () => {
    this.setState({
      modalId: i,
    });
  };

  toggleClose = () => {
    this.setState({
      modalId: null,
    });
  };

  handleSearch = (name, value) => {
    this.setState({ search: { ...this.state.search, [name]: value } });
  };

  searchHandler = async () => {
    let search = {
      event_title: this.state.search.event_title,
      description: this.state.search.description,
      date: this.state.search.date
        ? moment(this.state.search.date).format("YYYY-MM-DD HH:MM")
        : null,
    };
    Object.keys(search).forEach(
      (key) => search[key] == "" && delete search[key]
    );
    await this.props.getEventsAction(search).then((res) => {
      this.constructTableData();
    });
  };

  clearHandler = async () => {
    this.setState({
      search: {},
    });
    await this.props.getEventsAction().then((res) => {
      this.constructTableData();
    });
  };

  tableColumns = () => [
    {
      label: "#",
      field: "id",
    },
    {
      label: "Event Title",
      field: "title",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Name",
      },
    },
    {
      label: "Description",
      field: "description",
      width: 270,
    },
    {
      label: "Event Coordinator",
      field: "coordinator",
      width: 270,
    },
    {
      label: "Event Date and Time",
      field: "event_date",
      width: 270,
    },
    {
      label: "Venue",
      field: "venue",
      width: 270,
    },
    {
      label: "Action",
      field: "action",
      width: 270,
    },
  ];

  constructTableData = () => {
    let tableData = {
      columns: this.tableColumns().filter((ele) => ele),
      rows: this.actionContainer(),
    };

    this.setState({
      tableBody: tableData,
    });
  };

  actionContainer = () => {
    return this.props.eventList.map((item, i) => {
      let obj = {
        id: i + 1,
        title: item.event_title,
        description: item.description,
        coordinator: item.coordinator_name,
        event_date: item.date,
        venue: item.venue,
        action: (
          <div className="actionContainer">
            <MDBIcon
              icon="eye"
              size="lg"
              className="icon"
              onClick={this.toggle(item, i)}
            />
          </div>
        ),
      };
      return obj;
    });
  };

  searchContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-4 md-3 col-sm-12">
                <input
                  type="text"
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search.event_title
                  }
                  onChange={(e) =>
                    this.handleSearch("event_title", e.target.value)
                  }
                  className="form-control"
                  placeholder="Event Title"
                />
              </div>
              <div className="col-lg-4 md-3 col-sm-12">
                <input
                  type="text"
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search.description
                  }
                  onChange={(e) =>
                    this.handleSearch("description", e.target.value)
                  }
                  className="form-control"
                  placeholder="Description"
                />
              </div>
              <div className="col-lg-4 md-3 col-sm-12">
                <DateTimePicker
                  value={this.state.search.date}
                  onChange={(value) => this.handleSearch("date", value)}
                  placeholderText="Meeting date and Time"
                  className="form-control "
                />
              </div>
            </div>
            <div className="btn-container">
              <div>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.searchHandler}
                >
                  Search
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.clearHandler}
                >
                  Clear
                </MDBBtn>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </TabPane>
    );
  };

  tableContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body" id="capture">
            <h1 className="page-title">Event List</h1>
            {this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  searching={false}
                  sortRows={["title"]}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                />
              </div>
            )}
          </div>
        </div>
      </TabPane>
    );
  };

  viewModalDetails = (item) => {
    return (
      <>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Event Title</h6>
            <h6>{item.event_title}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Event Description</h6>
            <h6>{item.description}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Event Date & Time</h6>
            <h6>{item.date}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Event Coordinator</h6>
            <h6>{item.coordinator_name}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold"> Coordinator Contact No</h6>
            <h6>{item.coordinator_number}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Venue</h6>
            <h6>{item.venue}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Uploaded File</h6>
            <h6 style={{color:'blue'}}>{ <a href={item.file_url} target="_blank">{item.file_url}</a>}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Uploaded Image</h6>
            <h6>{<img src={item.image_url} alt="imageurl" />}</h6>
          </div>
        </div>
      </>
    );
  };

  modalContainer = () => {
    return (
      <div>
        {this.props.eventList.map((item, i) => {
          return (
            <MDBModal
              isOpen={this.state.modalId === i ? true : false}
              toggle={this.toggle}
            >
              <MDBModalHeader toggle={this.toggleClose}>
                Event Title
              </MDBModalHeader>
              <MDBModalBody>{this.viewModalDetails(item)}</MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggleClose}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          );
        })}
      </div>
    );
  };

  pageContainer = () => {
    const { activeTab } = this.state;
    return (
      <TabContent activeTab={activeTab}>
        {this.searchContainer()}
        {this.tableContainer()}
      </TabContent>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">Event List</h1>
                <ol className="breadcrumb page-breadcrumb">
                  <li className="breadcrumb-item">Search</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.pageContainer()}</div>
          {this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  eventList: state.getEventsReducer.list,
});

const mapDispatchToProps = (dispatch) => ({
  getEventsAction: (e) => dispatch(getEventsAction(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewEventStudent);
