

import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
// import './style.scss';
import { connect } from 'react-redux';
import { getStatesAndcitiesAction } from '../../../actions/Admin/ManageInstitutes/manageInstitutesAction';
import { MDBBtn,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { clearCircularByIdAction, clearCreateCircularAction, clearEditCircularAction, clearUploadCircularAction, createCircularAction, editCircularAction, getCircularByIdAction, uploadCircularFileAction } from '../../../actions/institute/ManageCircular/manageCircularAction';
import { getSchoolAction } from '../../../actions/institute/ManageSchool/manageSchoolAction';

class CreateCirculars extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
            category:'',
            input:{
                schoolName:'',
                title:'',
                description:'',
            },
            file: null,
	        circularData : {},
            editId : this.props.editId,
            uploadStatus:null,
            editStatus:null,
            url:'',
            school:[],

			role:this.props.role,
            
            modal:false,
            createModal:true,
            isSend:false
		};
	}

    componentDidMount(){
        if(localStorage.getItem('school')){
            let school = JSON.parse(localStorage.getItem('school'))

            let input = {...this.state.input}
            input.schoolName = school.id
            this.setState({ input:input });
        }
        
        this.props.getSchoolAction()
        if(this.state.editId){
            this.props.getCircularByIdAction(this.state.editId)
        }
	}


    componentWillReceiveProps(nextProps){
        if(this.state.school !== nextProps.schoolState.list){
			this.setState({
				school: nextProps.schoolState.list
			})
		}
		
        let input = {...this.state.input}
        if(this.props.circularData.list !== nextProps.circularData.list){
            let data = nextProps.circularData.list
            input.schoolName = data.school_id ? data.school_id : ''
            input.title = data.title
            input.description = data.description
            this.setState({url:data.url})
        }
        if(this.state.uploadStatus !== (nextProps.uploadStatus && nextProps.uploadStatus.status && nextProps.uploadStatus.status.file)){
            this.setState({
               uploadStatus:nextProps.uploadStatus.status.file
           },()=>{
                if(nextProps.uploadStatus && nextProps.uploadStatus.status && nextProps.uploadStatus.status.file){
                    this.createCircular(nextProps.uploadStatus.status.file)
                }
           })
        }
        this.setState({ input:input });
	}
    toggle=()=>{
        this.props.toggleClose()
        this.clearData()
    }
    clearData =()=>{
        this.props.clearCircularByIdAction()
        this.props.clearCreateCircularAction()
        this.props.clearEditCircularAction()
        this.props.toggleClose()
    }
    componentWillUnmount(){
        this.props.clearCreateCircularAction()
        this.props.clearEditCircularAction()
        this.props.clearCircularByIdAction()
        this.props.clearUploadCircularAction()
    }
    createCircular=(url)=>{
        let _url = url ? url : this.state.url
        let data = {
                "title" : this.state.input.title,
                "description" : this.state.input.description,
                "url" : _url ,
                "status": this.state.isSend ? '1' : '0',
                "school_id" : this.state.input.schoolName
            }
            if(this.state.editId){
                this.props.editCircularAction({id : this.state.editId,data})
            }else{
                this.props.createCircularAction(data)
            }
            this.clearData()
            this.props.getCirculars()
    }
    handleSave=()=>{
        if(this.state.file){
            this.props.uploadCircularFileAction(this.state.file)
        }
        else{
            this.createCircular('')
        }
    }
    submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
		if(event.target.checkValidity()) {
            this.setState({
                createModal:false,
                modal: true,
            })
		}
	}
    modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={ this.state.modal }>
                    <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
                    <MDBModalBody>
                        <div className='alertContainer'>
                            <h4> Do you want to {this.state.editId ? 'Update' : 'save'} it ? </h4>
                            <h6> Please check the information before submitting</h6>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleClose}>No</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.handleSave}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
			</MDBContainer>
		)
	}
    onContentChange =(value)=>{
        this.setState({context:value})
    }
    changeHandler = event => {
        let input = {...this.state.input}
        input[event.target.name] = event.target.value
		this.setState({ input:input });
	};
    openCategoryModal = e =>{
        this.setState({
            modal:true
        })
    }
    toggleClose = ()=>{
		this.setState({
			modal: false,
            createModal:true
		})
	}

    handleFile=(event)=>{
        this.setState({file : event.target.files[0]})
    }

    render(){
        const { activeTab } = this.state
        // if(!isLogined()) this.props.history.push('/')
        return(
            <>
             {this.modalContainer()}
            <MDBContainer>
                <MDBModal isOpen={ this.state.createModal } >
                    <MDBModalHeader toggle={this.toggle}>{this.state.editId ? 'Update Circular' : 'Create Circular'}</MDBModalHeader>
                    <form onSubmit={this.submitHandler} noValidate>
                        <MDBModalBody>
                            <div className="card">
                                <div className="card-body">
                                    {
                                        this.state.role === 'institute admin' ? 
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Select School
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    : 
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <select name="schoolName" value={this.state.input['schoolName']} className="form-control show-tick" onChange={this.changeHandler} required>
                                                        <option value="">Select School</option>
                                                        {this.state.school.map((item)=>{
                                                            return <option value={item.id}>{item.school_name}</option>
                                                        })}
                                                    </select>
                                                    <div className="invalid-feedback">
                                                        required school name.
                                                    </div>                                 
                                                </div>
                                            </div>
                                        </div>
                                        :''
                                    }
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Circular Title
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                :
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">

                                                <input type="text" className="form-control" value ={this.state.input['title']} 
                                                name="title" onChange={this.changeHandler} placeholder="Circular title" required/>
                                                
                                                <div className="invalid-feedback">
                                                    required circular title.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Description
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                :
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                <textarea type="text" className="form-control" value ={this.state.input['description']} 
                                                name="description" onChange={this.changeHandler} placeholder="Description" required></textarea>
                                                <div className="invalid-feedback">
                                                    required description.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Upload Files
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                :
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="">
                                                <input type="file" className="form-control"
                                                    onChange={this.handleFile} required={this.state.url ? false: true} accept = "application/pdf"/>
                                                <div className="invalid-feedback">
                                                    required file.
                                                </div>
                                                <div className="">
                                                    {this.state.file ? '' : this.state.url}
                                                </div>
                                                <div className='imageFlag'>
                                                    Only PDF file accepted
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn>
                            <MDBBtn color="secondary" type="submit">{this.state.editId ? 'Update': 'Save'}</MDBBtn>
                            <MDBBtn color="secondary" type="submit" onClick={()=>{this.setState({isSend:true})}}>Send</MDBBtn>
                        </MDBModalFooter>
                    </form>
                   
                </MDBModal>
            </MDBContainer>
            </>
        )
    }
}

const mapStateToProps = state => ({
	circularData : state.getCircularByIdReducer,
    editStatus :state.editCircularReducer,
    uploadStatus:state.uploadFileReducer,
	role: state.settings.role,
    schoolState: state.schoolReducer

})

const mapDispatchToProps = dispatch => ({
	getStatesAndcitiesAction: (e) => dispatch(getStatesAndcitiesAction(e)),
	createCircularAction: (e) => dispatch(createCircularAction(e)),
	getCircularByIdAction: (e) => dispatch(getCircularByIdAction(e)),
	clearCircularByIdAction: (e) => dispatch(clearCircularByIdAction(e)),
	clearCreateCircularAction: (e) => dispatch(clearCreateCircularAction(e)),
	clearEditCircularAction: (e) => dispatch(clearEditCircularAction(e)),
	editCircularAction: (e) => dispatch(editCircularAction(e)),
	uploadCircularFileAction: (e) => dispatch(uploadCircularFileAction(e)),
	clearUploadCircularAction: (e) => dispatch(clearUploadCircularAction(e)),
	getSchoolAction: (e) => dispatch(getSchoolAction(e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateCirculars)
