import React, { Component } from "react";
import "./style.scss";

class SchoolInformation extends Component {
  render() {
    return (
      <div class="container">
        <div className="box-school">
          <h1 className="mt-10">School Information</h1>
          <hr />
          <div className="row col-lg-12 col-md-4 col-sm-6 mt-4">
            <h6 className="col-lg-4 col-md-4 col-sm-6"> Institute Name</h6>
            <div className="col-lg-1 col-md-4 col-sm-6"> :</div>
            <h5 className="col-lg-7 col-md-4 col-sm-6">
              {localStorage.getItem("instituteName")}
            </h5>
          </div>
          <div className="row col-lg-12 col-md-4 col-sm-6 mt-4 mb-4">
            <h6 className="col-lg-4 col-md-4 col-sm-6"> School Name</h6>
            <div className="col-lg-1 col-md-4 col-sm-6"> :</div>
            <h5 className="col-lg-7 col-md-4 col-sm-6">
              {localStorage.getItem("schoolName")}
            </h5>
          </div>
        </div>
      </div>
    );
  }
}

export default SchoolInformation;
