import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import Loader from "../../../common/Loader";
import moment from "moment";
import {
  MDBDataTable,
  MDBBtn,
} from "mdbreact";
import { searchStudentAchievementsAction } from "../../../../actions/institute/StudentsAchievement/studentAcheivementAction";
import "./style.scss";
import {
  getSubjectAction,
} from "../../../../actions/institute/Utils/utilsAction";

class ViewAchievements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      tableBody: { columns: [], rows: [] },
      search: {},
      dropDownDataSubject: [],
    };
  }

  componentDidMount() {
    let search = {};
    this.props.searchStudentAchievementsAction(search).then((res) => {
      this.constructTableData();
    });
    var localSectionId = {
      section_id: JSON.parse(localStorage.getItem("section")).id,
    };
    this.props
      .getSubjectAction(localSectionId)
      .then((res) => this.setState({ dropDownDataSubject: res }));
  }

  handleSearch = (name, value) => {
    this.setState({ search: { ...this.state.search, [name]: value } });
  };

  searchHandler = async () => {
    let search = {
      subject_id: this.state.search.subject_id,
      rule_for: this.state.search.rule_for,
      year: this.state.search.year
        ? moment(this.state.search.year).format("YYYY")
        : null,
    };
    Object.keys(search).forEach(
      (key) => search[key] == "" && delete search[key]
    );
    await this.props.searchStudentAchievementsAction(search);
    this.constructTableData();
  };

  clearHandler = async () => {
    this.setState({
      search: {},
      dropDownDataSubject: [],
    });
    await this.props.searchStudentAchievementsAction().then((res) => {
      this.constructTableData();
    });
  };

  tableColumns = () => [
    {
      label: "#",
      field: "id",
    },
    {
      label: "Subject",
      field: "subject",
      width: 270,
    },
    {
      label: "Session",
      field: "session",
      width: 270,
    },
    {
      label: "Examination Name",
      field: "exam_name",
      width: 270,
    },
    {
      label: "Examination Date",
      field: "exam_date",
      width: 270,
    },
    {
      label: "Examination %",
      field: "exam_precentage",
      width: 270,
    },
    {
      label: "Achievement",
      field: "achievement",
      width: 270,
    },
  ];

  constructTableData = () => {
    let tableData = {
      columns: this.tableColumns().filter((ele) => ele),
      rows: this.actionContainer(),
    };

    this.setState({
      tableBody: tableData,
    });
  };

  actionContainer = () => {
    return this.props.studentAchievementsList.map((item, i) => {
      let obj = {
        id: i + 1,
        subject: item.subject_name,
        session: item.year,
        exam_name: item.name,
        exam_date: item.exam_date,
        exam_precentage: item.percentage,
        achievement:item.badge ? <img src={item.badge} alt="badge" width="20%"/> : '-',
      };
      return obj;
    });
  };

  searchContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="input-group">
                  <select
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search.subject_id
                    }
                    onChange={(e) =>
                      this.handleSearch("subject_id", e.target.value)
                    }
                    className="form-control show-tick"
                  >
                    <option value="">Subject</option>
                    {this.state.dropDownDataSubject.map((ele) => (
                      <option value={ele.id}>{ele.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <select
                   value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search.rule_for
                  }
                  onChange={(e) =>
                    this.handleSearch("rule_for", e.target.value)
                  }
                  className="form-control show-tick"
                >
                  <option value="">Select Type</option>
                  <option value="Assignment">Assignment</option>
                  <option value="Examination">Examination</option>
                </select>
              </div>
              <div className="col-lg-4 md-3 col-sm-12">
                <DatePicker
                  name="year"
                  selected={this.state.search.year}
                  onChange={(value) => this.handleSearch("year", value)}
                  placeholderText="Select Year"
                  className="form-control"
                  showYearPicker
                  dateFormat="yyyy"
                />
              </div>
            </div>
            <div className={`row`}>
              <div className="card-body cardBodyContainer">
                <div className={`row btnContainer`}>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <MDBBtn
                      color="primary"
                      className="btn"
                      onClick={this.searchHandler}
                    >
                      Search
                    </MDBBtn>{" "}
                    &nbsp;
                    <MDBBtn
                      color="primary"
                      className="btn"
                      onClick={this.clearHandler}
                    >
                      Clear
                    </MDBBtn>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabPane>
    );
  };

  tableContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body" id="capture">
            <h1 className="page-title">Achievements List</h1>
            {this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  searching={false}
                  sortRows={["title"]}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                />
              </div>
            )}
          </div>
        </div>
      </TabPane>
    );
  };

  pageContainer = () => {
    const { activeTab } = this.state;
    return (
      <TabContent activeTab={activeTab}>
        {this.searchContainer()}
        {this.tableContainer()}
      </TabContent>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">View Achievements</h1>
                <ol className="breadcrumb page-breadcrumb">
                  <li className="breadcrumb-item">Search</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.pageContainer()}</div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  studentAchievementsList: state.getStudentAchievements,
});

const mapDispatchToProps = (dispatch) => ({
  searchStudentAchievementsAction: (e) =>
    dispatch(searchStudentAchievementsAction(e)),
  getSubjectAction: (e) => dispatch(getSubjectAction(e)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewAchievements);
