import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import {
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import {
  createStudyMaterialAction,
  uploadStudyMaterialFileAction,
} from "../../../../actions/institute/Teacherdashboard/ManageStudyMaterial/ManageStudyMaterialAction";
import {
  getClassAction,
  getSubjectAction,
  getSectionAction,
} from "../../../../actions/institute/Utils/utilsAction";

class UploadStudy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {
        school_id: "",
        year: new Date().getFullYear(),
      },
      dropDownDataClass: [],
      dropDownDataSection: [],
      dropDownDataSubject: [],
      file: null,
      modal: false,
      isSave: true,
      createModal: true,
    };
  }

  componentDidMount() {
    {
      this.state.input.school_id === "" &&
        this.setState({
          input: {
            ...this.state.input,
            ["school_id"]: JSON.parse(localStorage.getItem("school")).id,
          },
        });
    }
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevState.input.school_id !== this.state.input.school_id) {
      if (this.state.input.school_id) {
        let search = {
          school_id: this.state.input.school_id,
        };
        this.props
          .getClassAction(search)
          .then((res) => this.setState({ dropDownDataClass: res }));
      }
    }
    if (prevState.input.class_id !== this.state.input.class_id) {
      if (this.state.input.class_id) {
        let search = {
          class_id: this.state.input.class_id,
        };
        this.props
          .getSectionAction(search)
          .then((res) => this.setState({ dropDownDataSection: res }));
      }
    }
    if (prevState.input.section_id !== this.state.input.section_id) {
      if (this.state.input.section_id) {
        let search = {
          section_id: this.state.input.section_id,
        };
        this.props
          .getSubjectAction(search)
          .then((res) => this.setState({ dropDownDataSubject: res }));
      }
    }
  }

  modalContainer = () => {
    return (
      <MDBContainer>
        <MDBModal isOpen={this.state.modal}>
          <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
          <MDBModalBody>
            <div className="alertContainer">
              <h4>
                {" "}
                Do you want to {this.state.editId ? "Update" : "save"} it ?{" "}
              </h4>
              <h6> Please check the information before submitting</h6>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              No
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.handleSave}>
              Yes
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  };

  toggle = () => {
    this.props.toggleClose();
    this.clearData();
  };
  clearData = () => {
    this.props.toggleClose();
  };

  handleSave = async () => {
    const temp = {
      ...this.state.input,
      file: this.props.studyMaterialupload,
    };
    this.setState({
      input: temp,
    });
    await this.props.createStudyMaterialAction(temp); 
    this.setState({
      modal: false,
    });
    await this.props.fetchStudyMaterialDataAction();
    this.props.toggleClose();
  };

  submitHandler = async (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (event.target.checkValidity()) {
      this.setState({
        createModal: false,
        modal: true,
      });
    }
    await this.props.uploadStudyMaterialFileAction(this.state.file);
  };

  toggleClose = () => {
    this.setState({
      modal: false,
      createModal: true,
    });
  };

  changeHandler = (name, value) => {
    this.setState({ input: { ...this.state.input, [name]: value } });
  };

  handleFile = (event) => {
    this.setState({ file: event.target.files[0] });
  };

  render() {
    return (
      <>
        {this.modalContainer()}
        <MDBContainer>
          <MDBModal isOpen={this.state.createModal}>
            <MDBModalHeader toggle={this.toggle}>
              Upload Study Material
            </MDBModalHeader>
            <form onSubmit={this.submitHandler} noValidate>
              <MDBModalBody>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                              <div className="input-group">Year</div>
                            </div>
                            <div className="col-lg-1 col-md-4 col-sm-6">
                              <div className="input-group">:</div>
                            </div>
                            <div className="col-lg-8 col-md-4 col-sm-6">
                              <div className="input-group">
                                {new Date().getFullYear()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                              <div className="input-group">Class Name</div>
                            </div>
                            <div className="col-lg-1 col-md-4 col-sm-6">
                              <div className="input-group">:</div>
                            </div>
                            <div className="col-lg-8 col-md-4 col-sm-6">
                              <div className="input-group">
                                <select
                                  value={this.state.input.class_id}
                                  onChange={(e) =>
                                    this.changeHandler(
                                      "class_id",
                                      e.target.value
                                    )
                                  }
                                  required
                                  className="form-control show-tick"
                                >
                                  <option value="">Select Class</option>
                                  {this.state.dropDownDataClass.map((ele) => (
                                    <option value={ele.id}>{ele.name}</option>
                                  ))}
                                </select>
                                <div className="invalid-feedback">
                                  required class name field.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                              <div className="input-group">Section Name</div>
                            </div>
                            <div className="col-lg-1 col-md-4 col-sm-6">
                              <div className="input-group">:</div>
                            </div>
                            <div className="col-lg-8 col-md-4 col-sm-6">
                              <div className="input-group">
                                <select
                                  value={this.state.input.section_id}
                                  onChange={(e) =>
                                    this.changeHandler(
                                      "section_id",
                                      e.target.value
                                    )
                                  }
                                  disabled={!this.state.input.class_id}
                                  required
                                  className="form-control show-tick"
                                >
                                  <option value="">Select Section</option>
                                  {this.state.dropDownDataSection.map((ele) => (
                                    <option value={ele.id}>{ele.name}</option>
                                  ))}
                                </select>
                                <div className="invalid-feedback">
                                  required section name field.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                              <div className="input-group">Subject Name</div>
                            </div>
                            <div className="col-lg-1 col-md-4 col-sm-6">
                              <div className="input-group">:</div>
                            </div>
                            <div className="col-lg-8 col-md-4 col-sm-6">
                              <div className="input-group">
                                <select
                                  value={this.state.input.subject_id}
                                  onChange={(e) =>
                                    this.changeHandler(
                                      "subject_id",
                                      e.target.value
                                    )
                                  }
                                  disabled={!this.state.input.section_id}
                                  required
                                  className="form-control show-tick"
                                >
                                  <option value="">Select Subject</option>
                                  {this.state.dropDownDataSubject.map((ele) => (
                                    <option value={ele.id}>{ele.name}</option>
                                  ))}
                                </select>
                                <div className="invalid-feedback">
                                  required subject name field.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                              <div className="input-group">File Name</div>
                            </div>
                            <div className="col-lg-1 col-md-4 col-sm-6">
                              <div className="input-group">:</div>
                            </div>
                            <div className="col-lg-8 col-md-4 col-sm-6">
                              <div className="input-group">
                                <input
                                  value={this.state.input.file_name}
                                  placeholder="File Name"
                                  onChange={(e) =>
                                    this.changeHandler(
                                      "file_name",
                                      e.target.value
                                    )
                                  }
                                  type="text"
                                  className="form-control"
                                  required
                                />
                                <div className="invalid-feedback">
                                  required file name field.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                              <div className="input-group">File Type</div>
                            </div>
                            <div className="col-lg-1 col-md-4 col-sm-6">
                              <div className="input-group">:</div>
                            </div>
                            <div className="col-lg-8 col-md-4 col-sm-6">
                              <div className="input-group">
                                <select
                                  value={this.state.input.file_type}
                                  onChange={(e) =>
                                    this.changeHandler(
                                      "file_type",
                                      e.target.value
                                    )
                                  }
                                  required
                                  className="form-control show-tick"
                                >
                                  <option value="">Select File Type</option>
                                  <option value="Doc">Doc</option>
                                  <option value="JPEG">JPEG</option>
                                  <option value="PNG">PNG</option>
                                  <option value="Video">Video</option>
                                  <option value="PPT">PPT</option>
                                </select>
                                <div className="invalid-feedback">
                                  required file field.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-4 col-sm-6 pt-3">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                              <div className="input-group">Upload Files</div>
                            </div>
                            <div className="col-lg-1 col-md-4 col-sm-6">
                              <div className="input-group">:</div>
                            </div>
                            <div className="col-lg-8 col-md-4 col-sm-6">
                              <div className="input-group">
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={this.handleFile}
                                  disabled={!this.state.input.file_type}
                                  accept={
                                    (this.state.input.file_type === "PNG" &&
                                      ".png") ||
                                    (this.state.input.file_type === "Doc" &&
                                      ".doc, .docx") ||
                                    (this.state.input.file_type === "JPEG" &&
                                      "image/gif, image/jpeg") ||
                                    (this.state.input.file_type === "PPT" &&
                                      ".ppt, .pptx,") ||
                                    (this.state.input.file_type === "Video" &&
                                      "video/mp4,video/x-m4v,video,")
                                  }
                                />
                                <div className="invalid-feedback">
                                  required file.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggle}>
                  Close
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  onClick={() => {
                    this.changeHandler("status", 1);
                  }}
                  className="btn"
                  type="submit"
                >
                  Go Live
                </MDBBtn>
                <MDBBtn
                  onClick={() => {
                    this.changeHandler("status", 0);
                  }}
                  color="secondary"
                  type="submit"
                >
                  Draft
                </MDBBtn>
              </MDBModalFooter>
            </form>
          </MDBModal>
        </MDBContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  studyMaterialupload: state.getStudyMaterialUploadReducer.file,
});

const mapDispatchToProps = (dispatch) => ({
  createStudyMaterialAction: (e) => dispatch(createStudyMaterialAction(e)),
  uploadStudyMaterialFileAction: (e) =>
    dispatch(uploadStudyMaterialFileAction(e)),
  getClassAction: (e) => dispatch(getClassAction(e)),
  getSectionAction: (e) => dispatch(getSectionAction(e)),
  getSubjectAction: (e) => dispatch(getSubjectAction(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadStudy);
