import { UPLOAD_STUDY_MATERIAL_FILE } from '../../../../actions/Constants/index'

const initialState = {}

export default (state = initialState, action) => {
	switch (action.type) {
		case UPLOAD_STUDY_MATERIAL_FILE:
			return action.payload;
        default:
            return state
    }
}

