import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBSpinner } from "mdbreact";
import "./style.scss";
import InsititesImage from '../../assets/images/insitutes.jpg';
import { superUserLoginAction } from '../../actions/Authentication/superUserLoginAction';
import { isLogined } from '../../utils/token'
import { getLogoAndImageAction} from '../../actions/Admin/ManageProfile/manageProfileAction'


class Login extends PureComponent {
	constructor() {
		super();
		this.state = {
			email: "",
			password: "",
			loginInfo: {
				isLogined : null,
				userInfo : null
			},
			loginStatus: null,
			logoAndImage : {
				logo: "",
				imageUrl: "",
				youtubeUrl: "",
				type: "",
				videoUrl:""
			},
		}
	}

	componentWillReceiveProps(nextProps) {
		if(this.state.loginInfo !== nextProps.loginInfo){
			this.setState({
				loginInfo : nextProps.loginInfo
			}, ()=> {
				if(this.state.loginInfo.isLogined){
					if(this.state.loginInfo.password_state === 0){
						this.props.history.push('/super-admin/manage-institutes')
					}else {
						this.props.history.push('/super-admin/changePassword')
					}
				}else if(this.state.loginInfo.isLogined === false){
					this.setState({
						loginStatus : "Invalid Login Credentials"
					})
				}	
			})
		}
		if(this.state.logoAndImage !== nextProps.logoAndImage.list){
			this.setState({
				logoAndImage: nextProps.logoAndImage.list
			})
		}
	}

	componentDidMount(){
		this.props.getLogoAndImageAction()
	}
	
	submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
		if(event.target.checkValidity()) {
			const obj = {
				email: this.state.email,
				password: this.state.password
			}
			this.props.superUserLoginAction(obj);
		}
	}

	changeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {
		if(isLogined()) this.props.history.push('/super-admin/manage-institutes')
		return (
			<div className="auth option2">
				<div className="auth_left authenticationContainer">
					<div className="card">
						<form  onSubmit={this.submitHandler} noValidate>
							<MDBContainer className="MDBContainer">
								<MDBRow>
									<MDBCol size="8">
										<div className={'loginImageContainer'}>
											{this.state.logoAndImage.type !== '' ? (
												<div>
													{this.state.logoAndImage.type === 'imageurl' ? (
													<img src={ this.state.logoAndImage.imageUrl} height={400} width={735}/> 
													): this.state.logoAndImage.type === "youtubeurl" ? (
														<iframe width={'600px'} height={'450px'} src={this.state.logoAndImage.youtubeUrl+'?autoplay=1&mute=1'} />

													) :(
														<video controls  width={'600px'} height={'450px'} autoPlay>
																	<source src={this.state.logoAndImage.youtubeUrl || this.state.logoAndImage.videoUrl}  />
														</video>

													)}

{/* </iframe> */}
												</div>

											):(
												<div className="spinner-border verticalCenter" role="status">
												<span className="sr-only">Loading...</span>
											  </div>
											)}
				
										</div>
									</MDBCol>
									<MDBCol size="4" style={{width:'1000px'}}>
										<div className="card-body">
											<div className="text-center">
												<Link className="header-brand" to="/">
													<i className="fa fa-graduation-cap brand-logo"></i>
												</Link>
												<div className="card-title mt-3">Super Admin Login</div>
												{/* <button type="button" className="mr-1 btn btn-facebook"><i className="fa fa-facebook mr-2"></i>Facebook</button>
												<button type="button" className="btn btn-google"><i className="fa fa-google mr-2"></i>Google</button>
												<h6 className="mt-3 mb-3">Or</h6> */}
											</div>
											<div className="form-group inputContainer">
												<input 
													value={this.state.email}
													name="email"
													onChange={this.changeHandler}
													type="email" 
													className="form-control" 
													id="inputEmail" 
													aria-describedby="emailHelp" 
													placeholder="Enter email" 
													required 
												/>
												<div className="invalid-feedback">
													Please provide a valid mail.
												</div>
											</div>
											<div className="form-group inputContainer">
												<input 
													value={this.state.password}
													name="password"
													onChange={this.changeHandler}
													type="password" 
													className="form-control" 
													id="inputPassword" 
													placeholder="Password" 
													required 
												/>
												<div className="invalid-feedback">
													Please provide a valid password.
												</div>
												<label className="form-label forgotLink "><Link to="/super-admin/forgotpassword" className="float-right small">Forgot Password ?</Link></label>
											</div>
											{this.state.loginStatus &&(
												<div className='alertFlag'>
													Invalid Login Credentials
												</div>
											)}
											<MDBBtn color="primary" type="submit" className="MDBBtn" >
												Sign In 
											</MDBBtn>
										</div>
									</MDBCol>
								</MDBRow>
							</MDBContainer>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	loginInfo: state.superUserLoginReducer,
	logoAndImage: state.getLogoAndImageReducer,
})

const mapDispatchToProps = dispatch => ({
	superUserLoginAction: (e) => dispatch(superUserLoginAction(e)),
	getLogoAndImageAction: (e) => dispatch(getLogoAndImageAction(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Login)
