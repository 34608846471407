import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import Loader from "../../../common/Loader";
import { MDBDataTable, MDBBtn, MDBIcon,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
// import './style.scss';
import moment from 'moment'
import DatePicker from "react-datepicker";
import {  getClassAction,getSectionAction, getSubjectAction } from '../../../../actions/institute/Utils/utilsAction';
import { clearGetCertificateAction, getCertificateAction } from '../../../../actions/institute/Teacherdashboard/ManageCertificate/manageCertificateAction';
import ViewCertificate from '../../StudentsDashboard/ManageCertificate/ViewCertificate';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

class ManageCertificate extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
			certificates : [],

			tableBody : { columns :[], rows: []},
			modal: false,

			school_id:'',
			class_id:'',
			section_id:'',
			subject_id:'',
			year:'',

			role:this.props.role,

			classList:[],
            sectionList:[],
            subjectList:[],
			utills:{},
			selectedCertificate:null
		};
	}

	componentWillReceiveProps(nextProps){
	
		if(this.state.certificates !== nextProps.certificateState.list){
			this.setState({
				certificates: nextProps.certificateState.list
			},()=>{
				this.constructTableData()
			})
		}
		if(this.state.subjectList !== nextProps.utills.subjectList){
            this.setState({
                subjectList: nextProps.utills.subjectList
            })
        }
        if(this.state.sectionList !== nextProps.utills.sectionList){
            this.setState({
                sectionList: nextProps.utills.sectionList
            })
        }
		if(this.state.classList !== nextProps.utills.classList){
			this.setState({
				classList: nextProps.utills.classList
			})
		}
		
	}
	componentDidUpdate(prevProp,prevState){
		if (this.state.school_id !== prevState.school_id) {
            if(this.state.school_id){
				let search = {
					school_id: this.state.school_id
				}
				this.props.getClassAction(search)
			}
		}
		if(this.state.class_id !== prevState.class_id) {
            if(this.state.school_id && this.state.class_id){
				let search = {
					class_id: this.state.class_id,
				}
				this.props.getSectionAction(search)
			}
		}
		if(this.state.section_id !== prevState.section_id) {
            if(this.state.school_id && this.state.class_id){
				let search = {
					section_id: this.state.section_id,
				}
				this.props.getSubjectAction(search)
			}
		}
	}
	componentDidMount(){
		if(localStorage.getItem('school')){
            let school = JSON.parse(localStorage.getItem('school'))
            this.setState({ school_id:school.id });
        }
		this.props.getCertificateAction()
	}
	openModal=(item)=>{
		this.setState({
			selectedCertificate:item,
			modal:true
		})
	}
	exportPdf = () => {
		html2canvas(document.querySelector("#capture")).then((canvas) => {
		  const imgData = canvas.toDataURL("image/png");
		  const pdf = new jsPDF();
		  pdf.addImage(imgData, "PNG", 0, 0, 200, 0);
		  pdf.save("Certificate.pdf");
		}).catch((e)=>{
		});
	  };
	constructTableData =()=>{
		let col= [
			{
				label: '#',
				field: 'id',
			},
            {
				label: 'Student',
				field: 'student_name',
				width: 270,
			},
            {
				label: 'Class',
				field: 'class_name',
				width: 270,
			},
			{
				label: 'Section',
				field: 'section_name',
				width: 270,
			},
			{
				label: 'Subject',
				field: 'subject_name',
				width: 270,
			},
			{
				label: 'Certification Type',
				field: 'certificate_type',
				width: 270,
			},
            {
				label: 'Certification For',
				field: 'certification_for',
				width: 270,
			},
            {
				label: 'Certification Issue Date',
				field: 'created_at',
				width: 270,
			},
            {
				label: 'Year',
				field: 'year',
				width: 270,
			},
            {
				label: 'Action',
				field: 'action',
				width: 270,
			}
		]
		let tableData = {
			columns: col,
			rows: 
				this.state.certificates.map((item,i)=>{
					let obj = {
						id: i+1,
						student_name: item.student_name ? item.student_name : '-',
						class_name: item.class_name ? item.class_name : '-',
						section_name: item.section_name ? item.section_name : '-',
						subject_name: item.subject_name ? item.subject_name : '-',
						certificate_type: item.certificate_type ? item.certificate_type : '-',
						certification_for: item.certification_for ? item.certification_for : '-',
						year: item.year ? item.year : '-',
						created_at: moment(item.created_at).format("YYYY-MM-DD") ,
						action:
						<div>
                            <MDBBtn color="primary" className='btn' onClick={()=>{ this.openModal(item)}}>View Certificate</MDBBtn>
						</div>

					}
					return obj
				})
		}	
		this.setState({
			tableBody: tableData
		})	
	}
	handleChange=(e)=>{
		this.setState({ [e.target.name] : e.target.value})
	}

	componentWillUnmount(){
		this.props.clearGetCertificateAction()
	}

	toggleClose = ()=>{
		this.setState({
			modal: false
		})
	}
	modalContainer = () => {
		return (
		  <div>
			<MDBModal isOpen={this.state.modal} className="viewPDF-modal">
			  <MDBModalHeader toggle={this.toggleClose}>View Certificate</MDBModalHeader>
			  <MDBModalBody>
				<div id="capture">
					<ViewCertificate selectedCertificate={ this.state.selectedCertificate }/>
				</div>
			  </MDBModalBody>
			  <MDBModalFooter>
				<MDBBtn color="secondary" onClick={this.exportPdf}>
				  Download
				</MDBBtn>
				<MDBBtn color="secondary" onClick={this.toggleClose}>
				  Close
				</MDBBtn>
			  </MDBModalFooter>
			</MDBModal>
		  </div>
		);
	  };

	handleYear =(value)=>{
		this.setState({ year:value});
    }
	handleSearch=()=>{
		let search = {
			class_id:this.state.class_id,
			section_id:this.state.section_id,
			subject_id:this.state.subject_id,
			year: this.state.year ? moment(this.state.year).format('YYYY') : '',
		}
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		this.props.getCertificateAction(search)
	}

	clearSearch=()=>{
		this.setState({
            class_id:'',
            section_id:'',
            subject_id:'',
			year:''
		})
		this.props.getCertificateAction()
	}

	render() {
		const { activeTab } = this.state
		// if(!isLogined()) this.props.history.push('/')
		return (
			<>
			{this.state.modal && this.modalContainer()}

				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Students Certificates</h1>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-3 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.class_id} 
												onChange={ this.handleChange } name="class_id">
                                                    <option value="">Select Class</option>
													{this.state.classList && this.state.classList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
											<div className="col-lg-3 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.section_id} 
												onChange={ this.handleChange } name="section_id" disabled ={this.state.class_id ? false : true}>
                                                    <option value="">Select Section</option>
													{this.state.sectionList && this.state.sectionList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
											<div className="col-lg-3 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.subject_id} 
												onChange={ this.handleChange } name="subject_id" disabled ={this.state.section_id ? false : true}>
                                                    <option value="">Select Subject</option>
                                                    {this.state.subjectList && this.state.subjectList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col-lg-3 col-md-2 col-sm-12">
											<DatePicker
													name="year"
                                                    selected={this.state.year}
                                                    onChange={this.handleYear}
                                                    placeholderText="Select Year"
                                                    className="form-control"
                                                    showYearPicker
                                                    dateFormat="yyyy"
                                                />
                                            </div>
                                            
										</div>
										<div className="btn-container">
										<div>
                                                <MDBBtn color="primary" className='btn' onClick={this.handleSearch}>Search</MDBBtn> 
												<MDBBtn color="primary" className='btn' onClick={this.clearSearch}>Clear</MDBBtn>
                                            </div>
                                                    <div>
														<MDBBtn color="primary" className='btn' 
														onClick={()=>{this.props.history.push('/teacher/create-certificate')}}>
															Create New Certificate</MDBBtn> 
													</div>
												</div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
									<h1 className="page-title">Certificate List</h1>
									{this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={['title']}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching = {false}
                />
              </div>
            )}
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	role: state.settings.role,
	utills: state.utillReducer,
	certificateState: state.ManageCertificateReducer,
})

const mapDispatchToProps = dispatch => ({

	getClassAction: (e) => dispatch(getClassAction(e)),
	getSectionAction: (e) => dispatch(getSectionAction(e)),
	getSubjectAction: (e) => dispatch(getSubjectAction(e)),
	getCertificateAction: (e) => dispatch(getCertificateAction(e)),
	clearGetCertificateAction: (e) => dispatch(clearGetCertificateAction(e)),

})
export default connect(mapStateToProps, mapDispatchToProps)(ManageCertificate)
