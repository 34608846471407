import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { MDBDataTable, MDBBtn, MDBIcon,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import './style.scss';
import moment from 'moment'
import DatePicker from "react-datepicker";
import Loader from "../../common/Loader";
import { getAcedemicsAction, clearGetAcedemicsAction } from '../../../actions/institute/ManageAcedemics/manageAcedemicsAction';
import CreateAcedemics from './CreateAcedemics';
import { CSVLink } from "react-csv";
import UploadExcel from './UploadExcel';
import { getAllSchoolAction, getClassAction,getSectionAction, getSubjectAction, getTeacherAction } from '../../../actions/institute/Utils/utilsAction';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

class ManageAcedemics extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
			acedemics : [],
			tableBody : { columns :[], rows: []},
			modal: false,
			selectedAcedemic: null,
			deleteModal:false,
			deleteStatus:null,
			deleteId:'',
			openCreateModal:false,
			editId:'',
			searchAcedemics:[],
			
			createdAt:'',
			school_id: "",
			class_id: "",
			section_id: "",
			subject_id: "",
			teacher_id: "",
			type: "",

			classList:[],
            sectionList:[],
            subjectList:[],
            teacher:[],

			exportCsv:null,
            school:[],
			openExcelModal:false,
			sampleCsv:null,
			utills:{}


		};
	}
	exportCsv =()=> {
		const exportCsvHeaders = [
			{ label: "SCHOOL NAME", key: "school_name" },
			{ label: "CLASS NAME", key: "class_name" },
			{ label: "SECTTION NAME", key: "section_name" },	
			{ label: "SUBJECT NAME", key: "subject_name" },	
			{ label: "TEACHER NAME", key: "teacher_name" },	
			{ label: "TYPE", key: "type" },	
			{ label: "CUT OFF", key: "cutoff" },	
			{ label: "CREATED DATE", key: "createdDate" }	
		];
		const exportCSVData = this.state.acedemics.map((item)=>{
			let obj = {
				class_name: item.class_name ? item.class_name : '-' ,
				school_name: item.school_name ? item.school_name: '-',
				section_name: item.section_name ? item.section_name: '-',
				subject_name: item.subject_name ? item.subject_name: '-',
				teacher_name: item.teacher_name ? item.teacher_name: '-',
				type: item.type ? item.type: '-',
				cutoff: item.cutoff ? item.cutoff: '-',
				createdDate: item.created_at,
			}
			return obj
		})
		let csv = {
			data: exportCSVData,
			headers: exportCsvHeaders,
			filename: 'acedemics.csv',
			style: {color:'white'}
		};
		this.setState({
			exportCsv: csv
		})
	}
	exportSampleCsv =()=> {
		const exportCsvHeaders = [
			{ label: "school_name", key: "school_name" },
			{ label: "class_name", key: "class_name" },
			{ label: "section_name", key: "section_name" },	
			{ label: "subject_name", key: "subject_name" },	
			{ label: "teacher_name", key: "teacher_name" },	
			{ label: "type", key: "type" },	
			{ label: "cutoff", key: "cutoff" },	
		];
		const exportCSVData = [{
			class_name: 'class1',
			school_name: 'School1',
			section_name: 'section1',
			subject_name: 'Social',
			teacher_name: 'teacher1',
			type: 'Assignment',
			cutoff:'50'
		}]
		let csv = {
			data: exportCSVData,
			headers: exportCsvHeaders,
			filename: 'sampleFile.csv',
			style: {color:'white'}
		};
		this.setState({
			sampleCsv: csv
		})
	}
	componentWillReceiveProps(nextProps){
		if(this.state.acedemics !== nextProps.acedemics.list){
			this.setState({
				acedemics: nextProps.acedemics.list
			},()=>{
				this.constructTableData()
				this.exportCsv()
				this.exportSampleCsv()
			})
		}
		if(this.state.teacher !== nextProps.utills.teacherList){
			this.setState({
				teacher: nextProps.utills.teacherList
			})
		}
		
        if(this.state.subjectList !== nextProps.utills.subjectList){
            this.setState({
                subjectList: nextProps.utills.subjectList
            })
        }
        if(this.state.sectionList !== nextProps.utills.sectionList){
            this.setState({
                sectionList: nextProps.utills.sectionList
            })
        }
		if(this.state.school !== nextProps.utills.schoolAllList){
			this.setState({
				school: nextProps.utills.schoolAllList
			})
		}
		if(this.state.classList !== nextProps.utills.classList){
			this.setState({
				classList: nextProps.utills.classList
			})
		}
		
	}
	componentDidUpdate(prevProp,prevState){
		if (this.state.school_id !== prevState.school_id) {
            if(this.state.school_id){
				let search = {
					school_id: this.state.school_id
				}
				this.props.getClassAction(search)
				this.props.getTeacherAction(search)
			}
		}
		if(this.state.class_id !== prevState.class_id) {
            if(this.state.school_id && this.state.class_id){
				let search = {
					school_id: this.state.school_id,
					class_id: this.state.class_id,
				}
				this.props.getSectionAction(search)
			}
		}
		if(this.state.section_id !== prevState.section_id) {
            if(this.state.school_id && this.state.class_id){
				let search = {
					school_id: this.state.school_id,
					class_id: this.state.class_id,
					section_id: this.state.section_id,
				}
				this.props.getSubjectAction(search)
			}
		}
	}
	componentDidMount(){
		this.props.getAcedemicsAction()
		this.props.getAllSchoolAction()
	}

	toggle = (value) =>{
		this.setState({
		  modal: true,
		  selectedAcedemic : value
		});
	}

	getApi=()=>{
		this.props.getAcedemicsAction()
	}

	exportPdf = () => {
		html2canvas(document.querySelector("#capture")).then(canvas => {
		   const imgData = canvas.toDataURL('image/png');
		   const pdf = new jsPDF();
		   pdf.addImage(imgData, 'PNG', 0, 0,200,0);
		   pdf.save("AcedamicData.pdf"); 
	   }); 
	}
	
	toggleClose = ()=>{
		this.setState({
			modal: false,
			selectedAcedemic: null,
			deleteModal: false,
			openCreateModal: false,
			openExcelModal:false
		})
	}
	openEditModal=(value)=>{
		this.setState({
			editId : value.id,
			openCreateModal:true
		})
	}
	openDeleteModal=(value)=>{
		this.setState({
			deleteModal: true,
			deleteId:value.id
		})
	}
	deleteKnowledgeBase=()=>{
		this.props.deleteCircularAction(this.state.deleteId)
		
	}
	constructTableData =()=>{
		let tableData = {
			columns: [
				{
					label: '#',
					field: 'id',
				},
				{
					label: 'School Name',
					field: 'school_name',
					width: 150,
					attributes: {
						'aria-controls': 'DataTable',
						'aria-label': 'Name',
					},
				},
				{
					label: 'Class Name',
					field: 'class_name',
					width: 270,
				},
				{
					label: 'Secttion Name',
					field: 'section_name',
					width: 270,
				},
                {
					label: 'Subject Name',
					field: 'subject_name',
					width: 270,
				},
                {
					label: 'Teacher Name',
					field: 'teacher_name',
					width: 270,
				},
                {
					label: 'Type',
					field: 'type',
					width: 270,
				},
				{
					label: 'Cut Off',
					field: 'cutoff',
					width: 270,
				},
				{
					label: 'created Date',
					field: 'createdDate',
					width: 270,
				},
				{
					label: 'Action',
					field: 'action',
					width: 300,
				},
			],
			rows: 
				this.state.acedemics.map((item,i)=>{
					let obj = {
						id: i+1,
						class_name: item.class_name ? item.class_name : '-' ,
						school_name: item.school_name ? item.school_name: '-',
						section_name: item.section_name ? item.section_name: '-',
						subject_name: item.subject_name ? item.subject_name: '-',
						teacher_name: item.teacher_name ? item.teacher_name: '-',
						type: item.type ? item.type: '-',
						cutoff: item.cutoff ? item.cutoff: '-',
						createdDate:item.created_at ,
						action: (<div>
							<MDBIcon  icon="edit" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{
								this.openEditModal(item)
							}} />
							<MDBIcon  icon="eye" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{
								this.toggle(item)
							}} />
						</div>)

					}
					return obj
				})
		}	
		this.setState({
			tableBody: tableData
		})	
	}
	getAcedemics=()=>{
		this.props.getAcedemicsAction()
	}
	handleChange=(e)=>{
		this.setState({ [e.target.name] : e.target.value})
	}
	getAcedemicsDetail = ()=>{
		return(
			<div className="card-body">
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							School Name
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							: 
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							{this.state.selectedAcedemic && this.state.selectedAcedemic.school_name ? this.state.selectedAcedemic.school_name : '-'}                                  
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Class Name
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							{this.state.selectedAcedemic && this.state.selectedAcedemic.class_name ? this.state.selectedAcedemic.class_name : '-'}                                  
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Section Name
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							{this.state.selectedAcedemic && this.state.selectedAcedemic.section_name ? this.state.selectedAcedemic.section_name : '-'}                                  
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Subject Name
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group" >
							{this.state.selectedAcedemic && this.state.selectedAcedemic.subject_name ? 
							 this.state.selectedAcedemic.subject_name 
							: '-'}                                  
						</div>
					</div>
				</div>
                <div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Teacher Name
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group" >
							{this.state.selectedAcedemic && this.state.selectedAcedemic.teacher_name ? 
							 this.state.selectedAcedemic.teacher_name 
							: '-'}                                  
						</div>
					</div>
				</div>
                <div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Type
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group" >
							{this.state.selectedAcedemic && this.state.selectedAcedemic.type ? 
							 this.state.selectedAcedemic.type 
							: '-'}                                  
						</div>
					</div>
				</div>
                <div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 cutoff">
						<div className="input-group">
							Cut Off Percentage
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group" >
							{this.state.selectedAcedemic && this.state.selectedAcedemic.cutoff ? 
							 this.state.selectedAcedemic.cutoff 
							: '-'}                                  
						</div>
					</div>
				</div>
			</div>		
		)
	}
	handleDate =(value)=>{
		this.setState({ createdAt:value});
    }
	handleSearch=()=>{
		let search = {
			school_id: this.state.school_id,
			class_id: this.state.class_id,
			section_id:	this.state.section_id,
			subject_id:	this.state.subject_id,
			teacher_id:	this.state.teacher_id,
			type:	this.state.type,
			created_at: this.state.createdAt ? moment(this.state.createdAt).format('YYYY-MM-DD') : '',
		}
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		this.props.getAcedemicsAction(search)
	}
	clearSearch=()=>{
		this.setState({
			school_id:'',
			class_id:'',
			section_id:'',
			subject_id:'',
			teacher_id:'',
			type:'',
			createdAt:''
		})
		this.props.getAcedemicsAction()
	}
	render() {
		const { activeTab } = this.state
		// if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage Academics</h1>
								<ol className="breadcrumb page-breadcrumb">
									<li className="breadcrumb-item">Search</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											
                                            <div className="col-lg-3 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.school_id} onChange={ this.handleChange } name="school_id">
                                                    <option value="">Select School</option>
													{this.state.school.map((item)=>{
                                                            return <option value={item.id}>{item.name}</option>
                                                        })}
                                                </select>
                                            </div>
											<div className="col-lg-2 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.class_id} 
												onChange={ this.handleChange } name="class_id" disabled ={this.state.school_id ? false : true}>
                                                    <option value="">Select Class</option>
													{this.state.classList && this.state.classList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
											<div className="col-lg-2 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.section_id} 
												onChange={ this.handleChange } name="section_id" disabled ={this.state.class_id && this.state.school_id ? false : true}>
                                                    <option value="">Select Section</option>
													{this.state.sectionList && this.state.sectionList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
											<div className="col-lg-2 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.subject_id} 
												onChange={ this.handleChange } name="subject_id" disabled ={this.state.section_id && this.state.class_id && this.state.school_id ? false : true}>
                                                    <option value="">Select Subject</option>
                                                    {this.state.subjectList && this.state.subjectList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
											<div className="col-lg-3 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.type} onChange={ this.handleChange } name="type">
                                                    <option value="">Select Type</option>
                                                    <option value="Assignment">Assignment</option>
                                                    <option value="Examination">Examination</option>
                                                </select>
                                            </div>
											<div className="col-lg-3 col-md-2 col-sm-12 mt-10">
                                                <select className="form-control show-tick" value={ this.state.teacher_id} 
												onChange={ this.handleChange } name="teacher_id" disabled ={this.state.school_id ? false : true}>
                                                    <option value="">Select Teacher</option>
													{this.state.teacher.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
											<div className="col-lg-3 col-md-2 col-sm-12 mt-10">
											<DatePicker
																selected={this.state.createdAt}
																onChange={this.handleDate}
																placeholderText="Select date"
																className="form-control"
															/>
                                            </div>
										</div>
										<div className="btn-container">
												<div>
														<MDBBtn color="primary" className='btn' onClick={this.handleSearch}>Search</MDBBtn> 
														<MDBBtn color="primary" className='btn' onClick={this.clearSearch}>Clear</MDBBtn>
														</div>
														<div>
														<MDBBtn color="primary" className='btn' onClick={()=>{
															this.setState({openCreateModal : true,editId:''})
														}}>Create</MDBBtn>
														<MDBBtn color="primary" className='btn' onClick={()=>{
															this.setState({openExcelModal : true})
														}}>Bulk Upload</MDBBtn>
														<MDBBtn color="primary" className='btn' onClick={this.exportPdf}>PDF 
															<MDBIcon  icon="download" size="lg" className='icon' /></MDBBtn>

														<MDBBtn color="primary" className='btn'>
															{this.state.exportCsv &&(
																<CSVLink {...this.state.exportCsv}>CSV<MDBIcon  icon="download" size="lg" className='icon' /></CSVLink>
															)}
														</MDBBtn>
												</div>
                                        </div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body" id="capture">
									<h1 className="page-title">Academics List</h1>
									{this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={['title']}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching = {false}
                />
              </div>
            )}
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>

					{this.state.openCreateModal ?  <CreateAcedemics editId={ this.state.editId } toggleClose ={ this.toggleClose } getAcedemics={ this.getAcedemics }/> : ''}
					{this.state.openExcelModal ?  <UploadExcel toggleClose ={ this.toggleClose } sampleCsv={this.state.sampleCsv} getApi={ this.getApi }/> : ''}
					
					{this.state.modal &&<MDBContainer>
						<MDBModal isOpen={this.state.modal} toggle={this.toggle}>
							<MDBModalHeader toggle={this.toggleClose}>View Academics</MDBModalHeader>
								<MDBModalBody>
									<div className="card">
										{this.getAcedemicsDetail()}
									</div>
								</MDBModalBody>
								<MDBModalFooter>
									<MDBBtn color="secondary" onClick={this.toggleClose}>Close</MDBBtn>
								</MDBModalFooter>
						</MDBModal>
					</MDBContainer>}
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	acedemics: state.manageAcedemics,
	utills: state.utillReducer
})

const mapDispatchToProps = dispatch => ({
	getAcedemicsAction: (e) => dispatch(getAcedemicsAction(e)),
	clearGetAcedemicsAction: (e) => dispatch(clearGetAcedemicsAction(e)),
	
	getAllSchoolAction: (e) => dispatch(getAllSchoolAction(e)),
	getClassAction: (e) => dispatch(getClassAction(e)),
	getSectionAction: (e) => dispatch(getSectionAction(e)),
	getSubjectAction: (e) => dispatch(getSubjectAction(e)),
	getTeacherAction: (e) => dispatch(getTeacherAction(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ManageAcedemics)
