// Import all components here
import Dashboard from "./university/Dashboard/Dashboard";
import ManageClass from "./Institute/ManageClass/ManageClass";
import CreateClass from "./Institute/ManageClass/CreateClass";
import ManageSection from "./Institute/ManageSection/ManageSection";
import CreateSection from "./Institute/ManageSection/CreateSection";
import InstituteLogin from "./Authentication/Institute/InstituteLogin";
import ManageSubject from "./Institute/ManageSubject/ManageSubject";
import CreateSubject from "./Institute/ManageSubject/CreateSubject";
import ManageNewsLetter from "./Institute/ManageNewsLetter/ManageNewsLetter";
import CreateNewsletter from "./Institute/ManageNewsLetter/CreateNewsletter";
import ManageAnnouncement from "./Institute/ManageAnnouncement/ManageAnnouncement";
import InstituteChangePassword from "./Authentication/Institute/InstituteChangePassword";
import ManageUser from "./Institute/ManageUser/ManageUser";
import CreateUser from "./Institute/ManageUser/CreateUser";
import ManageReward from "./Institute/ManageReward/ManageReward";
import CreateReward from "./Institute/ManageReward/CreateReward";
import ManageCustomerSupport from "./Institute/ManageCustomerSupport/ManageCustomerSupport";
import RaiseCustomerSupportQuery from "./Institute/ManageCustomerSupport/RaiseCustomerSupportQuery";
import ManagePrincipal from "./Institute/ManagePrincipal/ManagePrincipal";
import CreatePrincipal from "./Institute/ManagePrincipal/CreatePrincipal";
import ViewPrincipal from "./Institute/ManagePrincipal/ViewPrincipal";
import SchoolInformation from "./Institute/SchoolInformation/SchoolInformation";
import StudentsAchievements from "./Institute/StudentsAchievements/StudentsAchievements";
import ManageSchool from './Institute/ManageSchool/ManageSchool';
import CreateSchool from './Institute/ManageSchool/CreateSchool';
import ManageCircular from './Institute/ManageCircular/ManageCircular';
import ManageEvents from './Institute/ManageEvents/ManageEvents';
import InstituteInformation from "./Institute/InstituteInformation/InstituteInformation";
import ViewFAQ from './Institute/ViewFAQ';
import ManageGallery from './Institute/ManageGallery/ManageGallery';
import ManageFeedback from './Institute/ManageFeedback/ManageFeedback';
import MyProfile from './Institute/MyProfile';
import ManageAcedemics from "./Institute/MnageAcedemics/ManageAcedemics";
import CategoryList from "./Institute/KnowledgeBase/CategoryList";
import KnowledgeBase from "./Institute/KnowledgeBase/KnowledgeBase";
import ViewKnowledgebase from "./Institute/KnowledgeBase/ViewKnowledgebase";
import ManageStudentUsers from "./Institute/TeacherDashboard/ManageStudentUsers/ManageStudentUsers";
import ManageAnnouncementTeacher from "./Institute/TeacherDashboard/ManageAnnouncementTeacher/manageAnnouncementTeacher"
import CreateAnnouncement from "./Institute/TeacherDashboard/ManageAnnouncementTeacher/CreateAnnouncement";
import ClassInformation from "./Institute/TeacherDashboard/ClassInformation/ClassInformation";
import StudyMaterial from "./Institute/TeacherDashboard/StudyMaterial/StudyMaterial";
import ManageGalleryTeacher from "./Institute/TeacherDashboard/ManageGallery/ManageGalleryTeacher";
import StudentsAchievementsTeacher from "./Institute/TeacherDashboard/StudentsAchievements/studentsAchievementsTeacher";
import CircularScreenStudent from "./Institute/StudentsDashboard/CircularScreenStudent/CircularScreenStudent";
import ManageAssignment from "./Institute/TeacherDashboard/Assignment/ManageAssignment";
import ManageCertificate from "./Institute/TeacherDashboard/ManageCertificate/ManageCertificate";
import ManageFeedbackTeacherDashboard from "./Institute/TeacherDashboard/ManageFeedback/ManageFeedback";
import ManageSkill from "./Institute/TeacherDashboard/ManageSkill/ManageSkill"
import NewAssignment from "./Institute/TeacherDashboard/Assignment/NewAssignment";
import AddQuestion from "./Institute/TeacherDashboard/Assignment/AddQuestion";
import ViewAssignment from "./Institute/TeacherDashboard/Assignment/ViewAssignment";
import ViewSubmittedAnswer from "./Institute/TeacherDashboard/Assignment/ViewSubmittedAnswer";
import CreateCertificate from "./Institute/TeacherDashboard/ManageCertificate/CreateCertificate";
import CreateSkill from "./Institute/TeacherDashboard/ManageSkill/CreateSkill";
import ManageSkillRule from "./Institute/TeacherDashboard/ManageSkill/ManageSkillRule";
import AddNewSkillRule from "./Institute/TeacherDashboard/ManageSkill/AddNewSkillRule";
import ViewStudentSkill from "./Institute/TeacherDashboard/ManageSkill/ViewStudentSkill";
import ManageGoal from "./Institute/TeacherDashboard/ManageGoal/ManageGoal";
import AddGoal from "./Institute/TeacherDashboard/ManageGoal/AddGoal";
import ManageAchievementRule from "./Institute/TeacherDashboard/ManageAchievementRules/ManageAchievementRule";
import AddRule from "./Institute/TeacherDashboard/ManageAchievementRules/AddRule";
import ManageExamination from "./Institute/TeacherDashboard/Examination/ManageExamination";
import NewExamination from "./Institute/TeacherDashboard/Examination/NewExamination";
import ViewAnnouncement from "./Institute/StudentsDashboard/AnnouncementList/AnnouncementList";
import NewsletterScreen from "./Institute/StudentsDashboard/ManageNewsletterStudent/NewsletterScreenStudent"
import ManageOnlineMeeting from "./Institute/TeacherDashboard/ManageOnlineMeeting/ManageOnlineMeeting";
import CreateOnlineMeeting from "./Institute/TeacherDashboard/ManageOnlineMeeting/CreateOnlineMeeting";
import ManageChatScreen from "./Institute/TeacherDashboard/ManageChatScreen/ManageChatScreen";
import OnlineMeetingStudent from "./Institute/StudentsDashboard/OnlineMeeting/OnlineMeetingStudent";
import ManageGalleryStudent from "./Institute/StudentsDashboard/GalleryScreen/GalleryScreenStudent";
import ViewPendingAnswer from "./Institute/TeacherDashboard/Assignment/ViewPendingAnswer";
import ViewPendingExaminationAnswer from "./Institute/TeacherDashboard/Examination/ViewPendingAnswer";
import ViewSubmittedExaminationAnswer from "./Institute/TeacherDashboard/Examination/ViewSubmittedAnswer";
import ViewExamination from "./Institute/TeacherDashboard/Examination/ViewExamination";
import ManageTask from "./Institute/StudentsDashboard/ManageTask/ManageTask";
import CreateTask from "./Institute/StudentsDashboard/ManageTask/CreateTask";
import StudentClassInformation from "./Institute/StudentsDashboard/StudentClassInformation/StudentClassInformation";
import List from "./Institute/StudentsDashboard/ManageExamination/List";
import AssignmentList from "./Institute/StudentsDashboard/ManageAssignment/AssignmentList";
import studyMaterialStudent from "./Institute/StudentsDashboard/studyMaterialStudent/studyMaterialStudent";
import ExamResult from "./Institute/StudentsDashboard/ManageExamination/ExamResult";
import AssignmentResult from "./Institute/StudentsDashboard/ManageAssignment/AssignmentResult";
import TakeExam from "./Institute/StudentsDashboard/ManageExamination/TakeExam";
import SubmitAssignment from "./Institute/StudentsDashboard/ManageAssignment/SubmitAssignment";
import ViewEvent from "./Institute/StudentsDashboard/ViewEvent.js/ViewEvent";
import FeedbackList from "./Institute/StudentsDashboard/ManageFeedback/FeedbackList";
import CertificateList from "./Institute/StudentsDashboard/ManageCertificate/CertificateList";
import GoalList from "./Institute/StudentsDashboard/ManageGoal/GoalList";
import ViewSkill from "./Institute/StudentsDashboard/ManageSkill/ViewSkill";
import ViewAchievements from "./Institute/StudentsDashboard/ViewAchievements/ViewAchievements";
import ViewCertificate from "./Institute/StudentsDashboard/ManageCertificate/ViewCertificate";
import CalendarView from "./Institute/StudentsDashboard/ManageTask/CalendarView";
import ManageCurriculumPDFteacher from "./Institute/TeacherDashboard/ManageCurriculumPDF/ManageCurriculumPDFteacher";
import currPdfViewer from "./Institute/TeacherDashboard/ManageCurriculumPDF/currPdfViewer";

const InstituteRoutes = [
  {
    path: "/institute/login",
    name: "institute-login",
    exact: true,
    pageTitle: "manage-class",
    component: InstituteLogin,
    roles: ["institute admin", "principal"],
  },
  {
    path: "/institute/changePassword",
    name: "institute-change-password",
    exact: true,
    pageTitle: "change-password",
    component: InstituteChangePassword,
    roles: ["institute admin", "principal"],
  },
  {
    path: "/teacher/changePassword",
    name: "institute-change-password",
    exact: true,
    pageTitle: "change-password",
    component: InstituteChangePassword,
    roles: ["teacher"],
  },
  {
    path: "/student/changePassword",
    name: "institute-change-password",
    exact: true,
    pageTitle: "change-password",
    component: InstituteChangePassword,
    roles: ["student"],
  },
  {
    path: "/parent/changePassword",
    name: "institute-change-password",
    exact: true,
    pageTitle: "change-password",
    component: InstituteChangePassword,
    roles: ["parent"],
  },
  {
    path: "/institute/schoolinformation",
    name: "schoolinformation",
    exact: true,
    pageTitle: "schoolinformation",
    component: SchoolInformation,
    roles: ["institiutePrincipal", "principal"],
  },
  {
    path: "/institute/manage-class",
    name: "manage-class",
    exact: true,
    pageTitle: "manage-class",
    component: ManageClass,
    roles: ["institute admin", "institiutePrincipal", "principal"],
  },
  {
    path: "/institute/create-class",
    name: "create-class",
    exact: true,
    pageTitle: "create-class",
    component: CreateClass,
    roles: ["institute admin", "institiutePrincipal", "principal"],
  },
  {
    path: "/institute/edit-class/:id",
    name: "edit-class",
    exact: true,
    pageTitle: "edit-class",
    component: CreateClass,
    roles: ["institute admin", "institiutePrincipal", "principal"],
  },
  {
    path: "/institute/manage-section",
    name: "manage-section",
    exact: true,
    pageTitle: "manage-section",
    component: ManageSection,
    roles: ["institute admin", "institiutePrincipal", "principal"],
  },
  {
    path: "/institute/create-section",
    name: "create-section",
    exact: true,
    pageTitle: "create-section",
    component: CreateSection,
    roles: ["institute admin", "institiutePrincipal", "principal"],
  },
  {
    path: "/institute/edit-section/:id",
    name: "edit-section",
    exact: true,
    pageTitle: "edit-section",
    component: CreateSection,
    roles: ["institute admin", "institiutePrincipal", "principal"],
  },
  {
    path: "/institute/manage-subject",
    name: "manage-subject",
    exact: true,
    pageTitle: "manage-subject",
    component: ManageSubject,
    roles: ["institute admin", "institiutePrincipal", "principal"],
  },
  {
    path: "/institute/create-subject",
    name: "create-subject",
    exact: true,
    pageTitle: "create-subject",
    component: CreateSubject,
    roles: ["institute admin", "institiutePrincipal", "principal"],
  },
  {
    path: "/institute/edit-subject/:id",
    name: "edit-subject",
    exact: true,
    pageTitle: "edit-subject",
    component: CreateSubject,
    roles: ["institute admin", "institiutePrincipal", "principal"],
  },
  {
    path: "/institute/manage-newsletter",
    name: "manage-newsletter",
    exact: true,
    pageTitle: "manage-newsletter",
    component: ManageNewsLetter,
    roles: ["institute admin", "institiutePrincipal", "principal"],
  },
  {
    path: "/institute/create-newsletter",
    name: "create-newsletter",
    exact: true,
    pageTitle: "create-newsletter",
    component: CreateNewsletter,
    roles: ["institute admin", "institiutePrincipal", "principal"],
  },
  {
    path: "/institute/edit-newsletter/:id",
    name: "edit-newsletter",
    exact: true,
    pageTitle: "edit-newsletter",
    component: CreateNewsletter,
    roles: ["institute admin", "institiutePrincipal", "principal"],
  },
  {
    path: "/institute/manage-announcement",
    name: "manage-announcement",
    exact: true,
    pageTitle: "manage-announcement",
    component: ManageAnnouncement,
    roles: ["institute admin", "institiutePrincipal", "principal"],
  },
  {
    path: "/institute/students-achievements",
    name: "students-achievements",
    exact: true,
    pageTitle: "students-achievements",
    component: StudentsAchievements,
    roles: ["institute admin", "institiutePrincipal", "principal"],
  },
  {
    path: "/student/view-achievements",
    name: "students-achievements",
    exact: true,
    pageTitle: "students-achievements",
    component: ViewAchievements,
    roles: ["student"],
  },
  {
    path: "/parent/view-achievements",
    name: "students-achievements",
    exact: true,
    pageTitle: "students-achievements",
    component: ViewAchievements,
    roles: ["parent"],
  },
  {
    path: "/institute/manage-user",
    name: "manage-user",
    exact: true,
    pageTitle: "manage-user",
    component: ManageUser,
    roles: ["institute admin", "institiutePrincipal", "principal"],
  },
  {
    path: "/institute/create-user/:type",
    name: "create-user",
    exact: true,
    pageTitle: "create-user",
    component: CreateUser,
    roles: ["institute admin", "institiutePrincipal", "principal"],
  },
  {
    path: "/institute/edit-user/:id/:type",
    name: "edit-user",
    exact: true,
    pageTitle: "edit-user",
    component: CreateUser,
    roles: ["institute admin", "institiutePrincipal", "principal"],
  },
  {
    path: "/institute/manage-principal",
    name: "manage-principal",
    exact: true,
    pageTitle: "manage-principal",
    component: ManagePrincipal,
    roles : ['institute admin']
},
{
  path: "/institute/manage-reward",
  name: "manage-reward",
  exact: true,
  pageTitle: "manage-reward",
  component: ManageReward,
  roles: ["institute admin", "institiutePrincipal"],
},
{
  path: "/institute/create-reward",
  name: "create-reward",
  exact: true,
  pageTitle: "create-reward",
  component: CreateReward,
  roles: ["institute admin", "institiutePrincipal"],
},
{
  path: "/institute/edit-reward/:id",
  name: "edit-reward",
  exact: true,
  pageTitle: "edit-reward",
  component: CreateReward,
  roles: ["institute admin", "institiutePrincipal"],
},
{
  path: "/institute/manage-customersupport",
  name: "manage-customersupport",
  exact: true,
  pageTitle: "manage-customersupport",
  component: ManageCustomerSupport,
  roles: ["institute admin", "institiutePrincipal"],
},
{
  path: "/institute/create-customersupport",
  name: "create-customersupport",
  exact: true,
  pageTitle: "create-customersupport",
  component: RaiseCustomerSupportQuery,
  roles: ["institute admin", "institiutePrincipal"],
},
//school route
{
  path: "/institute/manage-school",
  name: 'manage-school',
  exact: true,
  pageTitle: "manage-school",
  component: ManageSchool,
  roles : ['institute admin','institiutePrincipal']
},
{
  path: "/institute/create-school",
  name: 'create-school',
  exact: true,
  pageTitle: "create-school",
  component: CreateSchool,
  roles : ['institute admin','institiutePrincipal']
},
{
  path: "/institute/edit-school/:id",
  name: 'edit-school',
  exact: true,
  pageTitle: "edit-school",
  component: CreateSchool,
  roles : ['institute admin','institiutePrincipal']
},

//circular routes

{
  path: "/institute/manage-circular",
  name: 'manage-circular',
  exact: true,
  pageTitle: "manage-circular",
  component: ManageCircular,
  roles : ['institute admin','principal']
},
//events route
{
  path: "/institute/manage-events",
  name: 'manage-events',
  exact: true,
  pageTitle: "manage-events",
  component: ManageEvents,
  roles : ['institute admin','principal']
},
{
  path: "/institute/information",
  name: 'institute-information',
  exact: true,
  pageTitle: "institute-information",
  component: InstituteInformation,
  roles : ['institute admin']
},
{
  path: "/institute/view-faq",
  name: 'view-faq',
  exact: true,
  pageTitle: "view-faq",
  component: ViewFAQ,
  roles : ['institute admin','institiutePrincipal']
},
{
  path: "/institute/manage-gallery",
  name: 'manage-gallery',
  exact: true,
  pageTitle: "manage-gallery",
  component: ManageGallery,
  roles : ['institute admin','principal']
},
{
  path: "/institute/manage-feedback",
  name: 'manage-feedback',
  exact: true,
  pageTitle: "manage-feedback",
  component: ManageFeedback,
  roles : ['institute admin','principal']
},
{
  path: "/institute/my-profile",
  name: 'my-profile',
  exact: true,
  pageTitle: "my-profile",
  component: MyProfile,
  roles : ['institute admin','principal']
},
{
  path: "/institute/manage-knowledgebase",
  name: 'manage-knowledgebase',
  exact: true,
  pageTitle: "manage-knowledgebase",
  component: CategoryList,
  roles : ['institute admin']
},
{
  path: "/institute/manage-knowledgebase/:id",
  name: 'manage-knowledgebase',
  exact: true,
  pageTitle: "manage-knowledgebase",
  component: KnowledgeBase,
  roles : ['institute admin']
},
{
  path: "/institute/manage-knowledgebase/detail/:id/:viewId",
  name: 'view-knowledgebase',
  exact: true,
  pageTitle: "view-knowledgebase",
  component: ViewKnowledgebase,
  roles : ['institute admin']
},
{
  path: "/institute/manage-acedemics",
  name: 'manage-acedemics',
  exact: true,
  pageTitle: "manage-acedemics",
  component: ManageAcedemics,
  roles : ['institute admin','institiutePrincipal']
},
  {
    path: "/institute/create-principal",
    name: "create-principal",
    exact: true,
    pageTitle: "create-principal",
    component: CreatePrincipal,
    roles: ["institute admin"],
  },
  {
    path: "/institute/edit-principal/:id",
    name: "edit-principal",
    exact: true,
    pageTitle: "edit-principal",
    component: CreatePrincipal,
    roles: ["institute admin"],
  },
  {
    path: "/institute/view-principal/:id",
    name: "view-principal",
    exact: true,
    pageTitle: "view-principal",
    component: ViewPrincipal,
    roles : ['institute admin']
  },
//teacher
  {
    path: "/teacher/manage-assignment",
    name: 'manage-assignment',
    exact: true,
    pageTitle: "manage-assignment",
    component: ManageAssignment ,
    roles : ['teacher']
  },
  {
    path: "/teacher/new-assignment",
    name: 'new-assignment',
    exact: true,
    pageTitle: "new-assignment",
    component: NewAssignment ,
    roles : ['teacher']
  },
   {
    path: "/teacher/new-assignment",
    name: 'new-assignment',
    exact: true,
    pageTitle: "new-assignment",
    component: NewAssignment ,
    roles : ['teacher']
  },
  {
    path: "/teacher/edit-assignment/:id",
    name: 'edit-assignment',
    exact: true,
    pageTitle: "edit-assignment",
    component: NewAssignment ,
    roles : ['teacher']
  },
  {
    path: "/teacher/add-question",
    name: 'add-question',
    exact: true,
    pageTitle: "add-question",
    component: AddQuestion ,
    roles : ['teacher']
  },
  {
    path: "/teacher/view-submitted-assignment/:id",
    name: 'view-submitted-assignment',
    exact: true,
    pageTitle: "view-submitted-assignment",
    component: ViewAssignment ,
    roles : ['teacher']
  },
  //examination
  {
    path: "/teacher/view-evaluated-examination-answers/:id/:studentId",
    name: 'view-evaluated-answers',
    exact: true,
    pageTitle: "view-evaluated-answers",
    component: ViewSubmittedExaminationAnswer ,
    roles : ['teacher']
  },
  {
    path: "/teacher/view-submitted-examination-answers/:id/:studentId",
    name: 'view-submitted-answers',
    exact: true,
    pageTitle: "view-submitted-answers",
    component: ViewPendingExaminationAnswer ,
    roles : ['teacher']
  },
  //assignment
  {
    path: "/teacher/view-evaluated-answers/:id/:studentId",
    name: 'view-evaluated-answers',
    exact: true,
    pageTitle: "view-evaluated-answers",
    component: ViewSubmittedAnswer ,
    roles : ['teacher']
  },
  {
    path: "/teacher/view-submitted-answers/:id/:studentId",
    name: 'view-submitted-answers',
    exact: true,
    pageTitle: "view-submitted-answers",
    component: ViewPendingAnswer ,
    roles : ['teacher']
  },


  {
    path: "/teacher/class-information",
    name: 'manage-class-information',
    exact: true,
    pageTitle: "manage-class-information",
    component: ClassInformation,
    roles : ['teacher']
  },
  {
    path: "/teacher/manage-student-users",
    name: 'manage-student-users',
    exact: true,
    pageTitle: "manage-student-users",
    component: ManageStudentUsers,
    roles : ['teacher']
  },
  {
    path: "/teacher/manage-announcement",
    name: "manage-announcement",
    exact: true,
    pageTitle: "manage-announcement",
    component: ManageAnnouncementTeacher,
    roles: ['teacher'],
  },
  {
    path: "/teacher/create-announcement",
    name: "create-announcement",
    exact: true,
    pageTitle: "create-announcement",
    component: CreateAnnouncement,
    roles: ['teacher'],
  },
  {
    path: "/teacher/edit-announcement/:id",
    name: "edit-announcement",
    exact: true,
    pageTitle: "edit-announcement",
    component: CreateAnnouncement,
    roles: ['teacher'],
  },
  {
    path: "/teacher/study-material",
    name: 'study-material',
    exact: true,
    pageTitle: "study-material",
    component: StudyMaterial,
    roles : ['teacher']
  },
  {
    path: "/teacher/manage_gallery",
    name: 'manage_gallery',
    exact: true,
    pageTitle: "manage_gallery",
    component: ManageGalleryTeacher,
    roles : ['teacher']
  },
  {
    path: "/teacher/manage_currpdf",
    name: 'manage_currpdf',
    exact: true,
    pageTitle: "manage_curriculum_pdf",
    component: ManageCurriculumPDFteacher,
    roles : ['teacher']
  },
  {
    path: "/teacher/view_currpdf",
    name: 'currpdf_viewer',
    exact: true,
    pageTitle: "view_pdf",
    component: currPdfViewer,
    roles : ['teacher']
  },
  {
    path: "/teacher/students-achievements",
    name: 'students_achievements',
    exact: true,
    pageTitle: "students_achievements",
    component: StudentsAchievementsTeacher,
    roles : ['teacher']
  },
  {
    path: "/teacher/my-profile",
    name: 'my-profile',
    exact: true,
    pageTitle: "my-profile",
    component: MyProfile,
    roles : ['teacher']
  },
  {
    path: "/student/my-profile",
    name: 'my-profile',
    exact: true,
    pageTitle: "my-profile",
    component: MyProfile,
    roles : ['student']
  },
  {
    path: "/parent/my-profile",
    name: 'my-profile',
    exact: true,
    pageTitle: "my-profile",
    component: MyProfile,
    roles : ['parent']
  },
  {
    path: "/teacher/manage-feedback",
    name: 'manage-feedback',
    exact: true,
    pageTitle: "manage-feedback",
    component: ManageFeedbackTeacherDashboard ,
    roles : ['teacher']
  },
  {
    path: "/student/manage-feedback",
    name: 'manage-feedback',
    exact: true,
    pageTitle: "manage-feedback",
    component: FeedbackList ,
    roles : ['student']
  },
  {
    path: "/parent/manage-feedback",
    name: 'manage-feedback',
    exact: true,
    pageTitle: "manage-feedback",
    component: FeedbackList ,
    roles : ['parent']
  },
  {
    path: "/teacher/manage-certificate",
    name: 'manage-certificate',
    exact: true,
    pageTitle: "manage-certificate",
    component: ManageCertificate ,
    roles : ['teacher']
  },
  {
    path: "/teacher/create-certificate",
    name: 'create-certificate',
    exact: true,
    pageTitle: "create-certificate",
    component: CreateCertificate ,
    roles : ['teacher']
  },
  {
    path: "/teacher/manage-skill",
    name: 'manage-skill',
    exact: true,
    pageTitle: "manage-skill",
    component: ManageSkill ,
    roles : ['teacher']
  },
  {
    path: "/teacher/add-new-skill",
    name: 'add-new-skill',
    exact: true,
    pageTitle: "add-new-skill",
    component: CreateSkill ,
    roles : ['teacher']
  },
  {
    path: "/teacher/edit-new-skill/:id",
    name: 'edit-new-skill',
    exact: true,
    pageTitle: "edit-new-skill",
    component: CreateSkill ,
    roles : ['teacher']
  },
  {
    path: "/teacher/manage-skill-rule",
    name: 'manage-skill-rule',
    exact: true,
    pageTitle: "manage-skill-rule",
    component: ManageSkillRule ,
    roles : ['teacher']
  },
  {
    path: "/teacher/add-new-skill-rule",
    name: 'add-new-skill-rule',
    exact: true,
    pageTitle: "add-new-skill-rule",
    component: AddNewSkillRule ,
    roles : ['teacher']
  },
  {
    path: "/teacher/edit-new-skill-rule/:id",
    name: 'edit-new-skill-rule',
    exact: true,
    pageTitle: "edit-new-skill-rule",
    component: AddNewSkillRule ,
    roles : ['teacher']
  },
  {
    path: "/teacher/view-student-skill",
    name: 'view-student-skill',
    exact: true,
    pageTitle: "view-student-skill",
    component: ViewStudentSkill ,
    roles : ['teacher']
  },
  {
    path: "/teacher/manage-goal",
    name: 'manage-goal',
    exact: true,
    pageTitle: "manage-goal",
    component: ManageGoal ,
    roles : ['teacher']
  },
  {
    path: "/teacher/add-goal",
    name: 'add-goal',
    exact: true,
    pageTitle: "add-goal",
    component: AddGoal ,
    roles : ['teacher']
  },
  {
    path: "/teacher/edit-goal/:id",
    name: 'add-goal',
    exact: true,
    pageTitle: "add-goal",
    component: AddGoal ,
    roles : ['teacher']
  },
  {
    path: "/teacher/manage-achievement-rule",
    name: 'manage-achievement-rule',
    exact: true,
    pageTitle: "manage-achievement-rule",
    component: ManageAchievementRule ,
    roles : ['teacher']
  },
  {
    path: "/teacher/add-new-rule",
    name: 'add-new-rule',
    exact: true,
    pageTitle: "add-new-rule",
    component: AddRule ,
    roles : ['teacher']
  },
  {
    path: "/teacher/edit-new-rule/:id",
    name: 'edit-new-rule',
    exact: true,
    pageTitle: "edit-new-rule",
    component: AddRule ,
    roles : ['teacher']
  },
  {
    path: "/teacher/manage-examination",
    name: 'manage-examination',
    exact: true,
    pageTitle: "manage-examination",
    component: ManageExamination ,
    roles : ['teacher']
  },
  {
    path: "/teacher/new-examination",
    name: 'new-examination',
    exact: true,
    pageTitle: "new-examination",
    component: NewExamination ,
    roles : ['teacher']
  },
  {
    path: "/teacher/edit-examination/:id",
    name: 'edit-examination',
    exact: true,
    pageTitle: "edit-examination",
    component: NewExamination ,
    roles : ['teacher']
  },
  {
    path:  "/teacher/manage-online-meeting",
    name: 'manage-online-meeting',
    exact: true,
    pageTitle: "manage-online-meeting",
    component: ManageOnlineMeeting ,
    roles : ['teacher']
  },
  {
    path: "/teacher/create-online-meeting",
    name: "create-online-meeting",
    exact: true,
    pageTitle: "create-online-meeting",
    component: CreateOnlineMeeting,
    roles: ['teacher'],
  },
  {
    path: "/teacher/edit-online-meeting/:id",
    name: "edit-online-meeting",
    exact: true,
    pageTitle: "edit-online-meeting",
    component: CreateOnlineMeeting,
    roles: ['teacher'],
  },
  {
    path:  "/teacher/manage-chat-screen",
    name: 'manage-chat-screen',
    exact: true,
    pageTitle: "manage-chat-screen",
    component: ManageChatScreen,
    roles : ['teacher']
  },
  {
    path:  "/student/manage-chat-screen",
    name: 'manage-chat-screen',
    exact: true,
    pageTitle: "manage-chat-screen",
    component: ManageChatScreen,
    roles : ['student']
  },
   {
      path: "/teacher/view-submitted-examination/:id",
    name: 'view-submitted-examination',
    exact: true,
    pageTitle: "view-submitted-examination",
    component: ViewExamination ,
    roles : ['teacher']
  },
  


  //Student Role
  {
    path: "/student/view-circulars",
    name: 'view-circulars',
    exact: true,
    pageTitle: "view-circulars",
    component: CircularScreenStudent,
    roles : ['student']
  },
  {
    path: "/student/view-announcement",
    name: 'view-announcement',
    exact: true,
    pageTitle: "view-announcement",
    component: ViewAnnouncement,
    roles : ['student']
  },
  {
    path: "/student/newsletter",
    name: 'newsletter',
    exact: true,
    pageTitle: "newsletter",
    component: NewsletterScreen,
    roles : ['student',]
  },
  {
    path: "/student/online-meeting",
    name: 'online-meeting',
    exact: true,
    pageTitle: "online-meeting",
    component: OnlineMeetingStudent,
    roles : ['student']
  },
  {
    path: "/student/view-events",
    name: 'view-events',
    exact: true,
    pageTitle: "view-events",
    component: ViewEvent,
    roles : ['student']
  },
  {
    path: "/student/manage_gallery",
    name: 'manage_gallery',
    exact: true,
    pageTitle: "manage_gallery",
    component: ManageGalleryStudent,
    roles : ['student']
  },
  {
    path: "/student/manage_task",
    name: 'manage_task',
    exact: true,
    pageTitle: "manage_task",
    component: ManageTask,
    roles : ['student']
  },
  {
    path: "/student/create-task",
    name: "create-task",
    exact: true,
    pageTitle: "create-task",
    component: CreateTask,
    roles: ['student'],
  },
  {
    path: "/student/calendar-View",
    name: "calendar-View",
    exact: true,
    pageTitle: "calendar-View",
    component: CalendarView,
    roles: ['student'],
  },
  {
    path: "/student/edit-task/:id",
    name: "edit-task",
    exact: true,
    pageTitle: "edit-task",
    component: CreateTask,
    roles: ['student'],
  },
  {
   path: "/student/class-information",
    name: 'class-information',
    exact: true,
    pageTitle: "class-information",
    component: StudentClassInformation,
    roles : ['student']
  },
  {
    path: "/parent/class-information",
     name: 'class-information',
     exact: true,
     pageTitle: "class-information",
     component: StudentClassInformation,
     roles : ['parent']
   },
  {
    path: "/student/manage-examination",
    name: 'manage-examination',
    exact: true,
    pageTitle: "manage-examination",
    component: List,
    roles : ['student']
  },
  {
    path: "/student/manage-assignment",
    name: 'manage-assignment',
    exact: true,
    pageTitle: "manage-assignment",
    component: AssignmentList,
    roles : ['student']
  },
  {
    path: "/student/exam-result/:id/:studentId",
    name: 'exam-result',
    exact: true,
    pageTitle: "exam-result",
    component: ExamResult,
    roles : ['student',"parent"]
  },
  {
    path: "/student/take-exam/:id",
    name: 'take-exam',
    exact: true,
    pageTitle: "take-exam",
    component: TakeExam,
    roles : ['student']
  },
  {
    path: "/student/assignment-result/:id/:studentId",
    name: 'assignment-result',
    exact: true,
    pageTitle: "assignment-result",
    component: AssignmentResult,
    roles : ['student',"parent"]
  },
  {
    path: "/student/take-assignment/:id",
    name: 'take-assignment',
    exact: true,
    pageTitle: "take-assignment",
    component: SubmitAssignment,
    roles : ['student']
  },
  {
    path: "/student/study_material",
    name: 'study_material',
    exact: true,
    pageTitle: "study_material",
    component: studyMaterialStudent,
    roles : ['student']
  },
  {
    path: "/student/view-certificates",
    name: 'view-certificate',
    exact: true,
    pageTitle: "view-certificate",
    component: CertificateList ,
    roles : ['student']
  },
  {
    path: "/student/manage-skills",
    name: 'manage-skills',
    exact: true,
    pageTitle: "manage-skills",
    component: ViewSkill ,
    roles : ['student']
  },
  {
    path: "/student/manage-goal",
    name: 'manage-goal',
    exact: true,
    pageTitle: "manage-goal",
    component: GoalList ,
    roles : ['student']
  },

  //Parent Role
  {
    path: "/parent/view-circulars",
    name: 'view-circulars',
    exact: true,
    pageTitle: "view-circulars",
    component: CircularScreenStudent,
    roles : ['parent']
  },
  {
    path: "/parent/view-announcement",
    name: 'view-announcement',
    exact: true,
    pageTitle: "view-announcement",
    component: ViewAnnouncement,
    roles : ['parent']
  },
  {
    path: "/parent/newsletter",
    name: 'newsletter',
    exact: true,
    pageTitle: "newsletter",
    component: NewsletterScreen,
    roles : ['parent']
  },
  {
    path: "/parent/view-events",
    name: 'view-events',
    exact: true,
    pageTitle: "view-events",
    component: ViewEvent,
    roles : ['parent']
  },
  {
    path: "/parent/manage-examination",
    name: 'manage-examination',
    exact: true,
    pageTitle: "manage-examination",
    component: List,
    roles : ["parent"]
  },
  {
    path: "/parent/manage-assignment",
    name: 'manage-assignment',
    exact: true,
    pageTitle: "manage-assignment",
    component: AssignmentList,
    roles : ["parent"]
  },
  {
    path: "/parent/assignment-result/:id/:studentId",
    name: 'assignment-result',
    exact: true,
    pageTitle: "assignment-result",
    component: AssignmentResult,
    roles : ["parent"]
  },
  {
    path: "/parent/exam-result/:id/:studentId",
    name: 'exam-result',
    exact: true,
    pageTitle: "exam-result",
    component: ExamResult,
    roles : ["parent"]
  },
  {
    path: "/parent/manage-skills",
    name: 'manage-skills',
    exact: true,
    pageTitle: "manage-skills",
    component: ViewSkill ,
    roles : ['parent']
  },
  {
    path: "/parent/manage-goal",
    name: 'manage-goal',
    exact: true,
    pageTitle: "manage-goal",
    component: GoalList ,
    roles : ['parent']
  },
  {
    path: "/parent/view-certificates",
    name: 'view-certificate',
    exact: true,
    pageTitle: "view-certificate",
    component: CertificateList ,
    roles : ['parent']
  },
];

export default InstituteRoutes;
