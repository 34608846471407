import { GET_KNOWLEDGE_BASE, 
    CREATE_KNOWLEDGE_BASE, 
    EDIT_KNOWLEDGE_BASE, 
    GET_KNOWLEDGE_BASE_BY_ID,
    GET_CATEGORY,
    GET_CATEGORY_WITH_COUNT,
    CREATE_CATEGORY,
    SHOW_SNACKBAR,
    DELETE_KNOWLEDGE_BASE } from '../../Constants/index';
import axios from 'axios';
import { manageKnowledgeBase } from '../../../utils/Api'
import { getToken } from '../../../utils/token'

export const getKnowledgeBaseAction = (params) => {
	return dispatch => {
        const url = `${manageKnowledgeBase.getKnowledgeBase}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_KNOWLEDGE_BASE,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_KNOWLEDGE_BASE,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearGetKnowledgeBaseAction = () => {
	return dispatch => {
        dispatch({
            type: GET_KNOWLEDGE_BASE,
            payload: {
                list : []
            }
        })
    }
}

export const createKnowledgeBaseAction = (data,history) => {
	return dispatch => {
        const url = `${manageKnowledgeBase.createKnowledgeBase}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: CREATE_KNOWLEDGE_BASE,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                 history.push("/super-admin/knowledge-base");
            }else {
                dispatch({
                    type: CREATE_KNOWLEDGE_BASE,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const clearCreateKnowledgeBaseAction = () => {
	return dispatch => {
        dispatch({
            type: CREATE_KNOWLEDGE_BASE,
            payload: {
                list : null
            }
        })
    }
}

export const editKnowledgeBaseAction = ({id,data,history}) => {
	return dispatch => {
        const url = `${manageKnowledgeBase.editKnowledgeBase(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PATCH',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: EDIT_KNOWLEDGE_BASE,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                 history.push("/super-admin/knowledge-base");
            }else {
                dispatch({
                    type: EDIT_KNOWLEDGE_BASE,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const clearEditKnowledgeBaseAction = () => {
	return dispatch => {
        dispatch({
            type: EDIT_KNOWLEDGE_BASE,
            payload: {
                list : null
            }
        })
    }
}

export const getKnowledgeBaseByIdAction = (id) => {
	return dispatch => {
        const url = `${manageKnowledgeBase.getKnowledgeBaseById(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA.knowledgeBase
                dispatch({
                    type: GET_KNOWLEDGE_BASE_BY_ID,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_KNOWLEDGE_BASE_BY_ID,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearKnowledgeBaseByIdAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_KNOWLEDGE_BASE_BY_ID,
            payload: {
                list : {}
            }
        })
    }
}
export const deleteKnowledgeBaseAction = (id) => {
	return dispatch => {
        const url = `${manageKnowledgeBase.deleteKnowledgeBase(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'DELETE'
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: DELETE_KNOWLEDGE_BASE,
                    payload: {
                        list : flag.status
                    }
                })
            }else {
                dispatch({
                    type: DELETE_KNOWLEDGE_BASE,
                    payload: {
                        list : flag.status
                    }
                })
            }
        })
    }
}


export const getCategoryAction = () => {
	return dispatch => {
        const url = `${manageKnowledgeBase.getCategory}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_CATEGORY,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_CATEGORY,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}
export const createCategoryAction = (data) => {
	return dispatch => {
        const url = `${manageKnowledgeBase.createCategory}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: CREATE_CATEGORY,
                    payload: {
                        list : flag.status
                    }
                })
            }else {
                dispatch({
                    type: CREATE_CATEGORY,
                    payload: {
                        list : flag.status
                    }
                })
            }
        })
    }
}

export const getCategoryWithCountAction = () => {
	return dispatch => {
        const url = `${manageKnowledgeBase.getCategoryCount}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_CATEGORY_WITH_COUNT,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_CATEGORY_WITH_COUNT,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearCategoryWithCountAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_CATEGORY_WITH_COUNT,
            payload: {
                list : []
            }
        })
    }
}