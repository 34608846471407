import { SEARCH_CUSTOMER_SUPPORT } from '../../../actions/Constants/index'

const initialState = {
    list : null
}

export default (state = initialState, action) => {
	switch (action.type) {
		case SEARCH_CUSTOMER_SUPPORT:

			return {
				...state,
                list : action.payload.list,
			}
        default:
            return state
    }
}
