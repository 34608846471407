import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.scss";
import { getClassInfoAction } from "../../../../actions/institute/StudentDashboard/ClassInfo";

class StudentClassInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {},
    };
  }

  componentDidMount() {
    this.props.getClassInfoAction().then((res) => this.setState({ info: res }));
  }

  render() {
    return (
      <>
        <div class="container">
          <div className="box-student">
            <h1 className="mt-10">Class Information</h1>
            <hr />
            {localStorage.getItem("loginedRole") === "parent" && (
              <div className="row col-lg-12 col-md-4 col-sm-6 mt-4">
                <h6 className="col-lg-4 col-md-4 col-sm-6">Student Name</h6>
                <div className="col-lg-1 col-md-4 col-sm-6"> :</div>
                <h5 className="col-lg-7 col-md-4 col-sm-6">
                  {this.state.info.student_name}
                </h5>
              </div>
            )}
            <div className="row col-lg-12 col-md-4 col-sm-6 mt-4">
              <h6 className="col-lg-4 col-md-4 col-sm-6">School Name</h6>
              <div className="col-lg-1 col-md-4 col-sm-6"> :</div>
              <h5 className="col-lg-7 col-md-4 col-sm-6">
                {this.state.info.school_name}
              </h5>
            </div>
            <div className="row col-lg-12 col-md-4 col-sm-6 mt-4">
              <h6 className="col-lg-4 col-md-4 col-sm-6">Class Name</h6>
              <div className="col-lg-1 col-md-4 col-sm-6"> :</div>
              <h5 className="col-lg-7 col-md-4 col-sm-6">
                {this.state.info.class_name}
              </h5>
            </div>
            <div className="row col-lg-12 col-md-4 col-sm-6 mt-4">
              <h6 className="col-lg-4 col-md-4 col-sm-6">Section Name</h6>
              <div className="col-lg-1 col-md-4 col-sm-6"> :</div>
              <h5 className="col-lg-7 col-md-4 col-sm-6">
                {this.state.info.section_name}
              </h5>
            </div>
            <div className="row col-lg-12 col-md-4 col-sm-6 mt-4">
              <h6 className="col-lg-4 col-md-4 col-sm-6">Subject Name</h6>
              <div className="col-lg-1 col-md-4 col-sm-6"> :</div>
              <h5 className="col-lg-7 col-md-4 col-sm-6">
                {this.state.info.subjects?.map((item) => (
                  <p key={item}>{item}</p>
                ))}
              </h5>
            </div>
            <div className="row col-lg-12 col-md-4 col-sm-6 mt-4">
              <h6 className="col-lg-4 col-md-4 col-sm-6">Principal Name</h6>
              <div className="col-lg-1 col-md-4 col-sm-6"> :</div>
              <h5 className="col-lg-7 col-md-4 col-sm-6">
                {this.state.info.principal_name}
              </h5>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getClassInfoAction: (e) => dispatch(getClassInfoAction()),
});
export default connect(null, mapDispatchToProps)(StudentClassInformation);
