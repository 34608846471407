import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import moment from "moment";
import Loader from "../../../common/Loader";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import {
  getAllTaskAction,
  searchTaskAction,
  deleteTaskAction,
} from "../../../../actions/institute/StudentDashboard/ManageTask/manageTaskAction";
import "./style.scss";

class ManageTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      tableBody: { columns: [], rows: [] },
      modalId: null,
      search: {},
      deleteModalId: null,
    };
  }

  componentDidMount() {
    this.props.getAllTaskAction().then((res) => {
      this.constructTableData();
    });
  }

  toggle = (institute, i) => () => {
    this.setState({
      modalId: i,
    });
  };

  toggleClose = () => {
    this.setState({
      modalId: null,
    });
  };

  handleSearch = (name, value) => {
    this.setState({ search: { ...this.state.search, [name]: value } });
  };

  deleteToggle = (i) => () => {
    this.setState({
      deleteModalId: i,
    });
  };

  handleDeleteFAQ = (id) => async () => {
    await this.props.deleteTaskAction(id);
    this.deleteToggleClose();
    await this.props.getAllTaskAction().then((res) => {
      this.constructTableData();
    });
  };

  searchHandler = async () => {
    let search = {
      task_title: this.state.search.task_title,
      task_description: this.state.search.task_description,
      status: this.state.search.status,
      deadline: this.state.search.deadline
        ? moment(this.state.search.deadline).format("YYYY-MM-DD")
        : null,
    };
    Object.keys(search).forEach(
      (key) => search[key] == "" && delete search[key]
    );
    await this.props.searchTaskAction(search);
    this.constructTableData();
  };

  deleteToggleClose = () => {
    this.setState({
      deleteModalId: null,
    });
  };

  clearHandler = async () => {
    this.setState({
      search: {},
    });
    await this.props.getAllTaskAction().then((res) => {
      this.constructTableData();
    });
  };

  tableColumns = () => [
    {
      label: "#",
      field: "id",
    },
    {
      label: "Task Title",
      field: "task_title",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Name",
      },
    },
    {
      label: "Description",
      field: "task_description",
      width: 270,
    },
    {
      label: "Status",
      field: "status",
      width: 270,
    },
    {
      label: "Created Date",
      field: "created_at",
      width: 270,
    },
    {
      label: "Deadline",
      field: "deadline",
      width: 270,
    },
    {
      label: "Action",
      field: "action",
      width: 270,
    },
  ];

  constructTableData = () => {
    let tableData = {
      columns: this.tableColumns().filter((ele) => ele),
      rows: this.actionContainer(),
    };

    this.setState({
      tableBody: tableData,
    });
  };

  actionContainer = () => {
    return this.props.taskList.map((item, i) => {
      let obj = {
        id: i + 1,
        task_title: item.task_title,
        task_description: item.task_description,
        status:
          item.status === 0
            ? "Pending"
            : "" || item.status === 1
            ? "Inprogress"
            : "" || item.status === 2
            ? "Completed"
            : "",
        created_at: moment(item.created_at).format("YYYY-MM-DD"),
        deadline: moment(item.deadline).format("YYYY-MM-DD"),
        action: (
          <div className="actionContainer">
            <MDBIcon
              icon="edit"
              size="lg"
              className="icon"
              onClick={() => {
                this.props.history.push(`/student/edit-task/${item.id}`);
              }}
            />
            <MDBIcon
              icon="eye"
              size="lg"
              className="icon"
              onClick={this.toggle(item, i)}
            />
            <MDBIcon
              icon="trash"
              size="lg"
              className="icon"
              onClick={this.deleteToggle(i)}
            />
          </div>
        ),
      };
      return obj;
    });
  };

  viewModalDetails = (item) => {
    return (
      <>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Task Title</h6>
            <h6>{item.task_title}</h6>
          </div>
        </div>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Task Description</h6>
            <h6>{item.task_description}</h6>
          </div>
        </div>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Status</h6>
            <h6>
              {item.status === 0
                ? "Pending"
                : "" || item.status === 1
                ? "Inprogress"
                : "" || item.status === 2
                ? "Completed"
                : ""}
            </h6>
          </div>
        </div>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Deadline</h6>
            <h6>{moment(item.deadline).format("YYYY-MM-DD")}</h6>
          </div>
        </div>
      </>
    );
  };

  searchContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-3 md-3 col-sm-12">
                <input
                  type="text"
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search.title
                  }
                  onChange={(e) =>
                    this.handleSearch("task_title", e.target.value)
                  }
                  className="form-control"
                  placeholder="Task Title"
                />
              </div>
              <div className="col-lg-3 md-3 col-sm-12">
                <input
                  type="text"
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search.title
                  }
                  onChange={(e) =>
                    this.handleSearch("task_description", e.target.value)
                  }
                  className="form-control"
                  placeholder="Description"
                />
              </div>
              <div className="col-lg-3 md-3 col-sm-12">
                <select
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search.status
                  }
                  onChange={(e) => this.handleSearch("status", e.target.value)}
                  className="form-control show-tick"
                >
                  <option value="">Status</option>
                  <option value="0">Pending</option>
                  <option value="1">Inprogress</option>
                  <option value="2">Completed</option>
                </select>
              </div>
              <div className="col-lg-3 md-3 col-sm-12">
                <DatePicker
                  selected={this.state.search.deadline}
                  onChange={(value) => this.handleSearch("deadline", value)}
                  placeholderText="Deadline date"
                  className="form-control "
                />
              </div>
            </div>
            <div className="btn-container">
              <div>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.searchHandler}
                >
                  Search
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.clearHandler}
                >
                  Clear
                </MDBBtn>
              </div>
              <div>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={() => {
                    this.props.history.push("/student/calendar-View");
                  }}
                >
                  Calendar View
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={() => {
                    this.props.history.push("/student/create-task");
                  }}
                >
                  Create
                </MDBBtn>
              </div>
            </div>
          </div>
        </div>
      </TabPane>
    );
  };

  tableContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body" id="capture">
            <h1 className="page-title">Task List</h1>
            {this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  searching={false}
                  sortRows={["title"]}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                />
              </div>
            )}
          </div>
        </div>
      </TabPane>
    );
  };

  modalContainer = () => {
    return (
      <div>
        {this.props.taskList.map((item, i) => {
          return (
            <MDBModal
              isOpen={this.state.modalId === i ? true : false}
              toggle={this.toggle}
            >
              <MDBModalHeader toggle={this.toggleClose}>
                View Task
              </MDBModalHeader>
              <MDBModalBody>{this.viewModalDetails(item)}</MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggleClose}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          );
        })}
        {this.props.taskList.map((item, i) => {
          return (
            <MDBModal
              isOpen={this.state.deleteModalId === i ? true : false}
              toggle={this.toggle}
            >
              <MDBModalHeader toggle={this.deleteToggleClose}>
                Alert
              </MDBModalHeader>
              <MDBModalBody>
                <h5>Are you sure you want to delete .?</h5>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.deleteToggleClose}>
                  No
                </MDBBtn>
                <MDBBtn
                  color="secondary"
                  onClick={this.handleDeleteFAQ(item.id)}
                >
                  Yes
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          );
        })}
      </div>
    );
  };

  pageContainer = () => {
    const { activeTab } = this.state;
    return (
      <TabContent activeTab={activeTab}>
        {this.searchContainer()}
        {this.tableContainer()}
      </TabContent>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">Manage Task</h1>
                <ol className="breadcrumb page-breadcrumb">
                  <li className="breadcrumb-item">Search</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.pageContainer()}</div>
          {this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  taskList: state.getTaskReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getAllTaskAction: () => dispatch(getAllTaskAction()),
  searchTaskAction: (e) => dispatch(searchTaskAction(e)),
  deleteTaskAction: (e) => dispatch(deleteTaskAction(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageTask);
