import { GET_REWARD, SHOW_SNACKBAR } from "../../Constants/index";
import axios from "axios";
import { manageReward } from "../../../utils/Api";
import { getToken } from "../../../utils/token";

export const createRewardAction = (data, history) => {
  return (dispatch) => {
    const url = `${manageReward.createReward}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "POST",
        data,
      };
      return axios(options).then((response) => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "success", message: data.SUCESS.status.msg, open: true },
          });
         history.push("/institute/manage-reward");
        } else {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "error", message: data.ERROR.status.msg, open: true },
          });
        }
        return data;
      });
  };
};

export const getRewardEditByIdAction = (id) => {
  return (dispatch) => {
    const url = `${manageReward.getEditRewardById(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        return data.DATA;
      }
      return {};
    });
  };
};

export const editRewardAction = ({ id, data, history }) => {
  return (dispatch) => {
    const url = `${manageReward.EditRewardById(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "PATCH",
        data,
      };
      return axios(options).then((response) => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "success", message: data.SUCESS.status.msg, open: true },
          });
         history.push("/institute/manage-reward");
        } else {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "error", message: data.ERROR.status.msg, open: true },
          });
        }
        return data;
      });
  };
};

export const searchRewardAction = (params) => {
  return (dispatch) => {
    const url = `${manageReward.searchReward}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params,
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_REWARD,
          payload: data.DATA,
        });
      } else {
        dispatch({
          type: GET_REWARD,
          payload: [],
        });
      }
    });
  };
};
