

import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import './style.scss';
import { connect } from 'react-redux';
import { MDBBtn, MDBFormInline, MDBInput} from 'mdbreact';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { createQuestionAction, updateQuestionListAction } from '../../../../actions/institute/Teacherdashboard/ManageQuestions/ManageQuestionAction';
import { uploadEventImageAction,clearUploadEventImageAction } from '../../../../actions/institute/ManageEvents/manageEventsAction';
import { uploadLoginVideoAction } from '../../../../actions/Admin/ManageProfile/manageProfileAction'

class AddQuestion extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
            input:{
                is_selected:false,
                name:'',
                image_url:'',
                video_url:'',
                marks:0,
                options:[],
                is_descriptive:0
            },
            file: null,
            video:null,
            questions_type:'multi option',
            editIndex: this.props.editIndex,

            questionModal: this.props.openModal,

            option1:[
                {
                    name:'',
                    is_correct:0
                },
                {
                    name:'',
                    is_correct:0
                }
            ],
            correct_ans1:'true',

            option2:[
                {
                    name:"",
                    is_correct:0
                },
                {
                    name:"",
                    is_correct:0
                },
                {
                    name:"",
                    is_correct:0
                },
                {
                    name:"",
                    is_correct:0
                },
                {
                    name:"",
                    is_correct:0
                }
            ],
            uploadImageStatus:null,
            uploadVideoStatus:null,
            correct_ans2:'',
            questionState:{},
            questionList:[]
		};
	}

    componentDidMount(){
        if(this.state.editIndex || this.state.editIndex == '0'){
            
            let data = this.props.questionState.list[this.state.editIndex]
            let input = {...this.state.input} 
            let questions_type = ''
            if(data.is_descriptive == '1'){

                questions_type = 'descriptive'
            }
            if(data.is_descriptive == '0' && data.options.length >2){
                questions_type = 'multi option'
            }
            if(data.is_descriptive == '0' && data.options.length === 2){

                questions_type = 'true false'
            }
            this.setState({
                input:data,
                questions_type:questions_type,
                questionList:this.props.questionState.list
            },()=>{
                this.getQuestionData()
            })
        }
	}
    getQuestionData=()=>{
        let data = {...this.state.input}
        if(this.state.questions_type === 'multi option'){
            this.setState({
                input:data,
                option2:data.options
            },()=>{
                this.state.option2.map((item,i)=>{
                    if(item.is_correct == 1){
                        this.setState({
                            correct_ans2:item.name
                        }) 
                    }
                })
            })
            
        }
        if(this.state.questions_type === 'true false'){
            this.setState({
                input:data,
                option1:data.options
            },()=>{
                if(this.state.option1[0].is_correct == 1){
                    this.setState({correct_ans1 : 'True'})
                }
                else{
                    this.setState({correct_ans1 : 'False'})  
                }
            })
            
        }
       
    }
    componentWillReceiveProps(nextProps){
        if(this.state.uploadImageStatus !== (nextProps.uploadImageStatus && nextProps.uploadImageStatus.status && nextProps.uploadImageStatus.status.file)){
            if(nextProps.uploadImageStatus.status.file){
                let input = {...this.state.input}
                input.image_url = nextProps.uploadImageStatus.status.file

                this.setState({
                    uploadImageStatus:nextProps.uploadImageStatus.status.file,
                    input:input
                },()=>{
                     if(nextProps.uploadImageStatus && nextProps.uploadImageStatus.status && nextProps.uploadImageStatus.status.file){
                        this.createExam()
                     }
                })
            }
        }
        if(this.state.uploadVideoStatus !== nextProps.loginVideoUrl.status){
            let input = {...this.state.input}
            input.video_url = nextProps.loginVideoUrl.status

            this.setState({
                uploadVideoStatus: nextProps.loginVideoUrl.status,
                input:input
            },()=>{
				this.createExam()
			})
        }
	}
    componentWillUnmount(){
        this.props.toggleClose()
        this.props.clearUploadEventImageAction()
    }
    
    clearData =()=>{
        this.props.toggleClose()
        this.props.clearUploadEventImageAction()
    }
    createExam=()=>{
        if(this.state.editIndex || this.state.editIndex == '0'){
            let questionList =  [...this.state.questionList]
            questionList[this.state.editIndex] = this.state.input
            this.props.updateQuestionListAction(questionList)
        }
        else{
            this.props.createQuestionAction(this.state.input)
        }
        this.clearData()
    }
    handleSave=()=>{
        if(this.state.file){
            this.props.uploadEventImageAction(this.state.file)
        }
        else if(this.state.video){
            this.props.uploadLoginVideoAction(this.state.video)
        }
        else{
            this.createExam()
        }
    }
    submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
        let input = {...this.state.input}   
        if(this.state.questions_type === 'multi option'){
            this.state.option2.map((item,i)=>{
                if(item.name === this.state.correct_ans2){
                    let option2 = [...this.state.option2]
                    option2[i].is_correct = 1
                    this.setState({
                        option2:option2
                    },()=>{
                        input.options = this.state.option2
                    })
                }
            })
        }
        if(this.state.questions_type === 'true false'){
            let option1 = [...this.state.option1]
            if(this.state.correct_ans1 === 'True'){
                option1[0].is_correct = 1
            }
            else{
                option1[1].is_correct = 1
            }
            this.setState({
                option1:option1
            },()=>{
                input.options = this.state.option1
            }) 
        }
        if(this.state.questions_type === 'descriptive'){
            input.is_descriptive = 1
            delete input.options
        }

        this.setState({ input:input },()=>{
            this.handleSave()
        });
	}


    handleOptionChange=(event,index)=>{
      let option2 = [...this.state.option2]
      option2[index].name = event.target.value
      this.setState({
        option2:option2
      })
    }
    handleOption1Change=(event,index)=>{
        let option1 = [...this.state.option1]
        option1[index].name = event.target.value
        this.setState({
          option1:option1
        })
      }
    handleImageFile=(event)=>{
        this.setState({file : event.target.files[0]})
    }
    handleVideoFile=(event)=>{
        this.setState({video : event.target.files[0]})
    }
    changeHandler = event => {
        let input = {...this.state.input}
        input[event.target.name] = event.target.value
		this.setState({ input:input });
	};
    toggleClose = ()=>{
		this.setState({
			questionModal: false,
		})
        this.props.toggleClose()
	}
    handeTypeChange = (event)=>{
       this.setState({ questions_type:event.target.value });
    }
    render(){
        const { activeTab } = this.state
        // if(!isLogined()) this.props.history.push('/')
        return(
            <>
            <MDBContainer>
                <MDBModal isOpen={this.state.questionModal} >
                    <MDBModalHeader toggle={this.toggleClose}>{(this.state.editIndex || this.state.editIndex == '0')  ? 'Edit Question' : 'New Question'}</MDBModalHeader>
                    <form noValidate>
                        <MDBModalBody>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Enter Question :
                                            </div>
                                        </div>
                                       
                                        <div className="col-lg-8 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                <input type="text" className="form-control" value = {this.state.input['name']} name="name" 
                                                onChange={this.changeHandler} placeholder="Enter Question" required/>
                                                
                                                <div className="invalid-feedback">
                                                    required Question.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Upload Question Image
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-4 col-sm-6 inputField">
                                            <div className="">
                                                <input type="file" className="form-control"
                                                    onChange={this.handleImageFile} required={this.state.input.image_url ? false: true} accept = "image/jpeg,image/*"/>
                                                <div className="">
                                                    {this.state.file ? '' : this.state.input.image_url}
                                                </div>
                                                <div className="invalid-feedback">
                                                    required file.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Upload Question Video
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-4 col-sm-6 inputField">
                                            <div className="">
                                            <input type="file" className="form-control"
                                                onChange={this.handleVideoFile} required={this.state.input.video_url ? false: true} />
                                                <div className="">
                                                    {this.state.video ? '' : this.state.input.video_url}
                                                </div>
                                            <div className="invalid-feedback">
                                                required file.
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Enter Marks
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                <input type="text" className="form-control" value = {this.state.input['marks']} name="marks" 
                                                onChange={this.changeHandler} placeholder="Enter Point Value" required/>
                                                
                                                <div className="invalid-feedback">
                                                    required point value.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Assignment Question Type
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                <MDBInput value="multi option" type='radio' name="questionType" 
                                                className="form-control" onClick={ this.handeTypeChange } checked= {this.state.questions_type === 'multi option'}/>MCQs 
                                                &nbsp;
                                                <MDBInput value="true false" type='radio' name="questionType" 
                                                className="form-control" onClick={ this.handeTypeChange } checked= {this.state.questions_type === 'true false'}/>True False
                                                &nbsp;
                                                <MDBInput label='' value="descriptive" type='radio' name="questionType" className="form-control" 
                                                checked= {this.state.questions_type === 'descriptive'} onChange={this.handeTypeChange}/>Descriptive 
                                                
                                            </div>
                                        </div>
                                    </div>
                                            {
                                                this.state.questions_type === 'multi option' ? 
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Option A :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value={this.state.option2[0].name} name="name" 
                                                                    onChange={(e)=>{this.handleOptionChange(e,0)}} required/>
                                                                
                                                                    <div className="invalid-feedback">
                                                                        required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Option B:
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value={this.state.option2[1].name} name="title" 
                                                                    onChange={(e)=>{this.handleOptionChange(e,1)}} required/>
                                                                
                                                                    <div className="invalid-feedback">
                                                                        required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Option C :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value={this.state.option2[2].name} name="title" 
                                                                    onChange={(e)=>{this.handleOptionChange(e,2)}} required/>
                                                                
                                                                    <div className="invalid-feedback">
                                                                        required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Option D
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value={this.state.option2[3].name} name="title" 
                                                                    onChange={(e)=>{this.handleOptionChange(e,3)}} required/>
                                                                
                                                                    <div className="invalid-feedback">
                                                                        required .
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Option E
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value={this.state.option2[4].name} name="title" 
                                                                    onChange={(e)=>{this.handleOptionChange(e,4)}}  required/>
                                                                
                                                                    <div className="invalid-feedback">
                                                                        required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Correct Answer
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value={this.state.correct_ans2} name="correct_ans2" 
                                                                    onChange={(e)=>{this.setState({correct_ans2:e.target.value})}} required/>
                                                                
                                                                    <div className="invalid-feedback">
                                                                        required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-4 col-sm-6 inputField">
                                                                <span>Note: In case of multiple correct answers,instructor will be required to enter all the correct answers. </span>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                : ''
                                            }
                                            {
                                                this.state.questions_type === 'true false' ? 
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 ">
                                                                <div className="input-group">
                                                                    True :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-4 col-sm-6 ">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value={this.state.option1[0].name} name="name" 
                                                                    onChange={(e)=>{this.handleOption1Change(e,0)}} required/>
                                                                
                                                                    <div className="invalid-feedback">
                                                                        required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    False
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value={this.state.option1[1].name} name="name" 
                                                                    onChange={(e)=>{this.handleOption1Change(e,1)}} required/>
                                                                
                                                                    <div className="invalid-feedback">
                                                                        required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Correct Answer
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select className="form-control show-tick" value = {this.state.correct_ans1} 
                                                                    name="subject_id" onChange={(e)=>{this.setState({correct_ans1:e.target.value})}} required>
                                                                        <option value="">Select</option>
                                                                        <option value="True">True</option>
                                                                        <option value="False">False</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                : ''
                                            }
                                </div>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="secondary" type="button" onClick={this.toggleClose}>Close</MDBBtn>
                            <MDBBtn color="secondary" type="button" onClick={this.submitHandler}>{(this.state.editIndex || this.state.editIndex == '0') ? 'Update': 'Save'}</MDBBtn>
                        </MDBModalFooter>
                    </form>
                </MDBModal>
            </MDBContainer>
            </>
        )
    }
}

const mapStateToProps = state => ({
	questionState: state.ManageQuestionReducer,
    uploadImageStatus :state.uploadEventImageReducer,
    loginVideoUrl : state.uploadVideoReducer
})

const mapDispatchToProps = dispatch => ({
	createQuestionAction: (e) => dispatch(createQuestionAction(e)),
	updateQuestionListAction: (e) => dispatch(updateQuestionListAction(e)),

	clearUploadEventImageAction: (e) => dispatch(clearUploadEventImageAction(e)),
	uploadEventImageAction: (e) => dispatch(uploadEventImageAction(e)),
	uploadLoginVideoAction: (e) => dispatch(uploadLoginVideoAction(e)),

})

export default connect(mapStateToProps, mapDispatchToProps)(AddQuestion)
