import {  GET_SCHOOL,
    GET_STATE,
    GET_CITY,
    GET_ALL_SCHOOL,
    GET_GLOBAL_CLASS,
    GET_GLOBAL_SECTION,
    GET_GLOBAL_SUBJECT,
    GET_GLOBAL_TEACHER,
    GET_GLOBAL_SUBJECT_NEW,
    GET_GLOBAL_EXAMINATION,
    GET_GLOBAL_ASSIGNMENT,
    GET_GLOBAL_SKILL,
    GET_SUBJECT_FOR_EXAM} from '../../../actions/Constants/index'

const initialState = {
    schoolList : [],
    stateList : [],
    cityList : [],
    schoolAllList:[],
    classList:[],
    sectionList:[],
    subjectList:[],
    subjectNewList:[],
    teacherList:[],
    examList:[],
    assignmentList:[],
    skillList:[],
    subjectForExam:[]
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_SCHOOL:
			return {
				...state,
                schoolList : action.payload.list,
			}

        case GET_STATE:
            return {
                ...state,
                stateList : action.payload.list,
            }

        case GET_CITY:
            return {
                ...state,
                cityList : action.payload.list,
            }
        case GET_ALL_SCHOOL:
                return {
                    ...state,
                    schoolAllList : action.payload.list,
                }
                
        case GET_GLOBAL_CLASS:
            return {
                ...state,
                classList : action.payload.list,
            }
        case GET_GLOBAL_SECTION:
            return {
                ...state,
                sectionList : action.payload.list,
            }
        case GET_GLOBAL_SUBJECT:
            return {
                ...state,
                subjectList : action.payload.list,
            }
        case GET_GLOBAL_TEACHER:
            return {
                ...state,
                teacherList : action.payload.list,
            }
        case GET_GLOBAL_SUBJECT_NEW:
            return {
                ...state,
                subjectNewList : action.payload.list,
            }
        case GET_GLOBAL_EXAMINATION:
        return {
            ...state,
            examList : action.payload.list,
        }
        case GET_GLOBAL_ASSIGNMENT:
        return {
            ...state,
            assignmentList : action.payload.list,
        }
        case GET_GLOBAL_SKILL:
        return {
            ...state,
            skillList : action.payload.list,
        }
        case GET_SUBJECT_FOR_EXAM:
        return {
            ...state,
            subjectForExam : action.payload.list,
        }
         
        default:
            return state
    }
}
