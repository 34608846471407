import { GET_SKILL_RULE, 
    CREATE_SKILL_RULE,
    EDIT_SKILL_RULE, 
    GET_SKILL_RULE_BY_ID } from '../../../../actions/Constants/index'

const initialState = {
    list : [],
    editStatus:null,
    createStatus:null,
    detail:null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_SKILL_RULE:
        return {
            ...state,
            list : action.payload.list,
        }
        case EDIT_SKILL_RULE:
        return {
            ...state,
            editStatus : action.payload.list,
        }
        case CREATE_SKILL_RULE:
        return {
            ...state,
            createStatus : action.payload.list,
        }
        case GET_SKILL_RULE_BY_ID:
        return {
            ...state,
            detail : action.payload.list,
        }
        default:
            return state
    }
}
