import { GET_SCHOOLS, 
    CREATE_SCHOOLS,
    EDIT_SCHOOLS, 
    GET_SCHOOLS_BY_ID, UPLOAD_SCHOOL,SHOW_SNACKBAR} from '../../Constants/index';
import axios from 'axios';
import { manageSchool } from '../../../utils/Api'
import { getToken } from '../../../utils/token'

export const getSchoolAction = (params) => {
	return dispatch => {
        const url =  `${manageSchool.searchSchool}` ,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_SCHOOLS,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_SCHOOLS,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearGetSchoolAction = () => {
	return dispatch => {
        dispatch({
            type: GET_SCHOOLS,
            payload: {
                list : []
            }
        })
    }
}

export const createSchoolAction = (data,history) => {
	return dispatch => {
        const url = `${manageSchool.createSchool}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: CREATE_SCHOOLS,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                  history.push("/institute/manage-school");
            }else {
                dispatch({
                    type: CREATE_SCHOOLS,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const clearCreateSchoolAction = () => {
	return dispatch => {
        dispatch({
            type: CREATE_SCHOOLS,
            payload: {
                list : null
            }
        })
    }
}

export const clearEditSchoolAction = () => {
	return dispatch => {
        dispatch({
            type: EDIT_SCHOOLS,
            payload: {
                list : null
            }
        })
    }
}

export const editSchoolAction = ({id,data,history}) => {
	return dispatch => {
        const url = `${manageSchool.editSchool(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PATCH',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: EDIT_SCHOOLS,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                  history.push("/institute/manage-school");
            }else {
                dispatch({
                    type: EDIT_SCHOOLS,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const getSchoolByIdAction = (id) => {
	return dispatch => {
        const url = `${manageSchool.getSchoolById(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_SCHOOLS_BY_ID,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_SCHOOLS_BY_ID,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearSchoolByIdAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_SCHOOLS_BY_ID,
            payload: {
                list : {}
            }
        })
    }
}

export const uploadSchoolFileAction = (data) => {
	return dispatch => {
        let formData = new FormData();
        formData.append('csv', data);
        const url = `${manageSchool.uploadSchool}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data : formData
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = flag.status
                dispatch({
                    type: UPLOAD_SCHOOL,
                    payload: {
                        status : data
                    }
                })
            }else {
                data = flag.status
                dispatch({
                    type: UPLOAD_SCHOOL,
                    payload: {
                        status : data
                    }
                })
            }
        })
    }
}

export const clearUploadSchoolAction = (id) => {
	return dispatch => {
        dispatch({
            type: UPLOAD_SCHOOL,
            payload: {
                list : {}
            }
        })
    }
}