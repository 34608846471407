import { GET_CURRICULUM_PDF_BY_ID} from "../../../../actions/Constants/index";

// const initialState = [];

// export default (state = initialState, action) => {
//   switch (action.type) {
//     case GET_CURRICULUM_PDF_BY_ID:
//       return action.payload;
//     default:
//       return state;
//   }
// };

// import { GET_CURRICULUM_PDF} from "../../../../actions/Constants/index";

const initialState = {
    list : []
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_CURRICULUM_PDF_BY_ID:
			return {
				...state,
                list : action.payload.list,
			}
   
        default:
            return state
    }
}