import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import "../style.scss";
import InsititesImage from '../../../assets/images/insitutes.jpg';
import { userLoginAction } from '../../../actions/institute/Authentication/userAuthenticationAction';

class InstituteLogin extends PureComponent {
	constructor() {
		super();
		this.state = {
			email: "", 
			password: "",
			loginStatus: null
		}
	}
	
	submitHandler = async (event) => {
		event.preventDefault();
		event.target.className += " was-validated";
		if (event.target.checkValidity()) {
		  const obj = {
			email: this.state.email,
			password: this.state.password,
		  };
		  await this.props.userLoginAction(obj);
		  if (this.props.loginInfo.isLogined === true) {
			localStorage.setItem("loginedRole", this.props.loginInfo.role.role);
			if (this.props.loginInfo.password_state === 0) {
			  if (this.props.loginInfo.role.role === "institute admin") {
				this.props.history.push("/institute/information");
			  }
			  if (this.props.loginInfo.role.role === "principal") {
				this.props.history.push("/institute/schoolinformation");
			  }
			  if (this.props.loginInfo.role.role === "teacher") {
				this.props.history.push("/teacher/class-information");
			  }
			  if (this.props.loginInfo.role.role === "student") {
				this.props.history.push("/student/class-information");
			  }
			  if (this.props.loginInfo.role.role === "parent") {
				this.props.history.push("/parent/class-information");
			  }
			} else {
				if (this.props.loginInfo.role.role === "institute admin") {
					this.props.history.push("/institute/changePassword");
				  }
				  if (this.props.loginInfo.role.role === "principal") {
					this.props.history.push("/institute/changePassword");
				  }
				  if (this.props.loginInfo.role.role === "teacher") {
					this.props.history.push("/teacher/changePassword");
				  }
				  if (this.props.loginInfo.role.role === "student") {
					this.props.history.push("/student/changePassword");
				  }
				  if (this.props.loginInfo.role.role === "parent") {
					this.props.history.push("/parent/changePassword");
				  }
			 
			}
		  } else {
			this.setState({
			  loginStatus: "Invalid Login Credentials",
			});
		  }
		}
	  };

	changeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {
		// if(isLogined()) this.props.history.push('/manage-institutes')
		return (
			<div className="auth option2">
				<div className="auth_left authenticationContainer">
					<div className="card">
						<form  onSubmit={this.submitHandler} noValidate>
							<MDBContainer className="MDBContainer">
								<MDBRow>
									<MDBCol size="8">
										<div>
											<img src={InsititesImage} height={400} width={735}/>
										</div>
									</MDBCol>
									<MDBCol size="4">
										<div className="card-body">
											<div className="text-center">
												<Link className="header-brand" to="/">
													<i className="fa fa-graduation-cap brand-logo"></i>
												</Link>
												<div className="card-title mt-3">Institute Login</div>
												{/* <button type="button" className="mr-1 btn btn-facebook"><i className="fa fa-facebook mr-2"></i>Facebook</button>
												<button type="button" className="btn btn-google"><i className="fa fa-google mr-2"></i>Google</button>
												<h6 className="mt-3 mb-3">Or</h6> */}
											</div>
											<div className="form-group inputContainer">
												<input 
													value={this.state.email}
													name="email"
													onChange={this.changeHandler}
													type="email" 
													className="form-control" 
													id="inputEmail" 
													aria-describedby="emailHelp" 
													placeholder="Enter email" 
													required 
												/>
												<div className="invalid-feedback">
													Please provide a valid mail.
												</div>
											</div>
											<div className="form-group inputContainer">
												<input 
													value={this.state.password}
													name="password"
													onChange={this.changeHandler}
													type="password" 
													className="form-control" 
													id="inputPassword" 
													placeholder="Password" 
													required 
												/>
												<div className="invalid-feedback">
													Please provide a valid password.
												</div>
												<label className="form-label forgotLink "><Link to="/institute/forgotpassword" className="float-right small">Forgot Password ?</Link></label>
											</div>
											{this.state.loginStatus &&(
												<div className='alertFlag'>
													Invalid Login Credentials
												</div>
											)}
											<MDBBtn color="primary" type="submit" className="MDBBtn" >
												Sign In 
											</MDBBtn>
											{/* <Link to="/super-admin/login">
											<button style={{ marginTop: '10px'}}>Redirect SuperAdmin</button>
											</Link> */}
										</div>
									</MDBCol>
								</MDBRow>
							</MDBContainer>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	loginInfo: state.instituteUserLoginReducer,
})

const mapDispatchToProps = dispatch => ({
	userLoginAction: (e) => dispatch(userLoginAction(e))
})
export default connect(mapStateToProps, mapDispatchToProps)(InstituteLogin)
