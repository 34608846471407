export const Columns_Data = {
  Teacher: [
    {
      label: "#",
      field: "id",
    },
    localStorage.getItem("loginedRole") !== "principal" && {
      label: "School Name",
      field: "school_name",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Name",
      },
    },
    {
      label: "Class Name",
      field: "class",
      width: 270,
    },
    {
      label: "Section Name",
      field: "section_name",
      width: 270,
    },
    {
      label: "Subject Name",
      field: "subject_name",
      width: 270,
    },
    {
      label: "Teacher Name",
      field: "username",
      width: 270,
    },
    {
      label: "Created Date",
      field: "created_at",
      width: 270,
    },
    {
      label: "Status",
      field: "status",
      width: 270,
    },
    {
      label: "Action",
      field: "action",
      width: 270,
    },
  ],
  Student: [
    {
      label: "#",
      field: "id",
    },
    localStorage.getItem("loginedRole") !== "principal" && {
      label: "School Name",
      field: "school_name",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Name",
      },
    },
    {
      label: "Class Name",
      field: "class",
      width: 270,
    },
    {
      label: "Section Name",
      field: "section_name",
      width: 270,
    },
    {
      label: "Student Name",
      field: "username",
      width: 270,
    },
    {
      label: "Created Date",
      field: "created_at",
      width: 270,
    },
    {
      label: "Status",
      field: "status",
      width: 270,
    },
    {
      label: "Action",
      field: "action",
      width: 270,
    },
  ],
  Parent: [
    {
      label: "#",
      field: "id",
    },
    localStorage.getItem("loginedRole") !== "principal" && {
      label: "School Name",
      field: "school_name",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Name",
      },
    },
    {
      label: "Class Name",
      field: "class",
      width: 270,
    },
    {
      label: "Section Name",
      field: "section_name",
      width: 270,
    },
    {
      label: "Student Name",
      field: "studentname",
      width: 270,
    },
    {
      label: "Parent Name",
      field: "username",
      width: 270,
    },
    {
      label: "Created Date",
      field: "created_at",
      width: 270,
    },
    {
      label: "Status",
      field: "status",
      width: 270,
    },
    {
      label: "Action",
      field: "action",
      width: 270,
    },
  ],
};
