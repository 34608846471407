import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { isLogined } from '../../../utils/token'
import './style.scss';
import { connect } from 'react-redux';
import { MDBBtn, MDBFormInline, MDBInput} from 'mdbreact';
import { getCitiesAction, getStateAction } from '../../../actions/institute/Utils/utilsAction';
import { clearCreateSchoolAction, clearEditSchoolAction, clearSchoolByIdAction, createSchoolAction, editSchoolAction, getSchoolByIdAction } from '../../../actions/institute/ManageSchool/manageSchoolAction';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

class CreateSchool extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
            category:'',
            input:{
                school_name:'',
                city_id:'',
                state_id:'',
                status: 0,
                contact_person:'',
                email:'',
                mobile:'',
                address_one:'',
                address_two:'',
                zip:''
            },
            categories:[],
            contextError:false,
            editId: this.props.match.params.id,
            modal: false,

			stateList:[],
			cityList:[],

            institute:{}

		};
	}

    componentDidMount(){
        if(localStorage.getItem('institute')){
			this.setState({
				institute:JSON.parse(localStorage.getItem('institute'))
			})
		}
		this.props.getStateAction()
		// this.props.getCitiesAction()
        if(this.state.editId){
            this.props.getSchoolByIdAction(this.state.editId)
        }
	}

    componentDidUpdate(prevProp, prevState) {
        if (prevState.input.state_id !== this.state.input.state_id) {
          if (this.state.input.state_id) {
            this.props
              .getCitiesAction(this.state.input.state_id)
          }
        }
    }

    componentWillReceiveProps(nextProps){
        if(this.state.stateList !== nextProps.stateList){
            this.setState({
                stateList: nextProps.stateList
            })
        }

        if(this.state.cityList !== nextProps.cityList){
            this.setState({
                cityList: nextProps.cityList
            })
        }

        if(this.props.schoolState.detail !== nextProps.schoolState.detail){
            let data = nextProps.schoolState.detail
            let input = {...this.state.input}
            input = data
            input.city_id = data.city_id
            input.state_id = data.state_id
            delete input['inst_id'];
            this.setState({input:input})
        }
	}
    
    clearData =()=>{
        this.props.clearCreateSchoolAction()
        this.props.clearEditSchoolAction()
        this.props.clearSchoolByIdAction()
        this.toggleClose()
        this.props.history.push('/institute/manage-school')
    }
    componentWillUnmount(){
      this.setState({createStatus : null})
       this.clearData()   
    }

    modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={ this.state.modal }>
                    <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
                    <MDBModalBody>
                        <div className='alertContainer'>
                            <h4> Do you want to {this.state.editId ? 'Update' : 'save'} it ? </h4>
                            <h6> Please check the information before submitting</h6>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleClose}>No</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.handleSave}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
			</MDBContainer>
		)
	}
    handleSave=()=>{
        let data = {
            ...this.state.input,
            state_id : Number(this.state.input.state_id),
            city_id : Number(this.state.input.city_id),
        }
        if(this.state.editId){
            this.props.editSchoolAction({id : this.state.editId,
                data:data,history:this.props.history})
        }else{
            this.props.createSchoolAction(data,this.props.history)
        }
    }
    submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
		if(event.target.checkValidity()) {
            this.setState({
                modal: true,
            })
		}
	}
    onContentChange =(value)=>{
        this.setState({context:value})
    }
    changeHandler = event => {
        let input = {...this.state.input}
        input[event.target.name] = event.target.value
		this.setState({ input:input });
	};
    openCategoryModal = e =>{
        this.setState({
            modal:true
        })
    }
    toggleClose = ()=>{
		this.setState({
			modal: false,
		})
	}
    handleStatus = (event)=>{
        let input = {...this.state.input}
        let value = event.target.value === "1" ? 1 : event.target.value === "0" ? 0 : null
        input[event.target.name] = value
		this.setState({ input:input });
    }
    render(){
        const { activeTab } = this.state
        // if(!isLogined()) this.props.history.push('/')
        return(
            <>
            <div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">{this.state.editId ? 'Update New School' : 'Add New School'} </h1>
							</div>
						</div>
					</div>
				</div>
                <div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
                                <form  onSubmit={this.submitHandler} noValidate>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Institute Name
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    {this.state.institute ? this.state.institute.name : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    School Name
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value = {this.state.input['school_name']} 
                                                                    name="school_name" onChange={this.changeHandler} placeholder="School name" required/>
                                                                    <div className="invalid-feedback">
                                                                        required school name.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Address 1
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value = {this.state.input['address_one']} name="address_one" 
                                                                    onChange={this.changeHandler} placeholder="Address1" required/>
                                                                    <div className="invalid-feedback">
                                                                        required address 1
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Contact Person
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value = {this.state.input['contact_person']} name="contact_person" 
                                                                    onChange={this.changeHandler} placeholder="Contact person" required/>
                                                                    <div className="invalid-feedback">
                                                                        required contact person.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Address 2 
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value = {this.state.input['address_two']} name="address_two"
                                                                     onChange={this.changeHandler} placeholder="Address2" />
                                                                    <div className="invalid-feedback">
                                                                        required address 2.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Email
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="email" className="form-control" value = {this.state.input['email']} name="email" 
                                                                    onChange={this.changeHandler} placeholder="Email" required/>
                                                                    <div className="invalid-feedback">
                                                                        required email.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Mobile
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value = {this.state.input['mobile']} name="mobile" 
                                                                    onChange={this.changeHandler} placeholder="Mobile" required/>
                                                                    <div className="invalid-feedback">
                                                                        required mobile.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    State
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select className="form-control show-tick" value = {this.state.input['state_id']} name="state_id" onChange={this.changeHandler} required>
                                                                        <option value="">Select State</option>
                                                                        {this.state.stateList.map((item,i)=>{
                                                                            return (<option value={item.id}>{item.name}</option>)
                                                                        })}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required category.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    City
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select className="form-control show-tick" value = {this.state.input['city_id']} disabled={!this.state.input.state_id} name="city_id" onChange={this.changeHandler} required>
                                                                        <option value="">Select City</option>
                                                                        {this.state.cityList.map((item,i)=>{
                                                                                return (<option value={item.id}>{item.name}</option>)
                                                                        })}
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required category.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Zip
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value = {this.state.input['zip']} name="zip" 
                                                                    onChange={this.changeHandler} placeholder="Zip" required/>
                                                                    <div className="invalid-feedback">
                                                                        required zip.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Status
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <MDBFormInline>
                                                                        <MDBInput
                                                                            value = {1}
                                                                            checked= {this.state.input['status'] === 1}
                                                                            name ={'status'}
                                                                            onChange =  {this.handleStatus}
                                                                            label='Active'
                                                                            type='radio'
                                                                            id='radio1'
                                                                            containerClass='mr-5'
                                                                            required
                                                                        />
                                                                        <MDBInput
                                                                            checked= {this.state.input['status'] ===0}
                                                                            value = {0}
                                                                            name ={'status'}
                                                                            onChange =  {this.handleStatus}
                                                                            label='Inactive'
                                                                            type='radio'
                                                                            id='radio2'
                                                                            containerClass='mr-5'
                                                                            required
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            required Status.
                                                                        </div>
                                                                    </MDBFormInline>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                </div>
                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField submitBtn">
                                                    <MDBBtn color="primary" className='btn' onClick= {()=>{
                                                        this.props.history.push('/institute/manage-school')
                                                    }}>Cancel</MDBBtn>
                                                    <MDBBtn color="primary" type={'submit'} className='btn'>{this.state.editId ? 'Update' : 'Save'}</MDBBtn>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            
                                </form>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
                {this.state.modal ? this.modalContainer() : ''}
            </>
        )
    }
}

const mapStateToProps = state => ({
	schoolState: state.schoolReducer,
	stateList :state.utillReducer.stateList,
    cityList: state.utillReducer.cityList,
})

const mapDispatchToProps = dispatch => ({
	getCitiesAction: (e) => dispatch(getCitiesAction(e)),
	getStateAction: (e) => dispatch(getStateAction()),
	createSchoolAction: (e,history) => dispatch(createSchoolAction(e,history)),
	editSchoolAction: (e,history) => dispatch(editSchoolAction(e,history)),
	clearEditSchoolAction: (e) => dispatch(clearEditSchoolAction()),
	clearCreateSchoolAction: (e) => dispatch(clearCreateSchoolAction()),
	getSchoolByIdAction: (e) => dispatch(getSchoolByIdAction(e)),
	clearSchoolByIdAction: (e) => dispatch(clearSchoolByIdAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateSchool)
