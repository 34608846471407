import { GET_SKILL, 
    CREATE_SKILL,
    EDIT_SKILL, 
    GET_SKILL_BY_ID,
    GET_STUDENT_SKILL,UPLOAD_SKILL_IMAGE} from '../../../Constants/index';
import axios from 'axios';
import { manageSkill } from '../../../../utils/Api'
import { getToken } from '../../../../utils/token'

export const getSkillAction = (params) => {
	return dispatch => {
        const url =  `${manageSkill.getSkill}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_SKILL,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_SKILL,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearGetSkillAction = () => {
	return dispatch => {
        dispatch({
            type: GET_SKILL,
            payload: {
                list : []
            }
        })
    }
}

export const createSkillAction = (data) => {
	return dispatch => {
        const url = `${manageSkill.createSkill}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: CREATE_SKILL,
                    payload: {
                        list : flag.status
                    }
                })
            }else {
                dispatch({
                    type: CREATE_SKILL,
                    payload: {
                        list : flag.status
                    }
                })
            }
        })
    }
}

export const clearCreateSkillAction = () => {
	return dispatch => {
        dispatch({
            type: CREATE_SKILL,
            payload: {
                list : null
            }
        })
    }
}

export const clearEditSkillAction = () => {
	return dispatch => {
        dispatch({
            type: EDIT_SKILL,
            payload: {
                list : null
            }
        })
    }
}

export const editSkillAction = ({id,data}) => {
	return dispatch => {
        const url = `${manageSkill.editSkill(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PATCH',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: EDIT_SKILL,
                    payload: {
                        list : flag.status
                    }
                })
            }else {
                dispatch({
                    type: EDIT_SKILL,
                    payload: {
                        list : flag.status
                    }
                })
            }
        })
    }
}

export const getSkillByIdAction = (id) => {
	return dispatch => {
        const url = `${manageSkill.getSkillById(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_SKILL_BY_ID,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_SKILL_BY_ID,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearSkillByIdAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_SKILL_BY_ID,
            payload: {
                list : {}
            }
        })
    }
}

export const getStudentSkillAction = (params) => {
	return dispatch => {
        const url =  `${manageSkill.getStudentSkill}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_STUDENT_SKILL,
                    payload: {
                        list : data.response
                    }
                })
            }else {
                dispatch({
                    type: GET_STUDENT_SKILL,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearGetStudentSkillAction = () => {
	return dispatch => {
        dispatch({
            type: GET_STUDENT_SKILL,
            payload: {
                list : []
            }
        })
    }
}

export const uploadSkillImageAction = (data) => {
	return dispatch => {
        let formData = new FormData();
        formData.append('image', data);
        const url = `${manageSkill.uploadSkillImage}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data : formData
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: UPLOAD_SKILL_IMAGE,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: UPLOAD_SKILL_IMAGE,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearUploadSkillImageAction = () => {
	return dispatch => {
        dispatch({
            type: UPLOAD_SKILL_IMAGE,
            payload: {
                list : {}
            }
        })
    }
}
