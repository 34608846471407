
import { DELETE_PDF_LIST_ID } from "../../../../actions/Constants" 

const initialState = {
    status : null
}

export default (state = initialState, action) => {
	switch (action.type) {
		case DELETE_PDF_LIST_ID:
			return {
				...state,
                status : action.payload.list,
			}
        default:
            return state
    }
}