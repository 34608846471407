import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import moment from "moment";
import Loader from "../../../common/Loader";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import "./style.scss";
import { CSVLink } from "react-csv";
import {
  searchStudentUserAction,
  studentPasswordUpdateAction,
} from "../../../../actions/institute/ManageUser/manageUserAction";
import {
  getClassAction,
  getSectionAction,
  getStudentAction,
} from "../../../../actions/institute/Utils/utilsAction";

class ManageStudentUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      tableBody: { columns: [], rows: [] },
      modalViewAlert: false,
      modalEditAlert: false,
      isView: false,
      modal: false,
      search: {
        school_id: "",
      },
      input: {},
      exportCsv: null,
      dropDownDataClass: [],
      dropDownDataStudent: [],
      dropDownDataSection: [],
      selectedItem: {}
    };
  }

  componentDidMount() {
    this.props.searchStudentUserAction().then((res) => {
      this.constructTableData();
      this.exportCsv();
    });
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevState.search.school_id !== this.state.search.school_id) {
      if (this.state.search.school_id) {
        let search = {
          school_id: this.state.search.school_id,
        };
        this.props
          .getClassAction(search)
          .then((res) => this.setState({ dropDownDataClass: res }));
      }
    }
    if (prevState.search.class_id !== this.state.search.class_id) {
      if (this.state.search.class_id) {
        let search = {
          class_id: this.state.search.class_id,
        };
        this.props
          .getSectionAction(search)
          .then((res) => this.setState({ dropDownDataSection: res }));
      }
    }
    if (prevState.search.section_id !== this.state.search.section_id) {
      if (this.state.search.section_id) {
        let search = {
          section_id: this.state.search.section_id,
        };
        this.props
          .getStudentAction(search)
          .then((res) => this.setState({ dropDownDataStudent: res }));
      }
    }
    {
      this.state.search.school_id === "" &&
        this.setState({
          search: {
            ...this.state.search,
            ["school_id"]: JSON.parse(localStorage.getItem("school")).id,
          },
        });
    }
  }

  changeHandler = (name, value) => {
    this.setState({ input: { ...this.state.input, [name]: value } });
  };

  toggle = (flag, item, i) => () => {
    this.setState({
      isView: true,
      modalEditAlert: flag,
      selectedItem: item
    });
  };

  toggleEye = (flag, item, i) => () => {
    this.setState({
      isView: false,
      modalViewAlert: true,
      selectedItem: item
    });
  };

  toggleClose = () => {
    this.setState({
      modalEditAlert: false,
      modalViewAlert: false,
    });
  };

  exportCsv = () => {
    const exportCsvHeaders = [
      { label: "Class Name", key: "class_name" },
      { label: "Section Name", key: "section_name" },
      { label: "Student Name", key: "student_name" },
      { label: "Created Date", key: "created_at" },
      { label: "Status", key: "status" },
    ];
    const exportCSVData = this.props.userList.map((item) => {
      let obj = {
        class_name: item.class_name,
        section_name: item.section_name,
        student_name: item.name,
        created_at: item.created_at,
        status: item.status === 1 ? "Active" : "Inactive",
      };
      return obj;
    });
    let csv = {
      data: exportCSVData,
      headers: exportCsvHeaders,
      filename: "manage-studentUser-report.csv",
      style: { color: "white" },
    };
    this.setState({
      exportCsv: csv,
    });
  };

  handleSearch = (name, value) => {
    this.setState({ search: { ...this.state.search, [name]: value } });
  };

  handleUpdate = (item) => async () => {
    const temp = {
      ...this.state.input,
      id: item.id,
      ["school_id"]: item.school_id,
      ["class_id"]: item.class_id,
      ["section_id"]: item.section_id,
      ["email"]: item.email,
    };
    this.setState({
      input: temp,
      modal: true,
      modalEditAlert: false,
      modalViewAlert: false,
    });
  };

  handleSave = async () => {
    await this.props.studentPasswordUpdateAction(this.state.input);
    await this.props.searchStudentUserAction().then((res) => {
      this.constructTableData();
      this.exportCsv();
    });
    this.setState({
      input: {},
      modal: false,
      modalEditAlert: false,
      modalViewAlert: false,
    });
  };

  toggleSaveUploadClose = (i) => {
    this.setState({
      modal: false,
      modalEditAlert: true,
    });
  };

  searchHandler = async () => {
    let search = {
      class_id: this.state.search.class_id,
      section_id: this.state.search.section_id,
      student_id: this.state.search.student_id,
      created_at: this.state.search.created_at
        ? moment(this.state.search.created_at).format("YYYY-MM-DD")
        : null,
      status: this.state.search.status,
    };
    Object.keys(search).forEach(
      (key) => search[key] == "" && delete search[key]
    );
    await this.props.searchStudentUserAction(search);
    this.constructTableData();
  };

  clearHandler = async () => {
    this.setState({
      search: {},
      dropDownDataClass: [],
    });
    await this.props.searchStudentUserAction().then((res) => {
      this.constructTableData();
    });
    {
      this.setState({
        search: {
          ...this.state.search,
          ["school_id"]: JSON.parse(localStorage.getItem("school")).id,
        },
      });
    }
  };

  tableColumns = () => [
    {
      label: "#",
      field: "id",
    },
    {
      label: "Class Name",
      field: "class_name",
      width: 270,
    },
    {
      label: "Section Name",
      field: "section_name",
      width: 270,
    },
    {
      label: "Student Name",
      field: "student_name",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Name",
      },
    },
    {
      label: "Created Date",
      field: "created_at",
      width: 270,
    },
    {
      label: "Status",
      field: "status",
      width: 270,
    },
    {
      label: "Action",
      field: "action",
      width: 270,
    },
  ];

  constructTableData = () => {
    let tableData = {
      columns: this.tableColumns().filter((ele) => ele),
      rows: this.actionContainer(),
    };

    this.setState({
      tableBody: tableData,
    });
  };

  actionContainer = () => {
    return this.props.userList.map((item, i) => {
      let obj = {
        id: i + 1,
        class_name: item.class_name,
        section_name: item.section_name,
        student_name: item.name,
        created_at: item.created_at,
        status: item.status === 1 ? "Active" : "Inactive",
        action: (
          <div className="actionContainer">
            <MDBIcon
              icon="edit"
              size="lg"
              className="icon"
              onClick={this.toggle(true, item, i)}
            />
            <MDBIcon
              icon="eye"
              size="lg"
              className="icon"
              onClick={this.toggleEye(false, item, i)}
            />
          </div>
        ),
      };
      return obj;
    });
  };

  viewModalDetails = (item) => {
    return (
      <>
        <div className="faq-modal-sub-container p-l-30">
          <div>
            <h6 className="font-weight-bold">Student Name</h6>
            <h6>{item.name}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Class</h6>
            <h6>{item.class_name}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Section</h6>
            <h6>{item.section_name}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Email</h6>
            <h6>{item.email}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Status</h6>
            <h6>{item.status === 1 ? "Active" : "Inactive"}</h6>
          </div>
          <div>
            <h6 className="font-weight-bold">Username</h6>
            <h6>{item.username}</h6>
          </div>
          {this.state.isView && (
            <div>
              <h6 className="font-weight-bold">New Password</h6>
              <input
                // value={this.state.input.title}
                placeholder="New Password"
                onChange={(e) => this.changeHandler("password", e.target.value)}
                type="password"
                className="form-control"
                required
              />
              <div className="invalid-feedback">required password field.</div>
            </div>
          )}
          {this.state.isView && (
            <div>
              <h6 className="font-weight-bold">Confirm Password</h6>
              <input
                // value={this.state.input.title}
                placeholder="Confirm Password"
                onChange={(e) =>
                  this.changeHandler("conform_password", e.target.value)
                }
                type="password"
                className="form-control"
                required
              />
              <div className="invalid-feedback">required password field.</div>
            </div>
          )}
        </div>
      </>
    );
  };

  searchContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="input-group">
                  <select
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search.class_id
                    }
                    onChange={(e) =>
                      this.handleSearch("class_id", e.target.value)
                    }
                    className="form-control show-tick"
                  >
                    <option value="">Select Class</option>
                    {this.state.dropDownDataClass.map((ele) => (
                      <option value={ele.id}>{ele.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="input-group">
                  <select
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search.section_id
                    }
                    onChange={(e) =>
                      this.handleSearch("section_id", e.target.value)
                    }
                    disabled={!this.state.search.class_id}
                    className="form-control show-tick"
                  >
                    <option value="">Select Section</option>
                    {this.state.dropDownDataSection.map((ele) => (
                      <option value={ele.id}>{ele.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <select
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search.status
                  }
                  onChange={(e) => this.handleSearch("status", e.target.value)}
                  className="form-control show-tick"
                >
                  <option value="">Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
              <div className="col-lg-3 md-3 col-sm-12 ">
                <DatePicker
                  selected={this.state.search.created_at}
                  onChange={(value) => this.handleSearch("created_at", value)}
                  placeholderText="Created date"
                  className="form-control "
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 mt-10">
                <div className="input-group">
                  <select
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search.student_id
                    }
                    onChange={(e) =>
                      this.handleSearch("student_id", e.target.value)
                    }
                    disabled={!this.state.search.section_id}
                    className="form-control show-tick"
                  >
                    <option value="">Student Name</option>
                    {this.state.dropDownDataStudent.map((ele) => (
                      <option value={ele.id}>{ele.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="btn-container">
              <div>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.searchHandler}
                >
                  Search
                </MDBBtn>{" "}
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.clearHandler}
                >
                  Clear
                </MDBBtn>
              </div>
              <div>
                {this.state.exportCsv && (
                  <MDBBtn color="primary">
                    <CSVLink {...this.state.exportCsv}>
                      CSV
                      <MDBIcon icon="download" size="lg" className="icon" />
                    </CSVLink>
                  </MDBBtn>
                )}
              </div>
            </div>
          </div>
        </div>
      </TabPane>
    );
  };

  tableContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body" id="capture">
            <h1 className="page-title">Users List</h1>
            {this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  searching={false}
                  sortRows={["title"]}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                />
              </div>
            )}
          </div>
        </div>
      </TabPane>
    );
  };

  showEditModal = () => {
    return (
      <MDBModal
        isOpen={
          this.state.isView
            ? this.state.modalEditAlert
            : this.state.modalViewAlert
        }
        toggle={this.toggle}
      >
        <MDBModalHeader toggle={this.toggleClose}>
          {this.state.isView ? "Edit" : "View"} User
        </MDBModalHeader>
        <MDBModalBody>{this.viewModalDetails(this.state.selectedItem)}</MDBModalBody>
        <MDBModalFooter>
          {this.state.isView && (
            <MDBBtn
              color="secondary"
              type="submit"
              onClick={this.handleUpdate(this.state.selectedItem)}
              disabled={
                this.state.input.password !== this.state.input.conform_password
              }
            >
              Update
            </MDBBtn>
          )}
          <MDBBtn color="secondary" onClick={this.toggleClose}>
            Close
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    );
  };

  modalContainer = () => {
    return (
      <div>
        <MDBModal isOpen={this.state.modal}>
          <MDBModalHeader toggle={this.toggleSaveUploadClose}>
            Alert
          </MDBModalHeader>
          <MDBModalBody>
            <div className="alertContainer">
              <h4> Do you want to Save it ? </h4>
              <h6> Please check the information before submitting</h6>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggleSaveUploadClose}>
              No
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.handleSave}>
              Yes
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        {this.showEditModal()}
      </div>
    );
  };

  pageContainer = () => {
    const { activeTab } = this.state;
    return (
      <TabContent activeTab={activeTab}>
        {this.searchContainer()}
        {this.tableContainer()}
      </TabContent>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">Manage Student Users</h1>
                <ol className="breadcrumb page-breadcrumb">
                  <li className="breadcrumb-item">Search</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">{this.pageContainer()}</div>
          {this.modalContainer()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userList: state.getUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
  searchStudentUserAction: (e) => dispatch(searchStudentUserAction(e)),
  getClassAction: (e) => dispatch(getClassAction(e)),
  getSectionAction: (e) => dispatch(getSectionAction(e)),
  getStudentAction: (e) => dispatch(getStudentAction(e)),
  studentPasswordUpdateAction: (e) => dispatch(studentPasswordUpdateAction(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageStudentUsers);
