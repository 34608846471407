import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import DateTimePicker from "react-datetime-picker";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { MDBDataTable, MDBBtn, MDBIcon,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import './style.scss';
import moment from 'moment'
import { clearGetEventDropdownAction, clearGetEventsAction, getEventsAction, getEventsDropdownAction } from '../../../actions/institute/ManageEvents/manageEventsAction';
import CreateEvents from './CreateEvents';
import { getSchoolAction } from '../../../actions/institute/ManageSchool/manageSchoolAction';

import DatePicker from "react-datepicker";
import Loader from "../../common/Loader";

const ExampleCustomTimeInput = ({ date, value, onChange }) => (
       <input
            value={value}
            onChange={(e) => onChange(e.target.value)}
            style={{ border: "solid 1px pink" }}
        />
      );

class ManageEvents extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
			events : [],
			tableBody : { columns :[], rows: []},
			modal: false,
			selectedEvent: null,
			deleteModal:false,
			deleteStatus:null,
			deleteId:'',
			openCreateModal:false,
			editId:'',
			searchEvents:[],
			dropdownData: null,

            school_name:'',
            event_title:'',
            coordinator_name:'',
            date: '',
            venue:'',
            createdAt:'',
			
            status:'',
			role:'',
            school:[],
			isLoggedInRole:
        localStorage.getItem("loginedRole") === "principal" ? true : false,
		};
	}

	componentWillReceiveProps(nextProps){
		if(this.state.events !== nextProps.events.list){
			this.setState({
				events: nextProps.events.list
			},()=>{
				this.constructTableData()
			})
		}
		if(this.state.dropdownData !== nextProps.dropdownData.list){
			this.setState({
				dropdownData: nextProps.dropdownData.list
			})
		}
		if(this.state.role !== nextProps.role){
			this.setState({
				role : nextProps.role
			})
		}
		if(this.state.school !== nextProps.schoolState.list){
			this.setState({
				school: nextProps.schoolState.list
			})
		}
	}

	componentDidMount(){
        this.props.getSchoolAction()
		this.props.getEventsDropdownAction()
		this.props.getEventsAction()
	}

	toggle = (value) =>{
		this.setState({
		  modal: true,
		  selectedEvent : value
		});
	}

	
	toggleClose = ()=>{
		this.setState({
			modal: false,
			selectedEvent: null,
			deleteModal: false,
			openCreateModal: false
		})
	}
	openEditModal=(value)=>{
		this.setState({
			editId : value.id,
			openCreateModal:true
		})
	}
	openDeleteModal=(value)=>{
		this.setState({
			deleteModal: true,
			deleteId:value.id
		})
	}
	deleteKnowledgeBase=()=>{
		this.props.deleteCircularAction(this.state.deleteId)
		this.props.getCircularAction()
		this.setState({
			deleteModal:false
		})
	}
	
	constructTableData =()=>{
		let col = []
		col.push(
			{
				label: '#',
				field: 'id',
			},
		)
		if(this.state.role === 'institute admin'){
			col.push({
				label: 'School Name',
				field: 'school_name',
				width: 150,
				attributes: {
					'aria-controls': 'DataTable',
					'aria-label': 'Name',
				},
			})
		}
		col.push(
			{
				label: 'Event Title',
				field: 'event_title',
				width: 270,
			},
			{
				label: 'Coordinator Name',
				field: 'coordinator_name',
				width: 270,
			},
			{
				label: 'Event Date And Time',
				field: 'date',
				width: 270,
			},
			{
				label: 'Venue',
				field: 'venue',
				width: 270,
			},
			{
				label: 'Status',
				field: 'status',
				width: 270,
			},
			{
				label: 'created Date',
				field: 'createdDate',
				width: 270,
			},
			{
				label: 'Action',
				field: 'action',
				width: 300,
			},
		)
		let tableData = {
			columns: col,
			rows: 
				this.state.events.map((item,i)=>{
					let obj = {
						id: i+1,
						school_name: item.school_name ? item.school_name : '-',
						event_title: item.event_title ? item.event_title : '-' ,
						coordinator_name: item.coordinator_name ? item.coordinator_name: '-',
						date: item.date ?  moment(item.date).format("DD MMM YYYY HH:MM"): '-',
						venue: item.venue ? item.venue: '-',
						createdDate: item.created_at,
						status: item.status ? 'Sent' : 'Draft',
						action: (<div>
							{item.status ? 
							<MDBIcon  icon="eye" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{
								this.toggle(item)
							}} /> : 
							<>
							<MDBIcon  icon="edit" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{
								this.openEditModal(item)
							}} />
							<MDBIcon  icon="eye" size="lg" style={{margin:'5px',cursor:'pointer'}} onClick={()=>{
								this.toggle(item)
							}} /></>
							}
							
						</div>)

					}
					return obj
				})
		}	
		this.setState({
			tableBody: tableData
		})	
	}
	getEvents=()=>{
		this.props.getEventsAction()
	}
	handleChange=(e)=>{
		this.setState({ [e.target.name] : e.target.value})
	}
	getEventDetail = ()=>{
		return(
			<div className="card-body">
				{
					this.state.role === 'institute admin' ? 
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								School Name
							</div>
						</div>
						<div className="col-lg-2 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								: 
							</div>
						</div>
						<div className="col-lg-6 col-md-4 col-sm-6 inputField">
							<div className="input-group">
								{this.state.selectedEvent && this.state.selectedEvent.school_name ? this.state.selectedEvent.school_name : '-'}                                  
							</div>
						</div>
					</div> 
					: ''
				}
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Event Title
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							{this.state.selectedEvent && this.state.selectedEvent.event_title ? this.state.selectedEvent.event_title : '-'}                                  
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Description
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							{this.state.selectedEvent && this.state.selectedEvent.description ? this.state.selectedEvent.description : '-'}                                  
						</div>
					</div>
				</div>
                <div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Venue
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							{this.state.selectedEvent && this.state.selectedEvent.venue ? this.state.selectedEvent.venue : '-'}                                  
						</div>
					</div>
				</div>
                <div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Coordinator
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							{this.state.selectedEvent && this.state.selectedEvent.coordinator_name ? this.state.selectedEvent.coordinator_name : '-'}                                  
						</div>
					</div>
				</div>
                <div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Date And Time
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							{this.state.selectedEvent && this.state.selectedEvent.date ? moment(this.state.selectedEvent.date).format('DD MMM YYYY HH:MM:SS') : '-'}                                  
						</div>
					</div>
				</div>
                <div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
                        Coordinator Contact No
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							{this.state.selectedEvent && this.state.selectedEvent.coordinator_number ? this.state.selectedEvent.coordinator_number : '-'}                                  
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Uploaded Files
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="">
							{this.state.selectedEvent && this.state.selectedEvent.file_url ? 
							 <a href={this.state.selectedEvent.file_url} target="_blank">{this.state.selectedEvent.file_url}</a> 
							: '-'}                                  
						</div>
					</div>
				</div>
                <div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							Uploaded Image
						</div>
					</div>
					<div className="col-lg-2 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							:
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-6 inputField">
						<div className="input-group">
							<img src={this.state.selectedEvent && this.state.selectedEvent.image_url ? this.state.selectedEvent.image_url : ''} alt="imageurl" />
						</div>
					</div>
				</div>
			</div>		
		)
	}
	clearSearch=()=>{
		this.setState({
			school_name:'',
            event_title:'',
            coordinator_name:'',
            date:'',
            venue:'',
            createdAt:'',
            status:'',
		})
		this.props.getEventsAction()
	}
	componentWillUnmount(){
		this.props.clearGetEventsAction()
		this.props.clearGetEventDropdownAction()
	}
	handleSearch=()=>{
		let search = {
            school_id:this.state.school_name,
            event_title:this.state.event_title,
            coordinator_name:this.state.coordinator_name,
            date: this.state.date ? moment(this.state.date).format('YYYY-MM-DD HH:MM') : '',
            venue:this.state.venue,
            created_at: this.state.createdAt ? moment(this.state.createdAt).format('YYYY-MM-DD') : '',
            status:this.state.status
		}
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		this.props.getEventsAction(search)
	}
    handleDate =(value)=>{
		this.setState({ date:value});
    }
    handleCreatedAt =(value)=>{
		this.setState({ createdAt : value});
    }
	render() {


		const { activeTab } = this.state
		// if(!isLogined()) this.props.history.push('/')
		return (
			<>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage Events</h1>
								<ol className="breadcrumb page-breadcrumb">
									<li className="breadcrumb-item">Search</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											{this.state.role === 'institute admin' ? 
												<div className="col-lg-3 col-md-2 col-sm-12">
													<select className="form-control show-tick" value={ this.state.school_name} onChange={ this.handleChange } name="school_name">
														<option value="">Select School</option>
														{this.state.school.map((item)=>{
															return <option value={item.id}>{item.school_name}</option>
														})}
													</select>
												</div> : ''
											}
                                            <div className="col-lg-3 col-md-4 col-sm-6">
												<div className="input-group">
													<input type="text" className="form-control" placeholder="Event title" value={ this.state.event_title} onChange={ this.handleChange } name="event_title"/>
												</div>
											</div>
                                            <div className="col-lg-3 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.coordinator_name} onChange={ this.handleChange } name="coordinator_name">
                                                    <option value="">Select Coordinator</option>
													{this.state.dropdownData && this.state.dropdownData.coordinator.map((item,i)=>{
														return(
															<option value={item}>{item}</option>
														)
													})}
                                                </select>
                                            </div>
                                            <div className="col-lg-3 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.venue} onChange={ this.handleChange } name="venue">
                                                    <option value="">Select Venue</option>
													{this.state.dropdownData && this.state.dropdownData.venue.map((item,i)=>{
														return(
															<option value={item}>{item}</option>
														)
													})}
                                                </select>
                                            </div>
                                            <div  className={`col-lg-3 md-3 col-sm-12 ${
                    !this.state.isLoggedInRole ? "mt-10" : ""
                  }`}>
                                                <div className="input-group">
												<DateTimePicker
                  value={this.state.date}
				  onChange={(date) => this.handleDate(date)}
                  placeholderText="Event date and Time"
                  className="form-control "
                />
                                                </div>
                                            </div>
											<div className="col-lg-3 col-md-2 col-sm-12 mt-10">
                                                <div className="input-group">
												<DatePicker
																selected={this.state.createdAt}
																onChange={this.handleCreatedAt }
																name="createdAt"
																placeholderText="created Date"
																className="form-control"
															/>
                                                </div>
                                            </div>
											<div className="col-lg-3 col-md-2 col-sm-12 mt-10">
                                                <div className="input-group">
												<select className="form-control show-tick" value={ this.state.status} onChange={ this.handleChange } name="status">
															<option value="">Select Status</option>
															<option value="0">Draft</option>
															<option value="1">Sent</option>
														</select>
                                                </div>
                                            </div>
										</div>
										<div className="btn-container">
													<div>
														<MDBBtn color="primary" className='btn' onClick={this.handleSearch}>Search</MDBBtn> 
														<MDBBtn color="primary" className='btn' onClick={this.clearSearch}>Clear</MDBBtn>
													</div>
																<div>
																	<MDBBtn color="primary" className='btn' onClick={()=>{
																		this.setState({openCreateModal : true,editId:''})
																	}}>Create</MDBBtn>
																</div>
                                        </div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
									<h1 className="page-title">Events List</h1>
									{this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={['title']}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching = {false}
                />
              </div>
            )}
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>
					{this.state.openCreateModal ?  <CreateEvents editId={ this.state.editId } toggleClose ={ this.toggleClose } getEvents={ this.getEvents }/> : ''}
					{this.state.modal &&
					<MDBContainer>
						<MDBModal isOpen={this.state.modal} toggle={this.toggle}>
							<MDBModalHeader toggle={this.toggleClose}>View Event</MDBModalHeader>
								<MDBModalBody>
									<div className="card">
										{this.getEventDetail()}
									</div>
								</MDBModalBody>
								<MDBModalFooter>
									<MDBBtn color="secondary" onClick={this.toggleClose}>Close</MDBBtn>
								</MDBModalFooter>
						</MDBModal>
					</MDBContainer>}
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	events: state.getEventsReducer,
	dropdownData:state.getEventDropdownReducer,
	role: state.settings.role,
    schoolState: state.schoolReducer

})

const mapDispatchToProps = dispatch => ({
	getEventsAction: (e) => dispatch(getEventsAction(e)),
	clearGetEventsAction: (e) => dispatch(clearGetEventsAction(e)),
	getEventsDropdownAction: (e) => dispatch(getEventsDropdownAction()),
	clearGetEventDropdownAction: (e) => dispatch(clearGetEventDropdownAction()),
	getSchoolAction: (e) => dispatch(getSchoolAction(e)),

})
export default connect(mapStateToProps, mapDispatchToProps)(ManageEvents)
