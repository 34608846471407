import { GET_GALLERY, 
    CREATE_GALLERY,
    EDIT_GALLERY, 
    GET_GALLERY_BY_ID, 
    SHOW_SNACKBAR,
    SEARCH_GALLERY,UPLOAD_GALLERY,DELETE_GALLERY,GALLERY_DROPDOWN,DELETE_ALL_GALLERY} from '../../Constants/index';
    
import axios from 'axios';
import { manageGallery } from '../../../utils/Api'
import { getToken } from '../../../utils/token'

export const getGalleryAction = (params) => {
    let params2= localStorage.getItem("school") ?{
        ...params,
        school_id:JSON.parse(localStorage.getItem("school")).id
      }
     : params 
	return dispatch => {
        const url =`${manageGallery.searchGallery}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params2
        };

        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_GALLERY,
                    payload: {
                        list : data
                    }
                })
                return data.DATA
            }else {
                dispatch({
                    type: GET_GALLERY,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearGetGAlleryAction = () => {
	return dispatch => {
        dispatch({
            type: GET_GALLERY,
            payload: {
                list : []
            }
        })
    }
}

export const createGAlleryAction = (data) => {
	return dispatch => {
        const url = `${manageGallery.createGallery}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: CREATE_GALLERY,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
            }else {
                dispatch({
                    type: CREATE_GALLERY,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const clearCreateGalleryAction = () => {
	return dispatch => {
        dispatch({
            type: CREATE_GALLERY,
            payload: {
                list : null
            }
        })
    }
}

export const clearEditGAlleryAction = () => {
	return dispatch => {
        dispatch({
            type: EDIT_GALLERY,
            payload: {
                list : null
            }
        })
    }
}

export const editGalleryAction = ({id,data}) => {
	return dispatch => {
        const url = `${manageGallery.editGallery(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PATCH',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: EDIT_GALLERY,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
            }else {
                dispatch({
                    type: EDIT_GALLERY,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const getGalleryByIdAction = (id) => {
	return dispatch => {
        const url = `${manageGallery.getGalleryById(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_GALLERY_BY_ID,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_GALLERY_BY_ID,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearGalleryByIdAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_GALLERY_BY_ID,
            payload: {
                list : {}
            }
        })
    }
}

export const searchGalleryAction = (params) => {
	return dispatch => {
        const url = `${manageGallery.searchGallery}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: SEARCH_GALLERY,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: SEARCH_GALLERY,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}


export const uploadGalleryFileAction = (data) => {
	return dispatch => {
        let formData = new FormData();
        formData.append('file', data);
        const url = `${manageGallery.uploadGalleryFile}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data : formData
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: UPLOAD_GALLERY,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: UPLOAD_GALLERY,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearUploadGalleryction = (id) => {
	return dispatch => {
        dispatch({
            type: UPLOAD_GALLERY,
            payload: {
                list : {}
            }
        })
    }
}

export const deleteGalleryAction = (data) => {
	return dispatch => {
        const url = `${manageGallery.deleteGallery}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'DELETE',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: DELETE_GALLERY,
                    payload: {
                        status : flag.status
                    }
                })
            }else {
                dispatch({
                    type: DELETE_GALLERY,
                    payload: {
                        status : {}
                    }
                })
            }
        })
    }
}

export const getGalleryDropdownAction = () => {
	return dispatch => {
        const url = `${manageGallery.dropDownData}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GALLERY_DROPDOWN,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GALLERY_DROPDOWN,
                    payload: {
                        list : null
                    }
                })
            }
        })
    }
}

export const clearGetGalleryDropdownAction = () => {
	return dispatch => {
        dispatch({
            type: GALLERY_DROPDOWN,
            payload: {
                list : null
            }
        })
    }
}

export const deletAlleGalleryAction = () => {
	return dispatch => {
        const url = `${manageGallery.deleteAllGallery}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'DELETE',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: DELETE_ALL_GALLERY,
                    payload: {
                        status : flag.status
                    }
                })
            }else {
                dispatch({
                    type: DELETE_ALL_GALLERY,
                    payload: {
                        status : {}
                    }
                })
            }
        })
    }
}
