import { GET_ACHIEVEMENT, 
    CREATE_ACHIEVEMENT,
    EDIT_ACHIEVEMENT, 
    GET_ACHIEVEMENT_BY_ID,GET_ACHIEVEMENT_BADGE_URL,SHOW_SNACKBAR} from '../../../Constants/index';
import axios from 'axios';
import { manageAchievement } from '../../../../utils/Api'
import { getToken } from '../../../../utils/token'

export const getAchievementAction = (params) => {
	return dispatch => {
        const url = `${manageAchievement.getAchievement}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_ACHIEVEMENT,
                    payload: {
                        list : data.data
                    }
                })
            }else {
                dispatch({
                    type: GET_ACHIEVEMENT,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}

export const clearGetAchievementAction = () => {
	return dispatch => {
        dispatch({
            type: GET_ACHIEVEMENT,
            payload: {
                list : []
            }
        })
    }
}

export const createAchievementAction = (data, history) => {
	return dispatch => {
        const url = `${manageAchievement.createAchievement}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'POST',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: CREATE_ACHIEVEMENT,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                 history.push("/teacher/manage-achievement-rule");
            }else {
                dispatch({
                    type: CREATE_ACHIEVEMENT,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const clearCreateAchievementAction = () => {
	return dispatch => {
        dispatch({
            type: CREATE_ACHIEVEMENT,
            payload: {
                list : null
            }
        })
    }
}

export const clearEditAchievementAction = () => {
	return dispatch => {
        dispatch({
            type: EDIT_ACHIEVEMENT,
            payload: {
                list : null
            }
        })
    }
}

export const editAchievementAction = ({id,data, history}) => {
	return dispatch => {
        const url = `${manageAchievement.editAchievement(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'PATCH',
            data
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                dispatch({
                    type: EDIT_ACHIEVEMENT,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "success", message: data.SUCESS.status.msg, open: true },
                  });
                 history.push("/teacher/manage-achievement-rule");
            }else {
                dispatch({
                    type: EDIT_ACHIEVEMENT,
                    payload: {
                        list : flag.status
                    }
                })
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: { type: "error", message: data.ERROR.status.msg, open: true },
                  });
            }
        })
    }
}

export const getAchievementByIdAction = (id) => {
	return dispatch => {
        const url = `${manageAchievement.getAchievementById(id)}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_ACHIEVEMENT_BY_ID,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_ACHIEVEMENT_BY_ID,
                    payload: {
                        list : {}
                    }
                })
            }
        })
    }
}

export const clearAchievementByIdAction = (id) => {
	return dispatch => {
        dispatch({
            type: GET_ACHIEVEMENT_BY_ID,
            payload: {
                list : {}
            }
        })
    }
}
export const getAchievementBadgeURLAction = (params) => {
	return dispatch => {
        const url = `${manageAchievement.getAchievementBadgeURL}`,    
        options = {
            url,
            headers:{
                Authorization : getToken(),
            },
            method: 'GET',
            params: params
        };
        return axios(options)
        .then(response => {
            let data = response && response.data ? response.data : false;
            const flag = data.SUCESS || data.ERROR
            if (data && (flag.status.errcode === 0)) {
                data = data.DATA
                dispatch({
                    type: GET_ACHIEVEMENT_BADGE_URL,
                    payload: {
                        list : data
                    }
                })
            }else {
                dispatch({
                    type: GET_ACHIEVEMENT_BADGE_URL,
                    payload: {
                        list : []
                    }
                })
            }
        })
    }
}
