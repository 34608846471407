import { GET_TASK, SHOW_SNACKBAR } from "../../../Constants/index";
import axios from "axios";
import { manageTask } from "../../../../utils/Api";
import { getToken } from "../../../../utils/token";

export const getAllTaskAction = () => {
  return (dispatch) => {
    const url = `${manageTask.manageTask}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_TASK,
          payload: data.DATA,
        });
      } else {
        dispatch({
          type: GET_TASK,
          payload: [],
        });
      }
    });
  };
};

export const createTaskAction = (data, history) => {
  return (dispatch) => {
    const url = `${manageTask.createTask}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "POST",
        data,
      };
      return axios(options).then((response) => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "success", message: data.SUCESS.status.msg, open: true },
          });
         history.push("/institute/manage-class");
        } else {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "error", message: data.ERROR.statusCode.msg, open: true },
          });
        }
        return data;
      });
  };
};

export const getTaskEditByIdAction = (id) => {
  return (dispatch) => {
    const url = `${manageTask.getEditTaskById(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        return data.DATA;
      }
      return {};
    });
  };
};

export const editTaskAction = ({ id, data, history }) => {
  return (dispatch) => {
    const url = `${manageTask.EditTaskById(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "PATCH",
        data,
      };
      return axios(options).then((response) => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "success", message: data.SUCESS.status.msg, open: true },
          });
         history.push("/institute/manage-class");
        } else {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "error", message:data.message, open: true },
          });
        }
        return data;
      })
  };
};

export const searchTaskAction = (params) => {
  return (dispatch) => {
    const url = `${manageTask.searchTask}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params,
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_TASK,
          payload: data.DATA,
        });
      } else {
        dispatch({
          type: GET_TASK,
          payload: [],
        });
      }
    });
  };
};

export const deleteTaskAction = (id) => {
  return () => {
    const url = `${manageTask.deleteTask(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "DELETE",
      };
    return axios(options);
  };
};

export const getTaskCalenderAction = () => {
  return (dispatch) => {
    const url = `${manageTask.manageTaskCalendar}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
      };
      return axios(options).then((response) => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          dispatch({
            type: GET_TASK,
            payload: data.DATA,
          });
        } else {
          dispatch({
            type: GET_TASK,
            payload: [],
          });
        }
      });
    };
  };