import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TabContent, TabPane, } from "reactstrap";
import classnames from 'classnames';
import { MDBFormInline, MDBInput, MDBBtn, MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { isLogined } from '../../../utils/token'
import './style.scss';
import {getPrincipalByIdAction,clearEditPrincipalAction, clearPrincipalByIdAction, createPrincipalAction,editPrincipalAction, clearCreatePrincipalAction,getPrincipalSchoolAction} from '../../../actions/institute/ManagePrincipal/managePrincipalAction'
import {getStateAction,getCitiesAction,getSchoolAction} from '../../../actions/institute/Utils/utilsAction'
class CreatePrincipal extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
            input:{
                school_id:'',
                principal_name:'',
                email:'',
                mobile:'',
                address_one:'',
                address_two:'',
                city_id:'',
                state_id:'',
                zip:'',
                username:'',
                password:'',
                status:'',
            },
            modal: false,
            editId: this.props.match.params.id,
            uploadImageUrl: null,
            principal: null,
            stateList:[],
            cityList:[],
            schoolList:[]
		};
	}

    componentDidMount(){
        this.props.getStateAction()
        // this.props.getCitiesAction()
        if(this.props.match.params.id){
            this.props.getSchoolAction()
            this.props.getPrincipalByIdAction(this.props.match.params.id)
        }else{
            this.props.getPrincipalSchoolAction()
        }
	}

    componentDidUpdate(prevProp, prevState) {
        if (prevState.input.state_id !== this.state.input.state_id) {
          if (this.state.input.state_id) {
            this.props
              .getCitiesAction(this.state.input.state_id)
          }
        }
    }

    componentWillReceiveProps(nextProps){

        if(this.props.input !== nextProps.input){
            this.setState({
                input : nextProps.input
            },()=>{
                let input = {...this.state.input};
                if(Object.keys(input).length >0){
                    input['school_id'] = this.state.input.school_id
                    input['state_id'] = this.state.input.state_id
                    input['city_id'] = this.state.input.city_id
                    delete input['school']
                    delete input['state']
                    delete input['city']
                    delete input['institute']
                    delete input['created_at']
                    delete input['updatedAt']
                    this.setState({
                        input : input
                    })
                }
            })
        }

        if(this.state.stateList !== nextProps.stateList){
            this.setState({
                stateList: nextProps.stateList
            })
        }

        if(this.state.cityList !== nextProps.cityList){
            this.setState({
                cityList: nextProps.cityList
            })
        }

        if(this.state.schoolList !== nextProps.schoolList){
            this.setState({
                schoolList: this.state.editId ? nextProps.schoolList : nextProps.principalSchoolList
            })
        }
	}
    componentWillUnmount(){
        this.clearData()   
    }
    clearData =()=>{
        this.props.clearEditPrincipalAction()
        this.props.clearPrincipalByIdAction()
        this.props.clearCreatePrincipalAction()
        this.toggleClose()
        this.props.history.push('/institute/manage-principal')
    }

    toggleClose = ()=>{
		this.setState({
			modal: false,
		})
	}

    changeHandler = event => {
        let input = {...this.state.input}
        input[event.target.name] = event.target.value
		this.setState({ input:input });
	};

    handleStatus = (event)=>{
        let input = {...this.state.input}
        let value = event.target.value === "1" ? 1 : event.target.value === "0" ? 0 : null
        input[event.target.name] = value
		this.setState({ input:input });
    }

    submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
        if(event.target.checkValidity()) {
            this.setState({
                modal: true,
            })
		}
	}

    handleSave =()=>{
        this.setState({
            input:this.state.input
        },()=>{
            if(this.state.editId){
                const obj = {
                    "school_id": this.state.input.school_id,
                    "principal_name": this.state.input.principal_name,
                    "email": this.state.input.email,
                    "mobile": this.state.input.mobile,
                    "address_one": this.state.input.address_one,
                    "address_two": this.state.input.address_two,
                    "city_id": this.state.input.city_id,
                    "state_id": this.state.input.state_id,
                    "zip": this.state.input.zip,
                    "username": this.state.input.username,
                    "password": this.state.input.password,
                    "status": this.state.input.status
                }
                this.props.editPrincipalAction({id : this.state.input.id,data:obj,history:this.props.history})
            }else{
                this.props.createPrincipalAction(this.state.input,this.props.history)
            }
        })
    }

    inputFieldContainer =()=> {
        const { activeTab } = this.state
        return(
            <TabContent activeTab={activeTab}>
                <TabPane tabId={1} className={classnames(['fade show'])}>
                    <form  onSubmit={this.submitHandler} noValidate>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    School
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <select name='school_id' disabled ={this.state.editId ? true : false} 
                                                    value={this.state.input['school_id']} className="form-control show-tick" 
                                                    onChange={this.changeHandler} required>
                                                        <option value="">Select school</option>
                                                        {this.state.schoolList.map((item,i)=>{
                                                            return (<option value={item.id}>{item.name}</option>)
                                                        })}
                                                    </select>
                                                    <div className="invalid-feedback">
                                                        required School name.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Name
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <input value = {this.state.input['principal_name']} name ={'principal_name'} onChange={this.changeHandler} type="text" className="form-control" placeholder="Name" required />
                                                    <div className="invalid-feedback">
                                                        required Name.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Email
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                <input  value = {this.state.input['email']} name ={'email'} type="email"  className="form-control" placeholder="Email" onChange={this.changeHandler} required />
                                                    <div className="invalid-feedback">
                                                        required valid email.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Mobile
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <input value = {this.state.input['mobile']} name ={'mobile'} onChange={this.changeHandler} type="text" className="form-control" placeholder="Mobile" required />
                                                    <div className="invalid-feedback">
                                                        required Mobile.
                                                    </div>
                                                </div>
                                            </div>
                                             <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Address 1
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <input value = {this.state.input['address_one']} name ={'address_one'} onChange={this.changeHandler} type="text" className="form-control" placeholder=" Address 1" required />
                                                    <div className="invalid-feedback">
                                                        required Address 1.
                                                    </div>
                                                </div>
                                            </div>
                                             <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                Address 2
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <input  value = {this.state.input['address_two']} name ={'address_two'} onChange={this.changeHandler} type="text" className="form-control" placeholder=" Address 2"  />
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    State
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <select name={'state_id'} value={this.state.input['state_id']} className="form-control show-tick" onChange={this.changeHandler} required>
                                                    <option value="">Select State</option>
                                                    {this.state.stateList.map((item,i)=>{
                                                        return (<option value={item.id}>{item.name}</option>)
                                                    })}
                                                </select>
                                                <div className="invalid-feedback">
                                                        required State.
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    City
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <select name={'city_id'} disabled={!this.state.input.state_id} value={this.state.input['city_id']} className="form-control show-tick" onChange={this.changeHandler} required>
                                                        <option value="">Select City</option>
                                                        {this.state.cityList.map((item,i)=>{
                                                            return (<option value={item.id}>{item.name}</option>)
                                                        })}
                                                    </select>
                                                    <div className="invalid-feedback">
                                                        required City.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Zip
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <input value = {this.state.input['zip']} name ={'zip'} type="text" className="form-control" onChange={this.changeHandler} placeholder="Zip" required />
                                                    <div className="invalid-feedback">
                                                        required Zip.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    User name
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <input value = {this.state.input['username']} name ={'username'} type="text" className="form-control"  onChange={this.changeHandler}  placeholder="User name" required />
                                                    <div className="invalid-feedback">
                                                        required User name.
                                                    </div>
                                                </div>
                                            </div>
                                            {!this.state.editId &&(
                                                <>
                                                <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Password
                                                </div>
                                                </div>
                                                <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                    <div className="input-group">
                                                        :
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                    <div className="input-group">
                                                        <div className="input-group">
                                                            <input value = {this.state.input['password']} name ={'password'} onChange={this.changeHandler} type="text" className="form-control" placeholder="Password" required />
                                                            <div className="invalid-feedback">
                                                                required Password.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                            )}
                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Status
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    <MDBFormInline>
                                                        <MDBInput
                                                            value = {1}
                                                            checked= {this.state.input['status'] === 1}
                                                            name ={'status'}
                                                            onChange =  {this.handleStatus}
                                                            label='Active'
                                                            type='radio'
                                                            id='radio1'
                                                            containerClass='mr-5'
                                                            required
                                                        />
                                                        <MDBInput
                                                            checked= {this.state.input['status'] ===0}
                                                            value = {0}
                                                            name ={'status'}
                                                            onChange =  {this.handleStatus}
                                                            label='Inactive'
                                                            type='radio'
                                                            id='radio2'
                                                            containerClass='mr-5'
                                                            required
                                                        />
                                                        <div className="invalid-feedback">
                                                            required Status.
                                                        </div>
                                                    </MDBFormInline>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-4 col-sm-6 inputField submitBtn">
                                        <MDBBtn color="primary" className='btn' onClick= {this.clearData}>Cancel</MDBBtn>
                                        <MDBBtn color="primary" type={'submit'} className='btn'>{this.state.editId ? 'Update' :'Save'}</MDBBtn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </TabPane>
            </TabContent>
        )
    }

    modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={ this.state.modal } toggle={this.toggle}>
                    <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
                    <MDBModalBody>
                        <div className='alertContainer'>
                            <h4> Do you want to {this.state.editId ? 'Update' : 'save'} it ? </h4>
                            <h6> Please check the information before submitting</h6>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleClose}>No</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.handleSave}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
			</MDBContainer>
		)
	}
    
    render(){
        if(!isLogined()) this.props.history.push('/')
        return(
            <>
                <div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title"> {this.state.editId ? 'Update' : 'Add New'} User</h1>
							</div>
						</div>
					</div>
				</div>
                <div className="section-body mt-4">
					<div className="container-fluid">
                        {this.inputFieldContainer()}
				    </div>
                    <div>
                    {this.modalContainer()}
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    editStatus: state.managePrincipalReducer.editStatus,
    input :  state.managePrincipalReducer.getById,
    stateList :state.utillReducer.stateList,
    cityList: state.utillReducer.cityList,
    schoolList: state.utillReducer.schoolList,
    principalSchoolList: state.managePrincipalReducer.schoolList
})

const mapDispatchToProps = dispatch => ({
    getPrincipalByIdAction: (e) => dispatch(getPrincipalByIdAction(e)),
    clearEditPrincipalAction: (e) => dispatch(clearEditPrincipalAction(e)),
    clearPrincipalByIdAction: (e) => dispatch(clearPrincipalByIdAction(e)),
    createPrincipalAction: (e,history) => dispatch(createPrincipalAction(e,history)),
    editPrincipalAction: (e,history) => dispatch(editPrincipalAction(e,history)),
    getStateAction: (e) => dispatch(getStateAction(e)),
    getCitiesAction: (e) => dispatch(getCitiesAction(e)),
    clearCreatePrincipalAction: (e) => dispatch(clearCreatePrincipalAction(e)),
    getSchoolAction: (e) => dispatch(getSchoolAction(e)),
    getPrincipalSchoolAction: (e) => dispatch(getPrincipalSchoolAction(e))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreatePrincipal)
