import React, { Component } from "react";
import { connect } from "react-redux";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import moment from "moment";
import Dropzone from "../../common/DropzoneExample";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBContainer,
} from "mdbreact";
import {
  searchTeacherUserAction,
  searchStudentUserAction,
  searchParentUserAction,
  uploadTeacherUserAction,
  uploadStudentUserAction,
  uploadParentUserAction,
} from "../../../actions/institute/ManageUser/manageUserAction";
import { CSVLink } from "react-csv";
import Loader from "../../common/Loader";
import html2canvas from "html2canvas";
import {
  getAllSchoolAction,
  getClassAction,
  getSectionAction,
  getSubjectAction,
  getTeacherAction,
  getStudentAction,
  getParentAction,
} from "../../../actions/institute/Utils/utilsAction";
import jsPDF from "jspdf";
import "./style.scss";
import { Columns_Data } from "./utilsUser";
import { clearUploadPrincipalFileAction } from "../../../actions/institute/ManagePrincipal/managePrincipalAction";
class ManageUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      tableBody: { columns: [], rows: [] },
      viewModal: false,
      search: {},
      selectedData: {},
      uploadModal: false,
      dropDownDataClass: [],
      dropDownDataSection: [],
      dropDownDataSchool: [],
      dropDownDataSubject: [],
      dropDownDataTeacher: [],
      dropDownDataStudent: [],
      dropDownDataParent: [],
      sampleCsv: null,
      exportCsv: null,
      file: "",
      modal: false,
      userType: "Teacher",
      isLoggedInRole:
        localStorage.getItem("loginedRole") === "principal" ? true : false,
    };
  }

  componentDidMount() {
    this.props
      .getAllSchoolAction()
      .then((res) => this.setState({ dropDownDataSchool: res }));
    this.props.searchTeacherUserAction().then((res) => {
      this.constructTableData();
      this.exportSampleCsv();
      this.exportCsv();
    });
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevState.userType !== this.state.userType) {
      {
        this.state.userType === "Teacher" &&
          this.props.searchTeacherUserAction().then((res) => {
            this.constructTableData();
            this.exportSampleCsv();
            this.exportCsv();
          });
      }
      {
        this.state.userType === "Student" &&
          this.props.searchStudentUserAction().then((res) => {
            this.constructTableData();
            this.exportSampleCsv();
            this.exportCsv();
          });
      }
      {
        this.state.userType === "Parent" &&
          this.props.searchParentUserAction().then((res) => {
            this.constructTableData();
            this.exportSampleCsv();
            this.exportCsv();
          });
      }
    }
    if (prevState.search.school_id !== this.state.search.school_id) {
      if (this.state.search.school_id) {
        let search = {
          school_id: this.state.search.school_id,
        };
        this.props
          .getClassAction(search)
          .then((res) => this.setState({ dropDownDataClass: res }));
      }
    }
    if (prevState.search.class_id !== this.state.search.class_id) {
      if (this.state.search.class_id) {
        let search = {
          class_id: this.state.search.class_id,
        };
        this.props
          .getSectionAction(search)
          .then((res) => this.setState({ dropDownDataSection: res }));
      }
    }
    if (prevState.search.section_id !== this.state.search.section_id) {
      if (this.state.search.section_id) {
        let search = {
          section_id: this.state.search.section_id,
        };
        this.props
          .getSubjectAction(search)
          .then((res) => this.setState({ dropDownDataSubject: res }));
      }
    }
    if (
      this.state.userType === "Teacher" &&
      prevState.search.school_id !== this.state.search.school_id
    ) {
      if (this.state.search.school_id) {
        let search = {
          school_id: this.state.search.school_id,
        };
        this.props
          .getTeacherAction(search)
          .then((res) => this.setState({ dropDownDataTeacher: res }));
      }
    }
    if (
      this.state.userType !== "Teacher" &&
      prevState.search.section_id !== this.state.search.section_id
    ) {
      if (this.state.search.section_id) {
        let search = {
          section_id: this.state.search.section_id,
        };
        this.props
          .getStudentAction(search)
          .then((res) => this.setState({ dropDownDataStudent: res }));
      }
    }
    if (prevState.search.student_id !== this.state.search.student_id) {
      if (this.state.search.student_id) {
        let search = {
          student_id: this.state.search.student_id,
        };
        this.props
          .getParentAction(search)
          .then((res) => this.setState({ dropDownDataParent: res }));
      }
    }
    if (
      this.state.isLoggedInRole &&
      this.state.search.school_id === undefined
    ) {
      this.setState({
        search: {
          ...this.state.search,
          ["school_id"]: localStorage.getItem("school_id"),
        },
      });
    }
  }

  toggle = (item, institute, i) => () => {
    this.setState({
      viewModal: true,
      uploadModal: false,
      selectedData: item,
    });
  };

  toggleClose = () => {
    this.props.clearUploadPrincipalFileAction();
    this.setState({
      viewModal: false,
      uploadModal: false,
      file:''
    });
  };
 
  submitHandler = async (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (this.state.userType === "Teacher" && this.state.file) {
      await this.props.uploadTeacherUserAction(this.state.file);
      if (this.props.uploadFileStatus?.errcode === 0) {
        this.setState({
          uploadModal: false,
        });
        await this.props.clearUploadPrincipalFileAction();
        await this.props.searchTeacherUserAction().then((res) => {
          this.constructTableData();
        });
      }
    }
    if (this.state.userType === "Student" && this.state.file) {
      await this.props.uploadStudentUserAction(this.state.file);
      if (this.props.uploadFileStatus?.errcode === 0) {
        this.setState({
          uploadModal: false,
        });
        await this.props.clearUploadPrincipalFileAction();
        await this.props.searchStudentUserAction().then((res) => {
          this.constructTableData();
        });
      }
    }
    if (this.state.userType === "Parent" && this.state.file) {
      await this.props.uploadParentUserAction(this.state.file);
      if (this.props.uploadFileStatus?.errcode === 0) {
        this.setState({
          uploadModal: false,
        });
        await this.props.clearUploadPrincipalFileAction();
        await this.props.searchParentUserAction().then((res) => {
          this.constructTableData();
        });
      }
    }
  };

  exportPdf = () => {
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0, 200, 0);
      pdf.save("UserList.pdf");
    });
  };

  exportSampleCsv = () => {
    const exportCsvHeaders = [
      { label: "school_name", key: "school_name" },
      { label: "name", key: "name" },
      { label: "email", key: "email" },
      { label: "mobile", key: "mobile" },
      { label: "class", key: "class" },
      { label: "section", key: "section" },
      { label: "address_one", key: "address_one" },
      { label: "address_two", key: "address_two" },
      { label: "city", key: "city" },
      { label: "state", key: "state" },
      { label: "zip", key: "zip" },
      { label: "username", key: "username" },
      { label: "password", key: "password" },
      { label: "status", key: "status" },
    ];

    if (this.state.userType === "Teacher") {
      exportCsvHeaders.splice(6, 0, { label: "subject", key: "subject" });
    }
    if (this.state.isLoggedInRole && this.state.userType === "Teacher") {
      exportCsvHeaders.shift();
    }
    if (this.state.isLoggedInRole && this.state.userType === "Student") {
      exportCsvHeaders.shift();
    }
    if (this.state.userType === "Parent") {
      exportCsvHeaders.splice(4, 0, {
        label: "student_email",
        key: "student_email",
      });
    }
    if (this.state.isLoggedInRole && this.state.userType === "Parent") {
      exportCsvHeaders.shift();
    }
    const exportCSVData = [
      {
        school_name: "schoolname",
        name:
          this.state.userType === "Teacher"
            ? "TeacherName"
            : this.state.userType === "Student"
            ? "StudentName"
            : this.state.userType === "Parent"
            ? "ParentName"
            : "",
        email: "email@gmail.com",
        mobile: "97853324324",
        class: "classname",
        section: "sectionname",
        subject: "subjectname",
        address_one: "Address1",
        address_two: "Address2",
        city: "city name",
        student_email: "studentemail@gmail.com",
        state: "state name",
        zip: "452009",
        username: "user@123",
        password: "userpassword@123",
        status: "1(Active) OR 0(Inactive)",
      },
    ];

    let csv = {
      data: exportCSVData,
      headers: exportCsvHeaders,
      filename:
        this.state.userType === "Teacher"
          ? "teacherData.csv"
          : this.state.userType === "Student"
          ? "studentData.csv"
          : this.state.userType === "Parent"
          ? "parentData.csv"
          : null,
      style: { color: "white" },
    };
    this.setState({
      sampleCsv: csv,
    });
  };

  exportCsv = () => {
    const exportCsvHeaders = [
      { label: "School Name", key: "school_name" },
      { label: "Class Name", key: "class" },
      { label: "Section Name", key: "section_name" },
      { label: "Username", key: "username" },
      { label: "Created Date", key: "created_at" },
      { label: "Status", key: "status" },
    ];
    if (this.state.userType === "Teacher") {
      exportCsvHeaders.splice(3, 0, {
        label: "Subject Name",
        key: "subject_name",
      });
    }
    if (this.state.userType === "Parent") {
      exportCsvHeaders.splice(3, 0, {
        label: "Student Name",
        key: "student_name",
      });
    }
    if (this.state.isLoggedInRole) {
      exportCsvHeaders.shift();
    }
    const exportCSVData = this.props.userList.map((item) => {
      let obj = {
        school_name: item.school_name,
        class: item.class_name,
        section_name: item.section_name,
        subject_name: item.subject_name,
        username: item.name,
        student_name: item.student_name,
        created_at: item.created_at,
        status: item.status === 1 ? "Active" : "Inactive",
      };
      return obj;
    });
    let csv = {
      data: exportCSVData,
      headers: exportCsvHeaders,
      filename: "manage-userType-report.csv",
      style: { color: "white" },
    };
    this.setState({
      exportCsv: csv,
    });
  };

  handleSearch = (name, value) => {
    this.setState({ search: { ...this.state.search, [name]: value } });
  };

  handleFile=(event)=>{
    this.setState({file : event.target.files[0]})
}

  toggleSaveUploadClose = () => {
    this.setState({
      uploadModal: true,
      modal: false,
      file:''
    });
  };

  searchHandler = async () => {
    let search = {
      school_id: this.state.search.school_id,
      class_id: this.state.search.class_id,
      section_id: this.state.search.section_id,
      subject_id: this.state.search.subject_id,
      username: this.state.search.username,
      status: this.state.search.status,
      teacher_id: this.state.search.teacher_id,
      student_id: this.state.search.student_id,
      parent_id: this.state.search.parent_id,
      created_at: this.state.search.created_at
        ? moment(this.state.search.created_at).format("YYYY-MM-DD")
        : null,
    };
    Object.keys(search).forEach(
      (key) => search[key] == "" && delete search[key]
    );
    if (this.state.userType === "Teacher") {
      await this.props.searchTeacherUserAction(search);
      this.constructTableData();
    }
    if (this.state.userType === "Student") {
      await this.props.searchStudentUserAction(search);
      this.constructTableData();
    }
    if (this.state.userType === "Parent") {
      await this.props.searchParentUserAction(search);
      this.constructTableData();
    }
  };

  handleUserButtonClick = (type) => {
    this.setState({ userType: type }, this.constructTableData);
  };

  clearHandler = async () => {
    this.setState({
      search: {},
      dropDownDataSchool: [],
      dropDownDataStudent: [],
      dropDownDataTeacher: [],
      dropDownDataParent: [],
    });
    {
      this.state.userType === "Teacher" &&
        (await this.props.searchTeacherUserAction().then((res) => {
          this.constructTableData();
        }));
    }
    {
      this.state.userType === "Student" &&
        (await this.props.searchStudentUserAction().then((res) => {
          this.constructTableData();
        }));
    }
    {
      this.state.userType === "Parent" &&
        (await this.props.searchParentUserAction().then((res) => {
          this.constructTableData();
        }));
    }
    await this.props
      .getAllSchoolAction()
      .then((res) => this.setState({ dropDownDataSchool: res }));
  };

  constructTableData = () => {
    let tableData = {
      columns: Columns_Data[this.state.userType].filter((ele) => ele),

      rows: this.actionContainer(),
    };

    this.setState({
      tableBody: tableData,
    });
  };

  actionContainer = () => {
    return this.props.userList.map((item, i) => {
      let obj = {
        id: i + 1,
        school_name: item.school_name,
        class: item.class_name,
        section_name: item.section_name,
        subject_name: item.subject_name,
        username: item.name,
        created_at: item.created_at,
        status: item.status === 1 ? "Active" : "Inactive",
        studentname: item.student_name,
        action: (
          <div className="actionContainer">
            <MDBIcon
              icon="edit"
              size="lg"
              className="icon"
              onClick={() => {
                this.props.history.push(
                  `/institute/edit-user/${item.id}/${this.state.userType}`
                );
              }}
            />
            <MDBIcon
              icon="eye"
              size="lg"
              className="icon"
              onClick={this.toggle(item, i)}
            />
          </div>
        ),
      };
      return obj;
    });
  };

  viewModalDetails = (item) => {
    return (
      <>
        <div className="faq-modal-container">
          <div className="faq-modal-sub-container">
            <div>
              <h6 className="font-weight-bold">UserType</h6>
              <h6>{item.user_type}</h6>
            </div>
            <div>
              <h6 className="font-weight-bold">Email</h6>
              <h6>{item.email}</h6>
            </div>
            <div>
              <h6 className="font-weight-bold">Class</h6>
              <h6>{item.class_name}</h6>
            </div>
            {this.state.userType === "Teacher" && (
              <div>
                <h6 className="font-weight-bold">Subject</h6>
                <h6>{item.subject_name}</h6>
              </div>
            )}
            {this.state.userType === "Parent" && (
              <div>
                <h6 className="font-weight-bold">Student Name</h6>
                <h6>{item.student_name}</h6>
              </div>
            )}
            <div>
              <h6 className="font-weight-bold">Address 1</h6>
              <h6>{item.address_one}</h6>
            </div>
            <div>
              <h6 className="font-weight-bold">City</h6>
              <h6>{item.city_name}</h6>
            </div>
            <div>
              <h6 className="font-weight-bold">UserName</h6>
              <h6>{item.username}</h6>
            </div>
            <div>
              <h6 className="font-weight-bold">Status</h6>
              <h6>{item.status === 1 ? "Active" : "Inactive"}</h6>
            </div>
          </div>
          <div className="faq-modal-sub-container p-l-30">
            {!this.state.isLoggedInRole && (
              <div>
                <h6 className="font-weight-bold">School</h6>
                <h6>{item.school_name}</h6>
              </div>
            )}
            <div>
              <h6 className="font-weight-bold">Name</h6>
              <h6>{item.name}</h6>
            </div>
            <div>
              <h6 className="font-weight-bold">Mobile</h6>
              <h6>{item.mobile}</h6>
            </div>
            <div>
              <h6 className="font-weight-bold">Section</h6>
              <h6>{item.section_name}</h6>
            </div>
            <div>
              <h6 className="font-weight-bold">Address 2 </h6>
              <h6>{item.address_two}</h6>
            </div>
            <div>
              <h6 className="font-weight-bold">State</h6>
              <h6>{item.state_name}</h6>
            </div>
            <div>
              <h6 className="font-weight-bold">Zip</h6>
              <h6>{item.zip}</h6>
            </div>
          </div>
        </div>
      </>
    );
  };

  searchContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              {!this.state.isLoggedInRole && (
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="input-group">
                    <select
                      value={
                        Object.keys(this.state.search).length === 0
                          ? ""
                          : this.state.search.school_id
                      }
                      onChange={(e) =>
                        this.handleSearch("school_id", e.target.value)
                      }
                      className="form-control show-tick"
                    >
                      <option value="">School Name</option>
                      {this.state.dropDownDataSchool.map((ele) => (
                        <option value={ele.id}>{ele.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <div className="col-lg-2 col-md-4 col-sm-6">
                <div className="input-group">
                  <select
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search.class_id
                    }
                    disabled={!this.state.search.school_id}
                    onChange={(e) =>
                      this.handleSearch("class_id", e.target.value)
                    }
                    className="form-control show-tick"
                  >
                    <option value="">Select Class</option>
                    {this.state.dropDownDataClass.map((ele) => (
                      <option value={ele.id}>{ele.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 ">
                <div className="input-group">
                  <select
                    value={
                      Object.keys(this.state.search).length === 0
                        ? ""
                        : this.state.search.section_id
                    }
                    onChange={(e) =>
                      this.handleSearch("section_id", e.target.value)
                    }
                    disabled={!this.state.search.class_id}
                    className="form-control show-tick"
                  >
                    <option value="">Section</option>
                    {this.state.dropDownDataSection.map((ele) => (
                      <option value={ele.id}>{ele.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              {this.state.userType === "Teacher" && (
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <div className="input-group">
                    <select
                      value={
                        Object.keys(this.state.search).length === 0
                          ? ""
                          : this.state.search.subject_id
                      }
                      onChange={(e) =>
                        this.handleSearch("subject_id", e.target.value)
                      }
                      disabled={!this.state.search.section_id}
                      className="form-control show-tick"
                    >
                      <option value="">Subject</option>
                      {this.state.dropDownDataSubject.map((ele) => (
                        <option value={ele.id}>{ele.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <div
                className={` md-3 col-sm-12 ${
                  this.state.userType === "Teacher" ? "col-lg-3" : "col-lg-2"
                }`}
              >
                <select
                  value={
                    Object.keys(this.state.search).length === 0
                      ? ""
                      : this.state.search.status
                  }
                  onChange={(e) => this.handleSearch("status", e.target.value)}
                  className="form-control show-tick"
                >
                  <option value="">Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
              <div
                className={`col-lg-3 md-3 col-sm-12 ${
                  !this.state.isLoggedInRole &&
                  this.state.userType === "Teacher"
                    ? "mt-10"
                    : ""
                }`}
              >
                <DatePicker
                  selected={this.state.search.created_at}
                  onChange={(value) => this.handleSearch("created_at", value)}
                  placeholderText="Created date"
                  className="form-control "
                />
              </div>
              {this.state.userType === "Teacher" && (
                <div className="col-lg-3 col-md-4 col-sm-6 mt-10">
                  <div className="input-group">
                    <select
                      value={this.state.search.teacher_id}
                      onChange={(e) =>
                        this.handleSearch("teacher_id", e.target.value)
                      }
                      disabled={!this.state.search.school_id}
                      className="form-control show-tick"
                    >
                      <option value="">Teacher Name</option>
                      {this.state.dropDownDataTeacher.map((ele) => (
                        <option value={ele.id}>{ele.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              {this.state.userType !== "Teacher" && (
                <div
                  className={`col-lg-3 md-3 col-sm-12 ${
                    !this.state.isLoggedInRole ? "mt-10" : ""
                  }`}
                >
                  <div className="input-group">
                    <select
                      value={this.state.search.student_id}
                      onChange={(e) =>
                        this.handleSearch("student_id", e.target.value)
                      }
                      disabled={!this.state.search.section_id}
                      className="form-control show-tick"
                    >
                      <option value="">Student Name</option>
                      {this.state.dropDownDataStudent.map((ele) => (
                        <option value={ele.id}>{ele.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              {this.state.userType === "Parent" && (
                <div className="col-lg-3 col-md-4 col-sm-6 mt-10">
                  <div className="input-group">
                    <select
                      value={this.state.search.parent_id}
                      onChange={(e) =>
                        this.handleSearch("parent_id", e.target.value)
                      }
                      disabled={!this.state.search.student_id}
                      className="form-control show-tick"
                    >
                      <option value="">Parent Name</option>
                      {this.state.dropDownDataParent.map((ele) => (
                        <option value={ele.id}>{ele.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
            <div className="btn-container">
              <div>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.searchHandler}
                >
                  Search
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.clearHandler}
                >
                  Clear
                </MDBBtn>{" "}
              </div>
              <div>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={() => {
                    this.props.history.push(
                      `/institute/create-user/${this.state.userType}`
                    );
                  }}
                >
                  Create
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={() => {
                    this.setState({ uploadModal: true });
                  }}
                >
                 Bulk Upload
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  className="btn"
                  onClick={this.exportPdf}
                >
                  PDF <MDBIcon icon="download" size="lg" className="icon" />
                </MDBBtn>
                {this.state.exportCsv && (
                  <MDBBtn color="primary">
                    <CSVLink {...this.state.exportCsv}>
                      CSV
                      <MDBIcon icon="download" size="lg" className="icon" />
                    </CSVLink>
                  </MDBBtn>
                )}
              </div>
            </div>
          </div>
        </div>
      </TabPane>
    );
  };

  tableContainer = () => {
    return (
      <TabPane tabId={1} className={classnames(["fade show"])}>
        <div className="card">
          <div className="card-body" id="capture">
            <h1 className="page-title">User List</h1>
            {this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  searching={false}
                  sortRows={["title"]}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                />
              </div>
            )}
          </div>
        </div>
      </TabPane>
    );
  };

  UploadFileContainer = () => {
    return (
      <MDBContainer>
        <MDBModal isOpen={this.state.uploadModal} toggle={this.uploadModal}>
          <MDBModalHeader toggle={this.toggleClose}>
            Upload your files
          </MDBModalHeader>
          <form onSubmit={this.submitHandler} noValidate>
          <MDBModalBody>
          <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                Upload Files
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                            <div className="input-group">
                                                :
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                            <div className="">
                                                <input type="file" className="form-control"
                                                    onChange={this.handleFile} required accept = ".csv"/>
                                                <div className="invalid-feedback">
                                                    required file.
                                                </div>
                                                <div className="">
                                                    {this.state.file ? '' : this.state.url}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
            <small id="fileHelp" className="form-text text-muted dowloadLable">
              <div style={{ color: "red", marginBottom: "10px" }}>
                {this.props.uploadFileStatus
                  ? this.props.uploadFileStatus.msg + " *"
                  : ""}
              </div>
              <MDBBtn color="primary">
                {this.state.sampleCsv && (
                  <CSVLink {...this.state.sampleCsv}>
                    Download Sample Template
                    <MDBIcon icon="download" size="lg" className="icon" />
                  </CSVLink>
                )}
              </MDBBtn>
            </small>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" type='submit'>
              Upload
            </MDBBtn>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              Close
            </MDBBtn>
          </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    );
  };

  modalContainer = () => {
    return (
      <MDBContainer>
        <MDBModal
          isOpen={this.state.viewModal}
          toggle={this.toggle}
          className="view-modal"
        >
          <MDBModalHeader toggle={this.toggleClose}>View Users</MDBModalHeader>
          <MDBModalBody>
            {this.viewModalDetails(this.state.selectedData)}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggleClose}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  };

  pageContainer = () => {
    const { activeTab } = this.state;
    return (
      <TabContent activeTab={activeTab}>
        {this.searchContainer()}
        {this.tableContainer()}
      </TabContent>
    );
  };

  render() {
    return (
      <>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">
                  Manage {this.state.userType} Users
                </h1>
                <MDBBtn
                  color="primary"
                  className="btn  mt-10 mr-10"
                  onClick={(e) => this.handleUserButtonClick("Teacher")}
                >
                  Teacher
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  className="btn mt-10 mr-10"
                  onClick={(e) => this.handleUserButtonClick("Student")}
                >
                  Students
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  className="btn mt-10 mr-10"
                  onClick={(e) => this.handleUserButtonClick("Parent")}
                >
                  Parent
                </MDBBtn>
                <ol className="breadcrumb page-breadcrumb">
                  <li className="breadcrumb-item mt-10">Search</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {this.state.userType ? (
          <div className="section-body mt-4">
            <div className="container-fluid">{this.pageContainer()}</div>
            {this.state.viewModal && this.modalContainer()}
            {this.state.uploadModal && this.UploadFileContainer()}
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userList: state.getUserReducer,
  uploadFileStatus: state.managePrincipalReducer.uploadFileStatus,
});

const mapDispatchToProps = (dispatch) => ({
  searchTeacherUserAction: (e) => dispatch(searchTeacherUserAction(e)),
  searchStudentUserAction: (e) => dispatch(searchStudentUserAction(e)),
  searchParentUserAction: (e) => dispatch(searchParentUserAction(e)),
  uploadTeacherUserAction: (e) => dispatch(uploadTeacherUserAction(e)),
  uploadStudentUserAction: (e) => dispatch(uploadStudentUserAction(e)),
  uploadParentUserAction: (e) => dispatch(uploadParentUserAction(e)),
  getAllSchoolAction: (e) => dispatch(getAllSchoolAction(e)),
  getClassAction: (e) => dispatch(getClassAction(e)),
  getSectionAction: (e) => dispatch(getSectionAction(e)),
  getSubjectAction: (e) => dispatch(getSubjectAction(e)),
  getTeacherAction: (e) => dispatch(getTeacherAction(e)),
  getStudentAction: (e) => dispatch(getStudentAction(e)),
  getParentAction: (e) => dispatch(getParentAction(e)),
  clearUploadPrincipalFileAction: () =>
    dispatch(clearUploadPrincipalFileAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageUser);
