import { GET_TASK } from "../../../../actions/Constants/index";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case  GET_TASK:
      return action.payload;
    default:
      return state;
  }
};
