import React from "react";
import Spinner from "reactstrap/lib/Spinner";

const Loader = () => {
  return (
    <div
      style={{ minHeight: '150px', display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      {" "}
      <Spinner>Loading...</Spinner>
    </div>
  );
};

export default Loader;
