import { PROFILE_LOGIN_IMAGE } from '../../../actions/Constants/index'

const initialState = {
    status : null
}

export default (state = initialState, action) => {
	switch (action.type) {
		case PROFILE_LOGIN_IMAGE:
			return {
				...state,
                status : action.payload.status,
			}
        default:
            return state
    }
}
