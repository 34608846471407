// Authentication
export const SUPER_USER_LOGIN = "SUPER_USER_LOGIN";
export const SUPER_USER_LOGOUT = "SUPER_USER_LOGOUT";
export const SUPER_USER_FORGOT_PASSWORD = "SUPER_USER_FORGOT_PASSWORD";
export const SUPER_USER_CHANGE_PASSWORD = "SUPER_USER_CHANGE_PASSWORD";

// Super Admin
export const STORE_DATA="STORE_DATA";
export const ADD_BOOKMARK="ADD_BOOKMARK"
export const DELETE_BOOKMARK="DELETE_BOOKMARK"
export const UPLOAD_PDF="UPLOAD_PDF"
export const SHOW_PDF_LIST="SHOW_PDF_LIST"
export const DELETE_PDF_LIST_ID="DELETE_PDF_LIST_ID"


// Institute Authentication
export const INSTITUTE_USER_LOGIN = "INSTITUTE_USER_LOGIN";
export const INSTITUTE_USER_LOGOUT = "INSTITUTE_USER_LOGOUT";
export const INSTITUTE_USER_FORGOT_PASSWORD = "INSTITUTE_USER_FORGOT_PASSWORD";
export const INSTITUTE_USER_CHANGE_PASSWORD = "INSTITUTE_USER_CHANGE_PASSWORD";

// Manage institutes
export const GET_INSTITUTES = "GET_INSTITUTES";
export const GET_STATESANDCITIES = "GET_STATESANDCITIES";
export const CREATE_INSTITUTES = "CREATE_INSTITUTES";
export const EDIT_INSTITUTES = "EDIT_INSTITUTES";
export const GET_INSTITUTES_BY_ID = "GET_INSTITUTES_BY_ID";
export const UPLOAD_INSTITUTES_FILE = "UPLOAD_INSTITUTES_FILE";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const GET_PROFILE = "GET_PROFILE";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";



// Manage FAQ
export const GET_FAQ = "GET_FAQ";

//customer support
export const GET_CUSTOMER_SUPPORT = "GET_CUSTOMER_SUPPORT";
export const GET_USER_AND_INSTITUTE = "GET_USER_AND_INSTITUTE";
export const REPLY_BY_ID = "REPLY_BY_ID";
export const SEARCH_CUSTOMER_SUPPORT = "SEARCH_CUSTOMER_SUPPORT";

// News Letter
export const GET_NEWS_LETTER = "GET_NEWS_LETTER";

// Manage receipts
export const GET_RECEIPTS = "GET_RECEIPTS";
export const CREATE_RECEIPTS = "CREATE_RECEIPTS";
export const EDIT_RECEIPTS = "EDIT_RECEIPTS";
export const GET_RECEIPTS_BY_ID = "GET_RECEIPTS_BY_ID";
export const GET_INVOICE_NUM = "GET_INVOICE_NUM";

//manage Profile
export const GET_PROFILE_INFO = "GET_PROFILE_INFO";
export const UPDATE_PROFILE_INFO = "UPDATE_PROFILE_INFO";
export const GET_LOGO_AND_IMAGE = "GET_LOGO_AND_IMAGE";
export const UPDATE_LOGO_AND_IMAGE = "UPDATE_LOGO_AND_IMAGE";
export const PROFILE_LOGIN_IMAGE = "PROFILE_LOGIN_IMAGE";
export const UPDATE_LOGIN_VIDEO = "UPDATE_LOGIN_VIDEO";

//manage Announcement
export const GET_ANNOUNCEMENT = "GET_ANNOUNCEMENT";
export const GET_CLASSDETAIL_TEACHER = "GET_CLASSDETAIL_TEACHER";
//manage Section
export const GET_SECTION = "GET_SECTION";

//manage Class
export const GET_CLASS = "GET_CLASS";

//manage Subject
export const GET_SUBJECT = "GET_SUBJECT";

//Student Achievements
export const GET_ACHEIVEMENTS = "GET_ACHEIVEMENTS";

// maange USer
export const GET_USER = "GET_USER";

//manage Reward
export const GET_REWARD = "GET_REWARD";

//manage CustomerSupport for institute
export const GET_CUSTOMERSUPPORT = "GET_CUSTOMERSUPPORT";

//manage school

export const GET_SCHOOLS = 'GET_SCHOOLS';
export const CREATE_SCHOOLS = 'CREATE_SCHOOLS';
export const EDIT_SCHOOLS = 'EDIT_SCHOOLS';
export const GET_SCHOOLS_BY_ID = 'GET_SCHOOLS_BY_ID';
export const UPLOAD_SCHOOL = 'UPLOAD_SCHOOL';


//manage circular
export const GET_CIRCULAR = 'GET_CIRCULAR';
export const CREATE_CIRCULAR = 'CREATE_CIRCULAR';
export const EDIT_CIRCULAR = 'EDIT_CIRCULAR';
export const GET_CIRCULAR_BY_ID = 'GET_CIRCULAR_BY_ID';
export const SEARCH_CIRCULAR = 'SEARCH_CIRCULAR';
export const DELETE_CIRCULAR = 'DELETE_CIRCULAR';
export const UPLOAD_CIRCULAR = 'UPLOAD_CIRCULAR';


//manage event
export const GET_EVENTS = 'GET_EVENTS';
export const CREATE_EVENTS = 'CREATE_EVENTS';
export const EDIT_EVENTS = 'EDIT_EVENTS';
export const GET_EVENTS_BY_ID = 'GET_EVENTS_BY_ID';
export const UPLOAD_FILE_EVENTS = 'UPLOADFILE_EVENTS';
export const UPLOAD_IMAGE_EVENTS = 'UPLOAD_IMAGE_EVENTS';
export const GET_EVENT_DROPDOWN_DATA = 'GET_EVENT_DROPDOWN_DATA';

export const GET_INSTITUTE_INFORMATION = 'GET_INSTITUTE_INFORMATION';

//gallery
export const GET_GALLERY = 'GET_GALLERY';
export const CREATE_GALLERY = 'CREATE_GALLERY';
export const EDIT_GALLERY = 'EDIT_GALLERY';
export const GET_GALLERY_BY_ID = 'GET_GALLERY_BY_ID';
export const SEARCH_GALLERY = 'SEARCH_GALLERY';
export const UPLOAD_GALLERY = 'UPLOAD_GALLERY';
export const DELETE_GALLERY = 'DELETE_GALLERY';
export const DELETE_ALL_GALLERY = 'DELETE_ALL_GALLERY';
export const GALLERY_DROPDOWN = 'GALLERY_DROPDOWN';

//feedback

export const GET_FEEDBACK = 'GET_FEEDBACK';
export const GET_FEEDBACK_BY_ID = 'GET_FEEDBACK_BY_ID';
export const SEARCH_FEEDBACK = 'SEARCH_FEEDBACK';
export const GET_FEEDBACK_DROPDOWN_DATA = 'GET_FEEDBACK_DROPDOWN_DATA';
export const CREATE_FEEDBACK = 'CREATE_FEEDBACK';


export const GET_KNOWLEDGE_BASE = "GET_KNOWLEDGE_BASE";
export const CREATE_KNOWLEDGE_BASE = "CREATE_KNOWLEDGE_BASE";
export const EDIT_KNOWLEDGE_BASE = "EDIT_KNOWLEDGE_BASE";
export const GET_KNOWLEDGE_BASE_BY_ID = "GET_KNOWLEDGE_BASE_BY_ID";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORY_WITH_COUNT = "GET_CATEGORY_WITH_COUNT";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const DELETE_KNOWLEDGE_BASE = "DELETE_KNOWLEDGE_BASE";


export const GET_ACEDEMICS = 'GET_ACEDEMICS';
export const CREATE_ACEDEMICS = 'CREATE_ACEDEMICS';
export const EDIT_ACEDEMICS = 'EDIT_ACEDEMICS';
export const GET_ACEDEMICS_BY_ID = 'GET_ACEDEMICS_BY_ID';
export const SEARCH_ACEDEMICS = 'SEARCH_ACEDEMICS';
export const DELETE_ACEDEMICS = 'DELETE_ACEDEMICS';
export const UPLOAD_ACEDEMICS = 'UPLOAD_ACEDEMICS';
export const GET_TEACHER = 'GET_TEACHER';


// manage Principal
export const GET_PRINCIPAL = "GET_PRINCIPAL";
export const CREATE_PRINCIPAL = "CREATE_PRINCIPAL";
export const UPDATE_PRINCIPAL = "UPDATE_PRINCIPAL";
export const GET_PRINCIPAL_BY_ID = "GET_PRINCIPAL_BY_ID";
export const UPLOAD_PRINCIPAL_FILE = "UPLOAD_PRINCIPAL_FILE";
export const PRINCIPAL_NAME_LIST = "PRINCIPAL_NAME_LIST";
export const SCHOOL_LIST_FOR_PRINCIPAL = "SCHOOL_LIST_FOR_PRINCIPAL";


//utils 
export const GET_SCHOOL = "GET_SCHOOL";
export const GET_ALL_SCHOOL = "GET_ALL_SCHOOL";
export const GET_STATE = "GET_STATE";
export const GET_CITY = "GET_CITY";
export const GET_GLOBAL_CLASS = "GET_GLOBAL_CLASS";
export const GET_GLOBAL_SECTION = "GET_GLOBAL_SECTION";
export const GET_GLOBAL_SUBJECT = "GET_GLOBAL_SUBJECT";
export const GET_GLOBAL_TEACHER = "GET_GLOBAL_TEACHER";
export const GET_GLOBAL_SUBJECT_NEW = "GET_GLOBAL_SUBJECT_NEW";
export const GET_GLOBAL_ASSIGNMENT = "GET_GLOBAL_ASSIGNMENT";
export const GET_GLOBAL_EXAMINATION = "GET_GLOBAL_EXAMINATION";
export const GET_GLOBAL_SKILL = "GET_GLOBAL_SKILL";
export const GET_SUBJECT_FOR_EXAM = "GET_SUBJECT_FOR_EXAM";

export const GET_STUDENT_USER = "GET_STUDENT_USER";

//manage Study Material  
export const GET_STUDY_MATERIAL = "GET_STUDY_MATERIAL";
export const UPLOAD_STUDY_MATERIAL_FILE = "UPLOAD_STUDY_MATERIAL_FILE";

// manage curriculum PDF
export const GET_CURRICULUM_PDF = "GET_CURRICULUM_PDF";
export const GET_CURRICULUM_PDF_BY_ID =" GET_CURRICULUM_PDF_BY_ID";


//manage Online Meeting  
export const GET_ONLINE_MEETING = "GET_ONLINE_MEETING";

export const GET_SKILL = 'GET_SKILL';
export const GET_STUDENT_SKILL = 'GET_STUDENT_SKILL';
export const CREATE_SKILL = 'CREATE_SKILL';
export const EDIT_SKILL = 'EDIT_SKILL';
export const GET_SKILL_BY_ID = 'GET_SKILL_BY_ID';
export const UPLOAD_SKILL_IMAGE = 'UPLOAD_SKILL_IMAGE';

export const GET_SKILL_RULE = 'GET_SKILL_RULE';
export const CREATE_SKILL_RULE = 'CREATE_SKILL_RULE';
export const EDIT_SKILL_RULE = 'EDIT_SKILL_RULE';
export const GET_SKILL_RULE_BY_ID = 'GET_SKILL_RULE_BY_ID';

export const GET_CERTIFICATE = 'GET_CERTIFICATE';
export const CREATE_CERTIFICATE = 'CREATE_CERTIFICATE';
export const GET_CERTIFICATE_BY_ID = 'GET_CERTIFICATE_BY_ID';
export const GET_PRINCIPAL_DETAIL = 'GET_PRINCIPAL_DETAIL';
export const UPLOAD_CERTIFICATE_LOGO = 'UPLOAD_CERTIFICATE_LOGO';
export const UPLOAD_CERTIFICATE_BADGE = 'UPLOAD_CERTIFICATE_BADGE';
export const UPLOAD_CERTIFICATE_SIGN = 'UPLOAD_CERTIFICATE_SIGN';


export const GET_GOAL = 'GET_GOAL';
export const CREATE_GOAL = 'CREATE_GOAL';
export const EDIT_GOAL = 'EDIT_GOAL';
export const GET_GOAL_BY_ID = 'GET_GOAL_BY_ID';
export const GET_STUDENT_GOAL = 'GET_STUDENT_GOAL';

export const GET_ACHIEVEMENT = 'GET_ACHIEVEMENT';
export const GET_ACHIEVEMENT_BADGE_URL = 'GET_ACHIEVEMENT_BADGE_URL';
export const CREATE_ACHIEVEMENT = 'CREATE_ACHIEVEMENT';
export const EDIT_ACHIEVEMENT = 'EDIT_ACHIEVEMENT';
export const GET_ACHIEVEMENT_BY_ID = 'GET_ACHIEVEMENT_BY_ID';

export const GET_EXAMINATION = 'GET_EXAMINATION';
export const GET_EXAMINATION_FOR_STUDENT = 'GET_EXAMINATION_FOR_STUDENT';
export const GET_EXAMINATION_FOR_PARENT = 'GET_EXAMINATION_FOR_PARENT';
export const CREATE_EXAMINATION = 'CREATE_EXAMINATION';
export const SUBMIT_EXAMINATION = 'SUBMIT_EXAMINATION';
export const EDIT_EXAMINATION = 'EDIT_EXAMINATION';
export const GET_EXAMINATION_BY_ID = 'GET_EXAMINATION_BY_ID';
export const PUBLISH_EXAMINATION = 'PUBLISH_EXAMINATION';
export const DELETE_EXAMINATION = 'DELETE_EXAMINATION';
export const GET_SUBMITTED_EXAM_DETAIL = 'GET_SUBMITTED_EXAM_DETAIL';
export const GET_NOT_SUBMITTED_EXAM_DETAIL = 'GET_NOT_SUBMITTED_EXAM_DETAIL';
export const GET_SUBMITTED_ANSWER_WITH_PENDING_STATUS = 'GET_SUBMITTED_ANSWER_WITH_PENDING_STATUS';
export const GET_SUBMITTED_ANSWER_WITH_EVALUATED_STATUS = 'GET_SUBMITTED_ANSWER_WITH_EVALUATED_STATUS';
export const EVALUATED_MARK_STATUS =  'EVALUATED_MARK_STATUS';

export const UPDATE_QUESTION_LIST = 'UPDATE_QUESTION_LIST';
export const CREATE_QUESTION = 'CREATE_QUESTION';
export const CLEAR_QUESTION = 'CLEAR_QUESTION';

export const GET_ASSIGNMENT = 'GET_ASSIGNMENT';
export const GET_ASSIGNMENT_FOR_STUDENT = 'GET_ASSIGNMENT_FOR_STUDENT';
export const GET_EXPIRED_ASSIGNMENT_FOR_STUDENT = 'GET_EXPIRED_ASSIGNMENT_FOR_STUDENT';
export const GET_ASSIGNMENT_FOR_PARENT = 'GET_ASSIGNMENT_FOR_PARENT';
export const CREATE_ASSIGNMENT = 'CREATE_ASSIGNMENT';
export const SUBMIT_ASSIGNMENT = 'SUBMIT_ASSIGNMENT';
export const EDIT_ASSIGNMENT = 'EDIT_ASSIGNMENT';
export const GET_ASSIGNMENT_BY_ID = 'GET_ASSIGNMENT_BY_ID';
export const PUBLISH_ASSIGNMENT = 'PUBLISH_ASSIGNMENT';
export const DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT';
export const GET_SUBMITTED_ASSIGNMENT_DETAIL = 'GET_SUBMITTED_ASSIGNMENT_DETAIL';
export const GET_NOT_SUBMITTED_ASSIGNMENT_DETAIL = 'GET_NOT_SUBMITTED_ASSIGNMENT_DETAIL';
export const GET_SUBMITTED_ASSIGNMENT_ANSWER_WITH_PENDING_STATUS = 'GET_SUBMITTED_ASSIGNMENT_ANSWER_WITH_PENDING_STATUS';
export const GET_SUBMITTED_ASSIGNMENT_ANSWER_WITH_EVALUATED_STATUS = 'GET_SUBMITTED_ASSIGNMENT_ANSWER_WITH_EVALUATED_STATUS';
export const ASSIGNMENT_EVALUATED_MARK_STATUS =  'ASSIGNMENT_EVALUATED_MARK_STATUS';

// Manage Task
export const GET_TASK = "GET_TASK";

export const CLASS_INFO = "CLASS_INFO";

export const SHOW_SNACKBAR = "SHOW_SNACKBAR";