import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TabContent, TabPane, } from "reactstrap";
import classnames from 'classnames';
import { MDBBtn} from 'mdbreact';
import { isLogined } from '../../../utils/token'
import './style.scss';
import {getPrincipalByIdAction, clearPrincipalByIdAction} from '../../../actions/institute/ManagePrincipal/managePrincipalAction'

class ViewPrincipal extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
            input:{},
            editId: this.props.match.params.id,
		};
	}

    componentDidMount(){
        if(this.props.match.params.id){
            this.props.getPrincipalByIdAction(this.props.match.params.id)
        }
	}

    componentWillReceiveProps(nextProps){
        if(this.state.input !== nextProps.input){
            this.setState({
                input : nextProps.input
            })
        }
	}

    clearData =()=>{
        this.props.clearPrincipalByIdAction()
        this.props.history.push('/institute/manage-principal')
    }

    changeHandler = event => {
        let input = this.state.input
        input[event.target.name] = event.target.value
		this.setState({ input:input });
	};

    inputFieldContainer =()=> {
        const { activeTab } = this.state
        return(
            <TabContent activeTab={activeTab}>
                <TabPane tabId={1} className={classnames(['fade show'])}>
                    <form noValidate>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    School
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    {this.state.input.school_name ? this.state.input.school_name : ""}
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Name
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    {this.state.input['principal_name']}
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Mobile
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    {this.state.input['mobile']}
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Email
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                     {this.state.input['email']}
                                                </div>
                                            </div>
                                             <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Address1
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    {this.state.input['address_one']}
                                                </div>
                                            </div>

                                             <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                Address2
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    {this.state.input['address_two']}
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    State
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                            {this.state.input.state_name ? this.state.input.state_name : ""} 
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    City
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                {this.state.input.city_name ? this.state.input.city_name : ""} 
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Zip
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    {this.state.input['zip']}
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    UserName
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    {this.state.input['username']}
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    Status
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    :
                                                </div>
                                            </div>
                                            <div className="col-lg-4  col-md-4 col-sm-6 inputField">
                                                <div className="input-group">
                                                    {this.state.input['status'] === 1 ? "Active" : "Inactive"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-4 col-sm-6 inputField submitBtn">
                                        <MDBBtn color="primary" className='btn' onClick= {this.clearData}>Close</MDBBtn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </TabPane>
            </TabContent>
        )
    }
    
    render(){
        if(!isLogined()) this.props.history.push('/')
        return(
            <>
                <div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title"> View User</h1>
							</div>
						</div>
					</div>
				</div>
                <div className="section-body mt-4">
					<div className="container-fluid">
                        {this.inputFieldContainer()}
				    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    input :  state.managePrincipalReducer.getById
})

const mapDispatchToProps = dispatch => ({
    getPrincipalByIdAction: (e) => dispatch(getPrincipalByIdAction(e)),
    clearPrincipalByIdAction: (e) => dispatch(clearPrincipalByIdAction(e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewPrincipal)
