

import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
// import './style.scss';
import { connect } from 'react-redux';
import { MDBBtn, MDBFormInline, MDBInput} from 'mdbreact';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { clearCreateSkillAction, clearEditSkillAction, clearSkillByIdAction, clearUploadSkillImageAction, createSkillAction, editSkillAction, getSkillByIdAction, uploadSkillImageAction } from '../../../../actions/institute/Teacherdashboard/ManageSkill/ManageSkillAction';

class CreateSkill extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
            input:{
                skill_image_url:'',
                skill_for:'',
                skill_title:'',
                status:1
            },
            editId: this.props.match.params.id,
            createStatus: null,
            modal: false,

            uploadImageStatus:null,
            file:null,
            imageUrl:null
		};
	}

    componentDidMount(){
        if(this.state.editId){
            this.props.getSkillByIdAction(this.state.editId)
        }
	}


    componentWillReceiveProps(nextProps){
        if(this.props.skillState.detail !== nextProps.skillState.detail){
            let input = {...this.state.input}

            let data = nextProps.skillState.detail
            input.skill_title = data.skill_title
            input.skill_for = data.skill_for
            input.status = data.status
            
            input.skill_image_url = data.skill_image_url
            this.setState({imageUrl:data.skill_image_url})
            this.setState({input:input})

        }
        if(this.state.uploadImageStatus !== (nextProps.skillState && nextProps.skillState.uploadStatus && nextProps.skillState.uploadStatus.file)){
            if(nextProps.skillState.uploadStatus.file){
                this.setState({
                    uploadImageStatus:nextProps.skillState.uploadStatus.file,
                    imageUrl:nextProps.skillState.uploadStatus.file
                },()=>{
                     if(nextProps.skillState && nextProps.skillState.uploadStatus && nextProps.skillState.uploadStatus.file){
                        this.createSkill(nextProps.skillState.uploadStatus.file)
                     }
                })
            }
        }
        if(this.state.editId){
            if(this.state.createStatus !== nextProps.skillState.editStatus){
                this.setState({
                    createStatus: nextProps.skillState.editStatus
                },()=>{
                    if(this.state.createStatus.errcode === 0){
                        this.clearData()
                    }
                })
            }
        }else{
            if(this.state.createStatus !== nextProps.skillState.createStatus){
                this.setState({
                    createStatus: nextProps.skillState.createStatus
                },()=>{
                    if(this.state.createStatus.errcode === 0){
                        this.clearData()
                    }
                })
            }
        }
	}
    
    clearData =()=>{
        this.props.clearEditSkillAction()
        this.props.clearCreateSkillAction()
        this.props.clearSkillByIdAction()
        this.props.clearUploadSkillImageAction()
        this.props.history.push('/teacher/manage-skill')
    }

    modalContainer =()=>{
		return(
			<MDBContainer>
				<MDBModal isOpen={ this.state.modal }>
                    <MDBModalHeader toggle={this.toggleClose}>Alert</MDBModalHeader>
                    <MDBModalBody>
                        <div className='alertContainer'>
                            <h4> Do you want to {this.state.editId ? 'Update' : 'save'} it ? </h4>
                            <h6> Please check the information before submitting</h6>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleClose}>No</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.handleSave}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
			</MDBContainer>
		)
	}
    handleSave=()=>{
        if(this.state.file){
            this.props.uploadSkillImageAction(this.state.file)
        }
        else{
            this.createSkill()
        }
    }
    createSkill=()=>{
        let data = {
            ...this.state.input,
            skill_image_url:this.state.imageUrl
        }
        if(this.state.editId){
            this.props.editSkillAction({id : this.state.editId,
                data:data})
        }else{
            this.props.createSkillAction(data)
        }
    }
    submitHandler = event => {
		event.preventDefault();
		event.target.className += " was-validated";
		if(event.target.checkValidity()) {
            this.setState({
                modal: true,
            })
		}
	}
    changeHandler = event => {
        let input = {...this.state.input}
        input[event.target.name] = event.target.value
		this.setState({ input:input });
	}
    toggleClose = ()=>{
		this.setState({
			modal: false,
		})
	}

    handleFile=(event)=>{
        this.setState({file : event.target.files[0]})
    }
    handleStatus = (event)=>{
        let input = {...this.state.input}
        let value = event.target.value === "1" ? 1 : event.target.value === "0" ? 0 : null
        input[event.target.name] = value
		this.setState({ input:input });
    }
    render(){
        const { activeTab } = this.state
        // if(!isLogined()) this.props.history.push('/')
        return(
            <>
            {this.modalContainer()}
            <div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">{this.state.editId ? 'Update New Skill' : 'Add New Skill'} </h1>
							</div>
						</div>
					</div>
				</div>
                <div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
                                <form  onSubmit={this.submitHandler} noValidate>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Upload Skill Image
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                            <div className="input-group">
                                                                <input type="file" className="form-control"
                                                                    onChange={this.handleFile} required={this.state.imageUrl ? false: true} accept = "image/png, image/gif, image/jpeg"/>
                                                                <div className="invalid-feedback">
                                                                    required file.
                                                                </div>
                                                                <div className="">
                                                                    {this.state.file ? '' : this.state.imageUrl}
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Skill Title
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value = {this.state.input.skill_title} name="skill_title" 
                                                                    onChange={this.changeHandler} placeholder="Address1" required/>
                                                                    <div className="invalid-feedback">
                                                                        required title
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Skill For
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <select className="form-control show-tick" value = {this.state.input['skill_for']} name="skill_for" onChange={this.changeHandler} required>
                                                                        <option value="">Select</option>
                                                                        <option value="Examination">Examination</option>
                                                                        <option value="Assignment">Assignment</option>
                                                                        
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    {this.state.createStatus && this.state.createStatus.errcode !== 0 &&(
                                                        <div className="errorFlag">
                                                            {this.state.createStatus.msg +' *'}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    Status
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    :
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-4 col-sm-6 inputField">
                                                                <div className="input-group">
                                                                    <MDBFormInline>
                                                                        <MDBInput
                                                                            value = {1}
                                                                            checked= {this.state.input['status'] === 1}
                                                                            name ={'status'}
                                                                            onChange =  {this.handleStatus}
                                                                            label='Active'
                                                                            type='radio'
                                                                            id='radio1'
                                                                            containerClass='mr-5'
                                                                        />
                                                                        <MDBInput
                                                                            checked= {this.state.input['status'] ===0}
                                                                            value = {0}
                                                                            name ={'status'}
                                                                            onChange =  {this.handleStatus}
                                                                            label='Inactive'
                                                                            type='radio'
                                                                            id='radio2'
                                                                            containerClass='mr-5'
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                            required Status.
                                                                        </div>
                                                                    </MDBFormInline>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 col-md-4 col-sm-6 inputField submitBtn">
                                                    <MDBBtn color="primary" className='btn' onClick= {()=>{
                                                        this.props.history.push('/teacher/manage-skill')
                                                    }}>Cancel</MDBBtn>
                                                    <MDBBtn color="primary" type={'submit'} className='btn'>{this.state.editId ? 'Update' : 'Save'}</MDBBtn>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            
                                </form>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
	skillState: state.ManageSkillReducer,
})

const mapDispatchToProps = dispatch => ({
	createSkillAction: (e) => dispatch(createSkillAction(e)),
	getSkillByIdAction: (e) => dispatch(getSkillByIdAction(e)),
	editSkillAction: (e) => dispatch(editSkillAction(e)),
	uploadSkillImageAction: (e) => dispatch(uploadSkillImageAction(e)),

	clearEditSkillAction: (e) => dispatch(clearEditSkillAction(e)),
	clearCreateSkillAction: (e) => dispatch(clearCreateSkillAction(e)),
	clearSkillByIdAction: (e) => dispatch(clearSkillByIdAction(e)),
	clearUploadSkillImageAction: (e) => dispatch(clearUploadSkillImageAction(e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateSkill)
