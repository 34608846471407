import { GET_USER, UPLOAD_PRINCIPAL_FILE,SHOW_SNACKBAR } from "../../Constants/index";
import axios from "axios";
import { manageUser } from "../../../utils/Api";
import { getToken } from "../../../utils/token";

//Teacher User Action
export const searchTeacherUserAction = (params) => {
  let params2= localStorage.getItem("school") ?{
    ...params,
    school_id:JSON.parse(localStorage.getItem("school")).id
  }
 : params 
  return (dispatch) => {
    const url = `${manageUser.searchTeacherUser}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params2,
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_USER,
          payload: data.DATA,
        });
        return data.DATA;
      } else {
        dispatch({
          type: GET_USER,
          payload: [],
        });
      }
    });
  };
};

export const createTeacherUserAction = (data,history) => {
  return (dispatch) => {
    const url = `${manageUser.createTeacherUser}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "POST",
        data,
      };
      return axios(options).then((response) => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "success", message: data.SUCESS.status.msg, open: true },
          });
         history.push("/institute/manage-user");
        } else {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "error", message: data.ERROR.status.msg, open: true },
          });
        }
        return data;
      });
  };
};

export const getTeacherUserEditByIdAction = (id) => {
  return (dispatch) => {
    const url = `${manageUser.getEditTeacherUserById(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        return data.DATA;
      }
      return {};
    });
  };
};

export const editTeacherUserAction = ({ id, data,history }) => {
  return (dispatch) => {
    const url = `${manageUser.EditTeacherUserById(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "PATCH",
        data,
      };
      return axios(options).then((response) => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "success", message: data.SUCESS.status.msg, open: true },
          });
         history.push("/institute/manage-user");
        } else {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "error", message: data.ERROR.status.msg, open: true },
          });
        }
        return data;
      });
  };
};

export const uploadTeacherUserAction = (data) => {
  return dispatch => {
  let formData = new FormData();
  formData.append("csv", data);
    const url = `${manageUser.uploadTeacherCSV}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "POST",
        data: formData,
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        data = flag.status;
        dispatch({
          type: UPLOAD_PRINCIPAL_FILE,
          payload: {
            status: data,
          },
        });
      } else {
        data = flag.status;
        dispatch({
          type: UPLOAD_PRINCIPAL_FILE,
          payload: {
            status: data,
          },
        });
      }
    });
  };
};

//Student User Action
export const searchStudentUserAction = (params) => {
  let params2= localStorage.getItem("school") ?{
    ...params,
    school_id:JSON.parse(localStorage.getItem("school")).id
  }
 : params 
  return (dispatch) => {
    const url = `${manageUser.searchStudentUser}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params2,
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_USER,
          payload: data.DATA,
        });
        return data.DATA;
      } else {
        dispatch({
          type: GET_USER,
          payload: [],
        });
      }
    });
  };
};

export const createStudentUserAction = (data,history) => {
  return (dispatch) => {
    const url = `${manageUser.createStudentUser}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "POST",
        data,
      };
      return axios(options).then((response) => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "success", message: data.SUCESS.status.msg, open: true },
          });
         history.push("/institute/manage-user");
        } else {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "error", message: data.ERROR.status.msg, open: true },
          });
        }
        return data;
      });
  };
};

export const getStudentUserEditByIdAction = (id) => {
  return (dispatch) => {
    const url = `${manageUser.getEditStudentUserById(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        return data.DATA;
      }
      return {};
    });
  };
};

export const editStudentUserAction = ({ id, data,history }) => {
  return (dispatch) => {
    const url = `${manageUser.EditStudentUserById(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "PATCH",
        data,
      };
      return axios(options).then((response) => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "success", message: data.SUCESS.status.msg, open: true },
          });
         history.push("/institute/manage-user");
        } else {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "error", message: data.ERROR.status.msg, open: true },
          });
        }
        return data;
      });
  };
};

export const uploadStudentUserAction = (data) => {
  return dispatch => {
  let formData = new FormData();
  formData.append("csv", data);
    const url = `${manageUser.uploadStudentCSV}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "POST",
        data: formData,
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        data = flag.status;
        dispatch({
          type: UPLOAD_PRINCIPAL_FILE,
          payload: {
            status: data,
          },
        });
      } else {
        data = flag.status;
        dispatch({
          type: UPLOAD_PRINCIPAL_FILE,
          payload: {
            status: data,
          },
        });
      }
    });
  };
};

export const studentPasswordUpdateAction = (data) => {
  return () => {
    const url = `${manageUser.studentPasswordUpdate}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "POST",
        data,
      };
    return axios(options);
  };
};

//Parent User Action
export const searchParentUserAction = (params) => {
  let params2= localStorage.getItem("school") ?{
    ...params,
    school_id:JSON.parse(localStorage.getItem("school")).id
  }
 : params 
  return (dispatch) => {
    const url = `${manageUser.searchParentUser}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params2,
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        dispatch({
          type: GET_USER,
          payload: data.DATA,
        });
        return data.DATA;
      } else {
        dispatch({
          type: GET_USER,
          payload: [],
        });
      }
    });
  };
};

export const createParentUserAction = (data,history) => {
  return (dispatch) => {
    const url = `${manageUser.createParentUser}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "POST",
        data,
      };
      return axios(options).then((response) => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "success", message: data.SUCESS.status.msg, open: true },
          });
         history.push("/institute/manage-user");
        } else {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "error", message: data.ERROR.status.msg, open: true },
          });
        }
        return data;
      });
  };
};

export const getParentUserEditByIdAction = (id) => {
  return (dispatch) => {
    const url = `${manageUser.getEditParentUserById(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        return data.DATA;
      }
      return {};
    });
  };
};

export const editParentUserAction = ({ id, data, history}) => {
  return (dispatch) => {
    const url = `${manageUser.EditParentUserById(id)}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "PATCH",
        data,
      };
      return axios(options).then((response) => {
        let data = response && response.data ? response.data : false;
        const flag = data.SUCESS || data.ERROR;
        if (data && flag.status.errcode === 0) {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "success", message: data.SUCESS.status.msg, open: true },
          });
         history.push("/institute/manage-user");
        } else {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { type: "error", message: data.ERROR.status.msg, open: true },
          });
        }
        return data;
      });
  };
};

export const getStudentNoParentAction = (params) => {
  return (dispatch) => {
    const url = `${manageUser.getAllStudentNoParent}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "GET",
        params: params,
      };
    return axios(options).then((response) => {
      let data = response?.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        return data.DATA;
      }
    });
  };
};

export const uploadParentUserAction = (data) => {
  return dispatch => {
    let formData = new FormData();
    formData.append("csv", data);
    const url = `${manageUser.uploadParentCSV}`,
      options = {
        url,
        headers: {
          Authorization: getToken(),
        },
        method: "POST",
        data: formData,
      };
    return axios(options).then((response) => {
      let data = response && response.data ? response.data : false;
      const flag = data.SUCESS || data.ERROR;
      if (data && flag.status.errcode === 0) {
        data = flag.status;
        dispatch({
          type: UPLOAD_PRINCIPAL_FILE,
          payload: {
            status: data,
          },
        });
      } else {
        data = flag.status;
        dispatch({
          type: UPLOAD_PRINCIPAL_FILE,
          payload: {
            status: data,
          },
        });
      }
    });
  };
};
