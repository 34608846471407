import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { MDBDataTable, MDBBtn, MDBIcon,MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import './style.scss';
import Loader from "../../../common/Loader";
import moment from 'moment'
import DatePicker from "react-datepicker";
import {  getSubjectAction } from '../../../../actions/institute/Utils/utilsAction';
import { clearGetCertificateAction, getCertificateAction } from '../../../../actions/institute/Teacherdashboard/ManageCertificate/manageCertificateAction';
import ViewCertificate from './ViewCertificate';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

class CertificateList extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			show: false,
			tableBody : { columns :[], rows: []},

			certificate_type:'',
			subject_id:'',

			role:this.props.role,

            subjectList:[],
            certificates:[],
			utills:{},
			modal:false,
			selectedCertificate:null
		};
	}

	componentWillReceiveProps(nextProps){
	
		if(this.state.certificates !== nextProps.certificateState.list){
			this.setState({
				certificates: nextProps.certificateState.list
			},()=>{
				this.constructTableData()
			})
		}
		if(this.state.subjectList !== nextProps.utills.subjectList){
            this.setState({
                subjectList: nextProps.utills.subjectList
            })
        }
		
	}
	componentDidMount(){

        let search = {
            section_id: JSON.parse(localStorage.getItem('section')).id
        }
        this.props.getSubjectAction(search)
		this.props.getCertificateAction()
	}
	constructTableData =()=>{
		let col= [
			{
				label: '#',
				field: 'id',
			},
			{
				label: 'Subject',
				field: 'subject_name',
				width: 270,
			},
			{
				label: 'Certification Type',
				field: 'certificate_type',
				width: 270,
			},
            {
				label: 'Examination',
				field: 'certification_for',
				width: 270,
			},
            {
				label: 'Certification Issue Date',
				field: 'created_at',
				width: 270,
			},
            {
				label: 'Action',
				field: 'action',
				width: 270,
			}
		]
		let tableData = {
			columns: col,
			rows: 
				this.state.certificates.map((item,i)=>{
					let obj = {
						id: i+1,
						certificate_type: item.certificate_type ? item.certificate_type : '-',
						subject_name: item.subject_name ? item.subject_name : '-' ,
						created_at: moment(item.created_at).format("YYYY-MM-DD") ,
						certification_for:item.certification_for ? item.certification_for : '-',
						action:
						<div>
                            <MDBBtn color="primary" className='btn' onClick={()=>{ this.openModal(item)}}>View Certificate</MDBBtn>
						</div>
						
					}
					return obj
				})
		}	
		this.setState({
			tableBody: tableData
		})	
	}
	handleChange=(e)=>{
		this.setState({ [e.target.name] : e.target.value})
	}
	openModal=(item)=>{
		this.setState({
			selectedCertificate:item,
			modal:true
		})
	}
	exportPdf = () => {
		html2canvas(document.querySelector("#capture")).then((canvas) => {
		  const imgData = canvas.toDataURL("image/png");
		  const pdf = new jsPDF();
		  pdf.addImage(imgData, "PNG", 0, 0, 200, 0);
		  pdf.save("Certificate.pdf");
		}).catch((e)=>{
		});
	  };
	componentWillUnmount(){
		this.props.clearGetCertificateAction()
	}
	toggleClose=()=>{
		this.setState({modal:false})
	}
	modalContainer = () => {
		return (
		  <div>
			<MDBModal isOpen={this.state.modal} className="viewPDF-modal">
			  <MDBModalHeader toggle={this.toggleClose}>View Certificate</MDBModalHeader>
			  <MDBModalBody>
				<div id="capture">
					<ViewCertificate selectedCertificate={ this.state.selectedCertificate }/>
				</div>
			  </MDBModalBody>
			  <MDBModalFooter>
				<MDBBtn color="secondary" onClick={this.exportPdf}>
				  Download
				</MDBBtn>
				<MDBBtn color="secondary" onClick={this.toggleClose}>
				  Close
				</MDBBtn>
			  </MDBModalFooter>
			</MDBModal>
		  </div>
		);
	  };

	handleSearch=()=>{
		let search = {
			subject_id:this.state.subject_id,
			certificate_type:this.state.certificate_type,
		}
		Object.keys(search).forEach((key) => (search[key] == '') && delete search[key]);
		this.props.getCertificateAction(search)
	}

	clearSearch=()=>{
		this.setState({
            subject_id:'',
            certificate_type:'',
		})
		this.props.getCertificateAction()
	}

	render() {
		const { activeTab } = this.state
		// if(!isLogined()) this.props.history.push('/')
		return (
			<>
			{this.state.modal && this.modalContainer()}
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center ">
							<div className="header-action">
								<h1 className="page-title">Manage Certificates</h1>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-4">
					<div className="container-fluid">
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-4 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.certificate_type} 
												onChange={ this.handleChange } name="certificate_type">
                                                    <option value="">Select Certification Type</option>
                                                    <option value="Assignment">Assignment</option>
                                                    <option value="Examination">Examination</option>
													
                                                </select>
                                            </div>
											<div className="col-lg-4 col-md-2 col-sm-12">
                                                <select className="form-control show-tick" value={ this.state.subject_id} 
												onChange={ this.handleChange } name="subject_id" 
												>
                                                    <option value="">Select Subject</option>
                                                    {this.state.subjectList && this.state.subjectList.map((item)=>{
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
											</div>
											<div className="btn-container">
											<div>
												<MDBBtn color="primary" className='btn' onClick={this.handleSearch}>Search</MDBBtn>
												<MDBBtn color="primary" className='btn' onClick={this.clearSearch}>Clear</MDBBtn>
											</div>
										</div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1} className={classnames(['fade show'])}>
								<div className="card">
									<div className="card-body">
									<h1 className="page-title">Certificate List</h1>
									{this.state.tableBody.columns.length === 0 ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <MDBDataTable
                  noBottomColumns
                  className="table table-hover js-basic-example dataTable table-striped table_custom border-style spacing5 no-footer"
                  entriesOptions={[5, 10, 20, 25]}
                  entries={5}
                  paging
                  pagesAmount={15}
                  data={this.state.tableBody}
                  searchTop
                  sortRows={['title']}
                  searchBottom={false}
                  onPageChange={{ activePage: 2, pagesAmount: 5 }}
                  disableRetreatAfterSorting={true}
                  searching = {false}
                />
              </div>
            )}
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	certificateState: state.ManageCertificateReducer,
	role: state.settings.role,
	utills: state.utillReducer

})

const mapDispatchToProps = dispatch => ({
	getCertificateAction: (e) => dispatch(getCertificateAction(e)),
	clearGetCertificateAction: (e) => dispatch(clearGetCertificateAction(e)),
	getSubjectAction: (e) => dispatch(getSubjectAction(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(CertificateList)
