import { GET_ASSIGNMENT, 
    CREATE_ASSIGNMENT,
    EDIT_ASSIGNMENT, 
    GET_ASSIGNMENT_BY_ID,
    PUBLISH_ASSIGNMENT,
    DELETE_ASSIGNMENT,
    GET_SUBMITTED_ASSIGNMENT_DETAIL,
    GET_NOT_SUBMITTED_ASSIGNMENT_DETAIL,
    GET_SUBMITTED_ASSIGNMENT_ANSWER_WITH_PENDING_STATUS,
    GET_SUBMITTED_ASSIGNMENT_ANSWER_WITH_EVALUATED_STATUS,
    ASSIGNMENT_EVALUATED_MARK_STATUS,
    GET_ASSIGNMENT_FOR_STUDENT,GET_EXPIRED_ASSIGNMENT_FOR_STUDENT,
    GET_ASSIGNMENT_FOR_PARENT,
    SUBMIT_ASSIGNMENT } from '../../../../actions/Constants/index'

const initialState = {
    list : [],
    studentList : [],
    parentList : [],
    expiredStudentList : [],
    editStatus:null,
    createStatus:null,
    submitStatus:null,
    detail:null,
    publishStatus:null,
    deleteStatus:null,
    submittedExamList:[],
    notSubmittedExamList:[],
    submittedAnswersWithPendingStatus:null,
    submittedAnswersWithEvaluatedStatus:null,
    evaluatedMarkStatus:null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_ASSIGNMENT:
        return {
            ...state,
            list : action.payload.list,
        }
        case EDIT_ASSIGNMENT:
        return {
            ...state,
            editStatus : action.payload.list,
        }
        case CREATE_ASSIGNMENT:
        return {
            ...state,
            createStatus : action.payload.list,
        }
        case PUBLISH_ASSIGNMENT:
        return {
            ...state,
            publishStatus : action.payload.list,
        }
        case GET_ASSIGNMENT_BY_ID:
        return {
            ...state,
            detail : action.payload.list,
        }
        case DELETE_ASSIGNMENT:
            return {
                ...state,
                deleteStatus : action.payload.status,
            }
        case GET_SUBMITTED_ASSIGNMENT_DETAIL:
            return {
                ...state,
                submittedExamList : action.payload.list,
            }
        case GET_NOT_SUBMITTED_ASSIGNMENT_DETAIL:
            return {
                ...state,
                notSubmittedExamList : action.payload.list,
            }
        case GET_SUBMITTED_ASSIGNMENT_ANSWER_WITH_PENDING_STATUS:
            return {
                ...state,
                submittedAnswersWithPendingStatus : action.payload.list,
            }
        case ASSIGNMENT_EVALUATED_MARK_STATUS:
            return {
                ...state,
                evaluatedMarkStatus : action.payload.list,
            }
        case GET_SUBMITTED_ASSIGNMENT_ANSWER_WITH_EVALUATED_STATUS:
        return {
            ...state,
            submittedAnswersWithEvaluatedStatus : action.payload.list,
        }
        case GET_ASSIGNMENT_FOR_STUDENT:
            return {
                ...state,
                studentList : action.payload.list,
            }
        case GET_EXPIRED_ASSIGNMENT_FOR_STUDENT : 
        return {
            ...state,
            expiredStudentList:action.payload.list
        }
        case  GET_ASSIGNMENT_FOR_PARENT: 
        return {
            ...state,
            parentList:action.payload.list
        }
        case SUBMIT_ASSIGNMENT:
        return {
            ...state,
            submitStatus : action.payload.list,
        }
        
        default:
            return state
    }
}
