import { GET_USER_AND_INSTITUTE } from '../../../actions/Constants/index'

const initialState = {
    list : {
        username:[],
        institute:[]
    }
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_USER_AND_INSTITUTE:
			return {
				...state,
                list : action.payload.list,
			}
        default:
            return state
    }
}
