import { CLASS_INFO } from "../../../actions/Constants/index";


const initialState = {
    detail:null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case CLASS_INFO:
        return {
            ...state,
            detail : action.payload.list,
        }
        default:
            return state
    }
}

