import { GET_NEWS_LETTER } from "../../../actions/Constants/index";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NEWS_LETTER:
      return action.payload;
    default:
      return state;
  }
};
