import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import "./style.scss";
import { getInstituteInformationAction } from "../../../actions/institute/Information/information";

class InstituteInformation extends Component {
  componentDidMount() { 
    this.props.getInstituteInformationAction();
  }

  render() {
    return (
      <>
        <div class="container-institute">
          <div className="box-institute">
            <h1 className="mt-10">Institute Information</h1>
            <hr />
            <div className="row col-lg-12 col-md-4 col-sm-6 mt-4">
              <h6 className="col-lg-4 col-md-4 col-sm-6">Institute Name</h6>
              <div className="col-lg-1 col-md-4 col-sm-6"> :</div>
              <h5 className="col-lg-7 col-md-4 col-sm-6">
                {this.props.info.institute_name}
              </h5>
            </div>
            <div className="row col-lg-12 col-md-4 col-sm-6 mt-4">
              <h6 className="col-lg-4 col-md-4 col-sm-6">License Period</h6>
              <div className="col-lg-1 col-md-4 col-sm-6"> :</div>
              <h5 className="col-lg-7 col-md-4 col-sm-6">
                {this.props.info.license_period}
              </h5>
            </div>
            <div className="row col-lg-12 col-md-4 col-sm-6 mt-4">
              <h6 className="col-lg-4 col-md-4 col-sm-6">
                License Expiration Date
              </h6>
              <div className="col-lg-1 col-md-4 col-sm-6"> :</div>
              <h5 className="col-lg-7 col-md-4 col-sm-6">
                {moment(this.props.info.license_expiry).format("YYYY-MM-DD")}
              </h5>
            </div>
            <div className="row col-lg-12 col-md-4 col-sm-6 mt-4">
              <h6 className="col-lg-4 col-md-4 col-sm-6">Number of School</h6>
              <div className="col-lg-1 col-md-4 col-sm-6"> :</div>
              <h5 className="col-lg-7 col-md-4 col-sm-6">
                {this.props.info.number_of_schools}
              </h5>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  info: state.getInformation.list,
});

const mapDispatchToProps = (dispatch) => ({
  getInstituteInformationAction: (e) =>
    dispatch(getInstituteInformationAction(e)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstituteInformation);
